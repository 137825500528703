import react from 'react';
import AppModel from 'models/app';

class Location extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.name = props.name;
		this.address = props.address;
		this.city = props.city;
		this.state = props.state;
		this.country = props.country;
		this.postalCode = props.postalCode;
		this.latitude = props.latitude;
		this.longitude = props.longitude;

		this.locationableId = props.locationableId;
		this.locationableType = props.locationableType;


		// BelongsTo
		this.booking = this.belongsTo('booking', props.booking);
		this.user = this.belongsTo('user', props.user);
		this.provider = this.belongsTo('provider', props.props);
	}


	// Computed
	get summary() {
		return this.address ? `${this.address}, ${this.city}, ${this.state} ${this.postalCode}`: '';
	}

	get cityState() {
		return this.city ? `${this.city}, ${this.state}` : '';
	}

	get cityStatePostal() {
		return this.city ? `${this.city}, ${this.state} ${this.postalCode}` : '';
	}
}

export default Location;
