import React, { Component } from 'react';
import DetailCard from 'components/cards/detail-card';
import LineCard from 'components/cards/line-card';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const CustomerLineCard = (props) => {
  const { customer = {}, loading, onClick, className = '' } = props;

  return (
    <LineCard
      status={customer.status}
      photo={customer.photo}
      className={className}>
      <div className='card-body'>
        <h5><b>{customer.name}</b></h5>
        <h6>{customer.email}</h6>
      </div>
    </LineCard>
  )
}

const CustomerPreviewCard = (props) => {
  const { customer = {}, loading, onClick, className = '' } = props;

  return (
    <PreviewCard
      photo={customer.photo}
      title={customer.name}
      line1={customer.email}
      line2={customer.displayPhone}
      className={className}
    />
  )
}

const CustomerListCard = (props) => {
  const { customer = {}, loading, onClick, className = '' } = props;

  return (
    <ListCard
      photo={customer.photo}
      title={customer.name}
      line1={customer.email}
      line2={customer.displayPhone}
      link={customer && customer.url()}
      className={className}
    />
  )
}

const CustomerDetailCard = (props) => {
  const { customer = {}, loading, onClick, className = '' } = props;

  return (
    <DetailCard
      photo={customer.photo}
      title={customer.name}
      line1={customer.email}
      line2={customer.displayPhone}
      line3={customer.location && customer.location.cityState}
      timestamp={customer.displayUpdatedAt}
      className={className}
    />
  )
}

export { CustomerLineCard, CustomerPreviewCard, CustomerListCard, CustomerDetailCard };
