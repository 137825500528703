import React from 'react';
import InfoCard from 'components/cards/info-card';
import PreviewCard from 'components/cards/preview-card';
import ListCard from 'components/cards/list-card';
import DetailCard from 'components/cards/detail-card';
import FAWrapper from 'components/vendors/fa-wrapper';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';
import { Badge } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const BookingSummaryCard = (props) => {
  const { booking = {}, loading, onClick, className = '' } = props;

  return (
    <InfoCard className={className}>
      <GoogleMapWrapper model={booking.location} className='xs mb-15' />
      <Badge color={booking.status} className='sm mb-2' pill>{booking.status}</Badge>
      <h6><FAWrapper icon='user' className='mr-15' />{booking.user ? booking.user.name : 'n/a'}</h6>
      <h6><FAWrapper icon='calendar' className='mr-15' />{booking.displayDateTime ? booking.displayDateTime + (booking?.timezoneAbbreviation ? ` ${booking?.timezoneAbbreviation}` : '') : 'n/a'}</h6>
      <h6><FAWrapper icon='clock' className='mr-15' />{booking.previewDuration ? booking.previewDuration + ' min appointment' : 'n/a'}</h6>
      <h6><FAWrapper icon='map-marker' className='mr-15' />{!isEmpty(booking.location) ? booking.location.summary : 'n/a'}</h6>
    </InfoCard>
  )
}

const BookingInfoCard = (props) => {
  const { booking = {}, loading, onClick, className = '' } = props;

  return (
    <InfoCard className={className}>
      <Badge color={booking.status} className='sm mb-2' pill>{booking.status}</Badge>
      <h6><FAWrapper icon='calendar' className='mr-15' />{booking.displayDateTime ? booking.displayDateTime : 'n/a'}</h6>
      <h6><FAWrapper icon='clock' className='mr-15' />{booking.previewDuration ? booking.previewDuration + ' min appointment' : 'n/a'}</h6>
      <h6><FAWrapper icon='map-marker' className='mr-15' />{!isEmpty(booking.location) ? booking.location.summary : 'n/a'}</h6>
    </InfoCard>
  )
}


const BookingListCard = (props) => {
  const { booking = {}, provider, loading, onClick, className = '' } = props;

  return (
    <ListCard
      status={booking.status}
      photo={booking.photo}
      title={booking?.totalServiceCount + `x Services`}
      line1={booking.displayDateTime}
      line2={booking.location ? booking.location.summary : '--'}
      link={booking.url}
      className={className}
    />
  )
}


const BookingPreviewCard = (props) => {
  const { booking = {}, loading, onClick, className = '' } = props;

  return (
    <PreviewCard
      status={booking.status}
      photo={booking.photo}
      title={booking.summary}
      line1={booking.displayDateTime}
      line2={booking.location ? booking.location.summary : '--'}
      className={className}
    />
  )
}


const BookingDetailCard = (props) => {
  const { booking = {}, loading, onClick, className = '', selectedTimezone = '' } = props;

  return (
    <DetailCard
      html={booking.location && <GoogleMapWrapper model={booking.location} className='md' />}
      status={booking.status}
      title={booking.summary}
      line1={booking.displayDateTime + " " + selectedTimezone}
      line2={booking.location ? booking.location.summary : '--'}
      line3={booking.card ? booking.card.summary : '--'}
      timestamp={booking.displayUpdatedAt}
    />
  )
}

export { BookingSummaryCard, BookingInfoCard, BookingListCard, BookingPreviewCard, BookingDetailCard };
