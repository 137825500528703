import React, { useEffect, useState } from 'react';
import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

import SanitizedHTML from 'react-sanitized-html';
import { timeout } from 'utils/helpers';

const ToastWrapper = (props) => {
  const { type, code, title, message} = props;
  const [ active, setActive ] = useState(false);


  // Hooks
  useEffect(() => {
    showToast();
  }, [])


  // Methods
  const showToast = async () => {
    try {
      setActive(true);
      await timeout(2000);
    } catch(e) {
      console.log('Toast: ', e);
    } finally {
      setActive(false);
    }
  }


  // Render
  return (
    <div className='toast-wrapper'>
      <Toast isOpen={active} className={active ? `${type} animated fadeInUp` : `${type} animated fadeOutDown`}>
        <div className='toast-header flex-between'>
          {type == 'success' && (
            <div className='flex-start'>
              <FAWrapper icon='check-circle' className='success-color mr-15'/>
              <span>{code}&nbsp;{title}</span>
            </div>
          )}
          {type == 'info' && (
            <div className='flex-start'>
              <FAWrapper icon='check-circle' className='warning-color mr-15'/>
              <span>{code}&nbsp;{title}</span>
            </div>
          )}
          {type == 'error' && (
            <div className='flex-start'>
              <FAWrapper icon='times-circle' className='danger-color mr-15'/>
              <span>{code}&nbsp;{title}</span>
            </div>
          )}
          <Button color='text-gray' size='xs' onClick={() => setActive(!active)}><FAWrapper icon='times'/></Button>
        </div>
        {message && (
          <ToastBody>
            <SanitizedHTML className='mkt-detail-content' html={message} />
          </ToastBody>
        )}
      </Toast>
    </div>
  )
}


export default ToastWrapper;

// Docs
