import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import BankForm from 'components/bank/bank-form';
import BankList from 'components/bank/bank-list';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { Breadcrumb, BreadcrumbItem, Button, Container, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BankSection = (props) => {
  const { provider, store } = props;
  const [ banks, setBanks ] = useState([]);
  const [ modal, toggleModal ] = useState(false);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let banks = await store.query('bank', params());
      setBanks(banks);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    return params;
  }

  const refreshData = () => {
    toggleModal(false);
    fetchData();
  }


  // Render
  return (
    <Fragment>
      <Modal isOpen={modal}>
        <BankForm provider={provider} toggleModal={() => toggleModal(!modal)} nextAction={() => refreshData()}/>
      </Modal>
      <SectionBlock>
        <SectionTitle title='Banking' className='flex-between'>
          <NextButton title='Add Bank' onClick={() => toggleModal(!modal)} />
        </SectionTitle>
        <SectionBody className='md'>
          <BankList
            banks={banks}
            loading={loading}
            nextAction={() => fetchData()}
          />
        </SectionBody>
        <SectionFooter>
          <PaginationWrapper meta={banks.meta} setPage={v => setPage(v)} />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  );
}


export default withStore(withToast(BankSection));
