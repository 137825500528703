import React from 'react';
import Empty from 'components/misc/empty';
import { ChargePreviewCard, ChargeSelectCard } from 'components/charge/charge-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ChargeList = (props) => {
  const { type = 'preview', charges = [], selectedID, onClick, loading, className = '' } = props;


  if (loading) {
    return <LoadingSkeleton type={type}/>

  }
  if (charges.length > 0) {
    return (
      <Row>
        {type == 'preview' && charges.map((charge, index) => (
          <Col md='12' key={charge.id}>
            <ChargePreviewCard
              type={type}
              charge={charge}
            />
          </Col>
        ))}
        {type == 'select' && charges.map((charge, index) => (
          <Col md='12' key={charge.id}>
            <ChargeSelectCard
              type={type}
              charge={charge}
              selectedID={selectedID}
              onClick={onClick}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='money-bill-wave'
      title='Charges'
      msg="Sorry, it looks like we don't have any charges on file yet."
    />
  )
}

export default ChargeList;
