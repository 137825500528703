import React, { useEffect, useState } from 'react';
import Mixpanel from 'mixpanel-browser';
import { isEmpty, logger, timeout } from 'utils/helpers';

const MixpanelContext = React.createContext(null);

const MixpanelProvider = ({ children }) => {
  const [ loaded, setLoaded ] = useState(false);


  // Hooks
  useEffect(() => {
    if (typeof window !== 'undefined') init();
  }, [])

  
  // Private Methods
  const init = () => {
    Mixpanel.init(window.env.REACT_APP_MIXPANEL_ID);
    setLoaded(true);
  }

  
  // Public Methods  
  const trackEvent = (eventName, data = {}) => {
    return Mixpanel.track(eventName, { data });
  }

  const trackView = (url) => {
    return Mixpanel.track('pageView', { url }); 
  }

  const trackUser = (user) => {
    // Mixpanel.identify({ unique_id: user.id });
    Mixpanel.people.set({
      id: user.id,
      photo: user.photo,
      name: user.name,
      email: user.email
    });
  }




  // Render
  return (
    <MixpanelContext.Provider value={{ trackEvent, trackView, trackUser, loaded }}>
      {children}
    </MixpanelContext.Provider>
  )
}


const withMixpanel = function(WrappedComponent) {
  return (props) => (
    <MixpanelContext.Consumer>
      {context => <WrappedComponent mixpanel={context || {}} {...props} />}
    </MixpanelContext.Consumer>
  )
}

export { MixpanelProvider, withMixpanel }

// Docs
// https://www.npmjs.com/package/mixpanel-browser
