import AppAdapter from 'adapters/app';

class AnalyticsAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';


	// Overrides
	static urlForQueryRecord(modelName, id) {
		return this.buildURL() + `/${modelName}`;
	}



}

export default AnalyticsAdapter;