import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import BankSection from 'components/bank/bank-section';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminSettingsBankingRoute = (props) => {
  const { providerId, history, provider } = props;


  return (
    <AdminRoute title='Blush Admin - Banking'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Banks</BreadcrumbItem>
      </Breadcrumb>

      <BankSection type='grid' provider={provider}/>
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsBankingRoute);

