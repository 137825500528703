import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import LineCard from 'components/cards/line-card';
import ListCard from 'components/cards/list-card';
import MktCard from 'components/cards/mkt-card';
import PreviewCard from 'components/cards/preview-card';
import SelectCard from 'components/cards/select-card';
import ProviderPreview from 'components/provider/provider-preview';
import StarRatingWrapper from 'components/vendors/star-rating-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { TextButton } from 'components/bootstrap/button-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Container, Row, Col, Modal, Badge, Button } from 'reactstrap';
import { isEmpty, truncate } from 'utils/helpers';


const ProviderLineCard = (props) => {
  const { provider = {}, loading, onClick, className = '' } = props;

  // Render
	return (
		<LineCard
			status={provider.status}
			photo={provider.photo}
			title={provider.name}
			line1={<StarRatingWrapper rating={provider.avgScore}/>}
			onClick={() => onClick(provider.id)}
			className={className}
		/>
	)
}

const ProviderPreviewCard = (props) => {
  const { provider = {}, loading, onClick, className = '' } = props;

	return (
		<PreviewCard
			status={provider.status}
			photo={provider.photo}
			title={provider.name}
			// line1={provider.email}
			line2={<StarRatingWrapper rating={provider.avgScore}/>}
			className={className}
		/>
	)
}

const ProviderListCard = (props) => {
  const { provider = {}, selectedID, loading, onClick, className = '' } = props;

	return (
		<ListCard
			status={provider.status}
			photo={provider.photo}
			title={provider.name}
			line1={<StarRatingWrapper rating={provider.avgScore}/>}
			line2={provider.previewDescription}
			link={provider.url}
			className={className}>
			<div className='mt-2 flex-start'>
				{provider.photos.map((photo, index) => (
					<img
						key={photo.id}
						src={photo.photo}
						alt={photo.title}
						className='thumbnail sm mr-3'
					/>
				))}
			</div>
		</ListCard>
	)
}

const ProviderMktCard = (props) => {
  const { provider = {}, loading, onClick, className = '' } = props;
  const [ modal, toggleModal ] = useState(false);

	return (
		<Fragment>
			<Modal isOpen={modal} toggle={() => toggleModal(!modal)} className='sm'>
	      <ProviderPreview
	        model={provider}
	        toggleModal={() => toggleModal(!modal)}>
	        <Link to='/book' className='btn btn-primary btn-lg btn-block'>Book Now<FAWrapper icon='chevron-right' className='ml-2'/></Link>
        </ProviderPreview>
	    </Modal>
			<MktCard
				photo={provider.photo}
				title={provider.name}
				line1={provider.location && provider.location.cityState}
				line2={<StarRatingWrapper rating={provider.avgScore}/>}
				className={className}>
				<TextButton title='Learn More' endIcon='chevron-right' onClick={() => toggleModal(!modal)} />
			</MktCard>
		</Fragment>
	)
}

const ProviderSelectCard = (props) => {
  const { provider = {}, selectedID, loading, onClick, className = '' } = props;
  const [ modal, toggleModal ] = useState(false);
  const selected = selectedID == provider.id;

	return (
		<Fragment>
			<Modal isOpen={modal} toggle={() => toggleModal(!modal)} className='sm'>
	      <ProviderPreview
	        model={provider}
	        selected={selected}
	        onSelect={() => onClick(provider.id)}
	        toggleModal={() => toggleModal(!modal)}>
        </ProviderPreview>
        <Link to='/book' title='Learn More' endIcon='chevron-right' onClick={() => toggleModal(!modal)} />
	    </Modal>
			<SelectCard
				status={provider.status}
				photo={provider.photo}
				title={provider.name}
				line1={<div className='flex-start'><StarRatingWrapper rating={provider.avgScore}/><span className='ml-2 gray-color'>{provider.totalReviews} Reviews</span></div>}
				line2={provider.previewDescription}
				selected={selected}
				onSelect={() => onClick(provider.id)}
				className={className}>
        <TextButton title='Learn More' endIcon='chevron-right' onClick={() => toggleModal(!modal)} />
			</SelectCard>
		</Fragment>
	)
}

const ProviderDetailCard = (props) => {
  const { provider = {}, selectedID, loading, onClick, className = '' } = props;

	return (
		<DetailCard
			status={provider.status}
			photo={provider.photo}
			title={provider.name}
			line1={provider.email}
			line2={provider.displayPhone}
			className={className}
		/>
	)
}


export { ProviderLineCard, ProviderListCard, ProviderPreviewCard, ProviderMktCard, ProviderSelectCard, ProviderDetailCard };
