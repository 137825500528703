import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Moment from "react-moment";
import Empty from "components/misc/empty";
import { AppendFormControl } from "components/bootstrap/input-group-wrapper";
import {
  DropdownWrapper,
  DropdownMenuWrapper,
} from "components/bootstrap/dropdowns";
import {
  DropdownToggle,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { getTimezoneFromLatLong } from "utils/common/commonFunctions";

const TimePickerInput = (props) => {
  const {
    type = "dropdown",
    date = moment(),
    disabled,
    setTime,
    className = "",
    booking = null
  } = props;
  const [dropdown, toggleDropdown] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");

  useEffect(() => {
    if (type !== 'dropdown') {
      getSelectedTimezone();
    }
  }, []);

  useEffect(() => {
    if (type == "dropdown" && booking) {
      getSelectedTimezone();
    }
  }, [booking]);

  // Hooks
  useEffect(() => {
    buildTimeline();
  }, [date.date()]);

  // Methods
  //   const buildTimeline = () => {
  //   	let timeline = [];
  //   	let current = moment();
  //   	let start = moment(date).startOf('day').add('8', 'h');
  //   	let end = moment(date).endOf('day');
  //   	let computedStart = current > start && current < end ? current.startOf('hour').add('2', 'h') : start;
  //   	let computedEnd = moment(date).startOf('day').add('18', 'h');
  //   	while (computedStart <= computedEnd) {
  //   		timeline.push(moment(computedStart));
  //   		computedStart = moment(computedStart).add('30', 'm');
  //   	}
  //   	setTimeline(timeline);
  //   }
  const getSelectedTimezone = async () => {
    const timezoneAbbreviation = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude);
    setSelectedTimezone(timezoneAbbreviation);
  }

  const buildTimeline = () => {
    let timeline = [];
    let current = moment();
    let start = moment(date).startOf("day").add("7", "h");
    let end = moment(date).endOf("day");
    let computedStart = start; //current > start && current < end ? current.startOf('hour').add('2', 'h') : start;
    let computedEnd = moment(date).startOf("day").add("19", "h");
    while (computedStart <= computedEnd) {
      timeline.push(moment(computedStart));
      computedStart = moment(computedStart).add("30", "m");
    }
    setTimeline(timeline);
  };

  const buildTime = (date) => {
    let formattedDate = moment(date).format();
    let hours = date.hours();
    let minutes = date.minutes();
    let newDate = moment(formattedDate)
      .hours(hours)
      .minutes(minutes)
      .seconds(0);
    setTime(newDate);

    toggleDropdown(!dropdown);
  };

  const formattedTime = () => {
    return date ? date.format("hh:mma") : null;
  };

  const selectedTime = (date, time) => {
    return date.format("hh:mma") == time.format("hh:mma") ? true : false;
  };

  // Render
  if (type == "dropdown") {
    return (
      <DropdownWrapper
        isOpen={dropdown}
        disabled={disabled}
        toggle={() => toggleDropdown(!dropdown)}
      >
        <DropdownToggle
          className="input-group"
          data-toggle="dropdown"
          aria-expanded={dropdown}
        >
          <AppendFormControl
            icon="clock"
            value={formattedTime()}
            placeholder="Select date"
            disabled={disabled}
          />
        </DropdownToggle>
        <DropdownMenuWrapper isOpen={dropdown} right={true} className="select">
          {timeline.map((time, index) => (
            <DropdownItem
              key={index}
              tag="li"
              active={selectedTime(date, time)}
              onClick={() => buildTime(time)}
            >
              <Moment format="hh:mma">{time}</Moment>
            </DropdownItem>
          ))}
        </DropdownMenuWrapper>
      </DropdownWrapper>
    );
  }
  return (
    <div className="time-picker-wrapper">
      <ListGroup>
        {timeline.length > 0 ? (
          timeline.map((time, index) => (
            <ListGroupItem tag="li" key={index} className="mb-15">
              <Button
                color="outline-primary"
                size="md"
                block
                active={selectedTime(date, time)}
                onClick={() => buildTime(time)}
              >
                <Moment format="hh:mma">{time}</Moment>
                {selectedTimezone ? ` ${selectedTimezone}` : ''}
              </Button>
            </ListGroupItem>
          ))
        ) : (
          <Empty
            icon="clock"
            type="index"
            title="No Availability"
            msg="Please select another date."
          />
        )}
      </ListGroup>
    </div>
  );
};

export default TimePickerInput;

// Docs
//
