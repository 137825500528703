import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { TextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';

const ModalBlock = (props) => {
	let { className = '', children } = props;

	return (
		<div className={'modal-block ' + className}>
			{children}
    </div>
	)
}

const ModalHeader = (props) => {
	let { title, className = '', toggleModal, children } = props;

	return (
		<div className={'modal-header ' + className}>
			{title && <ModalTitle title={title} />}
			{children}
			{toggleModal && <TextButton color='gray' onClick={toggleModal}><FAWrapper icon='times' /></TextButton>}
    </div>
	)
}

const ModalTitle = (props) => {
	let { title, className = '', children } = props;

	return (
		<h5 className={'modal-title ' + className}>{title ? title : children}</h5>
	)
}

const ModalHeaderImg = (props) => {
	let { className = '', children } = props;

	return (
		<div className={'modal-img ' + className}>
			{children}
    </div>
	)
}

export { Modal, ModalBlock, ModalHeader, ModalTitle, ModalHeaderImg, ModalBody, ModalFooter };

