import React, { Fragment, useState } from 'react';
import StarRatings from 'react-star-ratings';
import TextareaAutosize from 'react-autosize-textarea';
import { FormGroup, Label, Input } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import Empty from 'components/misc/empty';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { isEmpty, timeout } from 'utils/helpers';
import { inputProp, setProp } from 'utils/store-helpers';

const ReviewFlowDetails = (props) => {
  const { review = {}, booking = {}, session, store, toast, prevAction, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Tasks
  const saveReview = async () => {
    try {
      setTaskRunning(true)
      store.adapterFor('review').set('userId', session.userId);
      await review.save();
      store.adapterFor('review').set('userId', null);
      toast.showSuccess('Review saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Back' onClick={prevAction} />
      </SectionPreBlock>

      <SectionBlock>
        <SectionBody className='md'>

          <FormGroup className='mt-5 text-center mb-5'>
            <img className='avatar-img lg mb-3' src={booking.provider && booking.provider.photo} />
            <h4>How'd everything go?</h4>
            <h5 className='light gray-color'>We'll share your review with {booking.provider && booking.provider.name}.</h5>
          </FormGroup>

          <FormGroup className='text-center mb-5'>
            <StarRatings
              rating={review.score}
              starRatedColor='#9B5D72'
              starHoverColor='#561938'
              starEmptyColor='#C5CFD6'
              starDimension='50px'
              changeRating={d => setProp(review, 'score', d)}
              numberOfStars={5}
            />
          </FormGroup>

          <FormGroup className='autosize mb-5'>
            <TextareaAutosize
              rows={2}
              value={review.comment}
              className='clean md text-center'
              placeholder='Please share your experience with others...'
              onChange={p => inputProp(review, 'comment', p)}
            />
          </FormGroup>


        </SectionBody>
        <SectionFooter className='flex-between'>
          <NextButton
            title='Post Review'
            icon='check'
            taskRunning={taskRunning}
            onClick={() => saveReview()}
            className='btn-block btn-lg'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}

export default withRouter(ReviewFlowDetails);
