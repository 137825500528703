import React from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import AppFooter from 'components/app/app-footer';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import UserNotificationsForm from 'components/user/user-notifications-form'
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const UserSettingsNotifications = (props) => {
  const { userId, history, user } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Notifications'/>
      <MktAnnouncement />
      <MktNav />

      <main className='app-main'>
        <Container className='md'>

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/user/${userId}/settings`}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Notifications</BreadcrumbItem>
          </Breadcrumb>

          <SectionBlock>
            <UserNotificationsForm
              user={user}
              settingsId={user.settings.id}
              nextAction={() => history.push(`user/${userId}/settings`)}
            />
          </SectionBlock>

        </Container>
			</main>

      <AppFooter />
  	</div>
	);
}

export default withUser(UserSettingsNotifications);
