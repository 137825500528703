import React, { Component } from 'react';
import loadable from '@loadable/component';
const ReactFilestack = loadable(() => import('filestack-react'), { ssr: false });
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { Button } from 'reactstrap';

const FilestackDocWrapper = (props) => {
	const { file, width, height, updateName, updateUrl } = props;
	const pickerOptions = {
		accept: ['image/*', 'application/pdf'],
    maxFiles: 1,
    minFiles: 1,
    imageMin: [480, 480],
    imageMax: [1080, 1080],
    imageDim: [1080, 1080],
    uploadInBackground: false,
    transformations: {
    	rotate: true
    }
	}

  // Methods
	const fileUploaded = (p) => {
		if(!isEmpty(p.filesUploaded)) {
			let file = p.filesUploaded[0];
			updateName(file.filename);
			updateUrl(file.url);
		}
	}


  // Render
	return (
		<ReactFilestack
			apikey={typeof window === 'undefined' ? '' : window.env.REACT_APP_FILESTACK_KEY}
			actionOptions={pickerOptions}
			onSuccess={p => fileUploaded(p)}
			customRender={({ onPick }) => (
				<Button type='button' color='file' onClick={onPick}>
        	<div className='file-block'>
        		{file ? (
        			<img className='file-img' src={file}/>
      			) : (
          		<div className='file-block-inner'>
            		<FAWrapper iconFam='fad' icon='file-certificate' size='2x'/>
            		<small className='mt-3'>Upload Document</small>
          		</div>
        		)}
        	</div>
      	</Button>
			)}
		/>
	)
}

export default FilestackDocWrapper;

// Docs
//
