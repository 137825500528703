import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import FAWrapper from 'components/vendors/fa-wrapper';
import { Table } from 'reactstrap';

const MktComparisonTable = (props) => {
	const {type, className} = props;

	return (
		<div className={'mkt-table-block' + ' ' + type  + ' ' + className}>
			<Table className='mkt-table'>
				<thead>
					<tr>
						<th className='empty'></th>
						<th>Blush</th>
						<th>Blow Dry Bars</th>
						<th>Salon Suites</th>
						<th>Salons</th>
					</tr>
				</thead>
				<tbody>

					<tr>
						<td className='title'>Advertising</td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='times'/></td>
						<td><FAWrapper icon='check'/></td>
					</tr>

					<tr>
						<td className='title'>Convenience</td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='times'/></td>
						<td><FAWrapper icon='times'/></td>
						<td><FAWrapper icon='times'/></td>
					</tr>

					<tr>
						<td className='title'>Quality</td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
					</tr>

					<tr>
						<td className='title'>Service</td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
					</tr>

					<tr>
						<td className='title'>Reliability</td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='check'/></td>
						<td><FAWrapper icon='times'/></td>
						<td><FAWrapper icon='check'/></td>
					</tr>

				</tbody>
			</Table>
		</div>
	)

}

export default MktComparisonTable;
