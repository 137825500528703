import React, { useEffect, useState } from 'react';
import { withTracker } from 'contexts/tracker-context'
import { CheckoutProvider } from 'contexts/checkout-context';
import BookingFlowLocation from 'components/booking/booking-flow-location';
import BookingFlowServices from 'components/booking/booking-flow-services';
import BookingFlowScheduler from 'components/booking/booking-flow-schedule';
import BookingFlowProviders from 'components/booking/booking-flow-providers';
import BookingFlowAuth from 'components/booking/booking-flow-auth';
import BookingFlowReview from 'components/booking/booking-flow-review';
import BookingFlowConfirm from 'components/booking/booking-flow-confirm';
import BookingFlowSteps from 'components/booking/booking-flow-steps';
import ProgressBarWrapper from 'components/bootstrap/progress-bar-wrapper';
import { Container } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BookingFlow = ({ session, store, toast, tracker, bookingId, startDate, exitAction, nextAction }) => {
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ view, setView ] = useState('showLocation');

  const steps = [
    {
      view: 'showLocation',
      title: 'Location',
      value: '16.67',
      step: 1,
    }, {
      view: 'showServices',
      title: 'Services',
      value: '16.67',
      step: 2,
    }, {
      view: 'showSchedule',
      title: 'Schedule',
      value: '33.32',
      step: 3,
    }, {
      view: 'showProviders',
      title: 'Provider',
      value: '50',
      step: 4,
    }, {
      view: 'showAuth',
      title: 'Auth',
      value: '66.67',
      step: 5
    }, {
      view: 'showReview',
      title: 'Review',
      value: '83.32',
      step: 6,
    }, {
      view: 'showConfirm',
      title: 'Confirm',
      value: '100',
      step: 7,
    }
  ]


  // Hooks
  useEffect(() => {
    isEmpty(bookingId) ? createBooking(startDate) : fetchData();
  }, [])

  useEffect(() => {
    if (view) tracker.trackEvent(`VIEWED_BOOKING_${steps.find(step => step.view == view).title.toUpperCase()}`);
  }, [view])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let booking = await store.findRecord('booking', bookingId);
      setBooking(booking);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const createBooking = (startDate) => {
  	let booking = store.createRecord('booking', {
  		status: 'requested',
  		user: session && session.isAuthenticated() ? session.user : {},
    });
    setBooking(booking);
  }

	const step = (view) => {
    return view ? steps.find(step => step.view == view) : {};
  }


  const changeStep = (view) => {
    if (view == 'showAuth' && session.isAuthenticated()) {
      setView('showReview');
    } else if (view == 'showReview' && !session.isAuthenticated()) {
      setView('showAuth');
    } else {
      setView(view);
    }
  }


  // Render
  return (
    <CheckoutProvider booking={booking} session={session} store={store} steps={steps} step={step(view)} changeStep={step => changeStep(step.view)}>
      <BookingFlowSteps steps={steps} step={step(view)}/>

      <Container className='md'>
        <div className='flow-wrapper'>
          <ProgressBarWrapper steps={steps} step={step} />

          {view == 'showLocation' && (
            <BookingFlowLocation
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => exitAction()}
              nextAction={() => changeStep('showServices')}
            />
          )}
          {view == 'showServices' && (
            <BookingFlowServices
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showLocation')}
              nextAction={() => changeStep('showSchedule')}
            />
          )}
          {view == 'showSchedule' && (
            <BookingFlowScheduler
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showServices')}
              nextAction={() => changeStep('showProviders')}
            />
          )}
          {view == 'showProviders' && (
            <BookingFlowProviders
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showSchedule')}
              nextAction={() => changeStep('showReview')}
            />
          )}
          {view == 'showAuth' && (
            <BookingFlowAuth
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showProviders')}
              nextAction={() => changeStep('showReview')}
            />
          )}
          {view == 'showReview' && (
            <BookingFlowReview
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              changeStep={step =>setView(step)}
              prevAction={() => setView('showProviders')}
              nextAction={() => changeStep('showConfirm')}
            />
          )}
          {view == 'showConfirm' && (
            <BookingFlowConfirm
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              nextAction={nextAction}
            />
          )}
        </div>
      </Container>
    </CheckoutProvider>
  )
}


export default withTracker(BookingFlow);
