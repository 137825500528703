import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ServiceSection from 'components/service/service-section';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminSettingsServicesRoute = (props) => {
  const { history, providerId, provider } = props;

  // Render
  return (
    <AdminRoute title='Blush Admin - Services'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Services</BreadcrumbItem>
      </Breadcrumb>
      <ServiceSection provider={provider}/>
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsServicesRoute);

