import AppSerializer from 'serializers/app';

class PromoSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get relationships() {
		return {
			user: { serialize: 'id' },
			promoCode: { serialize: 'id' },
		}
	}

}

export default PromoSerializer;