import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import BookingActions from 'components/booking/booking-actions';
import BookingManager from 'components/booking/booking-manager';
import BookingDetail from 'components/booking/booking-detail';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Modal, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty, timeout } from 'utils/helpers';

const AdminBookingsDetailRoute = (props) => {
  const { bookingId, providerId, history, provider, store, toast } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('booking', bookingId, {
        include: 'card,charges,customer,location,promo,provider,refunds,review,services'
      },'providerBooking');
      setBooking(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Booking Detail'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/bookings`}>Bookings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Booking #{booking.confirmationCode}</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionTitle className='no-b flex-between'>
          <h4 className='title'>Booking #{booking.confirmationCode}</h4>
          <div className='flex-end'>
            <BookingManager
              type='detail'
              booking={booking}
              isAdmin={true}
              className='mr-3'
              refreshData={() => fetchData()}
            />
            <BookingActions
              booking={booking}
              provider={provider}
              isAdmin={true}
              refreshData={() => fetchData()}
            />
          </div>
        </SectionTitle>
      </SectionBlock>
      <BookingDetail isAdmin={true} booking={booking} loading={loading} />
    </AdminRoute>
  )
}

export default withAdmin(withStore(withToast(AdminBookingsDetailRoute)));
