import React, { useEffect, useState } from 'react';
import { withCookie } from 'contexts/cookie-context';

import FAWrapper from 'components/vendors/fa-wrapper';
import { BlushAppLogo } from 'utils/assets/branding';
import { useMediaQuery } from '@react-hook/media-query';
import LocalStorage from 'local-storage';

const GetAppBanner = (props) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery("only screen and (max-width: 500px)");
  const isTablet = useMediaQuery("only screen and (max-width: 900px)");

  // Hooks
  useEffect(() => {
    checkCookie();
  }, []);

  // Methods
  const checkCookie = async () => {
    let isAppBannerView = LocalStorage.get('app-banner');
    if (isAppBannerView) {
      setVisible(false);
    } else {
      setVisible(true)
    }
  }

  const setAppBannerCookie = () => {
    try {
      LocalStorage.set('app-banner', true);
      setVisible(false)
    } catch (e) {
    }
  }

  const onCloseClick = () => {
    setAppBannerCookie();
  }

  const isIOSPlatform = () => {
    let platform = navigator?.userAgent || navigator?.platform || 'unknown'
    return /iPhone|iPod|iPad/.test(platform)
  }

  const onUseAppClick = () => {
    try {
      const isIOS = isIOSPlatform();
      if (isIOS) {
        window.open("https://itunes.apple.com/us/app/id1523170173", "_blank")
      } else {
        window.open("https://play.google.com/store/apps/details?id=com.blushnowmobile")
      }
    } catch (e) { }
  }

  // Render
  return (
    (isMobile || isTablet) ? visible ?
      <div
        style={{
          background: '#fff',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
          display: 'flex',
          marginBottom: '0px'
        }}
        className='section-block'>
        <div style={{
          paddingLeft: '15px'
        }}
          onClick={() => onCloseClick()}>
          <FAWrapper icon={'times'} iconFam='fad' />
        </div>
        <img src={BlushAppLogo}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '6px',
            marginLeft: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#6f8f9b'
          }} />
        <div style={{
          flex: 1,
          marginLeft: '10px',
          marginRight: '10px'
        }}>
          <h6>Get the app</h6>
          <h6 style={{
            color: 'gray',
            lineHeight: 1
          }}>The fastest, easiest way to Blush</h6>
        </div>
        <div
          style={{
            background: '#007bff',
            borderRadius: 5,
            padding: 10,
            marginRight: '15px'
          }}
          onClick={() => {
            onUseAppClick()
          }}>
          <h6 style={{
            color: '#fff'
          }}>USE APP</h6>
        </div>
        <div style={{
        }} />
      </div> : <></> : <></>
  )
}


export default withCookie(GetAppBanner);
