import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { loadStripe } from '@stripe/stripe-js';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Form, FormGroup, FormText, Label, Input, Button,  Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const stripePromise = loadStripe(typeof window === 'undefined' ? '' : window.env.REACT_APP_STRIPE_KEY);

const BankForm = (props) => {
  const { provider = {}, store, toast, modal, toggleModal, nextAction } = props;
  const [ bank, setBank ] = useState({});
  const [ account, setAccount ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ name, setName ] = useState('');
  const [ routing, setRouting ] = useState('');
  const [ stripe, setStripe ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    initStripe();
    createBank();
  }, [])


  // Methods
  const initStripe = async () => {
    try {
      setLoading(true);
      let stripe = await stripePromise;
      setStripe(stripe)
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const saveForm = async (bank) => {
    try {
      if (taskRunning) { return; }
      setTaskRunning(true);
      let token = await fetchToken();
      await saveBank(bank, token);
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const fetchToken = async (bank) => {
    try {
      let token = await stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        account_holder_type: 'individual',
        account_holder_name: name,
        account_number: account,
        routing_number: routing
      });
      if(token.token) {
        return token.token;
      }
      throw token.error;
    } catch(e) {
    	throw e;
    }
  }

  const saveBank = async (bank, token) => {
    try {
      store.adapterFor('bank').set('providerId', provider.id);
      bank.set('token', token.id);
      await bank.save();
      toast.showSuccess('Bank saved!');
    } catch(e) {
      throw e;
    }
  }

  const createBank = () => {
    let bank = store.createRecord('bank', {
      provider: provider,
    });
    setBank(bank)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveForm(bank);
  }


  // Render
  return (
    <Form className='form-block' onSubmit={handleSubmit}>
      <div className='form-title flex-between'>
        <h4>Add Bank</h4>
        <Button color='text-gray' onClick={() => toggleModal()}><FAWrapper icon='times'/></Button>
      </div>

      <div className='form-body'>
        <FormGroup>
          <Label for='name'>Account Holder Name</Label>
          <Input id='name'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Kate Moss'
          />
        </FormGroup>
        <FormGroup>
          <Label for='account'>Account Number</Label>
          <Input id='account'
            type='text'
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            placeholder='000123456789'
          />
        </FormGroup>
        <FormGroup>
          <Label for='routing'>Routing Number</Label>
          <Input id='routing'
            type='text'
            value={routing}
            onChange={(e) => setRouting(e.target.value)}
            placeholder='110000000'
          />
        </FormGroup>
      </div>

      <div className='form-footer flex-between'>
        <h6><FAWrapper icon='lock' className='mr-15 small'/>This form is secure</h6>
        <Button type='submit' color='primary' size='md'>
          {taskRunning ? <LoadingIndicator color='white' size='18px' /> : 'Save' }
        </Button>
      </div>
    </Form>
  )
}


export default withStore(withToast(withToast(BankForm)));
