import React, { Component } from 'react';
import { withCheckout } from 'contexts/checkout-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, NavbarToggler, ListGroup, ListGroupItem, Button } from 'reactstrap';

const BookingFormSteps = (props) => {
  const { checkout, steps, step } = props;


	// Methods
	const selected = (stepView) => {
		return step.view == stepView;
	}

	const filteredSteps = () => {
		return steps.filter(step => step.title != '');
	}


	// Render
  return (
    <div className='progress-wrapper bb'>
      <Row className='flex-between'>
        <Col md='8'>
          <ListGroup className='flex-start' horizontal>
            {steps && filteredSteps().map(step => (
              <ListGroupItem key={step.step} className='flex'>
                <span className={selected(step.view) ? 'active' : ''}>{step.title}</span>
                {step.step != 4 && <FAWrapper icon={'chevron-right'} className='light-gray-color sm m-2'/>}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  )
}

export default withCheckout(BookingFormSteps);
