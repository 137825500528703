import React, { Component } from 'react';
import { Label, Input, Button, ButtonGroup } from 'reactstrap';


const NotificationItem = (props) => {
  const { name, value, action } = props;

	// Render
  return (
    <ButtonGroup>
      <Button color='' className='btn-toggle-primary' onClick={() => action(name)} active={value != true}>Off</Button>
      <Button color='' className='btn-toggle-primary' onClick={() => action(name)} active={value == true}>On</Button>
    </ButtonGroup>
  )
}


export default NotificationItem;
