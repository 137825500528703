import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PayoutActions from 'components/payout/payout-actions';
import TableHeader from 'components/misc/table-header';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Table, Button, Badge } from 'reactstrap';


const PayoutTable = (props) => {
  const { type = 'card', payouts, providerId, sortProp, sortValue, sortList, loading, refreshData, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type='table'/>
  }
  return (
    <Table className='index' responsive>
      <thead>
        <tr>
          <TableHeader text='ID' value='id' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Status' value='status' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Amount' value='amount' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Bank Account' value='bank' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Created At' value='createdAt' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <th className='uppercase'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {payouts.length > 0 && payouts.map(payout => (
          <tr key={payout.id}>
            <td>
              <Link className='td' to={payout.url}>{payout.id}</Link>
            </td>
            <td>
              <Link className='td' to={payout.url}><Badge color={`${payout.status}`} className='sm' pill>{payout.status}</Badge></Link>
            </td>
            <td>
              <Link className='td' to={payout.url}>{payout.displayAmount}</Link>
            </td>
            <td>
              <Link className='td' to={payout.url}>{payout.bank.summary}</Link>
            </td>
            <td>
              <Link className='td' to={payout.url}>{payout.displayCreatedAt}</Link>
            </td>
            <td>
              <PayoutActions type='table' payout={payout} refreshData={refreshData}/>
            </td>
          </tr>
          ))}
      </tbody>
    </Table>
  )
}


export default PayoutTable;
