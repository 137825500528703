import React, { Fragment, useEffect, useState } from 'react';
import { Container, Button, FormGroup, FormText, Label, Input } from 'reactstrap';
import CategoryList from 'components/category/category-list';
import { PrevButton, NextButton, BasicButton } from 'components/bootstrap/button-wrapper';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty } from 'utils/helpers';
import { pushRelation } from 'utils/store-helpers';

const ProviderFlowServices = (props) => {
  const { provider, store, toast, prevAction, nextAction } = props;
  const [ categories, setCategories ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('category', {
        status: 'active',
        sortProp: 'position',
        sortValue: 'asc',
        include: 'services'
      });
      setCategories(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const saveProvider = async () => {
    try {
      setTaskRunning(true);
      await provider.save();
      toast.showSuccess('Services saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  // Methods
  const pushService = (serviceId) => {
    let service = store.peekRecord('service', serviceId);
    pushRelation(provider, 'services', service);
  }


	// Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Back' onClick={prevAction} />
      </SectionPreBlock>

      <SectionBlock>
        <SectionTitle title='Select your services'/>
        <SectionBody className='lg'>
          <CategoryList
            type='select'
            categories={categories}
            loading={loading}
            selectedIDs={provider.serviceIds}
            onClick={serviceId => pushService(serviceId)}
          />
        </SectionBody>
        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            taskRunning={taskRunning}
            disable={isEmpty(provider.services)}
            onClick={() => saveProvider()}
            className='btn-lg btn-block'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}


export default ProviderFlowServices;
