import AlgoliaSearch from 'algoliasearch/lite';
import { isEmpty, logger } from 'utils/helpers';

class AlgoliaService {
	constructor(props) {
		this.env = window.env.REACT_APP_NODE_ENV;
		this.algoliaId = window.env.REACT_APP_ALGOLIA_ID;
		this.algoliaKey = window.env.REACT_APP_ALGOLIA_KEY;
		this.indices = ['bookings', 'customers', 'payouts'];
	}


	// Props
	get client() {
		return AlgoliaSearch(this.algoliaId, this.algoliaKey);
	}


	// Methods
	indexName(index) {
		return index + '_' + this.env;
	}

	buildIndex(query) {
		return this.client.initIndex(this.indexName(this.index));
	}

	buildIndices(providerId, query) {
		let indices = this.indices;
		let formattedIndices = indices.map(index => this.indexName(index));
		return formattedIndices.map(index => {
			return {
				indexName: index,
				query: query,
				params: {
					filters: `provider.id = ${providerId}`,
					hitsPerPage: 5
				}
			}
		})
	}

	async queryIndex(query = '', page = 0) {
		let client = this.buildIndex();
		let result = await client.search(query, {
			hitsPerPage: 10,
			page: page
		});
		logger('Results: ', result);
		return result;
	}

	async queryIndices(providerId, query) {
		let formattedQueries = this.buildIndices(providerId, query);
		let result = await this.client.multipleQueries(formattedQueries);
		logger('Results: ', result);
		return result.results;
	}


}

export default AlgoliaService;

// Docs
// https://www.algolia.com/doc/api-client/methods/search
