import React, { Fragment, useEffect, useState } from "react";

import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import EventForm from "components/event/event-form";
import IntervalForm from "components/interval/interval-form";
import ScheduleFlow from "components/schedule/schedule-flow";
import ScheduleCalendar from "components/schedule/schedule-calendar";
import { PrevButton, NextButton } from "components/bootstrap/button-wrapper";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
} from "components/blocks/section-blocks";
import { Modal } from "reactstrap";

import UpdateAvailbility from "components/availbility/update-availbility";
import moment from "moment";
import { useMediaQuery } from "@react-hook/media-query";

const ScheduleSection = (props) => {
  const { schedule = {}, provider, store, toast, loading, refreshData } = props;

  const [data, setData] = useState({});
  const [event, setEvent] = useState({});
  const [interval, setInterval] = useState({});
  const [view, setView] = useState("schedule");
  const [modal, toggleModal] = useState(false);
  const [moreSchedulDateSlots, setMoreScheduleDateSlots] = useState([]);
  const [moreSchedulDateSlotDateTitle, setMoreScheduleDateSlotDateTitle] =
    useState("");

  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [dateSelectedFromCalendar, setDateSelectedFromCalendar] =
    useState(null);

  const isMobile = useMediaQuery("only screen and (max-width: 500px)");
  const isTablet = useMediaQuery("only screen and (max-width: 900px)");

  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    let body = document.body.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top - window.scrollY,
    };
  }

  // Methods
  const selectDate = (fcData) => {
    // if (fcData.event && fcData.event.extendedProps.type == "Interval") {
    //   store
    //     .findRecord("interval", fcData.event.id)
    //     .then((interval) => showInterval(interval));
    // } else if (fcData.event && fcData.event.extendedProps.type == "Event") {
    //   store
    //     .findRecord("event", fcData.event.id)
    //     .then((event) => showEvent(event));
    // }

    if (fcData.event && fcData.event.extendedProps.type == "schedule_dates") {
      //Opens Update Avail Form

      setDateSelectedFromCalendar(fcData.event.start);
      setView("showAvailabilityForm");
      toggleModal(true);
    } else if (
      fcData.event &&
      fcData.event._def.extendedProps.type == "schedule_dates_all_dates"
    ) {
      //Opens Update Avail Form

      setDateSelectedFromCalendar(fcData.event.start);
      setView("showAvailabilityForm");
      toggleModal(true);
    } else if (
      //Opens Slot details
      fcData.event &&
      fcData.event.extendedProps.type == "schedule_dates_more_slots"
    ) {
      if (isMobile) {
        setPopupPosition({
          x: getOffset(fcData.el).left - 30,
          y: getOffset(fcData.el).top - 300,
        });
      } else if (isTablet) {
        setPopupPosition({
          x: getOffset(fcData.el).left - 118,
          y: getOffset(fcData.el).top - 300,
        });
      } else {
        setPopupPosition({
          x: getOffset(fcData.el).left - 230,
          y: getOffset(fcData.el).top - 300,
        });
      }

      //Sort Array Time wise
      let scheduleDatesArr = schedule.scheduleDate
        ?.filter((slot) => {
          let startTime = moment(slot?.startDate).format("HH:mm");
          let endTime = moment(slot?.endDate).format("HH:mm");
          if (slot?.isAllDay) {
            if (startTime === "07:00" && endTime === "19:00") return null;
            else return slot;
          }
        })
        ?.sort(function (left, right) {
          return moment.utc(left.startDate).diff(moment.utc(right.startDate));
        });
      let dateOfSelectedEvent = moment(fcData.event.start).format("YYYY-MM-DD");
      let filterSelectedDateEvents = scheduleDatesArr?.filter(
        (elem) =>
          moment(elem.startDate).format("YYYY-MM-DD") === dateOfSelectedEvent
      );

      setMoreScheduleDateSlots(
        filterSelectedDateEvents?.map(
          (item) =>
            `${moment(item?.startDate).format("hh:mm A")} - ${moment(
              item?.endDate
            ).format("hh:mm A")}`
        )
      );

      setMoreScheduleDateSlotDateTitle(
        moment(fcData.event.start).format("MMMM Do ddd")
      );

      setView("scheduleDatesMoreSlots");
    } else {
      //Opens Update Avail Form
      setDateSelectedFromCalendar(fcData.date);
      setView("showAvailabilityForm");
      toggleModal(true);
    }
  };

  const showEvent = (event = {}) => {
    setEvent(event);
    setView("event");
    toggleModal(true);
  };

  const showInterval = (interval = {}) => {
    setInterval(interval);
    setView("interval");
    toggleModal(true);
  };

  const showSchedule = (fcData) => {
    setView("schedule");
    setData(fcData);
    toggleModal(true);
  };

  const showAvailabilityForm = () => {
    setView("showAvailabilityForm");
    toggleModal(true);
  };

  const hideAvailabilityForm = () => {
    setView("");
    toggleModal(false);
    setDateSelectedFromCalendar(null);
  };

  const updateData = () => {
    toggleModal(!modal);
    refreshData();
  };

  const closeScheduleDatesMoreSlotsAndClearData = () => {
    setView("");
    setMoreScheduleDateSlots([]);
  };

  // Render
  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)} className="sm">
        {view == "event" && (
          <EventForm
            model={event}
            data={data}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            prevAction={() => showSchedule()}
            nextAction={() => updateData()}
          />
        )}
        {view == "interval" && (
          <IntervalForm
            model={interval}
            data={data}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            prevAction={() => showSchedule()}
            nextAction={() => updateData()}
          />
        )}
        {view == "schedule" && (
          <ScheduleFlow
            provider={provider}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            showEvent={() => showEvent()}
            showInterval={() => showInterval()}
          />
        )}
      </Modal>
      {view === "showAvailabilityForm" && (
        <Modal isOpen={modal} className="modal-update-availability">
          {view === "showAvailabilityForm" && (
            <UpdateAvailbility
              dateSelectedFromCalendar={dateSelectedFromCalendar}
              modal={modal}
              store={store}
              toast={toast}
              schedule={schedule}
              provider={provider}
              toggleModal={() => {
                hideAvailabilityForm();
              }}
              nextAction={() => updateData()}
            />
          )}
        </Modal>
      )}

      {view === "scheduleDatesMoreSlots" ? (
        <>
          {/* <div
            className="calendar-slots-popup-container"
            onClick={() => {
              closeScheduleDatesMoreSlotsAndClearData();
            }}
          > */}
          <div
            style={{
              left: `${popupPosition.x}px`,

              top: "35%",
            }}
            className="calendar-slots-popup-modal"
          >
            <div className="calendar-slots-popup-date-header-container">
              <div className="calendar-slots-popup-date-title">
                {moreSchedulDateSlotDateTitle}
              </div>
              <div
                className="calendar-slots-popup-close-icon"
                onClick={() => {
                  closeScheduleDatesMoreSlotsAndClearData();
                }}
              >
                X
              </div>
            </div>

            {moreSchedulDateSlots?.map((timings) => {
              return <span className="calendar-slots">{timings}</span>;
            })}
          </div>
          {/* </div> */}
        </>
      ) : null}
      <SectionBlock>
        <SectionTitle title="Availability" className="flex-between">
          <NextButton
            title="Update Availability"
            onClick={() => showAvailabilityForm()}
          />
        </SectionTitle>

        <SectionBody>
          <ScheduleCalendar
            type="settings"
            schedule={schedule}
            provider={provider}
            loading={loading}
            selectDate={(v) => selectDate(v)}
          />
        </SectionBody>
      </SectionBlock>
    </Fragment>
  );
};

export default withStore(withToast(ScheduleSection));
