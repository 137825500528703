import AppAdapter from 'adapters/app';

class ServiceAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

  static noFilter = false;
	static userId = '';
	static providerId = '';
	
}

export default ServiceAdapter;