import React from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import MerchantForm from 'components/merchant/merchant-form';
import { Breadcrumb, BreadcrumbItem, Container, Row } from 'reactstrap';

const AdminSettingsMerchantRoute = (props) => {
  const { history, providerId, provider } = props;


  return (
    <AdminRoute title='Blush Admin - Merchant'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Merchant</BreadcrumbItem>
      </Breadcrumb>

      <MerchantForm provider={provider} />
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsMerchantRoute);

