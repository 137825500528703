import React, { useEffect, useState } from 'react';
import { LoadScriptNext, GoogleMap, Marker } from '@react-google-maps/api';
import { MapMarker } from 'utils/assets/icons';
import { isEmpty } from 'utils/helpers';

const GoogleMapWrapper = (props) => {
	const { model = {}, style = [], className = '' } = props;
	const [ latitude, setLatitude ] = useState(39.56777);
	const [ longitude, setLongitude] = useState(-104.876065);
	const coordinates = { lat: latitude, lng: longitude };
	const options = {
		zoom: 15,
		icon: MapMarker,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		styles: style
	}


	// Hooks
	useEffect(() => {
		model.latitude && setCoordinates();
	}, [model.latitude, model.longitude])


	// Methods
	const setCoordinates = () => {
		setLatitude(parseFloat(model.latitude));
		setLongitude(parseFloat(model.longitude));
	}


	// Render
	return (
		<div className={'map-block ' + className}>
			<LoadScriptNext googleMapsApiKey={typeof window === 'undefined' ? '' : window.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
				<GoogleMap center={coordinates} options={options} mapContainerClassName='map'>
					<Marker position={coordinates} icon={MapMarker} animation='DROP'/>
				</GoogleMap>
			</LoadScriptNext>
		</div>
	)
}

export default GoogleMapWrapper;

// Docs
// https://react-google-maps-api-docs.netlify.app/
