import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Empty from 'components/misc/empty';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { timeout } from 'utils/helpers';

const BookingFormConfirm = (props) => {
  const { booking, isAdmin, session, history, prevAction, nextAction } = props;

  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    await timeout(3000);
    nextAction();
  }


	// Render
  return (
    <SectionBlock className='no-b no-m'>
      <SectionBody className='md'>
        <Empty
          type='index'
          icon='check-circle'
          iconSize='3x'
          title='Nice work!'
          msg={`We've updated your booking and notified your ${isAdmin ? 'customer' : 'provider' }.`}
          loading={true}
        />
      </SectionBody>

      <SectionFooter className='flex-between'>
        <PrevButton title='Back' onClick={prevAction} />
        <NextButton title='View Booking' onClick={nextAction} />
      </SectionFooter>
    </SectionBlock>
  )
}


export default withRouter(BookingFormConfirm);
