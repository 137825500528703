import React from 'react';
import DetailCard from 'components/cards/detail-card';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const PayoutListCard = (props) => {
  const { payout = {}, loading, onClick, className = '' } = props;

	return (
		<ListCard
			icon='piggy-bank'
			status={payout.status}
			title={payout.displayAmount}
			line1={payout.bank && payout.bank.summary}
			line2={payout.displayCreatedAt}
			link={payout.url}
			className={className}
		/>
	)
}

const PayoutPreviewCard = (props) => {
  const { payout = {}, loading, onClick, className = '' } = props;

	return (
		<PreviewCard
			icon='piggy-bank'
			status={payout.status}
			title={payout.displayAmount}
			line1={payout.bank && payout.bank.summary}
			line2={payout.displayCreatedAt}
			link={payout.url}
			className={className}
		/>
	)
}

const PayoutDetailCard = (props) => {
  const { payout = {}, loading, onClick, className = '' } = props;

	return (
		<DetailCard
			icon='piggy-bank'
			status={payout.status}
			title={payout.displayAmount}
			line1={payout.bank && payout.bank.summary}
			line2={payout.descriptor}
			timestamp={payout.displayCreatedAt}
			className={className}
		/>
	)
}

export { PayoutListCard, PayoutDetailCard };
