import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktCollapse from 'components/mkt/mkt-collapse';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { MakeupHeader } from 'utils/assets/headers';

const MktFaqRoute = (props) => {
  const { history } = props;

  return (
  	<div className='route'>
      <Head title='Blush - FAQ'/>
      <MktAnnouncement/>
      <MktNav />

      <MktHeader
        size='md'
        title='Frequently Asked Questions'
        bg={MakeupHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>

      <main className='mkt-main'>

         <section className='mkt-section'>
          <div className='mkt-section-body md'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>General</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <MktCollapse isOpen={false} icon='question-mark' title='How it works' className='animated fadeInUp'>
                    <h6>From day time glam to wedding glam, our Blush Pros have you covered! Choose your date, time and location and let our Pros take care of the rest!</h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='How do I prepare for my appointment?' className='animated fadeInUp delay-100ms'>
                    <h6>
                      In order for our Blush Professionals to provide the ultimate experience, we ask that you
                      have an area prepared for their arrival.  The spot you choose will need a chair, an outlet
                      and a surface area.
                    </h6>
                    <h6><b>Blowouts:</b> It is recommended you have your hair wet and clean prior to the arrival of a Blush Pro.</h6>
                    <h6><b>Special Occasion Hair:</b> For best results, wash your hair the night prior (do not style with hot tools) and wear a loose fitting or button down shirt.</h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='How do I schedule a group booking?' className='animated fadeInUp delay-200ms'>
                    <h6>Please visit <Link to='/special-events'>Group Bookings</Link> to submit your request and we'll get back to you within 24 hours!</h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='How does pricing work?' className='animated fadeInUp delay-300ms'>
                    <h6>For pricing, please see our <Link to='/our-services'>Services</Link> page.</h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='How am I charged?' className='animated fadeInUp delay-300ms'>
                    <h6>
                      Every booking requires a credit card on file. After the services are rendered, your
                      card will be charged and at that point you have the opportunity to leave a tip.
                    </h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='Is there a travel fee?' className='animated fadeInUp delay-300ms'>
                    <h6>
                      We do our best to provide you a Blush Pro anywhere you are. If for some reason
                      you are out of the range for a Blush Pro, we charge a travel fee starting at $20.
                      If travel is required, on-demand service times are subject to change.
                    </h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='What if I need to cancel?' className='animated fadeInUp delay-400ms'>
                    <h6>
                      In your profile you have the capability to cancel your appointment.  If you cancel prior to
                      the 24-hour period you will be refunded your service(s). If you cancel within a 24-hour
                      period you will be charged 50% of the service(s). If you cancel within an hour of your
                      service you will be charged 100% of the service(s).
                    </h6>
                  </MktCollapse>

                  <MktCollapse isOpen={false} icon='question-mark' title='How do I become a Blush Pro?' className='animated fadeInUp delay-400ms'>
                    <h6>Do you have a Cosmetology license? Simply visit the <Link to='/for-stylists'>For Stylists</Link> page and follow the easy steps to become a part of the Blush Pro Network!</h6>
                  </MktCollapse>

                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>

      <MktFooter />
  	</div>
	);
}

export default MktFaqRoute;
