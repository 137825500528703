import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import GridCard from 'components/cards/grid-card';
import PhotoForm from 'components/photo/photo-form';
import LoadingIndicator from 'components/loading/loading-indicator';
import { Row, Col, Badge, ButtonGroup, Button, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const PhotoGridCardWrapper = (props) => {
  const { photo = {}, store, toast, loading, nextAction, className = ''} = props;
  const [ modal, toggleModal ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


	// Methods
	const destroyPhoto = async () => {
    try {
      setTaskRunning(true)
      await photo.destroy();
      toast.showSuccess('Photo removed!');
      nextAction();
		} catch(e) {
			toast.showError(e);
		} finally {
      setTaskRunning(false);
		}
	}

  const refreshData = () => {
    toggleModal(!modal);
    nextAction();
  }


  // Render
  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)} className='sm'>
        <PhotoForm modal={modal} model={photo} refreshData={refreshData} toggleModal={() => toggleModal(!modal)}/>
      </Modal>
      <GridCard
        photo={photo.photo}
        title={photo.title}
        line1={photo.displayDescription}
        line2={photo.displayUpdatedAt}>
        <Button type='button' color='text-gray' size='xs' onClick={() => toggleModal(!modal)}>
          Edit
        </Button>
        <Button type='button' color='text-danger' size='xs' onClick={() => destroyPhoto()}>
          {taskRunning ? <LoadingIndicator color='danger' size='18px' /> : 'Delete'}
        </Button>
      </GridCard>
    </Fragment>
  )
}

const PhotoPreviewCard = (props) => {
  const { photo = {}, store, toast, loading, onClick, className = ''} = props;

  return null
}

const PhotoGridCard = withStore(withToast(PhotoGridCardWrapper));

export { PhotoGridCard, PhotoPreviewCard }
