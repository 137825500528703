import React, { Fragment, useEffect, useState } from 'react';
import { Container, Modal, Button, FormGroup, Label, Input } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import BankList from 'components/bank/bank-list';
import BankForm from 'components/bank/bank-form';
import FAWrapper from 'components/vendors/fa-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import { BasicButton, PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty } from 'utils/helpers';

const stripePromise = loadStripe(typeof window === 'undefined' ? '' : window.env.REACT_APP_STRIPE_KEY);

const ProviderFlowBanking = (props) => {
  const { provider, store, toast, prevAction, nextAction } = props;
  const [ bank, setBank ] = useState({});
  const [ name, setName ] = useState('');
  const [ stripe, setStripe ] = useState('');
  const [ account, setAccount ] = useState('');
  const [ routing, setRouting ] = useState('');
  const [ showModal, setShowModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    initStripe();
    createBank();
  }, [])

  // Tasks
  const initStripe = async () => {
    try {
      setLoading(true);
      let stripe = await stripePromise;
      setStripe(stripe);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  const saveBank = async () => {
    try {
      if (taskRunning) { return; }
      setTaskRunning(true)
      await fetchToken(bank);
      store.adapterFor('bank').set('providerId', provider.id);
      await bank.save();
      store.adapterFor('bank').set('providerId', null);
      toast.showSuccess('Bank saved!');
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const fetchToken = async (bank) => {
    let token = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      account_holder_type: 'individual',
      account_holder_name: name,
      account_number: account,
      routing_number: routing
    });
    bank.set('token', token.token.id);
  }

  // Methods
  const createBank = () => {
    let bank = store.createRecord('bank', { provider: provider });
    setBank(bank);
  }


  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Back' onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionTitle className='flex-between'>
          <h5>Add Banking</h5>
          <h6 className='gray-color'><FAWrapper icon='lock' className='mr-15 small' />This form is secure</h6>
        </SectionTitle>

        <SectionBody className='md'>
          <FormGroup>
            <Label for='name'>Account Holder Name</Label>
            <Input id='name'
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Kate Moss'
            />
          </FormGroup>

          <FormGroup>
            <Label for='account'>Account Number</Label>
            <Input id='account'
              type='text'
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              placeholder='000123456789'
            />
          </FormGroup>

          <FormGroup>
            <Label for='routing'>Routing Number</Label>
            <Input id='routing'
              type='text'
              value={routing}
              onChange={(e) => setRouting(e.target.value)}
              placeholder='110000000'
            />
          </FormGroup>
        </SectionBody>

        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            taskRunning={taskRunning}
            disable={isEmpty(bank)}
            onClick={() => saveBank()}
            className='btn-lg btn-block'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}


export default ProviderFlowBanking;
