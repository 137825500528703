import AppModel from 'models/app';
import moment from 'moment';

class ProviderSetting extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		// Attrs
		this.emailNotifications = props.emailNotifications;
		this.smsNotifications = props.smsNotifications;
		this.pushNotifications = props.pushNotifications;
		this.mktNotifications = props.mktNotifications;
		this.automaticPayouts = props.automaticPayouts;
		this.payoutSchedule = props.payoutSchedule;
		this.payoutDate = props.payoutDate

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}


	// Computed

	
}

export default ProviderSetting;