import AppAdapter from 'adapters/app';

class PhotoAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static providerId = '';
	static action = '';

}

export default PhotoAdapter;