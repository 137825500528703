import React, { useState } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';

import { randomString } from 'utils/helpers';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

const AppendFormControl = (props) => {
  const { icon, name, defaultValue, value, onChange, disabled = false, placeholder } = props;

  return (
    <InputGroup>
      <InputGroupAddon addonType='append'>
        <InputGroupText><FAWrapper icon={icon}/></InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        name={name}
      	value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      	onChange={e => onChange(e.target.value)}
      	placeholder={placeholder}
    	/>
    </InputGroup>
  )
}

const PrependFormControl = (props) => {
  const { icon, name, value, onChange, disabled = false, placeholder } = props;

  return (
    <InputGroup>
      <InputGroupAddon addonType='prepend'>
        <InputGroupText><FAWrapper icon={icon}/></InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        name={name}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </InputGroup>
  )
}

export { AppendFormControl, PrependFormControl };

// Docs
