import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/misc/external-link';

import { AppleStoreLogo, BlushLogo, GooglePlayLogo, TiktokLogo } from 'utils/assets/branding';
import { InstagramIcon } from 'utils/assets/icons';
import { EmailIcon, FacebookIcon, TwitterIcon, LinkedinIcon, PinterestIcon } from 'react-share';
import { Container, Row, Col } from 'reactstrap';
import { useMediaQuery } from '@react-hook/media-query';

const MktFooter = (props) => {
	const { } = props;
	const isMobile = useMediaQuery("only screen and (max-width: 500px)");
	const isTablet = useMediaQuery("only screen and (max-width: 900px)");


	const onGoolePlayRedirect = () => {
		try {
			window.open("https://play.google.com/store/apps/details?id=com.blushnowmobile")
		} catch (e) { }
	}
	const onAppleStoreRedirect = () => {
		try {
			window.open("https://itunes.apple.com/us/app/id1523170173", "_blank")
		} catch (e) { }
	}

	// Render
	return (
		<footer className='mkt-footer border-top'>
			<Container>
				<Row>

					<Col md='3'>
						<Link to='/'><img className='mkt-footer-logo' src={BlushLogo} /></Link>
						{
							isMobile || isTablet ? null :
								<div>
									<div
										onClick={() => onGoolePlayRedirect()}>
										<img src={GooglePlayLogo}
											className='mkt-footer-logo'
											style={{
												cursor: 'pointer'
											}}
										/>
									</div>
									<div
										onClick={() => onAppleStoreRedirect()}>
										<img src={AppleStoreLogo}
											className='mkt-footer-logo'
											style={{
												cursor: 'pointer'
											}}
										/>
									</div>
								</div>
						}
						<div className='mkt-footer-social flex-center'
							style={{
								maxWidth: "180px"
							}}>
							<ExternalLink className='btn btn-xs mr-3' to='https://facebook.com/blushnow1'>
								<FacebookIcon size='32' round={true} />
							</ExternalLink>
							<ExternalLink className='btn btn-xs mr-3' to='https://instagram.com/blushnow'>
								<img src={InstagramIcon} style={{ height: '34px', width: '34px' }} />
							</ExternalLink>
							<ExternalLink className='btn btn-xs mr-3' to='https://www.tiktok.com/@blushnow?_t=8gaVI4sH3aI&_r=1'>
								<img src={TiktokLogo} style={{ height: '26px', width: '26px' }} />
							</ExternalLink>
						</div>

					</Col>

					<Col md='3'>
						<div className='footer-column'>
							<h6 className='title'><b>Company</b></h6>
							<div className=''>
								<Link to='/about' className='btn btn-nav-gray'>About</Link>
								<Link to='/trust-safety' className='btn btn-nav-gray'>Trust &amp; Safety</Link>
								<Link to='/our-services' className='btn btn-nav-gray'>Our Services</Link>
								<Link to='/for-stylists' className='btn btn-nav-gray'>For Stylists</Link>
								{/*<Link to='/blog' className='btn btn-nav-gray'>Blog</Link>*/}
							</div>
						</div>
					</Col>

					<Col md='3'>
						<div className='footer-column'>
							<h6 className='title'><b>Other Resources</b></h6>
							<div className=''>
								<Link to='/covid' className='btn btn-nav-gray'>Covid Response</Link>
								<Link to='/special-events' className='btn btn-nav-gray'>Special Events</Link>
								<Link to='/contact' className='btn btn-nav-gray'>Contact</Link>
							</div>
						</div>
					</Col>

					<Col md='3'>
						<div className='footer-column'>
							<h6 className='title'><b>Legal</b></h6>
							<div className=''>
								<Link to='/terms-of-service' className='btn btn-nav-gray'>Terms</Link>
								<Link to='/privacy-policy' className='btn btn-nav-gray'>Privacy Policy</Link>
							</div>
						</div>
					</Col>

				</Row>
			</Container>
		</footer>
	)
}

export default MktFooter;
