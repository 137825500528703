import React from 'react';
import { withSession } from 'contexts/session-context';
import Head from 'components/basics/head';
import AuthNav from 'components/navs/auth-nav';
import AppFooter from 'components/app/app-footer';
import RegisterForm from 'components/auth/register-form';
import { Container } from 'reactstrap';


const RegisterRoute = (props) => {
  const { history, session } = props;


  // Methods
  const nextAction = (userId) => history.push(`/user/${userId}`);


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Register'/>
      <AuthNav session={session} isRegister={true} />

      <main className='auth-main'>
    		<Container className='xs'>
          <RegisterForm userID={session.userId} nextAction={userId => nextAction(userId)}/>
        </Container>
			</main>
      <AppFooter />
  	</div>
	);
}

export default withSession(RegisterRoute);
