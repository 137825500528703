import React from 'react';
import Butter from 'buttercms';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import BlogNav from 'components/navs/blog-nav';
import BlogList from 'components/blog/blog-list';
import { Container, Row, Col } from 'reactstrap';
import { WorkHeader } from 'utils/assets/headers';

const BlogIndexRoute = (props) => {
  const { history, slug, store, toast } = props;
  const [ article, setArticle ] = useState({});
  const [ loading, setLoading ] = useState([]);
  const butter = Butter(typeof window === 'undefined' ? '' : window.env.REACT_APP_BUTTER_KEY);


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Blog'/>
      <MktAnnouncement />
      <MktNav />
      <MktHeader size='md' title='Blog' bg={Work} />

      <main className='mkt-main'>

        <section className='mkt-section'>
          <div className='mkt-section-body md'>
            <Container>
              <Row>

                <Col md='3'>
                  <BlogNav/>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-body md'>
                    <BlogList articles={articles} loading={loading} />
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>
        <MktConsumerPrefooter />
			</main>
      <MktFooter />
  	</div>
	);
}

export default withStore(withToast(BlogIndexRoute));
