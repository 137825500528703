import React from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import AppFooter from 'components/app/app-footer';
import UserForm from 'components/user/user-form';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const UserSettingsProfile = (props) => {
  const { userId, user } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Personal Info'/>
      <MktAnnouncement />
      <MktNav />

      <main className='app-main'>
        <Container className='md'>

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/user/${userId}/settings`}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Personal Info</BreadcrumbItem>
          </Breadcrumb>

          <SectionBlock>
            <UserForm user={user}/>
	  			</SectionBlock>

        </Container>
			</main>

      <AppFooter />
  	</div>
  )
}

export default withUser(UserSettingsProfile);
