import React, { Fragment, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import PlainCard from 'components/cards/plain-card';
import PreviewCard from 'components/cards/preview-card';
import FAWrapper from 'components/vendors/fa-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const CardGridCardWrapper = (props) => {
  const { card = {}, store, toast, loading, nextAction, className = '' } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ destroyRunning, setDestroyRunning ] = useState(false);


  // Methods
  const updateCard = async () => {
    try {
      setTaskRunning(true);
      await card.save();
      toast.showSuccess('Card saved!', card);
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const destroyCard = async () => {
    try {
      setDestroyRunning(true);
      await card.destroy();
      toast.showSuccess('Card destroyed!', card);
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDestroyRunning(false);
    }
  }

  // Render
	return (
		<PlainCard
      icon='credit-card'
			title={card.summary}
			line1={card.name}
			line2={card.expiration}
			className={className}>
			<div className='card-footer'>
				{card.default ? (
					<Badge color='primary' pill><FAWrapper icon='check' className='mr-3'/>Primary</Badge>
				) : (
					<Button type='button' color='text-gray' size='xs' onClick={() => updateCard()}>
						{taskRunning ? <LoadingIndicator color='gray' size='18px' /> : 'Set Default'}
					</Button>
				)}
				<Button type='button' color='text-danger' size='xs' onClick={() => destroyCard()}>
					{destroyRunning ? <LoadingIndicator color='danger' size='18px' /> : 'Delete'}
				</Button>
			</div>
		</PlainCard>
	)
}

const CardPreviewCard = (props) => {
  const { card = {}, store, toast, onClick, loading, className = '' } = props;

  return (
    <PreviewCard
      icon='credit-card'
      status={card.default ? 'default' : null}
      title={card.summary}
      line1={card.name}
      line2={card.expiration}
    />
  )
}

const CardGridCard = withStore(withToast(CardGridCardWrapper));

export { CardGridCard, CardPreviewCard };
