import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Button, Dropdown, Form, FormGroup, Label, Input } from 'reactstrap';
import { isEmpty, timeout } from 'utils/helpers';
import { inputProp, setProp, setRelation } from 'utils/store-helpers'

const CustomerForm = (props) => {
  const { model = {}, provider, store, toast, modal, toggleModal, nextAction } = props;
  const [ customer, setCustomer ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    isEmpty(model.id) ? createCustomer() : setCustomer(model);
  }, [model.id])



  // Methods
  const saveCustomer = async () => {
    try {
      setTaskRunning(true)
      await customer.save();
      store.pushRecord('customer', customer);
      toast.showSuccess('Customer saved!');
      nextAction(customer);
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  const createCustomer = async () => {
    let model = store.createRecord('customer', { provider: provider });
    setCustomer(model);
  }


  // Render
  return (
    <Form>
      <SectionBlock className='basic'>
        <SectionTitle title={customer.id ? 'Edit Customer' : 'Add Customer'} className='flex-between'>
          {modal && <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>}
        </SectionTitle>

        <SectionBody className='md' >

          <FormGroup className='text-center sm'>
            <FilestackPhotoWrapper
              type='user'
              photo={customer.photo}
              setPhoto={d => setProp(customer, 'photo', d)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='name'>Name</Label>
            <Input id='name'
              type='text'
              defaultValue={customer.name}
              placeholder='Full name'
              onChange={e => inputProp(customer, 'name', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='email'>Email</Label>
            <Input id='email'
              type='text'
              defaultValue={customer.email}
              placeholder='Email address'
              onChange={e => inputProp(customer, 'email', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='phone'>Phone Number</Label>
            <PhoneMaskedInput id='phone'
              value={customer.phone}
              placeholder='(303) 444-2226'
              onChange={e => inputProp(customer, 'phone', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='address'>Home Address</Label>
            <GoogleAutocompleteWrapper
              location={customer.location}
              setLocation={location => setRelation(customer, 'location', location)}
            />
          </FormGroup>

        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            title='Save'
            icon='check'
            taskRunning={taskRunning}
            onClick={() => saveCustomer()}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}


export default withStore(withToast(CustomerForm));
