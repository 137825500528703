import AppAdapter from 'adapters/app';

class UserSettingAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';

	static urlForFindRecord(modelName, id) {
		return this.buildURL() + `/settings`;
	}

	static urlForUpdateRecord(modelName, id) {
		return this.buildURL() + `/settings/${id}`;
	}
}

export default UserSettingAdapter;
