import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ExternalLink from 'components/misc/external-link';
import SetupList from 'components/setup/setup-list';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import FaWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminGettingStartedRoute = (props) => {
  const { history, providerId, provider } = props;


  // Render
  return (
    <AdminRoute title='Blush Admin - Getting Started'>
      <Breadcrumb>
        <BreadcrumbItem active>Getting Started</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionTitle title='Getting Started' className='flex-between'>
          <ExternalLink to='/videos' text={<span><FaWrapper icon='external-link' className='mr-2'/>Watch Tutorials</span>}/>
        </SectionTitle>

        <SectionBody className='xl'>
          <SetupList provider={provider}/>
        </SectionBody>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withAdmin(AdminGettingStartedRoute);

