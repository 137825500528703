import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import SupportApi from 'apis/support-api';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import Empty from 'components/misc/empty';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const ContactForm = (props) => {
  const { errors = {}, store } = props;
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ name, setName ] = useState('');
  const [ reason, setReason ] = useState('');
  const [ submitted, setSubmitted ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    setReason('General Question')
  }, [])


  // Tasks
  const submitForm = async () => {
  	try {
      if (taskRunning) { return };
      setTaskRunning(true);
      await SupportApi.contact({
        name: name,
        email: email,
        reason: reason,
        message: message,
      });
      setSubmitted(true);
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
	const handleSubmit = (e) => {
		submitForm();
		e.preventDefault();
	}

  const resetForm = () => {
    setName('');
    setEmail('');
    setReason('General Question');
    setMessage('');
  }


  // Render
  if(submitted) {
    return (
      <SectionBlock>
        <SectionBody className='md'>
          <Empty
            type='index'
            icon='check-circle'
            title='Nice work!'
            msg='Thanks for your submission. Someone from our team will be in touch shortly.'>
            <NextButton title='Reset Form' onClick={() => resetForm()}/>
          </Empty>
        </SectionBody>
      </SectionBlock>
    )
  } else {
    return (
      <SectionBlock>
        <Form onSubmit={handleSubmit}>
          <SectionBody className='md'>
            <FormGroup>
              <Label for='user_name'>Name</Label>
              <Input id='user_name'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Your name'
              />
            </FormGroup>

            <FormGroup>
              <Label for='user_email'>Email</Label>
              <Input id='user_email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='you@example.com'
              />
            </FormGroup>

            <FormGroup>
              <Label for='reason'>Reason</Label>
              <Input id='reason'
                type='select'
                value={reason}
                onChange={(e) => setReason(e.target.value)}>
                <option value='General Question'>General Question</option>
                <option value='Technical Question'>Technical Question</option>
                <option value='Partnership Inquiry'>Partnership Inquiry</option>
                <option value='Report A Bug'>Report a Bug</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for='message'>Message</Label>
              <Input id='message'
                type='textarea'
                rows='5'
                name='message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='What would you like to share with our team?'
              />
            </FormGroup>
          </SectionBody>

          <SectionFooter>
            <NextButton
              type='submit'
              title='Send Message'
              taskRunning={taskRunning}
            />
          </SectionFooter>
        </Form>
      </SectionBlock>
    )
  }
}


export default withRouter(withStore(withToast(ContactForm)));
