import React, { useEffect, useState } from 'react';
import ReviewFlowAuth from 'components/review/review-flow-auth';
import ReviewFlowTip from 'components/review/review-flow-tip';
import ReviewFlowDetails from 'components/review/review-flow-details';
import ReviewFlowConfirm from 'components/review/review-flow-confirm';
import ReviewFlowSteps from 'components/review/review-flow-steps';
import ProgressBarWrapper from 'components/bootstrap/progress-bar-wrapper';
import { Container, Row, Col } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ReviewFlow = (props) => {
  const { session, bookingId, store, toast, taskRunning, exitAction } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ review, setReview ] = useState({});
  const [ view, setView ] = useState('');

  const steps = [
    {
      view: 'showAuth',
      title: 'Login',
      value: '0',
      step: 1,
    }, {
      view: 'showTip',
      title: 'Tip',
      value: '33.33',
      step: 2,
    }, {
      view: 'showDetails',
      title: 'Review',
      value: '66.67',
      step: 3,
    }, {
      view: 'showConfirm',
      title: 'Confirm',
      value: '100',
      step: 4,
    }
  ]


  // Hooks
  useEffect(() => {
    session.isAuthenticated() ? fetchData(bookingId) : setView('showAuth');
  }, [])

  useEffect(() => {
    if (booking.id) {
      isEmpty(booking.review) ? createReview(booking) : setReview(booking.review);
      !isEmpty(booking.provider) && setView('showTip');
    }
  }, [booking.id]);


  // Methods
  const fetchData = async (bookingId) => {
    try {
      setLoading(true)
      store.adapterFor('booking').set('userId', session.userId);
      let booking = await store.queryRecord('booking', bookingId, { include: 'card,provider,review' });
      setBooking(booking);
    } catch(e) {
      toast.showError(e);
    } finally {
      store.adapterFor('booking').set('userId', null);
      setLoading(false);
    }
  }

  const createReview = (booking) => {
  	let review = store.createRecord('review', {
      booking: booking
    });
    setReview(review);
  }

  const step = (view) => {
    return view ? steps.find(step => step.view == view) : {};
  }


	// Render
  return (
    <main id='review_flow' className='flow-main'>
      <ReviewFlowSteps steps={steps} step={step(view)} changeStep={step => setView(step.view)}/>

      <div className='flow-wrapper'>
        <Container className='sm'>
          <ProgressBarWrapper steps={steps} step={step(view)} />

          {view == 'showAuth' && (
            <ReviewFlowAuth
              session={session}
              taskRunning={taskRunning}
              nextAction={() => setView('showTip')}
            />
          )}

          {view == 'showTip' && (
            <ReviewFlowTip
              review={review}
              booking={booking}
              card={booking.card}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => exitAction()}
              nextAction={() => setView('showDetails')}
            />
          )}

          {view == 'showDetails' && (
            <ReviewFlowDetails
              review={review}
              booking={booking}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showTip')}
              nextAction={() => setView('showConfirm')}
            />
          )}

          {view == 'showConfirm' && (
            <ReviewFlowConfirm
              review={review}
              session={session}
              store={store}
              toast={toast}
              prevAction={() => setView('showDetails')}
              nextAction={() => exitAction()}
            />
          )}
        </Container>
      </div>
    </main>
  )
}


export default ReviewFlow;
