import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { CustomerPreviewCard } from 'components/customer/customer-card';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';

const CustomerPreviewSection = (props) => {
  const { type, customer = {}, loading } = props;


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Customer' className='flex-between' />
      <SectionBody>
        <CustomerPreviewCard customer={customer} loading={loading} />
      </SectionBody>
    </SectionBlock>
  )
}

export default withStore(withToast(CustomerPreviewSection));
