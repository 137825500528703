import React, { Component } from 'react';
import { IntercomAPI } from 'react-intercom';
import { Button } from 'reactstrap';

const IntercomBtnWrapper = (props) => {
	const { type, size = 'lg', color = 'primary', text = 'Live Chat', className = '', children } = props;


	// Methods
	const showIntercom = () => {
		IntercomAPI('show');
	}


	// Render
	return (
		<Button type='button' size={size} color={color} className={className} onClick={() => showIntercom()}>
			{children}
		</Button>
	)
}


export default IntercomBtnWrapper;

// Docs
//
