import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

const MktSection = (props) => {
	const { title, subtitle, link, size, className = '', children } = props;

	return (
		<div className={'mkt-section' + className}>
			<Container>
				<div className='mkt-section-title'>
					{title && <h2>{title}</h2>}
					{subtitle && <h6 >{subtitle}</h6>}
					<hr/>
				</div>
				<div className={'mkt-section-body ' + size}>
					{children}
				</div>
			</Container>
		</div>
	)
}

export { MktSection };

