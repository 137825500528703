import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { ReviewListCard, ReviewPreviewCard } from 'components/review/review-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col, Button } from 'reactstrap';


const ReviewList = (props) => {
  const { type = 'list' , reviews = [], loading, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (reviews.length > 0) {
    return (
      <Row>
        {type == 'preview' && reviews.map(review => (
          <Col xs='12' key={review.id}>
            <ReviewPreviewCard
              type={type}
              review={review}
              className={className}
            />
          </Col>
        ))}
        {type == 'list' && reviews.map(review => (
          <Col xs='12' key={review.id}>
            <ReviewListCard
              type={type}
              review={review}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='stars'
      title='Reviews'
      msg="Sorry, it looks like we don't have any reviews yet."
    />
  )
}

export default ReviewList;
