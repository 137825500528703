import React, { useState } from 'react';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import PayoutFormAuto from 'components/payout/payout-form-auto';
import PayoutFormManual from 'components/payout/payout-form-manual';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Form, FormGroup, ButtonGroup, Button } from 'reactstrap';


const PayoutForm = (props) => {
  const { type, provider, modal, toggleModal, refreshData } = props;
  const [ view, setView ] = useState('overview');


  // Methods
  const showManager = () => {
    setShowAuto(false);
    setShowManual(false);
  }

  const showAutoModal = () => {
    setShowAuto(true);
    setShowManual(false);
  }

  const showManualModal = () => {
    setShowAuto(false);
    setShowManual(true);
  }


  // Render
  if (view == 'auto') {
    return (
      <PayoutFormAuto
        provider={provider}
        toggleModal={toggleModal}
        refreshData={refreshData}
        prevAction={() => setView('overview')}
      />
    )
  }

  if (view == 'manual') {
    return (
      <PayoutFormManual
        provider={provider}
        toggleModal={toggleModal}
        refreshData={refreshData}
        prevAction={() => setView('overview')}
      />
    )
  }

  return (
    <SectionBlock className='basic'>
      <SectionTitle className='flex-between'>
        <h4>Payout Manager</h4>
        <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>
      </SectionTitle>

      <SectionBody>
        <ButtonGroup>
          <Row>

            <Col>
              <Button color='toggle-outline-lighter-gray' size='xl' onClick={() => setView('auto')}>
                <div className='pt-15 pb-15'>
                  <FAWrapper icon='wand-magic' iconFam='fad' size='3x'/>
                  <div className='text-center'>
                    <h5 className='title'>Auto Payouts</h5>
                    <h6>Setup payouts to run on an automatic schedule.</h6>
                  </div>
                </div>
              </Button>
            </Col>

            <Col>
              <Button color='toggle-outline-lighter-gray' size='xl' onClick={() => setView('manual')}>
                <div className='pt-15 pb-15'>
                  <FAWrapper icon='hand-pointer' iconFam='fad' size='3x'/>
                  <div className='text-center'>
                    <h5 className='title'>Manual Payout</h5>
                    <h6>Manually process a payout right now.</h6>
                  </div>
                </div>
              </Button>
            </Col>

          </Row>
        </ButtonGroup>
      </SectionBody>

    </SectionBlock>
  )
}

export default PayoutForm;
