import React, { useState } from 'react';
import { Row, Col, Progress } from 'reactstrap';

import { Lorem1, Lorem2, Lorem3, Lorem4 } from 'utils/placeholders';

const MktStat = (props) => {
	const { type, stat, percent, title, description, className = '' } = props;


	return (
		<div className={'mkt-stat-block ' + ' ' + type + ' ' + className}>
			<div className='mkt-stat-content'>
				<h4><b>{title}</b><span className='gray-color ml-15'>{stat}</span></h4>
				<h6 className='reg'>{description}</h6>
			</div>
			<div className='mkt-stat-progress'>
				<Progress
					value={percent}
					animated={true}
				/>
			</div>
		</div>
	)
}

export default MktStat;
