import React, { Component } from 'react';
import DetailCard from 'components/cards/detail-card';
import PlainCard from 'components/cards/plain-card';
import StarRatingWrapper from 'components/vendors/star-rating-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, Button, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ReviewListCard = (props) => {
  const { review = {}, loading, className = '' } = props;

  return (
    <PlainCard
      html={<div className='pb-2'><StarRatingWrapper rating={review.score} size='24px' /></div>}
      title={review.user && review.user.name}
      line1={review.comment}
      timestamp={review.displayUpdatedAt}
      className={className}
    />
  )
}

const ReviewPreviewCard = (props) => {
  const { review = {}, loading, className = '' } = props;

  return (
    <PlainCard
      html={<StarRatingWrapper rating={review.score} size='40px' />}
      title={review.user && review.user.name}
      line1={review.comment}
      timestamp={review.displayUpdatedAt}
      className={className}
    />
  )
}

const ReviewDetailCard = (props) => {
  const { review = {}, loading, className = '' } = props;

  return (
    <DetailCard
      icon='stars'
      preTitle={<div className='pb-2'><StarRatingWrapper rating={review.score} size='24px' /></div>}
      title={review.user && review.user.name}
      line1={review.comment}
      timestamp={review.displayUpdatedAt}
      className={className}
    />
  )
}


export { ReviewListCard, ReviewPreviewCard, ReviewDetailCard };
