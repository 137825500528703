import React from "react";
import FAWrapper, { FalIcon } from "components/vendors/fa-wrapper";
import { Row, Col, Badge, Button } from "reactstrap";
import { getServiceQty, pushService, removeService } from "utils/store-helpers";

const SelectCard = (props) => {
  const {
    status,
    icon,
    photo,
    title,
    subtitle,
    line1,
    line2,
    selected,
    onSelect,
    className = "",
    children,
    editBooking,
    booking,
    service,
  } = props;

  // Render
  return (
    <div className={"card select " + className}>
      <Row className="flex">
        <Col xs="3" sm="3" md="3" lg="2">
          <div className="card-header">
            {icon && (
              <FAWrapper
                icon={icon}
                iconFam="fad"
                className="card-header-icon"
              />
            )}
            {photo && <img className="card-header-img" src={photo} />}
          </div>
        </Col>
        <Col xs="9" sm="7" md="7" lg="8">
          <div className="card-body">
            {title && <h5 className="title">{title}</h5>}
            {subtitle && <h6 className="subtitle">{subtitle}</h6>}
            {line1 && <h6>{line1}</h6>}
            {line2 && <h6>{line2}</h6>}
            {children}
          </div>
        </Col>
        <Col xs="12" sm="2" md="2" className="flex-end">
          {editBooking ? (
            getServiceQty(booking, "services", service) <= 0 ? (
              <div
                className="multiple_product_selection"
                onClick={() => {
                  pushService(booking, "services", service);
                }}
              >
                <div className="inner_container_multiple_select">
                  <h4 className="add_content">Add </h4>
                  <FalIcon icon="plus" size={16} />
                </div>
              </div>
            ) : (
              <div className="multiple_product_selection_edit">
                <div className="multiple_select_container">
                  <div
                    className="icon_styles"
                    onClick={() => {
                      removeService(booking, "services", service);
                    }}
                  >
                    <FalIcon icon="minus" size={16} />
                  </div>
                  <h4 className="plus_icons_styles">
                    {" " + getServiceQty(booking, "services", service) + " "}
                  </h4>
                  <div
                    className="icon_styles"
                    onClick={() => {
                      pushService(booking, "services", service);
                    }}
                  >
                    <FalIcon icon="plus" size={16} />
                  </div>
                </div>
              </div>
            )
          ) : (
            <Button
              type="button"
              color={selected ? "primary" : "light-gray"}
              size="sm"
              onClick={onSelect}
            >
              {selected ? (
                <FAWrapper icon="check" />
              ) : (
                <span>
                  <FAWrapper icon="plus" /> Add
                </span>
              )}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SelectCard;
