import React, { useState } from 'react';
import { Container } from 'reactstrap';

const AdminContainer = (props) => {
	const { className, children } = props;
	const [ isOpen, setIsOpen ] = useState(false);

	return (
		<div className={'admin-container ' + className}>
			<Container>
				{children}
			</Container>
		</div>
	)
}

export default AdminContainer;
