import React, { useState } from 'react';
import {  FacebookShareButton, TwitterShareButton, LinkedinShareButton, PinterestShareButton, EmailShareButton } from 'react-share';
import { EmailIcon, FacebookIcon, LinkedinIcon, PinterestIcon, TwitterIcon } from "react-share";
import FAWrapper from 'components/vendors/fa-wrapper';

const ShareButtons = (props) => {
	const { size = '32', img, title, content, hashtag, url } = props;

	return (
		<div className='mkt-share-block'>
			<FacebookShareButton quote={title} hashtag={hashtag} url={url}>
				<FacebookIcon size={size} round={true} />
			</FacebookShareButton>

			<TwitterShareButton quote={title} hashtag={hashtag} url={url}>
				<TwitterIcon size={size} round={true} />
			</TwitterShareButton>

			<LinkedinShareButton quote={title} hashtag={hashtag} url={url}>
				<LinkedinIcon size={size} round={true} />
			</LinkedinShareButton>

			<PinterestShareButton quote={title} hashtag={hashtag} url={url}>
				<PinterestIcon size={size} round={true} />
			</PinterestShareButton>

			<EmailShareButton subject={title} body={content} url={url}>
				<EmailIcon size={size} round={true} />
			</EmailShareButton>

		</div>
	)
}

export default ShareButtons;

// Docs
