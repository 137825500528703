import AppModel from 'models/app';
import Accounting from 'accounting';
import { truncate } from 'utils/helpers';

class Service extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.title = props.title;
		this.description = props.description;
		this.photo = props.photo;
		this.duration = props.duration;
		this.featured = props.featured;
		this.price = props.price;
		this.quantity = props.quantity ? props.quantity : undefined;
		// If duration is 0 then it will be count as a addOn
		this.group = props.duration === 0 ? "addOn":"service";

		

		// Relationships
		this.category = this.belongsTo('category', props.category);
		this.provider = this.belongsTo('provider', props.provider);
		this.bookings = this.hasMany('bookings', props.bookings);
	}


	// Computed
	get displayPrice() {
		return Accounting.formatMoney(this.price);
	}

	get displayDuration() {
		return this.duration > 0 ? ` (${this.duration} mins)` : '';
	}

	get previewDescription() {
		return this.description ? truncate(this.description, 60, true) : '--';
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

	get url() {
		return '/book';
	}

}

export default Service;
