import React, { Fragment, useState } from 'react';
import Head from 'components/basics/head';

const AdminRoute = (props) => {
	const { title, description, style, children } = props;
  const [ open, toggleOpen ] = useState(true);

	return (
  	<Fragment>
      <Head title={title} description={description}/>
      {children}
    </Fragment>
	)
}

const AuthRoute = (props) => {
  const { title, description, style, children } = props;

  return (
    <Fragment>
      <Head title={title} description={description}/>
      <div id='route' className='mkt-route'>
        {children}
      </div>
    </Fragment>
  )
}

const MktRoute = (props) => {
	const { title, description, style, children } = props;

	return (
  	<Fragment>
      <Head title={title} description={description}/>
      <div id='route' className='mkt-route'>
        {children}
      </div>
    </Fragment>
	)
}

export { AdminRoute, AuthRoute, MktRoute }
