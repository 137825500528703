import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import CurrencyInputWrapper from 'components/vendors/currency-input-wrapper';
import { BasicButton, PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { FormGroup, FormText, Label, Input } from 'reactstrap';
import { isEmpty, timeout } from 'utils/helpers';
import { inputCurrency } from 'utils/store-helpers';

const ReviewFlowTip = (props) =>{
  const { review, booking, card, session, store, toast, prevAction, nextAction } = props;
  const [ charge, setCharge ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    createCharge();
  }, [])



  // Tasks
  const saveCharge = async () => {
    try {
      setTaskRunning(true);
      store.adapterFor('charge').set('userId', session.userId);
      await charge.save();
      store.adapterFor('charge').set('userId', null);
      toast.showSuccess('Tip sent!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const createCharge = () => {
    let charge = store.createRecord('charge', {
      status: 'pending',
      reason: 'tip',
      amount: 0,
      booking: booking,
      card: card,
    });
    setCharge(charge)
  }


	// Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Exit' onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionBody className='md'>
          <FormGroup className='mt-5 text-center mb-5'>
            <img className='avatar-img lg mb-3' src={booking.provider && booking.provider.photo} />
            <h4>Would you like to leave a tip?</h4>
            <h5>100% of your tip will go to {booking.provider && booking.provider.name}.</h5>
          </FormGroup>

          <FormGroup className='text-center mb-5'>
            <CurrencyInputWrapper
              type=''
              value={charge.amount}
              placeholder='$00.00'
              className='clean xl text-center'
              onChange={d => inputCurrency(charge, 'amount', d)}
            />
          </FormGroup>

        </SectionBody>

        <SectionBody className='p-15 mb-15 border'>
          <div className='flex-between'>
            <h6>Card</h6>
            <h5 className='accent-color'>{card.summary}</h5>
          </div>
          <div className='flex-between'>
            <h6>Booking Total</h6>
            <h5 className='accent-color'>{charge.booking && charge.booking.displayTotal}</h5>
          </div>
          <div className='flex-between'>
            <h6>Processing Fees</h6>
            <h5 className='accent-color'>{charge.displayServiceFees}</h5>
          </div>
          <div className='flex-between'>
            <h6>Tip Total</h6>
            <h5 className='accent-color'>{charge.displayAmount}</h5>
          </div>
        </SectionBody>

        <SectionFooter className='flex-end'>
          <BasicButton
            color='text-gray'
            title='Skip step'
            className='mr-15'
            onClick={nextAction}
          />
          <NextButton
            icon='check'
            title='Send Tip'
            taskRunning={taskRunning}
            onClick={() => saveCharge()}
            className='btn-lg'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}


export default withRouter(ReviewFlowTip);
