import React, { Component } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, NavbarToggler, ListGroup, ListGroupItem, Button } from 'reactstrap';

const RefundFlowProgress = (props) => {
	const { steps = [], step } = props;

	// Methods
	const selected = (stepView) => {
		return step.view == stepView;
	}


	// Render
	return (
		<div className='progress-wrapper'>
			<ListGroup className='flex-start' horizontal>
				{steps.map(step => (
					<ListGroupItem key={step.step}>
						<span className={selected(step.view) && 'active'}>{step.title}</span>
						{step.view != 'showConfirm' && <FAWrapper icon='chevron-right' className='gray-color sm ml-2 mr-2'/>}
					</ListGroupItem>
				))}
			</ListGroup>
		</div>
	)
}

export default RefundFlowProgress;
