import React, { Component } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, NavbarToggler, ListGroup, ListGroupItem, Button } from 'reactstrap';

const ReviewFlowProgress = (props) => {
  const { steps, step, changeStep } = props;


  // Methods
	const selected = (stepView) => {
		return step.view == stepView;
	}

	const filteredSteps = () => {
		return steps.filter(step => step.step != 1);
	}

	// Render

  return (
    <div className='progress-wrapper bb'>
      <Container fluid>
        <ListGroup className='flex-start' horizontal>
          {steps && filteredSteps().map(step => (
            <ListGroupItem key={step.step} className='flex'>
              <span className={selected(step.view) ? 'active' : ''}>{step.title}</span>
              {step.step != 4 && <FAWrapper icon='chevron-right' className='gray-color sm ml-2 mr-2'/>}
            </ListGroupItem>
          ))}
        </ListGroup>
      </Container>
    </div>
  )
}

export default ReviewFlowProgress;
