import AppModel from 'models/app';
import { isEmpty } from 'utils/helpers';

class Candidate extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.status = props.status;
		this.firstName = props.firstName;
		this.lastName = props.lastName;
		this.email = props.email;
		this.phone = props.phone;
		this.dob = props.dob;
		this.ssn = props.ssn;
		this.noMiddleName = props.noMiddleName;

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}
	

	// Statuses
	get isDraft() {
		return this.status == 'draft' ? true : false
	}

	get isActive() {
		return this.status == 'active' ? true : false
	}

	get isPending() {
		return this.status == 'pending' ? true : false
	}

	get isDisabled() {
		return this.status == 'disabled' ? true : false
	}

}

export default Candidate;