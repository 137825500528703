import React, { Fragment, useEffect, useState } from "react";
import { withTracker } from "contexts/tracker-context";
import ProviderFlowAuth from "components/provider/provider-flow-auth";
import ProviderFlowDetails from "components/provider/provider-flow-details";
import ProviderFlowSchedule from "components/provider/provider-flow-schedule";
import ProviderFlowServices from "components/provider/provider-flow-services";
import ProviderFlowBanking from "components/provider/provider-flow-banking";
import ProviderFlowConfirm from "components/provider/provider-flow-confirm";
import ProviderFlowSteps from "components/provider/provider-flow-steps";
import ProgressBarWrapper from "components/bootstrap/progress-bar-wrapper";
import { Container, Row, Col } from "reactstrap";
import { isEmpty } from "utils/helpers";

const ProviderFlow = (props) => {
  const {
    history,
    session,
    store,
    toast,
    tracker,
    nextAction,
    nextLoginAction,
  } = props;
  const [provider, setProvider] = useState({});
  const [loading, setLoading] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);
  const [view, setView] = useState("");

  const steps = [
    {
      view: "showAuth",
      title: "Login",
      value: "0",
      step: 1,
    },
    {
      view: "showDetails",
      title: "Details",
      value: "20",
      step: 2,
    },
    {
      view: "showServices",
      title: "Services",
      value: "40",
      step: 3,
    },

    {
      view: "showBanking",
      title: "Banking",
      value: "80",
      step: 4,
    },
    {
      view: "showConfirm",
      title: "Confirm",
      value: "100",
      step: 5,
    },
  ];

  // Hooks
  useEffect(() => {
    session.isAuthenticated() && session.user.providerId
      ? fetchData()
      : createProvider();
    session.isAuthenticated() ? setView("showDetails") : setView("showAuth");
  }, []);

  useEffect(() => {
    if (view)
      tracker.trackEvent(
        `VIEWED_PROVIDER_${steps
          .find((step) => step.view == view)
          .title.toUpperCase()}`
      );
  }, [view]);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.findRecord("provider", session.user.providerId, {
        include: "location,services",
      });
      setProvider(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  const step = (view) => {
    return view ? steps.find((step) => step.view == view) : {};
  };

  const createProvider = () => {
    let model = store.createRecord("provider", {
      status: "draft",
      photo: session.user.photo,
      name: session.user.name,
      email: session.user.email,
      user: session.user,
      location: store.createRecord("location"),
    });
    setProvider(model);
  };

  // Render
  return (
    <Fragment>
      <ProviderFlowSteps
        steps={steps}
        step={step(view)}
        changeStep={(step) => setView(step.view)}
      />

      <Container
        className={
          view == "showAuth" || view == "showDetails" || view == "showBanking"
            ? "sm"
            : "md"
        }
      >
        <div className="flow-wrapper">
          <ProgressBarWrapper steps={steps} step={step(view)} />

          {view == "showAuth" && (
            <ProviderFlowAuth
              session={session}
              provider={provider}
              toast={toast}
              taskRunning={taskRunning}
              nextAction={() => setView("showDetails")}
              nextLoginAction={() => nextLoginAction()}
            />
          )}
          {view == "showDetails" && (
            <ProviderFlowDetails
              session={session}
              store={store}
              toast={toast}
              provider={provider}
              loading={loading}
              taskRunning={taskRunning}
              prevAction={() => setView("showAuth")}
              nextAction={() => setView("showServices")}
            />
          )}
          {view == "showServices" && (
            <ProviderFlowServices
              store={store}
              provider={provider}
              toast={toast}
              taskRunning={taskRunning}
              prevAction={() => setView("showDetails")}
              nextAction={() => setView("showBanking")}
            />
          )}
          {view == "showSchedule" && (
            <ProviderFlowSchedule
              store={store}
              provider={provider}
              toast={toast}
              taskRunning={taskRunning}
              prevAction={() => setView("showServices")}
              nextAction={() => setView("showBanking")}
            />
          )}
          {view == "showBanking" && (
            <ProviderFlowBanking
              store={store}
              provider={provider}
              toast={toast}
              taskRunning={taskRunning}
              prevAction={() => setView("showServices")}
              nextAction={() => setView("showConfirm")}
            />
          )}
          {view == "showConfirm" && (
            <ProviderFlowConfirm
              store={store}
              provider={provider}
              toast={toast}
              taskRunning={taskRunning}
              prevAction={() => setView("showBanking")}
              nextAction={() => nextAction(provider.id)}
            />
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default withTracker(ProviderFlow);
