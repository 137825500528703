import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import CustomerForm from 'components/customer/customer-form';
import LoadingIndicator from 'components/loading/loading-indicator';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const AdminCustomersEditRoute = (props) => {
  const { customerId, providerId, provider, history, store, toast } = props;
  const [ customer, setCustomer ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('customer', customerId, { include: 'provider,location' });
      setCustomer(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(true);
    }
  }

  const viewCustomer = () => {
    history.push(`/admin/${providerId}/customers/${customerId}`);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Edit Customer'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/customers`}>Customers</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to={`/admin/${providerId}/customers/${customerId}`}>Customer #{customerId}</Link></BreadcrumbItem>
        <BreadcrumbItem active>Edit</BreadcrumbItem>
      </Breadcrumb>

      {customer.id && (
        <CustomerForm model={customer} nextAction={() => viewCustomer()}/>
      )}
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminCustomersEditRoute)));
