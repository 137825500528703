import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import Head from 'components/basics/head';
import AppFooter from 'components/app/app-footer';
import BookingForm from 'components/booking/booking-form';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';

const UserBookingsEditRoute = (props) => {
  const { bookingId, userId, history, user, store, toast, session } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('booking', bookingId, { include: 'location,services,provider,customer'});
      setBooking(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(true);
    }
  }

  const viewBooking = () => {
    history.push(`/user/${userId}/bookings/${bookingId}`);
  }


  // Render
  return (
    <div className='route'>
      <Head title={`Blush - ${user.name} - Edit Booking`} />
      <MktAnnouncement/>
      <MktNav />

      <main className='app-main'>
        <Container>

          <Breadcrumb>
            <BreadcrumbItem><Link to='/'>Home</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/user/${user.id}`}>{user.name}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/user/${user.id}/bookings/${bookingId}`}>Booking #{booking.confirmationCode}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Edit</BreadcrumbItem>
          </Breadcrumb>

          <BookingForm booking={booking} isAdmin={false} nextAction={() => viewBooking()} />
        </Container>
      </main>
    </div>
  );
}

export default withRouter(withStore(withToast(withSession(withUser(UserBookingsEditRoute)))));
