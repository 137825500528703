import React, { Component } from 'react';
import LocalStorage from 'local-storage';
import { timeout, logger } from 'utils/helpers';

const CookieContext = React.createContext();

class CookieProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookie: null,
      load: this.load.bind(this),
      accept: this.accept.bind(this),
      deny: this.deny.bind(this),
      accepted: this.accepted.bind(this),
    };
  }


  // Hooks
  componentDidMount() {
    let cookie = LocalStorage.get('cookie');
    cookie ? this.load(cookie) : logger('Cookies not accepted.');
  }


  // Tasks
  async load(cookie) {
    try {
      await this.setState({ cookie: cookie });
      logger('Cookies loaded.');
    } catch(e) {
      logger(e);
    }
  }

  async accept() {
    try {
      LocalStorage.set('cookie', true);
      await this.setState({ cookie: true });
      logger('Cookies accepted.');
    } catch(e) {
      logger(e);
    }
  }

  async deny() {
    try {
      LocalStorage.set('cookie', false);
      logger('Cookies denied.');
    } catch(e) {
      logger(e);
    }
  }

  async clear() {
    try {
      LocalStorage.set('cookie', null);
      logger('Cookies cleared.');
    } catch(e) {
      logger(e);
    }
  }

  // Methods
  accepted() {
    return this.state.cookie == true ? true : false;
  }


  // Render
  render() {
    const { errors, success } = this.state;
    return (
      <CookieContext.Provider value={this.state}>
        {this.props.children}
      </CookieContext.Provider>
    )
  }
};

const withCookie = function(WrappedComponent) {
  return (props) => (
    <CookieContext.Consumer>
      {context => <WrappedComponent cookies={context} {...props} />}
    </CookieContext.Consumer>
  );
};

export { CookieProvider, withCookie };
