import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import Empty from 'components/misc/empty';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Button, Badge, Form, FormGroup, FormText, Label, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { inputProp } from 'utils/store-helpers';

const  MerchantForm = (props) => {
  const { provider, store, toast, modal, toggleModal, nextAction } = props;
  const [ merchant, setMerchant ] = useState({})
  const [ loading, setLoading ] = useState(false);
  const [ verifyRunning, setVerifyRunning ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let merchant = await store.queryRecord('merchant', {});
      setMerchant(merchant);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const saveMerchant = async () => {
    try {
      setTaskRunning(true);
      await merchant.save();
      toast.showSuccess('Merchant saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMerchant();
  }


  // Render
  return (
    <Form onSubmit={handleSubmit}>
      <SectionBlock className='basic'>
        <SectionTitle title='Merchant' className='flex-between'>
          {modal && <Button color='text-gray' onClick={() => toggleModal()}><FAWrapper icon='times'/></Button>}
        </SectionTitle>
        <SectionBody className='md'>
          {loading ? (
            <div className='loading-section'><LoadingIndicator/></div>
          ) : (
            merchant.isUnverified ? (
              <div>
                <FormGroup>
                  <Label for='dob'>Date of Birth</Label>
                  <DobMaskedInput id='dob'
                    type='dob'
                    value={merchant.dob}
                    readOnly={merchant.dobProvided}
                    placeholder='MM/DD/YYYY'
                    onChange={e => inputProp(merchant, 'dob', e)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='ssnLastFour'>Last Four SSN</Label>
                  <Input id='ssnLastFour'
                    type='password'
                    defaultValue={merchant.ssnLastFour}
                    readOnly={merchant.ssnLastFourProvided}
                    placeholder='••••'
                    onChange={e => inputProp(merchant, 'ssnLastFour', e)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='dob'>Social Security #</Label>
                  <SsnMaskedInput id='idNumber'
                    type='idNumber'
                    value={merchant.idNumber}
                    readOnly={merchant.idNumberProvided}
                    placeholder='•••-••-••••'
                    onChange={e => inputProp(merchant, 'idNumber', e)}
                  />
                </FormGroup>
              </div>
            ) : (
              <Empty
                icon='stripe-s'
                iconFam='fab'
                type='index'
                title='Merchant'
                msg="Nothing is required at this time. Please contact support with questions."
              />
            )
          )}
        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            type='submit'
            title='Save'
            icon='check'
            taskRunning={taskRunning}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}


export default withStore(withToast(MerchantForm));
