import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { isEmpty, logger, timeout } from 'utils/helpers';

const SentryContext = React.createContext(null);

const SentryProvider = ({ children }) => {
  const [ loaded, setLoaded ] = useState(false);


  // Hooks
  useEffect(() => {
    if (typeof window !== 'undefined') init();
  }, [])


  // Methods
  const init = () => {
    Sentry.init({
      dsn: window.env.REACT_APP_SENTRY_DSN,
      environment: window.env.REACT_APP_NODE_ENV
    });
    setLoaded(true);
  }

  
  // Public Methods
  const sendError = () => {}

  const sendInfo = () => {}

  const sendWarning = () => {}



  // Render
  return (
    <SentryContext.Provider value={{ sendError, sendInfo, sendWarning }}>
      {children}
    </SentryContext.Provider>
  )
}


const withSentry = function(WrappedComponent) {
  return (props) => (
    <SentryContext.Consumer>
      {context => <WrappedComponent sentry={context || {}} {...props} />}
    </SentryContext.Consumer>
  )
}

export { SentryProvider, withSentry }

// Docs
// https://www.npmjs.com/package/@sentry/browser