import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Button, Dropdown, Form, FormGroup, Label, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { inputProp } from 'utils/store-helpers';

const PromoForm = (props) => {
  const { user = {}, store, toast, modal, toggleModal, nextAction } = props;
  const [ promo, setPromo ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    createPromo();
  }, [])


  // Tasks
  const savePromo = async () => {
    try {
      setTaskRunning(true);
      let promoCode = await store.queryRecord('promo-code', promo.code);
      promo.set('amount', promoCode.amount);
      promo.setRelation('promoCode', promoCode);
      await promo.save();
      toast.showSuccess('Promo saved!');
      nextAction(promo);
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const createPromo = () => {
    let promo = store.createRecord('promo', {
      status: 'active',
      user: user
    });
    setPromo(promo);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    savePromo();
  }


  // Render
  return (
    <Form onSubmit={handleSubmit}>
      <SectionBlock className='basic'>
        <SectionTitle className='flex-between'>
          <h4>{promo.id ? 'Edit Promo' : 'Add Promo'}</h4>
          {modal && <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>}
        </SectionTitle>

        <SectionBody className='xs' >

          <FormGroup>
            <Label for='code'>Promo Code</Label>
            <Input id='code'
              type='text'
              defaultValue={promo.code}
              placeholder='BLUSHNOW'
              onChange={e => inputProp(promo, 'code', e)}
            />
          </FormGroup>

        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            type='submit'
            title='Save'
            icon='check'
            taskRunning={taskRunning}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withStore(withToast(PromoForm));
