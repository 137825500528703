import React from 'react';
import DetailCard from 'components/cards/detail-card';
import InfoCard from 'components/cards/info-card';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const LocationInfoCard = (props) => {
  const { location = {}, loading, className = '' } = props;

	return (
		<InfoCard
			html={<GoogleMapWrapper model={location} className='xs mb-15' />}
			title={location.name}
			line1={location.address}
			line2={location.cityState}
			className={className}
		/>
	)
}

const LocationDetailCard = (props) => {
  const { location = {}, loading, className = '' } = props;

	return (
		<DetailCard
			map={<GoogleMapWrapper model={location} className='xs' />}
			title={location.name}
			line1={location.address}
			line2={location.cityState}
		/>
	)
}

export { LocationInfoCard, LocationDetailCard };
