import AppModel from 'models/app';
import { truncate } from 'utils/helpers';

class Activity extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.title = props.title;
		this.description = props.description;
		this.activitableType = props.activitableType;
		this.activitableId = props.activitableId;


		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}


	// Computed
	get shortDescription() {
		return truncate(this.description, 60, true);
	}

	get icon() {
		switch(this.activitableType) {
			case 'Booking':
			return 'calendar';

			case 'Customer':
			return 'user';

			case 'Service':
			return 'cut';

			case 'Payout':
			return 'university';

			default:
			return 'crown';
		}
	}

}

export default Activity;
