import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import RefundFlow from 'components/refund/refund-flow';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';
import { Button, DropdownToggle, DropdownItem, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BookingActions = (props) => {
  const { type = 'detail', booking = {}, provider, store, toast, isAdmin, loading, refreshData } = props;
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const [ modal, toggleModal ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ showRefundForm, setShowRefundForm ] = useState(false);


	// Methods
	const cancelBooking = async (booking) => {
    try {
      setTaskRunning(true);
      store.adapterFor('booking').set('action', 'cancel');
      await store.apiRequest('booking', booking.id);
    	store.adapterFor('booking').set('action', null);
      refreshData();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
	}

	const archiveBooking = async (booking) => {
    try {
      setTaskRunning(true);
      await booking.destroy();
      type == 'detail' ? history.push(`/admin/${provider.id}/bookings`) : refreshData();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
	}

	const confirmCancel = (booking) => {
		let confirmed = confirm(`Are you sure you'd like to cancel this booking? You might be charged if you're within 24 hours.`)
		if (confirmed) { cancelBooking(booking) }
	}


  // Render
  return (
    <Fragment>
      <Modal isOpen={modal}>
        <RefundFlow
          booking={booking}
          modal={modal}
          toggleModal={() => toggleModal(!modal)}
          refreshData={refreshData}
        />
      </Modal>
      <DropdownWrapper isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>

        {type == 'detail' && (
          <DropdownToggle color='gray' size='md'>
            {taskRunning ? <LoadingIndicator color='white' size='18px'/> : <span>Manage<FAWrapper icon='chevron-down' className='ml-2 sm'/></span>}
          </DropdownToggle>
        )}

        {type == 'table' && (
          <DropdownToggle color='icon-gray'>
            {taskRunning ? <LoadingIndicator color='gray' size='18px'/> : <FAWrapper icon='chevron-down'/>}
          </DropdownToggle>
        )}

        {isAdmin ? (
          <DropdownMenuWrapper isOpen={dropdownOpen} right={true}>
            <DropdownItem tag='li'>
              {booking.id && <Link className='btn' to={booking.url}>View</Link>}
            </DropdownItem>

            <DropdownItem tag='li'>
              {booking.id && <Link className='btn' to={`${booking.url}/edit`}>Edit</Link>}
            </DropdownItem>

            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => toggleModal(!modal)}>Issue refund</Button>
            </DropdownItem>

            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => archiveBooking(booking)}>Archive</Button>
            </DropdownItem>
          </DropdownMenuWrapper>
        ) : (
          <DropdownMenuWrapper isOpen={dropdownOpen} right={true}>
            <DropdownItem tag='li'>
              {booking.id && <Link className='btn' to='/book'>Book Again</Link>}
            </DropdownItem>

            <DropdownItem tag='li'>
              {booking.id && <Link className='btn' to={`${booking.url}/edit`}>Edit</Link>}
            </DropdownItem>

            <DropdownItem tag='li'>
              {booking.id && <Link className='btn' to={booking.id ? booking.reviewUrl : ''}>Tip and review</Link>}
            </DropdownItem>

            {(booking.isRequested || booking.isAccepted) && (
              <DropdownItem tag='li'>
                <Button color='transparent' onClick={() => confirmCancel(booking)}>Cancel</Button>
              </DropdownItem>
            )}
          </DropdownMenuWrapper>
        )}
      </DropdownWrapper>
    </Fragment>
  )
}

export default withRouter(withStore(withToast(BookingActions)));
