import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { MobileNavContext } from 'contexts/mobile-nav-context';
import SearchWrapper from 'components/search/search-wrapper';
import UserDropdown from 'components/user/user-dropdown';
import IntercomBtnWrapper from 'components/vendors/intercom-btn-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { TooltipWrapper } from 'components/bootstrap/tooltip-wrapper';
import { Button, Container, Nav, Navbar, NavbarToggler, NavbarBrand, NavItem, Form } from 'reactstrap';
import { BlushLogo } from 'utils/assets/branding';

const AdminNav = (props) => {
	const { providerId, session, className = '' } = props;

  const toggle = () => {
  }

  const logout = () => {
  	props.session.logout();
  	props.history.push('/');
  }

	return (
		<Navbar className={'navbar container-fluid admin flex-between sticky-top ' + className}>

			<div className='flex-start'>
				<NavbarBrand href='/' className='d-lg-none'><img src={BlushLogo} /></NavbarBrand>
				<SearchWrapper providerId={providerId} />
			</div>

			<div className='flex-end'>

				<MobileNavContext.Consumer>
					{state => (
						<NavbarToggler className={state.isOpen ? 'btn hamburger hamburger--squeeze is-active' : 'btn hamburger hamburger--squeeze'} type='button' onClick={state.toggleOpen}>
						  <span className='hamburger-box'>
						    <span className='hamburger-inner'></span>
						  </span>
						</NavbarToggler>
					)}
				</MobileNavContext.Consumer>

				<Nav className='ml-auto flex-end' navbar>
					<NavItem id='faq'>
						<NavLink to='/faq' exact className='btn btn-nav-gray'><FAWrapper icon='life-ring' size='2x'/></NavLink>
					</NavItem>
					<TooltipWrapper placement='bottom' target='faq'>Help Center</TooltipWrapper>

					<NavItem id='messages'>
						<IntercomBtnWrapper size='sm' color='btn btn-nav-gray'><FAWrapper icon='comments' size='2x'/></IntercomBtnWrapper>
					</NavItem>
					<TooltipWrapper placement='bottom' target='messages'>Live Chat</TooltipWrapper>

					<NavItem id='exit'>
						<NavLink to='/' exact className='btn btn-nav-gray'><FAWrapper icon='sign-out' size='2x'/></NavLink>
					</NavItem>
					<TooltipWrapper placement='bottom' target='exit'>Exit</TooltipWrapper>
					{session.isAuthenticated() && <UserDropdown user={session.user} logout={logout} />}
				</Nav>

			</div>

		</Navbar>
	)
}

export default withSession(withRouter(AdminNav));
