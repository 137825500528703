import React from 'react';
import AppSerializer from 'serializers/app';
import Axios from 'utils/axios';
import JsonApiError from 'utils/json-api-error';
import { timeout } from 'utils/helpers';

const axios = new Axios().instance();

class MktApi {

	static async mailchimp(data) {
		try {
			await timeout(300);
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/mailchimp`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
	  	throw JsonApiError.formatErrors(e);
		}
	}

}

export default MktApi;
