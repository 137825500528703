import React, { useState } from 'react';
import { LoadScriptNext, Autocomplete } from '@react-google-maps/api';
import FAWrapper from 'components/vendors/fa-wrapper';
import { AppendFormControl } from 'components/bootstrap/input-group-wrapper';
import { logger, randomString } from 'utils/helpers';

const GoogleAutocompleteWrapper = (props) => {
  const { location = {}, placeholder, disabled, setLocation, className = '' } = props;
  const [ autocomplete, setAutocomplete ] = useState(null)


	return (
		<div className='autocomplete-wrapper' key={location.name}>
			<LoadScriptNext googleMapsApiKey={typeof window == 'undefined' ? '' : window.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>

				<Autocomplete
          onLoad={value => setAutocomplete(value)}
          onPlaceChanged={() => setLocation(updateLocation(location, autocomplete))}
          options={{types: ['geocode', 'establishment']}}
          fields={['name', 'formatted_address', 'geometry', 'address_component']}>
          <AppendFormControl
            icon='search'
            name={randomString()}
            defaultValue={location.name || ''}
            placeholder='Search for a place or an address'
            disabled={disabled}
            onChange={value => null}
          />
        </Autocomplete>
			</LoadScriptNext>
		</div>
	)
}

function updateLocation(location, autocomplete) {
  let data = {};
  data.id = location.id || null;

  let google = autocomplete.getPlace();
  let googleProps = google.address_components;
  // Sometimes there aren't any results.
  let streetNumber = googleProps.find(c => c.types[0] == 'street_number');
  let route = googleProps.find(c => c.types[0] == 'route');

  data.name = google.formatted_address;
  data.address = streetNumber && route ? streetNumber.long_name + ' ' + route.short_name : null;
  data.city = googleProps.find(component => component.types[0] == 'locality');
  data.state = googleProps.find(component => component.types[0] == 'administrative_area_level_1');
  data.country = googleProps.find(component => component.types[0] == 'country');
  data.postalCode = googleProps.find(component => component.types[0] == 'postal_code');
  data.latitude = google.geometry.location.lat();
  data.longitude = google.geometry.location.lng();

  data.city = data.city ? data.city.long_name : '';
  data.state = data.state ? data.state.long_name : '';
  data.country = data.country ? data.country.long_name : '';
  data.postalCode = data.postalCode ? data.postalCode.long_name : '';

  return data;
}


export default GoogleAutocompleteWrapper;

// Docs
// https://react-google-maps-api-docs.netlify.app/#autocomplete
