import React, { Component } from 'react';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import SimpleNav from 'components/navs/simple-nav';
import ProviderFlow from 'components/provider/provider-flow';
import AppFooter from 'components/app/app-footer';
import { Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const StylistsNewRoute = (props) => {
  const { session, store, toast } = props;
  const history = useHistory();

  const viewDashboard = (providerId) => history.push(`/admin/${providerId}/dashboard`);

  const goToDashboard = () => history?.replace(`/for-stylists`);

  // Render
  return (
  	<div className='route'>
      <Head title='Blush - New Stylist'/>
      <SimpleNav/>
      <main className='flow-main'>
        {!session.loading && (
          <ProviderFlow
            user={session.user}
            session={session}
            store={store}
            toast={toast}
            nextAction={providerId => viewDashboard(providerId)}
            nextLoginAction={()=> goToDashboard()}
          />
        )}
      </main>
      <AppFooter />
  	</div>
	);
}

export default withSession(withStore(withToast(StylistsNewRoute)));
