import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withPixel } from 'contexts/pixel-context';
import Empty from 'components/misc/empty';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { timeout } from 'utils/helpers';
import { Helmet } from 'react-helmet-async';

const BookingFlowConfirm = ({ booking, history, pixel, session, store, toast, nextAction }) => {


  // Hooks
  useEffect(() => {
    trackPixel();
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    await timeout(3000);
    nextAction(session.user, booking);
  }

  const trackPixel = () => {
    let data = { currency: 'USD', value: booking.total }
    pixel.trackEvent('Purchase', data);
  }


	// Render
  return (
    <>
      <Helmet>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-371597806/l_PaCJjwgdcDEO7DmLEB'});`}</script>
      </Helmet>
      <SectionBlock>
        <SectionTitle title='Confirm'/>
        <SectionBody className='md'>
          <Empty
            type='index'
            icon='check-circle'
            iconSize='3x'
            title='Nice work!'
            msg="Your booking is processing. Check your email for a confirmation."
            loading={true}
          />
        </SectionBody>
        <SectionFooter className='flex-end'>

          <NextButton
            title='Confirm'
            icon='check'
            onClick={() => nextAction(session.user, booking)}
          />

        </SectionFooter>
      </SectionBlock>
    </>
  )
}

export default withRouter(withPixel(BookingFlowConfirm));
