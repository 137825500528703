import React from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';

const SearchCard = (props) => {
	const { status, icon, title, line1, line2, link, className = '', children } = props;

	return (
		<Link to={link} className={'card search ' + className}>
			<Row className='flex'>
				<Col sm='0' md='2'>
					<div className='card-header icon accent-bg'>
						<FAWrapper icon={icon} className='card-header-icon white-color'/>
					</div>
				</Col>
				<Col sm='10' md='7'>
					<div className='card-body no-p'>
						<h5 className='title'>{title}</h5>
						<h6>{line1}</h6>
						<h6>{line2}</h6>
					</div>
				</Col>
				<Col sm='2' md='3' className='text-right'>
					<FAWrapper icon='chevron-right' className='gray-color'/>
				</Col>
			</Row>
		</Link>
	)
}

export default SearchCard;
