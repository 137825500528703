import AppModel from 'models/app';

class Setup extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		// Attrs
		this.profileComplete = props.profileComplete;
		this.merchantComplete = props.merchantComplete;
		this.scheduleComplete = props.scheduleComplete;
		this.insuranceComplete = props.insuranceComplete;
		this.cosmetologyComplete = props.cosmetologyComplete;
		this.bankingComplete = props.bankingComplete;
		this.backgroundComplete = props.backgroundComplete;

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}
}

export default Setup;