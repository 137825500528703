import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktFooter from 'components/mkt/mkt-footer';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';

const NotAuthorizedRoute = (props) => {
  const { history } = props;

  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Not Authorized'/>
      <MktAnnouncement/>
      <MktNav />

      <main className='mkt-main'>
        <Empty
          type='section'
          icon='lock'
          title='Not Authorized'
          msg="Sorry, it looks like you're not authorized to view that resource.">
          <Link to='/book' className='btn btn-text-primary btn-lg'>
            Visit Homepage<FAWrapper icon='chevron-right' className='ml-15'/>
          </Link>
        </Empty>
      </main>
      <MktFooter />
  	</div>
	);
}

export default NotAuthorizedRoute;
