import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import QueryString from 'query-string';
import { Nav, NavItem } from 'reactstrap';

const BlogNav = (props) => {
	const { location } = props;


	// Methods
	const isActive = (param) => {
		let params = QueryString.parse(props.location.search);
		return param === params.collection ? 'active' : null;
	}


	// Render
	return (
		<div className='section-block'>
			<div className='section-title'>
				<h6>Sections</h6>
			</div>
			<div className='section-body'>
        <NavItem><Link to='/blog?collection=general' className={'btn btn-nav-gray ' + isActive('general')}>General</Link></NavItem>
        <NavItem><Link to='/blog?collection=inspiration' className={'btn btn-nav-gray ' + isActive('inspiration')}>Inspiration</Link></NavItem>
        <NavItem><Link to='/blog?collection=new' className={'btn btn-nav-gray ' + isActive('new')}>Whats New</Link></NavItem>
      </div>
    </div>
	)
}

export default withRouter(BlogNav);
