import AppModel from 'models/app';
import Moment from 'moment';

class Event extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);

		// Attrs
		this.startDate = props.startDate;
		this.endDate = props.endDate;

		
		// Keys
		this.scheduleId = props.scheduleId
		
		
		// Relationships
		this.schedule = this.belongsTo('schedule', props.schedule);
	}


	// Computed
	get displayStartDate() {
		return moment(this.startDate);
	}

	get displayEndDate() {
		return moment(this.endDate);
	}

	get calendarObject() {
		let event = {};
		event.id = this.id;
		event.type = 'Event';
		event.className = 'fc-schedule-event';
		event.title = Moment(this.startDate).format('hh:mma') + ' - ' + Moment(this.endDate).format('hh:mma');
		event.start = Moment(this.startDate).format();
		event.end = Moment(this.endDate).format();
		return event;
	}


	// Functions
	updateStartDate(date) {
		this.set('startDate', date);
		if (this.startDate > this.endDate) {
			this.updateEndDate(date);	
		}
	}

	updateEndDate(date) {
		this.set('endDate', date);
		if(this.startDate > this.endDate) {
			this.updateStartDate(date);	
		}
	}
}

export default Event;