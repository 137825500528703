import AppModel from 'models/app';
import Accounting from 'accounting';
import Moment from 'moment';

class Charge extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		// Attrs
		this.status = props.status;
		this.amount = props.amount;
		this.commissionFee = props.commissionFee;
		this.platformFee = props.platformFee;
		this.stripeFee = props.stripeFee;
		this.total = props.total;
		this.reason = props.reason;
		this.receiptUrl = props.receiptUrl;
		

		// Computed
		this.balance = props.balance;


		// Relationships
		this.booking = this.belongsTo('booking', props.booking);
		this.card = this.belongsTo('card', props.card);
		this.user = this.belongsTo('user', props.user);
	}


	// Statuses
	get isDraft() {
		return this.status == 'draft'
	}

	get isPending() {
		return this.status == 'pending'
	}

	get isSucceeded() {
		return this.status == 'succeeded'
	}

	get isFailed() {
		return this.status == 'failed'
	}

	get isCanceled() {
		return this.status == 'canceled'
	}


	// Displays
	get displayAmount() {
		return Accounting.formatMoney(this.amount);
	}

	get displayCommissionFee() {
		return Accounting.formatMoney(this.commissionFee);
	}

	get displayPlatformFee() {
		return Accounting.formatMoney(this.platformFee);
	}

	get displayStripeFee() {
		return Accounting.formatMoney(this.stripeFee);
	}

	get displayServiceFees() {
		return Accounting.formatMoney(this.serviceFees);
	}

	get displayTotal() {
		return Accounting.formatMoney(this.total);
	}
	
	get displayBalance() {
		return Accounting.formatMoney(this.balance);
	}
}

export default Charge;