import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Row, Col, FormGroup } from 'reactstrap';

const LoadingSkeleton = (props) => {
	const { type, count = 3, duration = 0.5, color = '#DAE2E7', activeColor = '#F6F8F9' } = props;


	// Methods
	const buildSkeletons = () => {
		let index = 0;
		let skeletons = [];
		while(index < count) {
			skeletons.push(index);
			index += 1;
		}
		return skeletons;
	}


	// Render
	return (
		<Row>
			{type == 'actions' && (
        <Col xs='12'>
					<ActionSkeleton type={type} color={color} activeColor={activeColor} />
				</Col>
			)}

			{(type == 'section' || type == 'wizard') && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='12'>
					<SectionSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{(type == 'line' || type =='preview') && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='12'>
					<PreviewSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{(type == 'list' || type == 'select') && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='12'>
					<ListSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{type == 'info' && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='6'>
					<InfoSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{type == 'table' && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='12'>
					<TableSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{(type == 'grid' || type == 'mkt') && buildSkeletons().map(skeleton => (
        <Col key={skeleton} md='4'>
					<GridSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
				</Col>
			))}

			{type == 'detail' && (
        <Col xs='12'>
					<DetailSkeleton type={type} color={color} activeColor={activeColor} />
				</Col>
			)}

			{type == 'calendar' && (
				<Col md='12'>
					<ActionSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
					/>
					<div className='mt-15'>
						<Row>
							{buildSkeletons().map(skeleton => (
				        <Col key={skeleton} xs='2'>
									<CalendarSkeleton
										type={type}
										color={color}
										activeColor={activeColor}
										className='mb-15'
									/>
								</Col>
							))}
						</Row>
					</div>
				</Col>
			)}

			{type == 'stat' && (
        <Col xs='12'>
					<StatSkeleton
						type={type}
						color={color}
						activeColor={activeColor}
						className='p-15'
					/>
				</Col>
			)}
		</Row>
	)
}

const ActionSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
			<Row className='flex'>
				<Col sm='6'>
					<Skeleton width='50%' height={40}/>
				</Col>
				<Col sm='6'>
					<div className='text-right'>
			  		<Skeleton width='20%' height={40} />
		  		</div>
		  	</Col>
	  	</Row>
		</SkeletonTheme>
	)
}

const CalendarSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card' + ' ' + type + ' ' + className}>
				<Skeleton height={120} />
			</div>
		</SkeletonTheme>
	)
}

const DetailSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + ' ' + type + ' ' + className}>
				<Skeleton height={140} />
				<div className='card-body'>
					<h6><Skeleton width='25%'/></h6>
					<h5><Skeleton width='75%'/></h5>
		  		<h6><Skeleton width='50%'/></h6>
		  		<h6><Skeleton width='50%'/></h6>
	  		</div>
			</div>
		</SkeletonTheme>
	)
}

const GridSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + ' ' + type + ' ' + className}>
				<Skeleton height={140} />
				<div className='card-body'>
					<h6><Skeleton width='25%'/></h6>
					<h5><Skeleton width='75%'/></h5>
		  		<h6><Skeleton width='50%'/></h6>
		  		<h6><Skeleton width='50%'/></h6>
	  		</div>
			</div>
		</SkeletonTheme>
	)
}

const InfoSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + ' ' + type + ' ' + className}>
				<div className='card-body'>
					<h6><Skeleton width='25%'/></h6>
					<h5><Skeleton width='75%'/></h5>
		  		<h6><Skeleton width='50%'/></h6>
	  		</div>
	  		<div className='card-footer'>
	  			<h6><Skeleton width='50%'/></h6>
  			</div>
			</div>
		</SkeletonTheme>
	)
}


const PreviewSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + ' ' + type + ' ' + className}>
				<Row className='flex'>
					<Col md='3'>
						<div className='card-header'>
							<Skeleton height={80} />
						</div>
					</Col>
					<Col xs='9' md='7'>
						<div className='card-body no-pad'>
							<h4><Skeleton width='75%'/></h4>
				  		<h6><Skeleton width='50%'/></h6>
				  		<h6><Skeleton width='50%'/></h6>
			  		</div>
			  	</Col>
		  	</Row>
			</div>
		</SkeletonTheme>
	)
}

const ListSkeleton = (props) => {
 	const { type, color, duration, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme duration={duration} color={color} highlightColor={activeColor}>
	 		<div className={'card ' + type}>
				<Row className='flex'>
					<Col md='3' lg='2'>
						<div className='card-header'>
							<Skeleton height={80} />
						</div>
					</Col>
					<Col xs='9' md='7' lg='8'>
						<div className='card-body no-pad'>
							<h5><Skeleton width='75%'/></h5>
				  		<h6><Skeleton width='50%'/></h6>
				  		<h6><Skeleton width='50%'/></h6>
			  		</div>
			  	</Col>
			  	<Col xs='12' md='2'>
			  		<h6><Skeleton width={75}/></h6>
		  		</Col>
		  	</Row>
			</div>
		</SkeletonTheme>
	)
}

const TableSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + type}>
				<Row className='flex'>
					<Col sm='2'>
						<h6><Skeleton/></h6>
					</Col>
					<Col sm='2'>
						<Skeleton circle={true} height={60}/>
					</Col>
					<Col sm='2'>
						<h6><Skeleton/></h6>
					</Col>
					<Col sm='2'>
						<h6><Skeleton/></h6>
					</Col>
					<Col sm='2'>
						<h6><Skeleton/></h6>
					</Col>
					<Col sm='2'>
						<h6><Skeleton/></h6>
					</Col>
		  	</Row>
			</div>
		</SkeletonTheme>
	)
}

const SectionSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={type}>
				<div className='card-body'>
		  		<h2><Skeleton/></h2>
	  		</div>
			</div>
		</SkeletonTheme>
	)
}

const StatSkeleton = (props) => {
 	const { type, color, activeColor, className = '' } = props;

 	return (
		<SkeletonTheme color={color} highlightColor={activeColor}>
	 		<div className={'card ' + ' ' + type + ' ' + className}>
				<Skeleton height={40}/>
				<div className='card-body'>
					<h5><Skeleton width='25%'/></h5>
					<h6><Skeleton width='75%'/></h6>
	  		</div>
			</div>
		</SkeletonTheme>
	)
}

export { LoadingSkeleton, ListSkeleton, GridSkeleton };
