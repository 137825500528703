import React, { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker'
import moment from 'moment-timezone';
import Moment from 'react-moment';
import FAWrapper from 'components/vendors/fa-wrapper';
import { AppendFormControl } from 'components/bootstrap/input-group-wrapper';
import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';
import { DropdownToggle, DropdownItem, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import 'react-day-picker/lib/style.css';


const DatePickerInput = (props) => {
	const { type = 'dropdown', date = moment(), disabled, setDate, booking = null } = props;
	const [ dropdown, toggleDropdown ] = useState(false);

  // Methods
  const buildDate = (date) => {
  	let newDate = moment(date).set({
  		hour: moment(date).hour(),
  		minute: moment(date).minute(),
  		second: moment(date).second(),
  	});

  	setDate(newDate);
  	toggleDropdown(!dropdown);
  }

  const formattededDate = () => {
    return date ? date.format('MMMM, Do, YYYY') : null;
  }

  const selectedDate = () => {
  	return date ? date.toDate() : moment().minute('60').second('00').toDate();
  }


  // Render
	if (type == 'dropdown') {
		return (
			<DropdownWrapper isOpen={dropdown} disabled={disabled} toggle={() => toggleDropdown(!dropdown)}>
				<DropdownToggle className='input-group' data-toggle='dropdown' aria-expanded={dropdown}>
					<AppendFormControl icon='calendar' value={formattededDate()} placeholder='Select date' disabled={disabled} />
				</DropdownToggle>
				<DropdownMenuWrapper isOpen={dropdown}>
					<DayPicker
		        disableDays={[]}
		        selectedDays={selectedDate()}
		        onDayClick={buildDate}
            className='p-15'
		        inputProps={{className: 'form-control'}}
		      />
	      </DropdownMenuWrapper>
      </DropdownWrapper>
		)
	}
	return (
		<DayPicker
			navbarElement={<Navbar />}
      disableDays={[]}
      selectedDays={selectedDate()}
      onDayClick={buildDate}
      inputProps={{ className: 'form-control' }}
    />
  )
}

const Navbar = (props) => {
	const { nextMonth, previousMonth, onPreviousClick, onNextClick, localeUtils, className = '' } = props;
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];

  return (
    <div className={className}>
      <Button color='text-gray' size='sm' onClick={() => onPreviousClick()}>
        <FAWrapper icon='chevron-left'/>
      </Button>
      <Button color='text-gray' size='sm' onClick={() => onNextClick()}>
        <FAWrapper icon={'chevron-right'}/>
      </Button>
    </div>
  );
}

export default DatePickerInput;

// Docs
// https://react-day-picker.js.org/
