import React from 'react';
import { Link } from 'react-router-dom';
import AvatarCard from 'components/cards/avatar-card';
import FAWrapper from 'components/vendors/fa-wrapper';


const BlogGridCard = (props) => {
	const { article = {}, loading, className = '' } = props;

	// Render
	return (
		<div className={'card ' + type + ' ' + className}>
			<Link to={article.formattedSlug}>
				<div className='card-header'>
					<img className='card-header-img' src={article.photo}/>
				</div>
			</Link>
			<div className='card-body'>
				<h4 className='bold'>{article.title}</h4>
				<h6>{article.displayPublishedAt}</h6>
        <AvatarCard
        	photo={article.author && article.author.photo}
        	title={article.author.name}
        />
				<h6>{article.summary}</h6>
			</div>
			<div className='card-footer'>
				<Link to={article.formattedSlug} className='btn btn-text-primary'>
					Read More<FAWrapper icon='chevron-right' className='ml-15'/>
				</Link>
			</div>
		</div>
	)
}



const BlogPreviewCard = (props) => {
	const { article = {}, loading, className = '' } = props;

	// Render
	return (
		<div className={'card ' + type + ' ' + className}>
			<Link to={article.formattedSlug}>
				<div className='card-header'>
					<img className='card-header-img' src={article.photo}/>
				</div>
			</Link>
			<div className='card-body pt-15 pb-15'>
				<h5 className='title'>{article.title}</h5>
				<h6>{article.displayPublishedAt}</h6>
        <AvatarCard
        	photo={article.author && article.author.photo}
        	title={article.author.name}
        />
				<h6>{article.summary}</h6>
				<div className='pt-15'>
					<Link to={article.formattedSlug} className='btn btn-text-primary'>
						Read More<FAWrapper icon='chevron-right' className='ml-15'/>
					</Link>
				</div>
			</div>
		</div>
	)
}

export { BlogGridCard, BlogPreviewCard };
