import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import BurgerMenu from 'react-burger-menu';
import FAWrapper from 'components/vendors/fa-wrapper';
import { BlushLogoWhite } from 'utils/assets/branding';
import { isEmpty } from 'utils/helpers';
import { Nav, NavbarBrand, NavItem, Collapse, Link, Button } from 'reactstrap';

export const MobileNavContext = React.createContext();

class MobileNavContextProvider extends Component {
  constructor(props) {
    super(props);
    this.setMenuStatus = this.setMenuStatus.bind(this),
    this.logout = this.logout.bind(this),
    this.state = {
      menuStyle: 'slide',
      toggleOpen: this.toggleOpen.bind(this),
      isOpen: false,
      route: '',
    }
  }


  // Hooks
  componentDidMount() {
    this.mounted = true;
    this.setState({ route: this.props.history.location.pathname });
  }

  componentDidUpdate() {
    this.props.history.listen(location => {
      if(!isEmpty(this.state.route) && this.state.isOpen) {
        this.mounted ? this.setState({ isOpen: false, userOpen: false, adminOpen: false }) : null;
      } else {
        this.mounted ? this.setState({ route: location.pathname }) : null;
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }


  // Methods
  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  setMenuStatus(state) {
    if(this.state.isOpen != state.isOpen) {
      this.setState({ isOpen: state.isOpen });
    }
  };

  logout() {
    this.props.session.logout();
    this.props.history.push('/');
  }


  // Render
  render() {
    const { session } = this.props;
    const { menuStyle, isOpen, userOpen, userSettingsOpen, adminOpen, adminSettingsOpen } = this.state;

    let Menu = BurgerMenu[menuStyle];
    let user = session.user;

    return (
      <MobileNavContext.Provider value={this.state}>

        <Menu outerContainerId='application' pageWrapId='page-wrap' width='80%' side='left' isOpen={isOpen} onStateChange={this.setMenuStatus}>
          <Nav className='mobile-nav' navbar>

            {session.isAuthenticated() ? (
              <div>
                <div className='mobile-nav-header'>
                  <NavItem className='pt-5 pb-5'>
                    <img className='mobile-nav-user circle mb-15' src={user.photo}/>
                    <h3>{session.user.name}</h3>
                    <h5>{session.user.email}</h5>
                  </NavItem>
                </div>

                <div className='mobile-nav-body'>

                  <NavItem>
                    <Button color='transparent' className='btn btn-nav-gray lg' onClick={() => this.setState({userSettingsOpen: !this.state.userSettingsOpen})}>
                      <span className='flex-between'>User Profile {userSettingsOpen ? <FAWrapper icon='chevron-down' className='ml-2 sm'/> : <FAWrapper icon='chevron-right' className='ml-2 sm'/>}</span>
                    </Button>

                    <Collapse tag='ul' isOpen={userSettingsOpen} className='ml-15 bl'>
                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}`}>View Profile</NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}/settings/profile`}>Edit Profile</NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}/settings/account`}>Account Settings</NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}/settings/credit-cards`}>Credit Cards</NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}/settings/notifications`}>Notifications</NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink exact className='btn btn-nav-gray lg' to={`/user/${user.id}/settings/promotions`}>Promotions</NavLink>
                      </NavItem>

                    </Collapse>
                  </NavItem>

                  {session.user.providerId && (
                    <NavItem>
                      <Button color='transparent' className='btn btn-nav-gray lg' onClick={() => this.setState({adminOpen: !this.state.adminOpen})}>
                        <span className='flex-between'>My Dashboard {adminOpen ? <FAWrapper icon='chevron-down' className='ml-2 sm'/> : <FAWrapper icon='chevron-right' className='ml-2 sm'/>}</span>
                      </Button>

                      <Collapse tag='ul' isOpen={adminOpen} className='ml-15 bl'>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/dashboard`}>Dashboard</NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/getting-started`}>Getting Started</NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/appointments`}>Appointments</NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/bookings`}>Bookings</NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/customers`}>Customers</NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/payouts`}>Payouts</NavLink>
                        </NavItem>

                        <NavItem>
                          <Button color='transparent' className='btn btn-nav-gray lg' onClick={() => this.setState({adminSettingsOpen: !this.state.adminSettingsOpen})}>
                            <span className='flex-between'>Settings {adminSettingsOpen ? <FAWrapper icon='chevron-down' className='ml-2 sm'/> : <FAWrapper icon='chevron-right' className='ml-2 sm'/>}</span>
                          </Button>

                          <Collapse tag='ul' isOpen={adminSettingsOpen} className='ml-15 bl'>

                             <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/profile`}>Edit Profile</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/merchant`}>Merchant</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/licensing`}>Licensing</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/services`}>Services</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/schedule`}>Schedule</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/banking`}>Banking</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/photos`}>Photos</NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink exact className='btn btn-nav-gray lg' to={`/admin/${session.user.providerId}/settings/notifications`}>Notifications</NavLink>
                            </NavItem>

                          </Collapse>
                        </NavItem>

                      </Collapse>

                    </NavItem>
                  )}

                  <NavItem>
                    <Button color='' className='btn btn-nav-gray lg' onClick={this.logout}>Log out</Button>
                  </NavItem>
                </div>
              </div>
            ) : (
              <div>
                <div className='mobile-nav-header'>
                  <NavItem className='pt-5 pb-5'>
                    <h3>Hey There!</h3>
                    <h5>Where would you like to head?</h5>
                  </NavItem>
                </div>

                <div className='mobile-nav-body'>
                  <NavItem>
                    <NavLink className='btn btn-nav-gray lg' to='/login'>Login</NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink className='btn btn-nav-gray lg' to='/register'>Register</NavLink>
                  </NavItem>
                </div>
              </div>
            )}

            <div className='mobile-nav-footer'>
              <NavItem>
                <NavLink className='btn-nav-gray lg' to='/about'>About</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='/our-services'>Our Services</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='/trust-safety'>Trust &amp; Safety</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='/faq'>FAQ</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='for-stylists'>Become a Blush Pro</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='special-events'>Special Events</NavLink>
              </NavItem>

              <NavItem>
                <a href="https://cdn.giftup.app/dist/checkout.html?site=70ce1775-75cc-4997-34c4-08da9590d9f1&environment=Production&instance=5tan7839tsk#" target="_blank" className='btn-nav-gray lg'>
                  Gift Certificate
                </a>
              </NavItem>

              <NavItem>
                <NavLink className='btn-nav-gray lg' to='/contact'>Contact us</NavLink>
              </NavItem>
            </div>
          </Nav>
        </Menu>
        <main id='page-wrap'>
          {this.props.children}
        </main>
      </MobileNavContext.Provider>
    )
  }

};

export default withSession(withRouter(MobileNavContextProvider));
