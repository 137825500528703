import React, { useEffect, useState } from 'react';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { isEmpty } from 'utils/helpers';

const UserContext = React.createContext();

const UserProviderWrapper = (props) => {
  const { userId, history, session, store, toast, children } = props;
  const [ user, setUser ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
    return () => { updateAdapters(user) }
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.findRecord('user', userId, { include: 'location,settings' });
      setAdapters(model);
      setUser(model);
      setLoaded(true);
    } catch (e) {
      toast.showError(e);
      history.push('/login');
    } finally {
      setLoading(false);
    }
  }


  // Methods
  const setAdapters = (user) => {
    store.adapterFor('booking').set('userId', user.id);
    store.adapterFor('card').set('userId', user.id);
    store.adapterFor('charge').set('userId', user.id);
    store.adapterFor('location').set('userId', user.id);
    store.adapterFor('notification').set('userId', user.id);
    store.adapterFor('promo').set('userId', user.id);
    store.adapterFor('user-setting').set('userId', user.id);
    store.adapterFor('setup').set('userId', user.id);
  }

  const updateAdapters = () => {
    store.adapterFor('booking').set('userId', null);
    store.adapterFor('card').set('userId',null);
    store.adapterFor('charge').set('userId', null);
    store.adapterFor('location').set('userId', null);
    store.adapterFor('notification').set('userId', null);
    store.adapterFor('promo').set('userId', null);
    store.adapterFor('user-setting').set('userId', null);
    store.adapterFor('setup').set('userId', null);
  }


  // Render
  return (
    <UserContext.Provider value={user}>
      {loaded ? children : null}
    </UserContext.Provider>
  )
};

const withUser = function(WrappedComponent) {
  return (props) => (
    <UserContext.Consumer>
      {context => <WrappedComponent user={context} {...props} />}
    </UserContext.Consumer>
  );
};

const UserProvider = withSession(withStore(withToast(UserProviderWrapper)));

export { UserProvider, withUser };
