import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import AuthApi from 'apis/auth-api';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import { NavLink, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const ForgotPasswordForm = (props) => {
  const { store, toast } = props;
  const [ email, setEmail ] = useState('')
  const [ complete, setComplete ] = useState(false)
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const submitForm = async (data) => {
  	try {
      setTaskRunning(true);
  		let user = await AuthApi.forgotPassword(data);
      toast.showSuccess('Email sent!');
      setComplete(true);
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const handleSubmit = (e) => {
		let data = { email: email };
		submitForm(data);
		e.preventDefault();
	}


	// Render
  return (
    <Form className='form-block border' onSubmit={handleSubmit}>
      <div className='form-title'>
        <h4>Forgot Password</h4>
      </div>
      {complete ? (
        <div>
          <div className='form-body xs'>
            <Empty
              type='index'
              icon='paper-plane'
              title='Email sent!'
              msg="Please check your inbox for further instructions."
            />
          </div>

          <div className='form-footer'>
            <NavLink className='btn btn-primary btn-block' size='lg' href='/'>Back Home<FAWrapper icon={'chevron-right'} className='ml-15'/></NavLink>
          </div>
        </div>
      ) : (
        <div>
          <div className='form-body xs'>
            <FormGroup>
              <Label for='email'>Your Email Address</Label>
              <Input id='email'
                type='email'
                value={email}
                  onChange={(e) => setEmail(e.target.value)}
                placeholder='kate@ck.com'
              />
            </FormGroup>

            <FormGroup>
              <i>We'll send instructions on how to reset your password to the email address above.</i>
            </FormGroup>
          </div>

          <div className='form-footer'>
            <Button type='submit' color='primary' size='lg' block>
              {taskRunning ? <LoadingIndicator color='white' size='18px' /> : 'Send Email Instructions' }
            </Button>
          </div>
        </div>
      )}
    </Form>
  )
}

export default withStore(withToast(ForgotPasswordForm));
