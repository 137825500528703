import React, { useEffect, useState } from 'react';
import { isEmpty, logger, timeout } from 'utils/helpers';

const PixelContext = React.createContext(null);

const PixelProvider = ({ children }) => {
  const [ pixel, setPixel ] = useState({});
  const [ user, setUser ] = useState({});
  const [ loaded, setLoaded ] = useState(false);
  
  const events = {
    AddPaymentInfo: ['content_category', 'content_ids', 'contents', 'currency', 'value'],
    AddToCart: ['content_ids', 'content_name', 'content_type', 'contents', 'currency', 'value'],
    CompleteRegistration: ['content_name', 'currency', 'status', 'value'],
    InitiateCheckout: ['content_category', 'content_ids', 'contents', 'currency', 'num_items', 'value'],
    Lead: ['content_category', 'content_name', 'currency', 'value'],
    Purchase: ['content_ids', 'content_name', 'content_type', 'contents', 'currency', 'num_items', 'value'],
  }

  const advancedMatching = (newUser = {}) => {
    if (isEmpty(newUser)) return {}
    return {
      em: newUser ? newUser.email : user.email,
      fn: newUser ? newUser.firstName : user.firstName,
      ln: newUser ? newUser.lastName : user.lastName,
    }
  }
  

  // Hooks
  useEffect(() => {
    if (typeof window !== 'undefined') init();
  }, [])


  // Private Methods
  const init = async () => {
    await buildTracker();
    setLoaded(true);
  }


  const buildTracker = async (newUser) => {
    if (!isEmpty(pixel)) return pixel;
    const ReactPixel = await import('react-facebook-pixel');
    ReactPixel.default.init(window.env.REACT_APP_FB_PIXEL, advancedMatching(newUser), { autoConfig: true, debug: false });
    setPixel(ReactPixel.default);
  }

  
  // Public Methods
  const trackEvent = async (eventName, data) => {
    if (eventName in events) {
      return pixel.track(eventName, data);
    }
  }

  const trackView = async (url) => {
    const ReactPixel = await buildTracker();
    return pixel.pageView();
  }

  const trackUser = async (eventName, newUser, data) => {
    setUser(newUser);
    const ReactPixel = await buildTracker(newUser);
    if (eventName in events) {
      return pixel.track(eventName, data);
    }
  }



  // Render
  return (
    <PixelContext.Provider value={{ trackEvent, trackView, trackUser, loaded }}>
      {children}
    </PixelContext.Provider>
  )
}


const withPixel = function(WrappedComponent) {
  return (props) => (
    <PixelContext.Consumer>
      {context => <WrappedComponent pixel={context || {}} {...props} />}
    </PixelContext.Consumer>
  )
}

export { PixelProvider, withPixel }

// Docs
// https://www.npmjs.com/package/react-facebook-pixel
// https://github.com/zsajjad/react-facebook-pixel
// https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
