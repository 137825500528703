import React, { Fragment, useState } from 'react';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button } from 'reactstrap';

const BasicButton = (props) => {
	const { type = 'button', title, size, color = 'primary', startIcon, endIcon, disabled, onClick, taskRunning, className = '', children } = props;

	return (
		<Button type={type} size={size} color={color} className={className} disabled={disabled} onClick={onClick}>
			{taskRunning ? <LoadingIndicator color='white' size='18px'/> : (
				<Fragment>
					{startIcon && <FAWrapper icon={startIcon} className='mr-15'/>}
					{title && title}
					{endIcon && <FAWrapper icon={endIcon} className='ml-15'/>}
					{children}
				</Fragment>
			)}
		</Button>
	)
}

const TextButton = (props) => {
	const { type = 'button', title, size = 'xs', color = 'primary', startIcon, endIcon, disabled, taskRunning, onClick, className = '', children } = props;

	return (
		<Button type={type} size={size} color={`text-${color}`} className={className} disabled={disabled} onClick={onClick}>
			{taskRunning ? <LoadingIndicator color='white' size='18px'/> : (
				<Fragment>
					{startIcon && <FAWrapper icon={startIcon} className='mr-15'/>}
					{title && title}
					{endIcon && <FAWrapper icon={endIcon} className='ml-15'/>}
					{children}
				</Fragment>
			)}
		</Button>
	)
}

const PrevButton = (props) => {
	const { type = 'button', title, icon = 'chevron-left', size, color = 'gray', disabled, onClick, taskRunning, className = '' } = props;

	return (
		<Button type={type} color={`text-${color}`} size={size} className={className} disabled={disabled} onClick={onClick}>
			{taskRunning ? <LoadingIndicator color='gray' size='18px'/> : (
				<Fragment>
					{icon && <FAWrapper icon={icon} className='mr-15' />}
					{title && title}
				</Fragment>
			)}
		</Button>
	)
}

const NextButton = (props) => {
	const { type = 'button', icon = 'chevron-right', title, color = 'primary', disabled, onClick, taskRunning, className = '' } = props;

	return (
		<Button type={type} color={color} disabled={disabled} onClick={onClick} className={className}>
			{taskRunning ? <LoadingIndicator color='white' size='18px'/> : (
				<Fragment>
					{title && title}
					{icon && <FAWrapper icon={icon} className='ml-15'/>}
				</Fragment>
			)}
		</Button>
	)
}

const SubmitButton = (props) => {
	const { icon = 'chevron-right', title, color = 'primary', disabled, onClick, taskRunning, className = '' } = props;

	return (
		<Button type='submit' color={color} disabled={disabled} onClick={onClick} className={className}>
			{taskRunning ? <LoadingIndicator color='white' size='18px'/> : (
				<Fragment>
					{title && title}
					{icon && <FAWrapper icon={icon} className='ml-15'/>}
				</Fragment>
			)}
		</Button>
	)
}

export { BasicButton, TextButton, PrevButton, NextButton };
