import React, { useState } from 'react';

import FAWrapper from 'components/vendors/fa-wrapper';
import { Lorem1, Lorem2, Lorem3, Lorem4 } from 'utils/placeholders';

const MktTestimonial = (props) => {
	const { photo, name, location, msg, className = '' } = props;

	return (
		<div className={'mkt-testimonial-block flex-start-top ' + className}>
			<img className='mkt-testimonial-img animated fadeInLeft' src={photo} />
			<div className='mkt-testimonial-content animated fadeInUp delay-100ms'>
				<blockquote>{Lorem1}</blockquote>
				<h5 className='bold animated fadeInUp delay-100ms'>{name}</h5>
				<h6 className='animated fadeInUp delay-200ms'>{location}</h6>
			</div>
		</div>
	)
}

export default MktTestimonial;
