import AppModel from 'models/app';
import Accounting from 'accounting';

class Balance extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		
		// Attrs
		this.pendingBalance = props.pendingBalance;
		this.availableBalance = props.availableBalance;

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}


	// Finances
	get displayPendingBalance() {
		return Accounting.formatMoney(this.pendingBalance);
	}

	get displayAvailableBalance() {
		return Accounting.formatMoney(this.availableBalance);
	}


}

export default Balance;