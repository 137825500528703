import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button } from 'reactstrap';

const AdminAnnouncement = (props) => {
	const { provider, className = '' } = props;
	const [ active, setActive ] = useState(true);


	if (active && !provider.isActive) {
		return (
			<div className={'admin-announcement-block flex-between ' + provider.status + ' ' + className}>
				<h6 className='white-color'><FAWrapper icon='exclamation-triangle' className='mr-3'/>Please complete the setup guide! <Link to={`/admin/${provider.id}/getting-started`} className='white-color underline'>Get Started</Link></h6>
				<Button color='text-lighter-gray' onClick={() => setActive(!active)}><FAWrapper icon='times-circle'/></Button>
			</div>
		)
	}
	return null;
}

export default AdminAnnouncement;
