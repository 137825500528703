import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CardForm from 'components/card/card-form';
import CardList from 'components/card/card-list';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Breadcrumb, BreadcrumbItem, Button, Modal } from 'reactstrap';
import { isEmpty, timeout } from 'utils/helpers';

const stripePromise = loadStripe(typeof window === 'undefined' ? '' : window.env.REACT_APP_STRIPE_KEY);

const CardSection = (props) => {
  const { type = 'grid', user, store, toast } = props;
  const [ cards, setCards ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ loading, setLoading ] = useState(false);
  const [ modal, toggleModal ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let cards = await store.query('card', params());
      setCards(cards);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    return params;
  }


  // Render
  return (
    <SectionBlock>
      <Modal isOpen={modal}>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <CardForm
                user={user}
                stripe={stripe}
                elements={elements}
                modal={modal}
                toggleModal={() => toggleModal(!modal)}
                nextAction={() => fetchData()}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </Modal>

      <SectionTitle title='Cards' className='flex-between'>
        <Button color='primary' size='md' onClick={() => toggleModal(!modal)}>Add Card</Button>
      </SectionTitle>

      <SectionBody className='md'>
        <CardList
          type={type}
          cards={cards}
          loading={loading}
          nextAction={() => fetchData()}
        />
      </SectionBody>
      <SectionFooter>
        <PaginationWrapper meta={cards.meta} setPage={v => setPage(v)} />
      </SectionFooter>

    </SectionBlock>
  )
}

export default withStore(withToast(CardSection));
