import AppAdapter from 'adapters/app';

class CustomerAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
}

export default CustomerAdapter;