import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { MobileNavContext } from 'contexts/mobile-nav-context';
import UserDropdown from 'components/user/user-dropdown';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Nav, Navbar, NavbarToggler, NavbarBrand, NavItem } from 'reactstrap';
import { BlushLogo } from 'utils/assets/branding';

const SimpleNav = (props) => {
	const { session } = props;

  const logout = () => {
  	props.session.logout();
  	props.history.push('/');
  }

	return (
		<Navbar className='navbar container-fluid flex-between'>
			<NavbarBrand href='/'><img src={BlushLogo} /></NavbarBrand>

			<div className='flex-end'>

				<MobileNavContext.Consumer>
					{state => (
						<NavbarToggler className={state.isOpen ? 'btn hamburger hamburger--squeeze is-active' : 'btn hamburger hamburger--squeeze'} type='button' onClick={state.toggleOpen}>
						  <span className='hamburger-box'>
						    <span className='hamburger-inner'></span>
						  </span>
						</NavbarToggler>
					)}
				</MobileNavContext.Consumer>

				<Nav className='ml-auto flex-end' navbar>
					{session.isAuthenticated() ? (
						<UserDropdown user={session.user} logout={logout} />
					) : (
						<NavItem className='flex'><NavLink to='/register' className='btn btn-primary btn-md ml-15'>Get Started<FAWrapper icon={'chevron-right'} className='sm ml-2'/></NavLink></NavItem>
					)}
				</Nav>

			</div>
		</Navbar>
	)
}

export default withSession(withRouter(SimpleNav));
