import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { ReviewPreviewCard } from 'components/review/review-card';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Modal, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ReviewSection = (props) => {
  const { review = {}, loading, className = '' } = props;

	return (
    <SectionBlock>
      <SectionTitle title='Review' className='flex-between' />
      <SectionBody className='xs'>
      	{review.id ? (
      		<ReviewPreviewCard
      			review={review}
      			loading={loading}
    			/>
    		) : (
	    		<Empty
			      icon='star-exclamation'
			      title='Review'
			      msg="This booking doesn't have a review yet."
		      />
    		)}
  		</SectionBody>
		</SectionBlock>
	)
}

export default ReviewSection;
