import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import RefundList from 'components/refund/refund-list';
import Empty from 'components/misc/empty';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const RefundSection = (props) => {
  const { type, model = [], bookingId, store, toast, dataLoading = false } = props;
  const [ refunds, setRefunds ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('');
  const [ sortValue, setSortValue ] = useState('');
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    dataLoading ? fetchData() : setRefunds(model);
  }, [model, page, pageSize]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('refund', params());
      setRefunds(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    return params;
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Refunds' className='flex-between' />
      <SectionBody>
        <RefundList refunds={refunds} loading={loading} />
      </SectionBody>
      <SectionFooter>
        <PaginationWrapper meta={refunds.meta} setPage={v => setPage(v)} />
      </SectionFooter>
    </SectionBlock>
  )
}

export default withStore(withToast(RefundSection));
