import React, { useEffect, useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FilestackDocWrapper from 'components/vendors/filestack-doc-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { isEmpty } from 'utils/helpers';
import { inputProp, setProp } from 'utils/store-helpers'
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Button, Dropdown, Form, FormGroup, Label, Modal, Input } from 'reactstrap';

const LicenseForm = (props) => {
  const { model = {}, licenseType = 'cosmetology', provider, store, toast, modal, toggleModal, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ license, setLicense ] = useState({});


  // Hooks
  useEffect(() => {
    isEmpty(model) ? createLicense() : setLicense(model);
  }, [])


  // Methods
  const saveLicense = async () => {
    try {
      setTaskRunning(true)
      await license.save();
      toast.showSuccess('License saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const createLicense = () => {
    let model = store.createRecord('license', {
      licenseType: licenseType,
      provider: provider
    });
    setLicense(model);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!taskRunning) saveLicense();
  }


  // Render
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <SectionBlock className='basic'>
        <SectionTitle title='Licensing' className='flex-between'>
          {modal && <Button color='text-gray' onClick={() => toggleModal()}><FAWrapper icon='times'/></Button>}
        </SectionTitle>
        <SectionBody className='md'>

          <FormGroup>
            <FilestackDocWrapper
              file={license.url}
              updateName={d => setProp(license, 'fileName', d)}
              updateUrl={d => setProp(license, 'url', d)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='license_type'>License Type</Label>
            <Input id='license_type'
              type='select'
              value={license.licenseType}
              onChange={d => inputProp(license, 'licenseType', d)}>
              <option value='cosmetology'>Cosmetology License</option>
              <option value='insurance'>Insurance Certificate (optional)</option>
              <option value='driver'>Drivers License</option>
              <option value='other'>Other</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for='notes'>Notes (optional)</Label>
            <Input id='notes'
              type='textarea'
              rows='3'
              defaultValue={license.notes}
              placeholder='Please share any relevant information...'
              onChange={e => inputProp(license, 'notes', e)}
            />
          </FormGroup>

        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            type='submit'
            icon='check'
            title='Save'
            taskRunning={taskRunning}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withAdmin(withStore(withToast(LicenseForm)));
