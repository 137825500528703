import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import { TextButton } from 'components/bootstrap/button-wrapper';
import { ModalBlock, ModalHeaderImg, ModalBody, ModalFooter } from 'components/basics/modals';
import { Container, Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { timeout, isEmpty } from 'utils/helpers';

const ServicePreview = (props) => {
  const { model = {}, store, toast, toggleModal, children, type = '' } = props;
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState({});


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    if (!props?.showLocalData) {
      try {
        setLoading(true);
        let service = await store.queryRecord('service', model.id, { include: 'category' });
        setService(service);
      } catch (e) {
        toast.showError(e);
      } finally {
        setLoading(false)
      }
    } else {
      setService(model)
    }
  }

  const selectService = (serviceId) => {
    onClick(serviceId);
    toggleModal();
  }


  // Render
  return (
    <ModalBlock>
      <ModalHeaderImg>
        <img alt={service.title} src={service.photo} />
        <TextButton color='white' onClick={toggleModal}><FAWrapper icon='times' size='2x' /></TextButton>
      </ModalHeaderImg>

      <ModalBody>
        <Badge color='gray mb-2' pill>{service.category && service.category.title}</Badge>
        <h4 className='title'>{service.title}</h4>
        <h6 className='subtitle'>{service.displayPrice + (type === 'mkt' ? '+ ' : '') + service.displayDuration}</h6>
        <h6 className='p'>{service.description}</h6>
      </ModalBody>
      {children && (
        <ModalFooter className='flex-between'>
          {children}
        </ModalFooter>
      )}
    </ModalBlock>
  )
}


export default withStore(withToast(ServicePreview));
