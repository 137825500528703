import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import ServiceList from 'components/service/service-list';
import { Collapse, Badge, Button } from 'reactstrap';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';

const CategorySectionCard = (props) => {
  const { category = {}, showServices, selectedIDs, onClick, className = '' } = props;
  const [ collapse, setCollapse ] = useState(false);

  useEffect(() => { showServices && setCollapse(true) }, []);

  return (
    <div className={'card section ' + className}>
      <button type='button' className='btn btn-block btn-lg flex-between' onClick={() => setCollapse(!collapse)}>
        <h4>{category.title}</h4>
        <FAWrapper icon='plus' size='2x' transform={collapse ? { rotate: 135 } : { rotate: 0 } } />
      </button>
      <Collapse isOpen={collapse}>
        <SectionBody type='basic' className='no-m sm'>
          <ServiceList
            type='mkt'
            categoryId={category.id}
            services={category.services}
            onClick={onClick}
          />
        </SectionBody>
      </Collapse>
    </div>
  )
}

const CategorySelectCard = (props) => {
  const { category = {}, showServices, selectedIDs, onClick, className = '' } = props;
  const [ collapse, setCollapse ] = useState(false);

  useEffect(() => { showServices && setCollapse(true) }, []);

  return (
    <div className={'card section ' + className}>
      <button type='button' className='btn btn-block btn-lg flex-between' onClick={() => setCollapse(!collapse)}>
        <h4>{category.title}</h4>
        <FAWrapper icon='plus' size='2x' transform={collapse ? { rotate: 135 } : { rotate: 0 } } />
      </button>
      <Collapse isOpen={collapse}>
        <SectionBody type='basic' className='no-m sm'>
          <ServiceList
            type='select'
            selectedIDs={selectedIDs}
            services={category.services}
            onClick={onClick}
            bookingFlow={props?.bookingFlow}
            editBooking={props?.editBooking}
            booking={props?.booking}
          />
        </SectionBody>
      </Collapse>
    </div>
  )
}

export { CategorySectionCard, CategorySelectCard };
