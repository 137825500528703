import React, { Fragment, useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import AdminAnnouncement from 'components/admin/admin-announcement';
import AdminNav from 'components/navs/admin-nav';
import AdminSidenav from 'components/admin/admin-sidenav';
import AdminContainer from 'components/admin/admin-container';

const AdminMain = (props) => {
  const { provider, children } = props;
  const [ menu, toggleMenu ] = useState(false);

  // Render
	return (
    <Fragment>
      <AdminAnnouncement provider={provider} className={menu ? 'collapsed' : ''}/>
      <AdminNav providerId={provider.id} className={menu ? 'collapsed' : ''}/>

			<main className={menu ? 'admin-main collapsed' : 'admin-main'}>
        <AdminSidenav
          providerId={provider.id}
          isCollapsed={menu}
          toggle={() => toggleMenu(!menu)}
          className={menu ? 'collapsed' : ''}
        />
        <AdminContainer className={menu ? 'collapsed' : ''}>
          {children}
        </AdminContainer>
    	</main>
    </Fragment>
	)
}

export default withAdmin(AdminMain);
