import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import ServiceList from 'components/service/service-list';
import { BookingSummaryCard } from 'components/booking/booking-card';
import { CustomerPreviewCard } from 'components/customer/customer-card';
import BookingFinancePreview from 'components/booking/booking-finance-preview';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { PreviewBlock, PreviewTitle, PreviewBody } from 'components/blocks/preview-blocks';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button } from 'reactstrap';
import { getTimezoneFromLatLong } from 'utils/common/commonFunctions';

const BookingPreview = (props) => {
  const { bookingId, store, toast, showActions, action } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(true);



  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let booking = await store.queryRecord('booking', bookingId, { include: 'card,customer,location,provider,services' });
      const timezoneAbbreviation = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude);
      booking.timezoneAbbreviation = timezoneAbbreviation ? timezoneAbbreviation : ''
      setBooking(booking)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false)
    }
  }



  // Render
  return (
    <SectionBlock className='basic'>
      <SectionTitle title={'Booking #' + booking.id} className='flex-between'>
        <Button color='icon-gray' onClick={() => action()}><FAWrapper icon='times'/></Button>
      </SectionTitle>

      <SectionBody>

        <PreviewBlock className='bb'>
          <PreviewTitle title='Scheduling' />
          <PreviewBody>
            <BookingSummaryCard booking={booking} />
          </PreviewBody>
        </PreviewBlock>

        <PreviewBlock className='bb'>
          <PreviewTitle title='Customer' />
          <PreviewBody>
            <CustomerPreviewCard customer={booking.customer} />
          </PreviewBody>
        </PreviewBlock>

        <PreviewBlock className='bb'>
          <PreviewTitle title='Services' />
          <PreviewBody>
            <ServiceList
              type='preview'
              services={booking.services}
              loading={loading}
            />
          </PreviewBody>
        </PreviewBlock>

        <PreviewBlock className=''>
          <PreviewTitle title='Finances' />
          <PreviewBody>
            <BookingFinancePreview booking={booking} />
          </PreviewBody>
        </PreviewBlock>

      </SectionBody>

      <SectionFooter>
        <Link to={`bookings/${booking.id}`} className='btn btn-primary btn-lg btn-block'>
          View Booking<FAWrapper icon='chevron-right' className='ml-15'/>
        </Link>
      </SectionFooter>

    </SectionBlock>
  )
}

export default withStore(withToast(BookingPreview));
