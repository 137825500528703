import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import SimpleNav from 'components/navs/simple-nav';
import AppFooter from 'components/app/app-footer';
import ReviewFlow from 'components/review/review-flow';
import { Container } from 'reactstrap';

const ReviewIndexRoute = (props) => {
  const { history, location, session, store, toast } = props;
  const [ bookingId, setBookingId ] = useState(null);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = () => {
    try {
      setLoading(true);
      let values = QueryString.parse(location.search);
      setBookingId(values.bookingId);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const exitAction = () => history.push(`/user/${session.user.id}`);


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Book'/>
      <SimpleNav />
      <main className='flow-main'>
        {(!session.loading && bookingId) && (
          <ReviewFlow
            session={session}
            store={store}
            toast={toast}
            bookingId={bookingId}
            exitAction={exitAction}
          />
        )}
      </main>
      <AppFooter />
  	</div>
	);
}

export default withSession(withStore(withToast(ReviewIndexRoute)));
