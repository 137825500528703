import React, { Fragment, useState } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import DatePickerWrapper from "components/vendors/date-picker-wrapper";
import TimePickerWrapper from "components/vendors/time-picker-wrapper";
import { PrevButton, NextButton } from "components/bootstrap/button-wrapper";
import {
  SectionPreBlock,
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import { isEmpty } from "utils/helpers";
import moment from "moment";
import { useEffect } from "react";

const BookingFlowScheduler = (props) => {
  const { booking, session, store, toast, prevAction, nextAction } = props;
  const [refresh, setRefresh] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);
  const [isMDT, setIsMDT] = useState(false);
  // Methods

  useEffect(() => {
    // checkForMdtOrMst(moment());
  }, []);

  const setDate = (date) => {
    // checkForMdtOrMst(date);
    booking.updateStartDate(date);
  };

  const checkForMdtOrMst = (initialDate) => {
    function getSecondSundayInMarch(year) {
      let date1 = moment(`${year}-03-01`);
      let day = moment(`${year}-03-01`).day();

      if (day === 0) {
        // Check case if 1st of march falls on Sunday
        date1 = moment(`${year}-03-01`).add(1, "week").day(0);
      } else {
        date1 = moment(`${year}-03-01`).add(2, "week").day(0);
      }

      return date1.format("YYYY-MM-DD");
    }
    function getFirstSundayInNovember(year) {
      let date2 = moment(`${year}-11-01`);
      let day = moment(`${year}-11-01`).day();

      if (day === 0) {
        // Check case if 1st of November falls on Sunday
        date2 = moment(`${year}-11-01`).day(0);
      } else {
        date2 = moment(`${year}-11-01`).add(1, "week").day(0);
      }

      return date2.format("YYYY-MM-DD");
    }

    const year = initialDate.year(); // Change this to the desired year

    const marchSecondSunday = getSecondSundayInMarch(year);
    const novemberFirstSunday = getFirstSundayInNovember(year);

    // Define your start and end dates
    const startDate = new Date(marchSecondSunday);
    const endDate = new Date(novemberFirstSunday);

    // Date to check
    const dateToCheck = new Date(initialDate.format("YYYY-MM-DD"));

    // console.log("Second Sunday in March:", marchSecondSunday);
    // console.log("First Sunday in November:", novemberFirstSunday);
    // console.log("date", initialDate.format("YYYY-MM-DD"));

    if (dateToCheck >= startDate && dateToCheck <= endDate) {
      // console.log("The input date is in  (MDT).");
      setIsMDT(true);
    } else {
      // console.log("The input date is  (MST).");
      setIsMDT(false);
    }
  };

  const setTime = (date) => {
    booking.updateStartDate(date);
  };

  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title="Back" onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionTitle
          title={"Select date / time"}
        />
        <SectionBody className="md">
          <Row>
            <Col md="7" className="d-none d-md-block">
              <FormGroup>
                <DatePickerWrapper
                  type="in-place"
                  date={booking.startDate}
                  showDropdown={false}
                  setDate={(date) => setDate(date)}
                />
              </FormGroup>
            </Col>

            <Col md="5" className="d-none d-md-block">
              <FormGroup>
                <TimePickerWrapper
                  type="inline"
                  mode="booking"
                  date={booking.startDate}
                  booking={booking}
                  setTime={(date) => setTime(date)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="7" className="d-block d-md-none">
              <FormGroup>
                <Label>Select Date</Label>
                <DatePickerWrapper
                  type="dropdown"
                  date={booking.startDate}
                  booking={booking}
                  showDropdown={false}
                  setDate={(date) => setDate(date)}
                />
              </FormGroup>
            </Col>

            <Col md="5" className="d-block d-md-none">
              <FormGroup>
                <Label>Select Time</Label>
                <TimePickerWrapper
                  type="dropdown"
                  mode="booking"
                  date={booking.startDate}
                  booking={booking}
                  setTime={(date) => setTime(date)}
                />
              </FormGroup>
            </Col>
          </Row>
        </SectionBody>
        <SectionFooter className="flex-between">
          <NextButton
            title="Next"
            taskRunning={taskRunning}
            disabled={isEmpty(booking.startDate)}
            onClick={nextAction}
            className="btn-block btn-lg"
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  );
};

export default BookingFlowScheduler;
