import React, { useState } from 'react';
import { withSession } from 'contexts/session-context';
import { withPixel } from 'contexts/pixel-context';
import Script from 'react-load-script';
import AuthApi from 'apis/auth-api';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button } from 'reactstrap';
import { logger } from 'utils/helpers';
import TermsConditionPopup from './terms-condition-popup';
import serializerFor from 'store/serializer-for';
import Axios from 'utils/axios';
import JsonApiError from 'utils/json-api-error';
import adapterFor from 'store/adapter-for';

const GoogleBtn = ({ pixel, session, toast, nextAction, className = '', isDisable, signature, isProviderAuth }) => {
  const [auth, setAuth] = useState('');
  const [taskRunning, setTaskRunning] = useState(false);
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  const [signatureData, setSignatureData] = useState('');
  const [userInfo, setUserInfo] = useState({});


  // Methods
  const googleAuth = async (data, isSignIn) => {
    try {
      setTaskRunning(true);
      let user = await AuthApi.googleAuth(data);
      setUserInfo(user)
      if (user?.providerId && (!user?.termsCondition || !user?.signedUrl)) {
        setUserInfo(user);
        setTermsConditionModal(true)
      } else {
        user = await session.authenticate(user);
        toast.showSuccess('User logged in!');
        pixel.trackEvent('CompleteRegistration', user, { content_name: 'GoogleAuth' });
        nextAction(user.id);
      }
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const initGoogle = () => {
    window.gapi.load('auth2', () => {
      let auth = gapi.auth2.init({
        client_id: typeof window === 'undefined' ? '' : window.env.REACT_APP_GOOGLE_AUTH_ID,
        cookiepolicy: 'single_host_origin',
      })
      setAuth(auth)
    });
  }
  const base64ToFile = async (base64) => {
    const res = await fetch(base64)
    const buf = await res.arrayBuffer()
    const file = new File([buf], "signature.jpeg", {
      type: 'image/jpeg',
    })
    return file;
  };
  const update = async (data, user) => {
    try {
      let url = adapterFor(user?.type).urlForUpdateRecord(user?.type, user?.id);
      let axios = new Axios().instance();
      let response = await axios.put(url, data);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
  const updateProfile = async (signatureUrl) => {
    let userData = {
      ...userInfo,
      termsCondition: true,
      signedUrl: signatureUrl,
    }

    try {
      let data = serializerFor('user').serialize(userData);
      let response = await update(data, userData)
      let formattedResponse = serializerFor('user').normalize(response.data, response.included, response.meta);

      user = await session.authenticate(formattedResponse);
      toast.showSuccess('User logged in!');
      pixel.trackEvent('CompleteRegistration', formattedResponse, { content_name: 'GoogleAuth' });
      nextAction(formattedResponse.id);
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }
  const uploadSignature = async (data, isSignIn, sign) => {
    if (signature || sign) {
      const fileData = await base64ToFile(signature || sign);
      try {
        const baseURL = typeof window === 'undefined' ? '' : window.env.REACT_APP_API_DOMAIN;
        var formdata = new FormData();
        formdata.append("media", fileData, `signature.jpeg`);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(baseURL + "/upload", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (isSignIn) {
              updateProfile(result?.photo)
            } else {
              googleAuth({ ...data, signedUrl: result?.photo }, isSignIn)
            }
          })
          .catch(error => console.log('error', error));
      } catch (e) {
        console.log(e);
      }
    }
  }
  const googleLogin = () => {
    if (!isDisable) {
      auth.signIn({ scope: 'profile email openid' })
        .then(google => {
          let profile = google.getBasicProfile();
          let data = {
            name: profile.getName(),
            email: profile.getEmail(),
            photo: profile.getImageUrl(),
            googleId: profile.getId()
          };
          if (isProviderAuth && signature) {
            uploadSignature(data, false);
          } else {
            googleAuth(data, true);
          }
          // googleAuth(data);
        }).catch(e => logger(e));
    } else if (isProviderAuth) {
      toast.showError([{ detail: 'Please agree to terms of service.' }]);
    }
  }


  // Render
  return (
    <div className='googleSignInWrapper'>
      <Button id='google_signin' type='button' color='google' size='lg' className={className} onClick={() => googleLogin()} block>
        {taskRunning ? <LoadingIndicator size='18' color='white' /> : <span><FAWrapper icon='google' iconFam='fab' className='mr-15' /> Login</span>}
      </Button>
      <Script url='https://apis.google.com/js/platform.js' onLoad={() => initGoogle()} />
      <TermsConditionPopup
        isOpen={termsConditionModal}
        onSignature={(img) => {
          setSignatureData(img)
          uploadSignature({}, true, img)
        }}
        isCancelHide={true}
        dissmissModal={() => { setTermsConditionModal(false) }} />
    </div>
  )
}


export default withSession(withPixel(GoogleBtn));
