import React, { useState } from 'react';

import FAWrapper from 'components/vendors/fa-wrapper';

const MktColumn = (props) => {
	const { type, img, icon, title, msg, url, className = '' } = props

	if (icon) {
		return (
			<div className={'mkt-column-block ' + type + ' ' + className}>
				<FAWrapper icon={icon} iconFam='fad' size='3x' className='mkt-column-icon' />
				<div className='card-body'>
					<h5>{title}</h5>
					<h6>{msg}</h6>
				</div>
			</div>
		)
	} else {
		return (
			<div className={'mkt-column-block' + ' ' + type + ' ' + className}>
				<img className='mkt-column-img' src={img} />
				<div className='card-body'>
					<h5>{title}</h5>
					<h6>{msg}</h6>
				</div>
			</div>
		)
	}
}

export default MktColumn;
