import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Empty from 'components/misc/empty';
import { ProviderPreviewCard } from 'components/provider/provider-card';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Modal, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ProviderSection = (props) => {
  const { provider = {}, loading, className = '' } = props;

  return (
    <SectionBlock>
      <SectionTitle title='Stylist' className='flex-between' />
      <SectionBody className='xs'>
        {provider.id ? (
          <ProviderPreviewCard provider={provider} loading={loading}
          />
        ) : (
          <Empty
            icon='briefcase'
            title='Provider'
            msg="Sorry, looks like we don't have a provider on file."
          />
        )}
      </SectionBody>
    </SectionBlock>
  )
}

export default ProviderSection;
