import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { PhotoGridCard, PhotoPreviewCard } from 'components/photo/photo-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col, Button } from 'reactstrap';


const PhotoList = (props) => {
  const { type = 'list' , photos = [], loading, nextAction, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (photos.length > 0) {
    return (
      <Row>
        {type == 'preview' && photos.map(photo => (
          <Col md='12' key={photo.id}>
            <PhotoPreviewCard
              type={type}
              photo={photo}
              nextAction={nextAction}
              className={className}
            />
          </Col>
        ))}
        {type == 'grid' && photos.map(photo => (
          <Col xs='6' sm='6' md='6' lg='4' key={photo.id}>
            <PhotoGridCard
              type={type}
              photo={photo}
              photos={photos}
              nextAction={nextAction}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='camera'
      title='Photos'
      msg="Sorry, it looks like we don't have any photos on file yet."
    />
  )
}

export default PhotoList;
