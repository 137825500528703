import React from 'react';
import VimeoPlayer from '@u-wave/react-vimeo';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import { Container, Row, Col, Button } from 'reactstrap';
import Work from 'assets/headers/work.jpg';

const MktVideosRoute = (props) => {
  const { history } = props;

	return (
		<div className='route'>
      <Head title='Blush - Videos'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='md'
        title='Videos'
        subtitle="Watch our videos below to learn how Blush works and start building your professional brand."
        bg={Work}
      />

      <main className='mkt-main'>
       <section className='mkt-section'>
          <div className='mkt-section-body md'>
            <Container>
              <Row>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='getting_started'
                      video='454088082'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='appointments'
                      video='454087482'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='bookings'
                      video='454087638'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='edit_booking'
                      video='454087953'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='deny_booking'
                      video='454087835'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='accept_booking'
                      video='454087284'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='cancel_booking'
                      video='454087746'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='settings'
                      video='454088370'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={4}>
                  <div className='video-player-wrapper'>
                    <VimeoPlayer
                      id='schedule'
                      video='454088355'
                      className='vimeo-player'
                      color='#9B5D72'
                    />
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />
      </main>

      <MktFooter />
		</div>
	)
}

export default MktVideosRoute;
