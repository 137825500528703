import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';

import Head from 'components/basics/head';
import SimpleNav from 'components/navs/simple-nav';
import AppFooter from 'components/app/app-footer';
import BookingFlow from 'components/booking/booking-flow';

import { Container } from 'reactstrap';

const BookIndexRoute = (props) => {
  const { history, session, store, toast } = props;


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Book'/>
      <SimpleNav/>
      <main id='booking_flow' className='flow-main'>
        <BookingFlow
          session={session}
          store={store}
          toast={toast}
          exitAction={() => history.push('/')}
          nextAction={(user, booking) => history.push(`/user/${user.id}/bookings/${booking.id}`)}
        />
      </main>
      <AppFooter />
  	</div>
	);
}

export default withSession(withStore(withToast(withRouter(BookIndexRoute))));
