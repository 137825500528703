import React, { Fragment } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import ServiceList from 'components/service/service-list';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import { BookingInfoCard } from 'components/booking/booking-card';
import { ProviderLineCard } from 'components/provider/provider-card';
import { BasicButton, TextButton } from 'components/bootstrap/button-wrapper';
import { CartBlock, CartTitle, CartBody, CartFooter } from 'components/blocks/cart-blocks';
import { PreviewBlock, PreviewTitle, PreviewBody, PreviewFooter } from 'components/blocks/preview-blocks';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { pushRelation, pushProp } from 'utils/store-helpers';


const BookingFlowCart = (props) => {
  const { booking = {}, store, steps, step, changeStep, toggleMenu } = props;


  // Methods
  const removeProvider = (providerID) => {
    let provider = store.peekRecord('provider', providerID);
    booking.setRelation('provider', {});
  }

  const pushService = (serviceID) => {
    let service = store.peekRecord('service', serviceID);
    pushRelation(booking, 'services', service);
  }

  const updateStep = (step) => {
    changeStep(step);
    toggleMenu();
  }

  const nextStep = (step) => {
    let currentStep = step;
    let nextStep = steps.find(step => step.step == currentStep.step + 1)
    if (currentStep.step != 8) {
      changeStep(nextStep);
    }
    toggleMenu();
  }


	// Render
  return (
    <CartBlock className='lighter-gray-bg'>
      <CartTitle type='' className='flex-between'>
        <h3 className='bold'>Your Order</h3>
      </CartTitle>
      <CartBody>

        <PreviewBlock className='bb'>
          <PreviewTitle title='Scheduling' />
          <PreviewBody>
            <BookingInfoCard booking={booking} />
          </PreviewBody>
        </PreviewBlock>

        {!isEmpty(booking.provider) && (
          <PreviewBlock className='bb'>
            <PreviewTitle title='Provider' />
            <PreviewBody>
              <ProviderLineCard
                provider={booking.provider}
                onClick={providerId => removeProvider(providerId)}
              />
            </PreviewBody>
          </PreviewBlock>
        )}

        <PreviewBlock className=''>
          <PreviewTitle title='Services' />
          <PreviewBody className='xl'>
            {!isEmpty(booking.services) ? (
              <Fragment>
                <ServiceList
                  type='line'
                  services={booking.services}
                  onClick={serviceId => pushService(serviceId)}
                  booking={booking}
                  isMultipleService={true}
                />
                <BasicButton startIcon='plus' title='Add Service(s)' onClick={() => updateStep(steps.find(step => step.step == 2))} />
              </Fragment>
            ) : (
              <Empty
                type='index'
                icon='shopping-cart'
                title='Cart'
                msg='Your cart is currently empty.'>
                <TextButton startIcon='plus' title='Add Service(s)' onClick={() => updateStep(steps.find(step => step.step == 2))} />
              </Empty>
            )}
          </PreviewBody>
        </PreviewBlock>

      </CartBody>
      <CartFooter>
        <div className='p-2'>
          <div className='flex-between'>
            <h5 className='bold'>Total:</h5>
            <h4><span className='small bold gray-color mr-3'>USD</span>{booking.displayPreviewTotal}</h4>
          </div>
          <h6 className='gray-color'>**You will not be charged until the booking is complete.</h6>
        </div>
        <Button color='primary' size='lg' block onClick={() => nextStep(step)}>Next Step<FAWrapper icon='chevron-right' className='ml-15 sm'/></Button>
      </CartFooter>
    </CartBlock>
  )
}

export default withStore(withToast(BookingFlowCart));
