import AppAdapter from 'adapters/app';

class PromoAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static action = '';

}

export default PromoAdapter;