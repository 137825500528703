import React from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import SearchCard from 'components/cards/search-card';
import Accounting from 'accounting';
import Moment from 'react-moment';

const SearchBookingResult = (props) => {
	const { hit = {}, indexName, providerId, highlightIndex, className = '' } = props;

	return (
		<SearchCard
			icon='calendar'
			status={hit.status}
			title={'#' + hit.confirmationCode}
			line1={hit.customer && hit.customer.name}
			line2={<span>Start date: <Moment format='MMMM Do, YYYY'>{hit.startDate}</Moment></span>}
			link={`/admin/${providerId}/bookings/${hit.objectID}`}
			className='search-result'
		/>
	)
}

const SearchCustomerResult = (props) => {
	const { hit = {}, indexName, providerId, highlightIndex, className = '' } = props;

	return (
		<SearchCard
			icon='user'
			status={hit.status}
			title={hit.name}
			line1={hit.email}
			line2={<span>Created on <Moment format='MMMM Do, YYYY hh:mm'>{hit.createdAt}</Moment></span>}
			link={`/admin/${providerId}/customers/${hit.objectID}`}
			className='search-result'
		/>
	)
}

const SearchPayoutResult = (props) => {
	const { hit = {}, indexName, providerId, highlightIndex, className = '' } = props;

	return (
		<SearchCard
			icon='university'
			status={hit.status}
			title={Accounting.formatMoney(hit.amount)}
			line1={hit.bank && hit.bank.bankName + ' ••••' + hit.bank.lastFour}
			line2={<span>Created on <Moment format='MMMM Do, YYYY hh:mm'>{hit.createdAt}</Moment></span>}
			link={`/admin/${providerId}/payouts/${hit.objectID}`}
			className='search-result'
		/>
	)
}


export { SearchBookingResult, SearchCustomerResult, SearchPayoutResult };
