import React from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import CustomerForm from 'components/customer/customer-form';
import LoadingIndicator from 'components/loading/loading-indicator';

import { isEmpty } from 'utils/helpers';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminCustomersDetailRoute = (props) => {
  const { providerId, history, provider, store, toast } = props;


  // Methods
  const viewCustomer = (customerId) => {
    history.push(`/admin/${providerId}/customers/${customerId}`);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - New Customer'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/customers`}>Customers</Link></BreadcrumbItem>
        <BreadcrumbItem active>New</BreadcrumbItem>
      </Breadcrumb>

      <CustomerForm
        type='page'
        provider={provider}
        nextAction={customer => viewCustomer(customer.id)}
      />
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminCustomersDetailRoute)));
