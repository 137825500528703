import AppModel from 'models/app';
import { isEmpty, capitalize } from 'utils/helpers';

class License extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.licenseType = props.licenseType;
		this.fileName = props.fileName;
		this.url = props.url;
		this.notes = props.notes;

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}

	get providerId() {
		return this.provider ? this.provider.id : null;
	}

  get displayLicenseType() {
    return capitalize(this.licenseType);
  }

  get summary() {
    switch (this.displayLicenseType) {
      case 'Driver':
      return this.displayLicenseType + ' License';

      case 'Cosmetology': 
      return this.displayLicenseType + ' License';
      
      case 'Insurance': 
      return this.displayLicenseType + ' Certificate';

      default: 
      return 'Other License';
    }
  }
}

export default License;