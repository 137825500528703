import React, { useState } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';
import { Button, DropdownToggle, DropdownItem, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BookingFilter = (props) => {
	const { type, options, value, setFilter, className = '' } = props;
	const [ dropdownOpen, showDropdown ] = useState(false);


	// Methods
	const applyFilter = (type, option) => {
		return option == 'all' ? setFilter(type, '') : setFilter(type, option);
	}


	// Render
	return (
		<DropdownWrapper isOpen={dropdownOpen} toggle={() => showDropdown(!dropdownOpen)}>
	    <DropdownToggle color='text-gray' className='btn-sm'>
  			<span>{value ? value : type}<FAWrapper icon='chevron-down' className='ml-2'/></span>
			</DropdownToggle>

			<DropdownMenuWrapper isOpen={dropdownOpen} right={false}>
				{options.map(option => (
		      <DropdownItem tag='li' key={option}>
		      	<Button type='button' color='transparent' className='btn' onClick={() => applyFilter(type, option)}>
		      		{value == option ? (
		      			<span className='flex-between'>{option}<FAWrapper icon='check'/></span>
	      			) : (
	      				<span>{option}</span>
	      			)}
	      		</Button>
	      	</DropdownItem>
				))}
    	</DropdownMenuWrapper>
  	</DropdownWrapper>
	)
}

export default BookingFilter;
