import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import { BlushLogoGray } from 'utils/assets/branding';

const AppFooter = (props) => {
	const {} = props;

	return (
    <footer className='footer-block bt'>
    	<Container fluid>
  			<div className='footer-inner'>
					<div className='footer-logo mr-5'><NavLink to='/'><img src={BlushLogoGray}/></NavLink></div>
					<div className='footer-links flex-start'>
		      	<NavLink to='/terms-of-service' className='btn btn-nav-gray'>Terms</NavLink>
		      	<NavLink to='/privacy-policy' className='btn btn-nav-gray'>Privacy Policy</NavLink>
	      	</div>
      	</div>
    	</Container>
    </footer>
	)
}

export default AppFooter;

