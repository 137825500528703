import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { BlushIconPNG } from 'utils/assets/branding';
import { SEOIndex } from 'utils/assets/seo';

const MktHead = (props) => {
	const { title, description, img, url } = props;
	const { pathname } = useLocation();


  // Render
	return (
    <Helmet>
      <link rel="shortcut icon" href={BlushIconPNG} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {(typeof window !== 'undefined' && window.env.REACT_APP_NODE_ENV !== 'production') && <meta name="robots" content="noindex" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={SEOIndex} />
      <meta property="og:url" content={pathname} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={SEOIndex} />
      <meta property="twitter:card" content='summary_large_image' />
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-371597806"></script> 
      <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-371597806');`}</script>
    </Helmet>
	)
}


MktHead.defaultProps = {
	title: 'Blush | On-Demand Beauty',
	description: 'Blush is an on demand beauty app that connects you to cerified beauty professionals that will come to you.',
}


export default MktHead;
