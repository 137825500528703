import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { MakeupHeader } from 'utils/assets/headers';


const MktTrustSafetyRoute = (props) => {
  const { history } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Trust &amp; Safety'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='md'
        title='Trust &amp; Safety'
        subtitle="We recognize that every time you open your Blush app, you're putting your trust in our technology to connect you with a Blush Professional.  That trust is what drives us to continuously set the standard for World Class experiences, and more."
        bg={MakeupHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>
      <main className='mkt-main'>

         <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Verified</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>Each Blush Professional has completed over a thousand hours of training and are fully licensed and certified.</h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Safe</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>
                      We request that all Blush Professionals are fully insured with a multi-million dollar protection policy.
                      This guarantees that both the guest and Blush Professionals are cared for in the event of an unexpected injury or home damage.
                    </h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Background verified</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>
                      We care about the safety of our guests and Blush Professionals.
                      That’s why we verify all Blush professionals' information and conduct background checks on our Providers.
                    </h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>24-Hour Support</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>
                      We want to be here for you whenever you may need us.
                      Whether you have questions about the app, booking your appointment, or beauty services in general, our concierge team is available 24/7 via phone or email.
                    </h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
             <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Secure Payment</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>Our secure platform ensures your money gets to the Service Provider. That’s why
                    we always ask you to pay through Blush to guarantee you a receipt and a protected transfer of funds.</h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Review System</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>After each appointment, our Blush Professionals and our guests have the opportunity
                    to rate their experience so everyone always has a say! We also require our guests to provide valid
                    credit card information for identity verification purposes. Our team reads every review and will
                    quickly reach out to ensure any dissatisfaction is addressed.</h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row className='flex'>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Scam Prevention</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>By using Blush from booking to the post-beauty transaction, we’re able to
                    ensure a safe and secure experience. That’s why we always ask you to pay and communicate
                    directly through the Blush app.</h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>

      <MktFooter />
  	</div>
	);
}

export default MktTrustSafetyRoute;
