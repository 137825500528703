import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import LicenseForm from 'components/license/license-form';
import LicenseList from 'components/license/license-list';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { Button, Container, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const LicenseSection = (props) => {
  const { store, toast } = props;
  const [ licenses, setLicenses ] = useState([]);
  const [ modal, toggleModal ] = useState(false);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ loading, setLoading ] = useState(false);

  // Hooks
  useEffect(() => {
    fetchData();
  }, [page])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('license', params());
      setLicenses(model)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    return params;
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <Fragment>
      <Modal isOpen={modal}>
        <LicenseForm modal={modal} toggleModal={() => toggleModal(!modal)} nextAction={refreshData} />
      </Modal>
      <SectionBlock>
        <SectionTitle title='Licensing' className='flex-between'>
          <NextButton title='Add License' onClick={() => toggleModal(!modal)} />
        </SectionTitle>
        <SectionBody className='md'>
          <LicenseList
            type='grid'
            licenses={licenses}
            loading={loading}
            nextAction={() => fetchData()}
          />
        </SectionBody>
        <SectionFooter>
          <PaginationWrapper meta={licenses.meta} setPage={p => setPage(p)} />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  );
}

export default withStore(withToast(LicenseSection));
