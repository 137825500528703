import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import TimePickerWrapper from 'components/vendors/time-picker-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { setProp } from 'utils/store-helpers';
import { Row, Col, Button, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap';

const PayoutFormAuto = (props) => {
  const { type = 'form', provider, store, toast, modal, toggleModal, prevAction, refreshData } = props;
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ settings, setSettings ] = useState({})



  // Hooks
  useEffect(() => {
    fetchData(provider);
  }, [])


  // Tasks
  const fetchData = async (provider) => {
    try {
      setLoading(true);
      let settings = await store.findRecord('provider-setting', provider.settings.id);
      setSettings(settings)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const saveSettings = async () => {
    try {
      setTaskRunning(true)
      await settings.save();
      toast.showSuccess('Settings saved!');
      refreshData();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const handleSubmit = (e) => {
    e.preventDefault();
    saveSettings();
  }


  // Render
  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <SectionBlock className='basic'>
        <SectionTitle className='flex-between'>
          <h4>Automatic Payouts</h4>
          <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>
        </SectionTitle>

        <SectionBody className='sm'>

        <FormGroup>
          <Label for='schedule'>Schedule</Label>
          <Input
            id='schedule'
            type='select'
            onChange={d => setProp(settings.payoutSchedule, d)}>
            <option value='daily'>Daily</option>
          </Input>
        </FormGroup>

          <FormGroup>
            <Label>Run Time</Label>
            <TimePickerWrapper
              type='dropdown'
              date={settings.payoutDate}
              setTime={date => setProp(settings, 'payoutDate', date)}
            />
          </FormGroup>

          <FormGroup className='sm flex-between'>
            <Label>Turn on automatic payouts?</Label>
            <ButtonGroup>
              <Button color='' className='btn-toggle-primary' onClick={() => setProp(settings, 'automaticPayouts', false)} active={settings.automaticPayouts != true}>Off</Button>
              <Button color='' className='btn-toggle-primary' onClick={() => setProp(settings, 'automaticPayouts', true)} active={settings.automaticPayouts == true}>On</Button>
            </ButtonGroup>
          </FormGroup>

        </SectionBody>

        <SectionFooter className='flex-between'>
          <PrevButton
            title='Back'
            onClick={() => prevAction()}
          />
          <NextButton
            icon='check'
            title='Save'
            taskRunning={taskRunning}
            onClick={() => saveSettings()}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}


export default withStore(withToast(PayoutFormAuto));
