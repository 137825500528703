import AppModel from 'models/app';

class PromoCode extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		
		// Attrs
		this.status = props.status;
		this.code = props.code;
		this.amount = props.amount;
		this.totalQty = props.totalQty;

		
		// Relationships
		this.promos = this.hasMany('promos', props.promos);
	}

}

export default PromoCode;