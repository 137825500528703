import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container } from 'reactstrap';

const MktHeader = (props) => {
	const { title, subtitle, size = '', bg, className = '', children } = props;

	return (
		<div className={'mkt-header-block flex ' + size + ' ' + className} style={{backgroundImage: `url(${bg}`}}>
			<div className='mkt-header-overlay'></div>
			<Container>
				<div className='mkt-header-content'>
					<h2>{title}</h2>
					<h6>{subtitle}</h6>
					<div className='mt-15'>
						{children ? children : <hr />}
					</div>
				</div>
			</Container>
		</div>
	)
}

export default MktHeader;
