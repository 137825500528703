import AppModel from 'models/app';

class UserSetting extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		// Attrs
		this.emailNotifications = props.emailNotifications;
		this.smsNotifications = props.smsNotifications;
		this.pushNotifications = props.pushNotifications;
		this.mktNotifications = props.mktNotifications;

		
		// Relationships
		this.user = this.belongsTo('user', props.user);
	}


	// Computed

	
}

export default UserSetting;