import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AnalyticsRatingCard, AnalyticsStatCard } from 'components/analytics/analytics-card';
import LoadingIndicator from 'components/loading/loading-indicator';
import Empty from 'components/misc/empty';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty } from 'utils/helpers';
import { Row, Col } from 'reactstrap';

const AnalyticsSection = (props) => {
  const { provider = {}, store, toast } = props;
  const [ activities, setActivities ] = useState({});
  const [ analytics, setAnalytics ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    isEmpty(analytics) && fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let analytics = await store.queryRecord('analytics', {});
      setAnalytics(analytics);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Analytics' className='flex-between' />
      <SectionBody className='xs'>
        <Row>
          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Total Customers'
              description="Total # of unique customers."
              value={analytics.displayTotalCustomers}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Total Bookings'
              description="Total # of unique bookings."
              value={analytics.displayTotalBookings}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsRatingCard
              title='Avg Score'
              description={'Based on ' + analytics.displayTotalReviews + ' reviews'}
              value={analytics.avgScore}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Pending Commissions'
              description="Revenue that hasn't been charged."
              value={analytics.displayPendingCommissions}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Net Commissions'
              description="Revenue earned via commission."
              value={analytics.displayNetCommissions}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Net Tips'
              description="Revenue earned via tips."
              value={analytics.displayNetTips}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Net Refunds'
              description="Total refunds issued to clients."
              value={analytics.displayNetRefunds}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Total Revenue'
              description="Total revenue earned overall."
              value={analytics.displayNetRevenue}
              loading={loading}
            />
          </Col>

          <Col sm='6' md='4'>
            <AnalyticsStatCard
              title='Paid Revenue'
              description="Revenue that's been paid out."
              value={analytics.displayPaidRevenue}
              loading={loading}
            />
          </Col>
        </Row>
      </SectionBody>
    </SectionBlock>
  )
}

export default withStore(withToast(withToast(AnalyticsSection)));
