import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withCookie } from 'contexts/cookie-context';

import FAWrapper from 'components/vendors/fa-wrapper';
import { timeout } from 'utils/helpers';
import { Container, Row, Col, Button } from 'reactstrap';

const CookieBanner = (props) => {
  const { cookies } = props;
  const [ visible, setVisible ] = useState(false);


  // Hooks
  useEffect(() => {
    checkCookie();
  }, []);

  // Methods
  const checkCookie = async () => {
    await timeout(600);
    if (cookies.accepted()) {
      setVisible(false);
    } else if (!cookies.accepted()) {
      setVisible(true);
    }
  }


  const acceptCookies = () => {
    cookies.accept();
    setVisible(false);
  }


	// Render
  return (
    <div className={visible ? 'banner-block visible darker-gray-bg' : 'banner-block darker-gray-bg'}>
      <Container>
        <Row className='flex'>
          <Col sm='12' md='8'>
            <div className='banner-content'>
              <h5>We use cookies <FAWrapper icon='cookie-bite' iconFam='fad' className='ml-15'/></h5>
              <p>
                This website uses cookies. By continuing to use this website, you are giving consent to cookies being used.
                For more information, please visit our <Link to='/privacy' className='btn btn-text-white underline'>Privacy Policy</Link>
                <Link to='/privacy' className='btn btn-text-white underline'>Terms of Service</Link>.
              </p>
            </div>
          </Col>
          <Col sm='12' md='4'>
            <div className='banner-btns'>
              <Button
                type='button'
                color='white'
                size='md'
                onClick={() => acceptCookies()}>
                Accept
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}


export default withCookie(CookieBanner);
