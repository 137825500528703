import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import BookingList from 'components/booking/booking-list';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Modal, Button } from 'reactstrap';
import { isEmpty, timeout } from 'utils/helpers';

const BookingSection = (props) => {
  const { type, provider = {}, history, store, toast, addNew, include } = props;
  const [ bookings, setBookings ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('start_date');
  const [ sortValue, setSortValue ] = useState('desc');
  const [ loading, setLoading ] = useState(false);

  // Hooks
  useEffect(() => {
    fetchData();
  }, [page, pageSize]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('booking', params());
      setBookings(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (status) params.status = status;
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    if (include) params.include = include;
    return params;
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Bookings' className='flex-between'>
        {addNew && <NextButton title='New Booking' onClick={() => history.push('/book')} />}
      </SectionTitle>
      <SectionBody className='md no-pad'>
        <BookingList type={type} bookings={bookings} loading={loading} />
      </SectionBody>
      <SectionFooter>
        <PaginationWrapper meta={bookings.meta} setPage={p => setPage(p)} />
      </SectionFooter>
    </SectionBlock>
	);
}

export default withRouter(withStore(withToast(BookingSection)));
