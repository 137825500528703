import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

const MktProviderPrefooter = (props) => {
	const {} = props;

	return (
		<div className='mkt-prefooter'>
			<Container>
				<Row className='flex-between'>

					<Col md='7'>
						<div className='mkt-prefooter-content'>
							<h2 className='bold'>Build your brand</h2>
							<h5>List your services today absolutely free.</h5>
						</div>
					</Col>

					<Col md='5'>
						<div className='flex-end'>
							<Link to='/stylists/new' className='btn btn-lighter-gray btn-lg'>
								Become a Pro<FAWrapper icon={'chevron-right'} className='ml-15' />
							</Link>
						</div>
					</Col>

				</Row>
			</Container>
		</div>
	)

}

export default MktProviderPrefooter;