import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ReviewTable from 'components/review/review-table';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionSubtitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Breadcrumb, BreadcrumbItem, Modal } from 'reactstrap';

const AdminReviewsIndexRoute = (props) => {
  const { history, store, toast, providerId, provider } = props;
  const [ reviews, setReviews ] = useState([]);
  const [ status, setStatus ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('id');
  const [ sortValue, setSortValue ] = useState('desc');
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortProp, sortValue]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('review', params());
      setReviews(model)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (status) params.status = status;
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    params.include = 'booking,user'
    return params;
  }

  const sortData = (sortProp, sortValue) => {
    setSortProp(sortProp);
    setSortValue(sortValue);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Reviews'>
      <SectionBlock>
        <SectionTitle title='Reviews' className='flex-between'>
        </SectionTitle>

        <SectionBody className='md'>
          <ReviewTable
            reviews={reviews}
            sortProp={sortProp}
            sortValue={sortValue}
            sortList={(p, v) => sortData(p, v)}
            loading={loading}
            refreshData={() => fetchData()}
          />
        </SectionBody>
        <SectionFooter>
          <PaginationWrapper meta={reviews.meta} setPage={v => setPage(v)} />
        </SectionFooter>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminReviewsIndexRoute)));

