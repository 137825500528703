import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import NotificationItem from 'components/notification/notification-item';
import LoadingIndicator from 'components/loading/loading-indicator';
import { NavLink, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const MailCenterForm = (props) => {
	const { store } = props;
	const [ settings, setSettings ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
  	fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
		try {
  		setLoading(true);
  		let user = await store.findRecord('user', 1, { include: 'settings' });
  		setSettings(user.settings);
    } catch(e) {
    	toast.showError(e);
    } finally {
    	setLoading(false);
    }
  }

  const submitForm = async (settings, userID) => {
  	try {
  		setTaskRunning(true);
  		store.adapterFor('setting').set('userId', this.props.userID);
  		await settings.save();
  		toast.showSuccess('Notifications saved!');
    } catch(e) {
    	toast.showError(e);
    } finally {
    	setTaskRunning(false);
    }
  }

	const handleSubmit = (e) => {
		submitForm();
		e.preventDefault();
	}


	// Render
	return (
		<Form className='form-block' onSubmit={handleSubmit}>
			<div className='form-title'>
				<h4>Notifications</h4>
			</div>
			<div className='form-body md'>
				<div className='subsection-block'>
      		<div className='subsection-body pt-15 pb-15'>

						<FormGroup className='md flex-between'>
							<h5 for='phone'>Email Notifications</h5>
							<NotificationItem
								name='emailNotifications'
								value={settings.emailNotifications}
								action={v => this.toggleProp(settings, v)}
							/>
						</FormGroup>

						<FormGroup className='md flex-between'>
							<h5 for='phone'>SMS Notifications</h5>
							<NotificationItem
								name='smsNotifications'
								value={settings.smsNotifications}
								action={v => this.toggleProp(settings, v)}
							/>
						</FormGroup>

						<FormGroup className='md flex-between'>
							<h5 for='phone'>Push Notifications</h5>
							<NotificationItem
								name='pushNotifications'
								value={settings.pushNotifications}
								action={v => this.toggleProp(settings, v)}
							/>
						</FormGroup>

						<FormGroup className='md flex-between'>
							<h5 for='phone'>Marketing Notifications</h5>
							<NotificationItem
								name='mktNotifications'
								value={settings.mktNotifications}
								action={v => this.toggleProp(settings, v)}
							/>
						</FormGroup>

					</div>
				</div>
			</div>

    	<div className='form-footer'>
    		<Button type='submit' color='primary' size='sm'>
    			{taskRunning ? <LoadingIndicator color='white' size='18px' /> : 'Update Notifications' }
  			</Button>
  		</div>
		</Form>
	)
}

export default withStore(withToast(MailCenterForm));
