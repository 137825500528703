import React from 'react';
import { AdminRoute } from 'components/basics/routes';
import { withRouter, Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import BookingCalendar from 'components/booking/booking-calendar';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminAppointmentsRoute = (props) => {
  const { history, providerId, provider } = props;


  // Render
  return (
    <AdminRoute title='Blush Admin - Appointments'>
      <Breadcrumb>
        <BreadcrumbItem active>Appointments</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionTitle title='Appointments' className='flex-between' />
        <SectionBody className='md'>

          <BookingCalendar
            type='detail'
            provider={provider}
            filterProp='status'
            filterValue={'draft,requested,accepted,finished'}
            include='location,customer'
          />

        </SectionBody>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withRouter(AdminAppointmentsRoute);

