import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FaWrapper = (props) => {
	const { icon = '', iconFam, size = '1x', transform, className = '' } = props;

	switch (iconFam) {
		case 'fab':
  	return <FabIcon icon={icon} size={size} transform={transform} className={className}/>

		case 'fad':
  	return <FadIcon icon={icon} size={size} transform={transform} className={className}/>

  	default:
  	return <FalIcon icon={icon} size={size} transform={transform} className={className}/>
  }
}

const FabIcon = (props) => {
	const { icon = '', size = '1x', transform, className = '' } = props;
  return <FontAwesomeIcon icon={['fab', icon]} size={size} transform={transform} className={className} />
}

const FadIcon = (props) => {
	const { icon = '', size = '1x', transform, className = '' } = props;
  return <FontAwesomeIcon icon={['fad', icon]} size={size} transform={transform} className={className} />
}

export const FalIcon = (props) => {
	const { icon = '', size = '1x', transform, className = '' } = props;
  return <FontAwesomeIcon icon={['fal', icon]} size={size} transform={transform} className={className} />
}

export default FaWrapper;

// Docs
// https://github.com/FortAwesome/react-fontawesome
