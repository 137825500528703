import React, { Component } from 'react';
import StarRating from 'react-star-ratings';

const StarRatingWrapper = (props) => {
	const { rating, size = '16px', emptyColor = '#C5CFD6', fillColor = '#9B5D72' } = props;

	return (
		<StarRating
      rating={rating}
      starRatedColor={fillColor}
      starEmptyColor={emptyColor}
      starDimension={size}
      starSpacing='1px'
      numberOfStars={5}
    />
	)
}

export default StarRatingWrapper;

// Docs
// https://github.com/ekeric13/react-star-ratings
