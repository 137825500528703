import React, { useEffect, useState } from "react";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import { MktSection } from "components/mkt/mkt-section";
import ProviderList from "components/provider/provider-list";
import adapterFor from "store/adapter-for";
import serializerFor from "store/serializer-for";

const MktFeaturedProviders = (props) => {
  const { store, toast } = props;
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  // Hooks
  useEffect(() => {
    fetchData();
    fetchBrandData();
  }, []);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query("provider", {
        featured: true,
        page: page,
        pageSize: pageSize,
        include: "location",
      });
      setProviders(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandData = async () => {
    try {
      let response = await adapterFor("sponser").query("sponser", null);
      let records = serializerFor("sponser").normalizeArray(
        response?.data,
        response?.included,
        response?.meta
      );
      console.log(records);
      setBrands(records?.records || []);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <MktSection title="Featured Stylists">
      <ProviderList type="mkt" providers={providers} loading={loading} />
      {brands.length > 0 ? (
        <div>
          <div className="mkt-section-title">
            <h2
              style={
                {
                  // fontWeight: '300'
                }
              }
            >
              Official Hair + Makeup Sponsors of
            </h2>
            <hr />
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "scroll",
        }}
        className="hide-scrollbar"
      >
        {brands.map((item) => {
          return (
            <img
              src={item?.photo}
              style={{
                width: "400px",
                height: "300px",
                marginRight: "15px",
              }}
            />
          );
        })}
      </div>
    </MktSection>
  );
};

export default withStore(withToast(MktFeaturedProviders));
