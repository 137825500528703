import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

const AppCard = (props) => {
  const { type, icon, title, subtitle, line1, link, className = '', children } = props;

	if (link) {
		return (
			<Link to={link}>
				<div className={'card app ' + className}>
					<div className='flex-start'>
						<div className='card-header icon list mr-15'>
							{icon && <FAWrapper icon={icon} iconFam='fad' size='2x' className='card-header-icon'/>}
						</div>
						<div className='flex-between w-100'>
							<div className='card-body'>
								{title && <h5 className='title'><b>{title}</b></h5>}
								{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
								{line1 && <h6>{line1}</h6>}
								{children}
							</div>
							<div><FAWrapper icon='chevron-right' size='2x' className='btn-text-gray' /></div>
						</div>
					</div>
				</div>
			</Link>
		)
	}
	return (
		<div className={'card app ' + className}>
			<div className='flex-start'>
				<div className='card-header icon list mr-15'>
					{icon && <FAWrapper icon={icon} iconFam='fad' size='2x' className='card-header-icon'/>}
				</div>
				<div className='flex-between w-100'>
					<div className='card-body'>
						{title && <h5 className='title'><b>{title}</b></h5>}
						{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
						{line1 && <h6>{line1}</h6>}
					</div>
					<div>{children}</div>
				</div>
			</div>
		</div>
	)
}

export default AppCard;
