import React, { Component } from 'react';
import { Label, Input, Button, ButtonGroup } from 'reactstrap';

const SwitchWrapper = (props) => {
  const { name, value, onClick } = props;

	// Render
  return (
    <ButtonGroup>
      <Button color='' className='btn-toggle-primary' onClick={() => onClick(name)} active={value != true}>Off</Button>
      <Button color='' className='btn-toggle-primary' onClick={() => onClick(name)} active={value == true}>On</Button>
    </ButtonGroup>
  )
}


export default SwitchWrapper;
