import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import ActivityList from 'components/activity/activity-list';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty, logger } from 'utils/helpers';

const ActivitySection = (props) => {
  const { type, store, toast, className = '' } = props;
  const [ activities, setActivities ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('id');
  const [ sortValue, setSortValue ] = useState('desc');
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [sortProp, sortValue, setPage])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('activity', params());
      setActivities(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    return params;
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Recent Activity' />
      <SectionBody className='sm'>
        <ActivityList
          type='list'
          activities={activities}
          loading={loading}
          className={className}
        />
      </SectionBody>
      <SectionFooter>
        <PaginationWrapper meta={activities.meta} setPage={p => setPage(p)} />
      </SectionFooter>
    </SectionBlock>
  )
}

export default withStore(withToast(ActivitySection));
