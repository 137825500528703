import React, { Fragment, useEffect, useState } from 'react';

import { Modal, Button } from 'reactstrap';
import SanitizedHTML from 'react-sanitized-html';
import { termsOfService } from './BlushTermsofService';
import { TermsHeaderImage } from 'utils/assets/headers';
import SignaturePad from 'react-signature-canvas'



const TermsConditionPopup = (props) => {
    const [data, setData] = useState('')
    const signRef = React.useRef();
    const onSignatureSubmit = () => {
        if (signRef) {
            if(!signRef.current.isEmpty()){
                setData(signRef.current.toDataURL());    
                const img = signRef.current.getTrimmedCanvas().toDataURL("image/png")
                props.onSignature(img)
                props.dissmissModal()
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            if(data && signRef && props.isOpen){
                try{
                signRef.current.fromDataURL(data);
                }catch(e){}
             }   
        }, 1000);
     
    }, [props.isOpen])
    

    // Render
    return (
        <Fragment>
            <Modal isOpen={props.isOpen}
                className='modal-terms-width'
                toggle={() => props.isCancelHide ? null : props.dissmissModal(false)}>
                <div>
                    <div style={{
                        width: '100%',
                        height: '450px',
                        position: 'relative',
                    }}>
                        <div style={{
                            // height: '100%',
                            width: '100%',
                            overflow: 'scroll',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <img src={TermsHeaderImage}
                                style={{
                                    width: '150px',
                                    height: '70px',
                                    marginTop: '15px',
                                    marginBottom: '5px'
                                }} />
                            <div style={{
                                height: '350px'
                            }}>
                                <SanitizedHTML
                                    html={termsOfService}
                                // className='mkt-detail-content'
                                />
                            </div>

                        </div>

                    </div>
                    <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}>

                        <small style={{
                            marginRight: '10px'
                        }}>Signature: </small>

                        <SignaturePad canvasProps={{ className: 'sign-input-width' }}
                            ref={signRef}
                        />
                    </div>
                    <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: '20px',
                        marginBottom: '20px',
                        marginLeft: '74px',
                        justifyContent: 'center'
                    }}>
                        <Button
                            // color={'primary'}
                            size='lg'
                            style={{
                                width: '100px',
                                marginRight: '10px'
                            }}
                            onClick={() => onSignatureSubmit()}>
                            {'SUBMIT'}
                        </Button>
                       {props.isCancelHide ? null : <Button size='lg'
                            style={{
                                width: '100px'
                            }}
                            onClick={() => props.dissmissModal()}>
                            {'CANCEL'}
                        </Button> 
                        }
                    </div>
                </div>
            </Modal>

        </Fragment>
    )
}


export default TermsConditionPopup;
