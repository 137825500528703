import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import SimpleNav from 'components/navs/simple-nav';
import AppFooter from 'components/app/app-footer';
import ForgotPasswordForm from 'components/auth/forgot-password-form';
import { Container } from 'reactstrap';


const ForgotPasswordRoute = (props) => {
  const { history } = props;


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Forgot Password'/>
      <SimpleNav />
      <main className='auth-main'>
    		<Container className='xs'>
          <ForgotPasswordForm />
        </Container>
			</main>
      <AppFooter />
  	</div>
	);
}

export default ForgotPasswordRoute;
