import AppModel from 'models/app';
import { isEmpty } from 'utils/helpers';

class Merchant extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.requirements = props.requirements;
		this.verification = props.verification;
		this.ssnLastFourProvided = props.ssnLastFourProvided;
		this.dobProvided = props.dobProvided;
		this.idNumberProvided = props.idNumberProvided;
		this.idNumber = props.idNumber;

		
		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}

	
	// Computed
	get status() {
		return !isEmpty(this.verification) ? this.verification.status : null;
	}

	get currentlyDue() {
		return !isEmpty(this.requirements) ? this.requirements.currentlyDue : null;
	}

	// Statuses
	get isPending() {
		return this.status == 'pending';
	}

	get isUnverified() {
		return this.status == 'unverified';
	}

	get isVerified() {
		return this.status == 'verified';
	}

}

export default Merchant;