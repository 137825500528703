import AppModel from "models/app";
import moment from "moment";
import {
  compareIfDateIsSameOrGreater,
  createDateFormat,
} from "utils/common/commonFunctions";

class Schedule extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.timezone = props.timezone;

    // Relationships
    this.provider = props.provider || {};
    this.intervals = this.hasMany("interval", props.intervals);
    this.events = this.hasMany("event", props.events);
    this.scheduleDate = this.hasMany("schedule-date", props.scheduleDate);
  }

  // Computed
  get calendarData() {
    let data = [];

    let allDaySlotsToHideArr = [];

    this?.scheduleDate?.forEach((slot) => {
      let date = moment(slot?.date).format("YYYY-MM-DD");
      let startTime = moment(slot?.startDate).format("YYYY-MM-DD HH:mm");
      let endTime = moment(slot?.endDate).format("YYYY-MM-DD HH:mm");

      let onlyStartTime = moment(slot?.startDate).format("HH:mm");

      let onlyEndTime = moment(slot?.endDate).format("HH:mm");
      let flag = slot?.isAllDay;

      let date1 = createDateFormat(moment().format("YYYY-MM-DD"));
      let date2 = createDateFormat(moment(slot?.date).format("YYYY-MM-DD"));

      if (
        onlyStartTime === "07:00" &&
        onlyEndTime === "19:00" &&
        flag === false &&
        compareIfDateIsSameOrGreater(date1, date2)
      ) {
        allDaySlotsToHideArr.push({
          ...slot,

          isHide: true,
          event: {
            id: slot.id,
            type: "schedule_dates",
            className: "fc-schedule-date-hide",
            title:
              moment(slot.startDate).format("hh:mm") +
              " - " +
              moment(slot.endDate).format("hh:mm"),
            startTime: startTime,
            endTime: endTime,
            date: date,
            allDay: true,
          },
        });
      }
    });

    let allScheduleData = this.scheduleDate
      ?.filter((slot) => {
        let date1 = createDateFormat(moment().format("YYYY-MM-DD"));
        let date2 = createDateFormat(moment(slot?.date).format("YYYY-MM-DD"));

        if (compareIfDateIsSameOrGreater(date1, date2)) {
          if (slot?.isAllDay) {
            return slot;
          }
        }
      })
      ?.map((slot) => {
        let date = moment(slot?.startDate).format("YYYY-MM-DD");
        let startDate = moment(slot?.startDate).format("YYYY-MM-DD HH:mm");
        let endDate = moment(slot?.endDate).format("YYYY-MM-DD HH:mm");
        let startTime = moment(slot?.startDate).format("HH:mm");
        let endTime = moment(slot?.endDate).format("HH:mm");

        return {
          ...slot,
          startTime: startTime,
          endTime: endTime,
          event: {
            id: slot.id,
            type: "schedule_dates",
            className: "fc-schedule-date",
            title:
              moment(slot.startDate).format("hh:mma") +
              " - " +
              moment(slot.endDate).format("hh:mma"),
            startTime: startDate,
            endTime: endDate,
            date: date,
            allDay: true,
          },
        };
      });

    // Remove Slots for the date if 7am -7pm data is present
    let allDaySlotsDateArr = [];
    let allDaySlotsDataArr = allScheduleData?.filter((slot) => {
      let startTime = moment(slot?.startDate).format("HH:mm");
      let endTime = moment(slot?.endDate).format("HH:mm");
      if (startTime === "07:00" && endTime === "19:00") {
        allDaySlotsDateArr.push(slot?.date);
        return slot;
      }
    });

    let filteredSlots = [];

    allScheduleData?.forEach((slot) => {
      let date = moment(slot?.startDate).format("YYYY-MM-DD");
      if (!allDaySlotsDateArr.includes(date)) {
        filteredSlots.push(slot);
      }
    });

    //Map all dates from 7am to 7pm
    filteredSlots?.push({
      event: {
        id: 999999,
        type: "schedule_dates_all_dates",
        className: "fc-schedule-date",
        title: "07:00am" + " - " + "07:00pm",
        startRecur: moment().startOf("day").format(),
        date: moment().format("YYYY-MM-DD"),
        allDay: true,
      },
    });

    //Sort Array Time wise
    filteredSlots.sort(function (left, right) {
      return moment.utc(left.startDate).diff(moment.utc(right.startDate));
    });

    filteredSlots?.push(...allDaySlotsToHideArr);

    //Remove Duplicates

    const uniqueSlotsArr = [];

    filteredSlots.forEach((obj) => {
      const index = uniqueSlotsArr.findIndex((t) => t.date === obj.date);
      if (index === -1) {
        uniqueSlotsArr.push({
          ...obj,
          count: 0,
        });
      }
    });

    // Increment count key in unique Slots Arr  if date is present more than once in schedule dates array

    filteredSlots.forEach((slot) => {
      uniqueSlotsArr.forEach((item, i) => {
        if (slot.date === item.date && !slot.isHide) {
          uniqueSlotsArr[i].count = item.count + 1;
        }
      });
    });

    //Create a slot which contains title of + 2 or +3 more so on if there are multiple slots in that date

    uniqueSlotsArr.forEach((slot) => {
      if (slot.count > 1) {
        uniqueSlotsArr.push({
          event: {
            type: "schedule_dates_more_slots",
            className: "fc-schedule-date-more-slots",
            title: " " + slot.count - 1 + "+",
            date: moment(slot.startDate).format("YYYY-MM-DD"),
            allDay: false,
          },
        });
      }
    });

    uniqueSlotsArr.forEach((x) => data.push(x.event));

    data = data.flat();
    return data;
  }
}

export default Schedule;
