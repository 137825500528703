import React, { Component } from 'react';
import LocalStorage from 'local-storage';
import { withStore } from 'contexts/store-context';
import { withTracker } from 'contexts/tracker-context';
import JsonApiError from 'utils/json-api-error';
import { timeout, logger } from 'utils/helpers';

const SessionContext = React.createContext();

class SessionProviderWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userId: null,
      token: null,
      loading: true,
      loadUser: this.loadUser.bind(this),
      isAuthenticated: this.isAuthenticated.bind(this),
      authenticate: this.authenticate.bind(this),
      logout: this.logout.bind(this),
    };
  }


  // Hooks
  componentDidMount() {
    let userId = LocalStorage.get('userId');
    let token = LocalStorage.get('token');
    userId && token ? this.loadUser(userId, token) : this.setState({ loading: false });
  }


  // Tasks
  async loadUser(userId, token, silent = false) {
    try {
      this.setState({ loading: true });
      let user = await this.props.store.queryRecord('user', userId, { include: 'location,settings,provider' });
      await this.setState({ userId: userId, token: token, user: user });
      this.props.tracker.trackUser(user);
      return user;
    } catch(e) {
      this.logout();
    } finally {
      this.setState({ loading: false });
      logger(`Session ${this.state.isAuthenticated() ? 'authenticated!' : 'n/a'}`);
    }
  }

  async authenticate(user) {
    try {
      LocalStorage.set('userId', user.id);
      LocalStorage.set('token', user.token);
      user = await this.props.store.createRecord('user', user);
      await this.setState({ userId: user.id, token: user.token, user: user });
      await this.loadUser(user.id, user.token, true);
      this.props.tracker.trackEvent('USER_AUTHENTICATED');
      logger('New Session: ', this.state);
      return user;
    } catch(e) {
      throw JsonApiError.formatErrors(e);
    }
  }

  async logout() {
    try {
      localStorage.clear();
      await this.setState({ userId: '', token: '', user: {} });
      this.props.tracker.trackEvent('USER_LOGOUT');
      this.props.tracker.trackUser({});
      logger('Session: ', this.state);
    } catch(e) {
      throw JsonApiError.formatErrors(e);
    }
  }


  // Methods
  isAuthenticated() {
    return this.state.userId ? true : false;
  }


  // Render
  render() {
    const { errors, success } = this.state;
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    )
  }
};

const withSession = function(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <SessionContext.Consumer>
          {context => <WrappedComponent session={context} {...this.props} />}
        </SessionContext.Consumer>
      );
    }
  };
};

const SessionProvider = withStore(withTracker(SessionProviderWrapper));

export { SessionProvider, withSession };
