import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import AdminMain from 'components/admin/admin-main';

// Contexts
import { AdminProvider } from 'contexts/admin-context';
import { withTracker } from 'contexts/tracker-context';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { UserProvider } from 'contexts/user-context';
import MobileNavContext from 'contexts/mobile-nav-context';

// Utils
import ScrollTop from 'utils/scroll-top';
import { isEmpty, timeout, logger } from 'utils/helpers';


/**** Mkt ****/


// Basics
import IndexRoute from 'routes/mkt/index';
import AboutRoute from 'routes/mkt/about';
import CovidRoute from 'routes/mkt/covid';
import FaqRoute from 'routes/mkt/faq';
import OurServicesRoute from 'routes/mkt/our-services';
import TestimonialsRoute from 'routes/mkt/testimonials';
import TrustSafetyRoute from 'routes/mkt/trust-safety';
import ForStylistsRoute from 'routes/mkt/for-stylists';
import SpecialEventsRoute from 'routes/mkt/special-events';
import VideosRoute from 'routes/mkt/videos';
import ContactRoute from 'routes/mkt/contact';

// Legal
import TermsRoute from 'routes/legal/terms';
import PrivacyRoute from 'routes/legal/privacy';

// Blog
import BlogIndexRoute from 'routes/blog/index';
import BlogDetailRoute from 'routes/blog/detail';


/**** App ****/


// Auth
import LoginRoute from 'routes/auth/login';
import RegisterRoute from 'routes/auth/register';
import ForgotPasswordRoute from 'routes/auth/forgot-password';
import ResetPasswordRoute from 'routes/auth/reset-password';
import MailCenterRoute from 'routes/auth/mail-center';

// Stylist
import StylistIndexRoute from 'routes/stylists/index';
import StylistDetailRoute from 'routes/stylists/detail';
import StylistNewRoute from 'routes/stylists/new';

// Book
import BookRoute from 'routes/book/index';

// Review
import ReviewRoute from 'routes/review/index';

// Misc
import NotFoundRoute from 'routes/misc/not-found';
import NotAuthorizedRoute from 'routes/misc/not-authorized';


/**** Admin ****/


// Basics
import AdminDashboardRoute from 'routes/admin/dashboard';
import AdminAppointmentsRoute from 'routes/admin/appointments';
import AdminSetupRoute from 'routes/admin/getting-started';

// Bookings
import AdminBookingsRoute from 'routes/admin/bookings/index';
import AdminBookingsNewRoute from 'routes/admin/bookings/new';
import AdminBookingsDetailRoute from 'routes/admin/bookings/detail';
import AdminBookingsEditRoute from 'routes/admin/bookings/edit';

// Customers
import AdminCustomersRoute from 'routes/admin/customers/index';
import AdminCustomersNewRoute from 'routes/admin/customers/new';
import AdminCustomersDetailRoute from 'routes/admin/customers/detail';
import AdminCustomersEditRoute from 'routes/admin/customers/edit';

// Payouts
import AdminPayoutsRoute from 'routes/admin/payouts/index';
import AdminPayoutsDetailRoute from 'routes/admin/payouts/detail';

// Reviews
import AdminReviewsRoute from 'routes/admin/reviews/index';
import AdminReviewsDetailRoute from 'routes/admin/reviews/detail';

// Settings
import AdminSettingsRoute from 'routes/admin/settings/index';
import AdminProfileRoute from 'routes/admin/settings/profile';
import AdminMerchantRoute from 'routes/admin/settings/merchant';
import AdminLicensingRoute from 'routes/admin/settings/licensing';
import AdminServicesRoute from 'routes/admin/settings/services';
import AdminScheduleRoute from 'routes/admin/settings/schedule';
import AdminBankingRoute from 'routes/admin/settings/banking';
import AdminPhotosRoute from 'routes/admin/settings/photos';
import AdminNotificationsRoute from 'routes/admin/settings/notifications';


/**** User ****/

// Bookings
import UserBookingIndexRoute from 'routes/user/bookings/index';
import UserBookingDetailRoute from 'routes/user/bookings/detail';
import UserBookingEditRoute from 'routes/user/bookings/edit';

// Settings
import UserSettingsRoute from 'routes/user/settings/index';
import UserProfileRoute from 'routes/user/settings/profile';
import UserAccountRoute from 'routes/user/settings/account';
import UserNotificationsRoute from 'routes/user/settings/notifications';
import UserPromotionsRoute from 'routes/user/settings/promotions';
import UserCreditCardsRoute from 'routes/user/settings/credit-cards';


const Router = ({ tracker, session }) => {
  const { pathname, search } = useLocation();
  const [ currentPath, setCurrentPath ] = useState('');

  
  // Hooks
  useEffect(() => {
    if (typeof window !== 'undefined' && tracker.loaded) trackPage();
  }, [pathname, tracker.loaded])


  // Methods
  const trackPage = () => {
    if (pathname != currentPath || isEmpty(currentPath)) {
      const url = pathname + search;
      tracker.trackView(url);
      setCurrentPath(pathname);
    }
  }


  // Render
	return (
    <Fragment>
      <ScrollTop />
      <MobileNavContext>
        <Switch>
          <Route exact path='/' component={IndexRoute}/>
          <Route exact path='/about' component={AboutRoute} />
          <Route exact path='/contact' component={ContactRoute}/>
          <Route exact path='/covid' component={CovidRoute} />
          <Route exact path='/faq' component={FaqRoute} />
          <Route exact path='/testimonials' component={TestimonialsRoute}/>
          <Route exact path='/trust-safety' component={TrustSafetyRoute}/>
          <Route exact path='/our-services' component={OurServicesRoute}/>
          <Route exact path='/for-stylists' component={ForStylistsRoute}/>
          <Route exact path='/special-events' component={SpecialEventsRoute}/>
          <Route exact path='/terms-of-service' component={TermsRoute}/>
          <Route exact path='/privacy-policy' component={PrivacyRoute}/>
          <Route exact path='/videos' component={VideosRoute}/>
          <Route exact path='/login' component={LoginRoute}/>
          <Route exact path='/register' component={RegisterRoute}/>
          <Route exact path='/forgot-password' component={ForgotPasswordRoute}/>
          <Route exact path='/reset-password' component={ResetPasswordRoute}/>
          <Route exact path='/mail-center' component={MailCenterRoute}/>
          <Route path='/not-found' component={NotFoundRoute}/>
          <Route path='/not-authorized' component={NotAuthorizedRoute}/>

          <Route path='/book' component={BookRoute}/>
          <Route path='/review' component={ReviewRoute}/>

          <Route path="/blog">
            <Blog/>
          </Route>

          <Route path="/stylists">
            <Stylist/>
          </Route>

          <Route path='/admin/:providerId'>
            <Admin session={session} />
          </Route>

          <Route path='/user/:userId'>
            <User session={session} />
          </Route>

          <Route path='*'>
            <Redirect to='/not-found' />
          </Route>

        </Switch>
      </MobileNavContext>
    </Fragment>
	)
}

// Admin
const Admin = (props) => {
  const { session } = props;
  const { path } = useRouteMatch();
  const { providerId } = useParams();
  const history = useHistory();

  if (!session.loading && session.isAuthenticated()) {
    return (
      <AdminProvider providerId={providerId} history={history}>
        <AdminMain>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`/admin/${providerId}/dashboard`} />
            </Route>

            <Route path={path + '/dashboard'}>
              <AdminDashboardRoute providerId={providerId} history={history}/>
            </Route>

            <Route path={path + '/getting-started'}>
              <AdminSetupRoute providerId={providerId} history={history} />
            </Route>

            <Route path={path + '/appointments'}>
              <AdminAppointmentsRoute providerId={providerId} history={history} />
            </Route>

            <Route exact path={path + '/bookings'}>
              <AdminBookingsRoute providerId={providerId} history={history} />
            </Route>

            <Route path={path + '/bookings/new'}>
              <AdminBookingsNewRoute providerId={providerId} history={history} />
            </Route>

            <Route path={path + '/bookings/:bookingId'}>
              <AdminBookingDetail />
            </Route>

            <Route exact path={path + '/customers'}>
              <AdminCustomersRoute providerId={providerId} history={history} />
            </Route>

            <Route exact path={path + '/customers/new'}>
              <AdminCustomersNewRoute providerId={providerId} history={history} />
            </Route>

            <Route path={path + '/customers/:customerId'}>
              <AdminCustomerDetail />
            </Route>

            <Route exact path={path + '/payouts'}>
              <AdminPayoutsRoute providerId={providerId} history={history}/>
            </Route>

            <Route path={path + '/payouts/:payoutId'}>
              <AdminPayoutDetail />
            </Route>

            <Route exact path={path + '/reviews'}>
              <AdminReviewsRoute providerId={providerId} history={history}/>
            </Route>

            <Route path={path + '/reviews/:reviewId'}>
              <AdminReviewDetail />
            </Route>

            <Route path={path + '/settings'}>
              <AdminSettings />
            </Route>

            <Route path='*'>
              <Redirect to='/not-found' />
            </Route>
          </Switch>
        </AdminMain>
      </AdminProvider>
    )
  }
  if (!session.loading && !session.isAuthenticated()) {
    return (
      <Switch>
        <Redirect to='/login' />
      </Switch>
    )
  }
  return null;
}

function AdminBookingDetail() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerId, bookingId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminBookingsDetailRoute providerId={providerId} bookingId={bookingId} history={history}/>
      </Route>
      <Route path={path + '/edit'}>
        <AdminBookingsEditRoute providerId={providerId} bookingId={bookingId} history={history}/>
      </Route>
    </Switch>
  )
}

function AdminCustomerDetail() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerId, customerId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminCustomersDetailRoute providerId={providerId} customerId={customerId} history={history}/>
      </Route>
      <Route path={path + '/edit'}>
        <AdminCustomersEditRoute providerId={providerId} customerId={customerId} history={history}/>
      </Route>

    </Switch>
  )
}

function AdminPayoutDetail() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerId, payoutId } = useParams();

  return (
    <Switch>
      <Route path={path}>
        <AdminPayoutsDetailRoute providerId={providerId} payoutId={payoutId} history={history}/>
      </Route>
    </Switch>
  )
}

function AdminReviewDetail() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerId, reviewId } = useParams();

  return (
    <Switch>
      <Route path={path}>
        <AdminReviewsDetailRoute providerId={providerId} reviewId={reviewId} history={history}/>
      </Route>
    </Switch>
  )
}


function AdminSettings() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { providerId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <AdminSettingsRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/profile'}>
        <AdminProfileRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/merchant'}>
        <AdminMerchantRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/licensing'}>
        <AdminLicensingRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/photos'}>
        <AdminPhotosRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/services'}>
        <AdminServicesRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/schedule'}>
        <AdminScheduleRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/banking'}>
        <AdminBankingRoute providerId={providerId} history={history} />
      </Route>

      <Route path={path + '/notifications'}>
        <AdminNotificationsRoute providerId={providerId} history={history} />
      </Route>
    </Switch>
  )
}


// Stylists
function Stylist() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <StylistIndexRoute/>
      </Route>
      <Route path={path + '/new'}>
        <StylistNewRoute/>
      </Route>
      <Route path={path + '/:stylistId'}>
        <StylistDetail/>
      </Route>
    </Switch>
  )
}


function StylistDetail() {
  const { stylistId } = useParams();

  return (
    <Switch>
      <StylistDetailRoute stylistId={stylistId}/>
    </Switch>
  )
}


// Blog
function Blog() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <BlogIndexRoute/>
      </Route>
      <Route path={path + '/:blogId'}>
        <BlogDetail/>
      </Route>
    </Switch>
  )
}

function BlogDetail() {
  const { blogId } = useParams();

  return (
    <Switch>
      <BlogDetailRoute slug={blogId}/>
    </Switch>
  )
}


// User
const User = (props) => {
  const { session } = props;
  const { path } = useRouteMatch();
  const { userId } = useParams();
  const history = useHistory();

  if (session.isAuthenticated()) {
    return (
      <UserProvider userId={userId} history={history}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`/user/${userId}/bookings`}/>
          </Route>
          <Route exact path={path + '/bookings'}>
            <UserBookings/>
          </Route>
          <Route path={path + '/bookings/:bookingId'}>
            <UserBookingDetail/>
          </Route>
          <Route path={path + '/settings'}>
            <UserSettings/>
          </Route>

          <Route path='*'>
            <Redirect to='/not-found' />
          </Route>
        </Switch>
      </UserProvider>
    )
  }
  if (!session.loading && !session.isAuthenticated()) {
    return (
      <Switch>
        <Redirect to='/login' />
      </Switch>
    )
  }
  return null;
}

function UserDetail() {
  const { path } = useRouteMatch();
  const { userId } = useParams();

  return (
    <Switch>
      <Redirect to={path + '/bookings'} />
    </Switch>
  )
}

function UserBookings() {
  const { path } = useRouteMatch();
  const { userId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <UserBookingIndexRoute userId={userId}/>
      </Route>
    </Switch>
  )
}

function UserBookingDetail() {
  const { path } = useRouteMatch();
  const { userId, bookingId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <UserBookingDetailRoute userId={userId} bookingId={bookingId}/>
      </Route>
      <Route path={path + '/edit'}>
        <UserBookingEditRoute userId={userId} bookingId={bookingId}/>
      </Route>

    </Switch>
  )
}

function UserSettings() {
  const { path } = useRouteMatch();
  const { userId } = useParams();

  return (
    <Switch>

      <Route exact path={path}>
        <UserSettingsRoute userId={userId}/>
      </Route>

      <Route path={`${path}/profile`}>
        <UserProfileRoute userId={userId}/>
      </Route>

      <Route path={`${path}/account`}>
        <UserAccountRoute userId={userId}/>
      </Route>

      <Route path={`${path}/credit-cards`}>
        <UserCreditCardsRoute userId={userId}/>
      </Route>

      <Route path={`${path}/notifications`}>
        <UserNotificationsRoute userId={userId}/>
      </Route>

      <Route path={`${path}/promotions`}>
        <UserPromotionsRoute userId={userId}/>
      </Route>

    </Switch>
  )
}




export default withStore(withSession(withToast(withTracker(Router))));
