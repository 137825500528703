import React from 'react';
import ListCard from 'components/cards/list-card';
import PreviewCard from 'components/cards/preview-card';
import LoadingIndicator from 'components/loading/loading-indicator';
import { Row, Col, Badge, Button } from 'reactstrap';

const PromoListCard = (props) => {
  const { promo = {}, loading, onClick, className = '' } = props;

  return (
    <ListCard
      icon='tags'
      status={promo.status}
      title={promo.code}
      line1={promo.displayAmount}
      onClick={() => onClick(promo.id)}
      className={className}
    />
  )
}

const PromoPreviewCard = (props) => {
  const { promo = {}, loading, onClick, className = '' } = props;

  return (
    <PreviewCard
      icon='tags'
      status={promo.status}
      title={promo.code}
      line1={promo.displayAmount}
      className={className}
    />
  )
}


export { PromoListCard, PromoPreviewCard };
