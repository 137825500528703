import AppModel from 'models/app';

class Category extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		
		// Attrs
		this.title = props.title;
		this.description = props.description;
		this.featured = props.featured;
		this.photo = props.photo;


		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
		this.services = this.hasMany('services', props.services);
	}

}

export default Category;