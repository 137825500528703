import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import BookingForm from 'components/booking/booking-form';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const AdminBookingsNewRoute = (props) => {
  const { providerId, history, provider, store, toast } = props;


  // Methods
  const viewBooking = (bookingId) => {
    history.push(`/admin/${providerId}/bookings/${bookingId}`);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - New Booking'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/bookings`}>Bookings</Link></BreadcrumbItem>
        <BreadcrumbItem active>New</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionTitle className='no-m p-15'>
          <h3>New Booking</h3>
        </SectionTitle>
        <SectionBody className='md'>
          <BookingForm
            provider={provider}
            store={store}
            nextAction={booking => viewBooking(booking.id)}
          />
        </SectionBody>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminBookingsNewRoute)));
