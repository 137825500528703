import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import SupportApi from 'apis/support-api';
import moment from 'moment';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import TimePickerWrapper from 'components/vendors/time-picker-wrapper';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import Empty from 'components/misc/empty';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const SpecialEventForm = (props) => {
  const { errors, className = '', store } = props;
  const [ date, setDate ] = useState(moment().add('1', 'h').startOf('h'));
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ location, setLocation ] = useState('');
  const [ name, setName ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ reason, setReason ] = useState('Wedding');
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Tasks
  const submitForm = async () => {
  	try {
      setTaskRunning(true);
      await SupportApi.specialEvent({
        name: name,
        email: email,
        phone: phone,
        reason: `Special Event: ${reason}`,
        date: moment(date).format(),
        location: location,
        message: message,
      });
      setIsSubmitted(true);
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
	const handleSubmit = (e) => {
		submitForm();
		e.preventDefault();
	}

  const resetForm = () => {
    setName('');
    setEmail('');
    setReason('');
    setDate(moment().add('1', 'h').startOf('h'));
    setLocation('');
    setMessage('');
  }


// Render
  if (isSubmitted) {
    return (
      <SectionBlock>
        <SectionBody className='md'>
          <Empty
            icon='check-circle'
            type='index'
            title='Request Sent!'
            msg='Thanks for your submission. Someone from our team will be in touch shortly.'>
            <NextButton title='Reset Form' onClick={() => resetForm()}/>
          </Empty>
        </SectionBody>
      </SectionBlock>
    )
  }
  return (
    <Form onSubmit={handleSubmit}>
      <SectionBlock>
        <SectionTitle title='Special Events'/>
        <SectionBody className='md'>
          <FormGroup>
            <Label for='user_name'>Name</Label>
            <Input id='user_name'
              type='text'
              value={name}
              placeholder='Kate Moss'
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='user_email'>Email</Label>
            <Input id='user_email'
              type='email'
              value={email}
              placeholder='kate@ck.com'
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='phone'>Phone</Label>
            <PhoneMaskedInput id='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder='(303) 444-2226'
            />
          </FormGroup>

          <FormGroup>
            <Label for='reason'>Reason</Label>
            <Input id='reason'
              type='select'
              value={reason}
              onChange={(e) => setReason(e.target.value)}>
              <option value='Wedding'>Wedding</option>
              <option value='Celebration'>Celebration</option>
              <option value='Girls'>Girls Night</option>
            </Input>
          </FormGroup>

          <Row>
            <Col md='6'>
              <FormGroup>
                <Label>Date</Label>
                <DatePickerWrapper
                  type='dropdown'
                  name='date'
                  date={date}
                  setDate={date => setDate(date)}
                />
              </FormGroup>
            </Col>

            <Col md='6'>
              <FormGroup>
                <Label>Time</Label>
                <TimePickerWrapper
                  type='dropdown'
                  name='date'
                  date={date}
                  setTime={date => setDate(date)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for='phone'>Location</Label>
            <GoogleAutocompleteWrapper
              location={location}
              setLocation={newLocation => setLocation(newLocation)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='message'>Message</Label>
            <Input id='message'
              type='textarea'
              value={message}
              rows='5'
              placeholder='Tell us about your event...'
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormGroup>
        </SectionBody>
        <SectionFooter className='flex-between'>
          <NextButton
            type='submit'
            title='Send Message'
            taskRunning={taskRunning}
          />
        </SectionFooter>
      </SectionBlock>
    </Form>
  )
}


export default withRouter(withStore(withToast(SpecialEventForm)));
