import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, FormGroup, FormText, Label, Input } from 'reactstrap';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { isEmpty } from 'utils/helpers';
import { inputProp, setProp, setRelation } from 'utils/store-helpers';

const ProviderFlowDetails = (props) => {
  const { provider, session, store, toast, loading, prevAction, nextAction } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveProvider = async () =>{
    try {
      if (taskRunning) { return; }
      setTaskRunning(true);
      await provider.save();
      toast.showSuccess('Provider saved!');
      nextAction(provider);
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


	// Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Exit' onClick={prevAction} />
      </SectionPreBlock>

      <SectionBlock>
        <SectionTitle title='Provider profile'/>
        <SectionBody className='md'>
          <FormGroup className='text-center'>
            <FilestackPhotoWrapper
              photo={provider.photo}
              setPhoto={d => setProp(provider, 'photo', d)}
            />
          </FormGroup>

          <Row>
            <Col md='6'>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type='text'
                  defaultValue={provider.firstName}
                  placeholder='First name'
                  onChange={p => inputProp(provider, 'providerFirstName', p)}
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type='text'
                  defaultValue={provider.lastName}
                  placeholder='Last name'
                  onChange={p => inputProp(provider, 'providerLastName', p)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label>Email</Label>
            <Input
              type='text'
              defaultValue={provider.email}
              placeholder='Email address'
              onChange={p => inputProp(provider, 'email', p)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='address'>Address</Label>
            <GoogleAutocompleteWrapper
              location={provider.location}
              setLocation={location => setRelation(provider, 'location', location)}
            />
          </FormGroup>

          <Row>
            <Col md='6'>
              <FormGroup>
                <Label for='phone'>Phone Number</Label>
                <PhoneMaskedInput id='phone'
                  value={provider.phone}
                  onChange={p => inputProp(provider, 'phone', p)}
                  placeholder='(303) 444-2226'
                />
              </FormGroup>
            </Col>
            <Col md='6'>
              <FormGroup>
                <Label for='web'>Web Address (optional)</Label>
                <Input id='web'
                  type='text'
                  defaultValue={provider.social && provider.social.webUrl}
                  onChange={p => inputProp(provider, 'social.webUrl', p)}
                  placeholder='www.katemoss.com'
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md='6'>
              <FormGroup>
                <Label for='dob'>Date of Birth</Label>
                <DobMaskedInput id='dob'
                  type='dob'
                  value={provider.dob}
                  placeholder='MM/DD/YYYY'
                  onChange={p => inputProp(provider, 'dob', p)}
                />
              </FormGroup>
            </Col>
              <Col md='6'>
              <FormGroup>
                <Label for='ssn'>Last Four SSN</Label>
                <Input id='ssn'
                  type='password'
                  defaultValue={provider.ssnLastFour}
                  placeholder='••••'
                  readOnly={provider.ssnLastFourProvided}
                  onChange={p => inputProp(provider, 'ssnLastFour', p)}
                />
              </FormGroup>
            </Col>
            </Row>

          <FormGroup>
            <Label for='email'>Description</Label>
            <Input id='email'
              type='textarea'
              rows='4'
              defaultValue={provider.description}
              onChange={p => inputProp(provider, 'description', p)}
              placeholder='Please share a little about yourself...'
            />
          </FormGroup>
        </SectionBody>

        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            taskRunning={taskRunning}
            disable={isEmpty(provider)}
            onClick={() => saveProvider()}
            className='btn-block btn-lg'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}

export default ProviderFlowDetails;
