import React, { UseState } from 'react';
import StarRatings from 'react-star-ratings';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col, Badge, ButtonGroup, Button } from 'reactstrap';

const AnalyticsRatingCard = (props) => {
	const { title, description, value, loading, className = '' } = props;

	return (
		<div className={'card rating ' + className}>
			<div className='stat'>
				<StarRatings
          rating={value}
          starRatedColor='#9B5D72'
          starEmptyColor='#C5CFD6'
          starDimension='30px'
          starSpacing='3px'
          numberOfStars={5}
        />
			</div>
			<div className='card-body'>
				<h6 className='title'>{title}</h6>
				<h6>{description}</h6>
			</div>
		</div>
	)
}

const AnalyticsStatCard = (props) => {
	const { title, description, value, loading, className = '' } = props;
	return (
		<div className={'card stat ' + className}>
			<div className='stat'>
				<h2>{value}</h2>
			</div>
			<div className='card-body'>
				<h6 className='title'>{title}</h6>
				<h6>{description}</h6>
			</div>
		</div>
	)
}

export { AnalyticsRatingCard, AnalyticsStatCard };
