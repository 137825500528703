import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomerActions from 'components/customer/customer-actions';
import TableHeader from 'components/misc/table-header';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Table, Button, Badge } from 'reactstrap';


const CustomerTable = (props) => {
  const { customers = [], sortProp, sortValue, sortData, loading, refreshData, className = ''} = props;

  // Render
  if (loading) {
    return <LoadingSkeleton type='table'/>
  }
  return (
    <Table className='index' responsive>
      <thead>
        <tr>
          <TableHeader text='ID' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Photo' value='photo' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Name' value='name' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Email' value='email' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Location' value='location' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Created At' value='createdAt' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <th className='uppercase'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {customers.map(customer => (
          <tr key={customer.id}>
            <td>
              <Link className='td' to={customer.url}>{customer.id}</Link>
            </td>
            <td>
              <Link className='td' to={customer.url}><img src={customer.photo} className='avatar-img sm' /></Link>
            </td>
            <td>
              <Link className='td' to={customer.url}>{customer.name}</Link>
            </td>
            <td>
              <Link className='td' to={customer.url}>{customer.email}</Link>
            </td>
            <td>
              <Link className='td' to={customer.url}>{customer.location ? customer.location.cityState : '--'}</Link>
            </td>
            <td>
              <Link className='td' to={customer.url}>{customer.displayCreatedAt}</Link>
            </td>
            <td>
              <CustomerActions type='table' customer={customer} refreshData={refreshData}/>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default CustomerTable;
