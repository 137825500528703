import React, { useEffect, useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import BookingFilter from 'components/booking/booking-filter';
import BookingTable from 'components/booking/booking-table';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionSubtitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getTimezoneFromLatLong } from 'utils/common/commonFunctions';

const AdminBookingsIndexRoute = (props) => {
  const { history, providerId, provider, store, toast } = props;
  const [bookings, setBookings] = useState([]);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortProp, setSortProp] = useState('id');
  const [sortValue, setSortValue] = useState('desc');
  const [loading, setLoading] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [status, page, pageSize, sortProp, sortValue]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('booking', params());
      setBookings(model)
      if (model && model?.length > 0) {
        const updatedItems = await Promise.all(model?.map(async (item) => {
          if (item?.location) {
            const timezoneAbbreviation = await getTimezoneFromLatLong(item?.location?.latitude, item?.location?.longitude);
            item.timezoneAbbreviation = timezoneAbbreviation
            return item
          }
          return item
        }));
        updatedItems.meta = model?.meta
        setBookings(updatedItems)
      }
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (status) params.status = status;
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    params.include = 'location,provider,customer,services'
    return params;
  }

  const sortData = (sortProp, sortValue) => {
    setSortProp(sortProp);
    setSortValue(sortValue);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Bookings'>
      <Breadcrumb>
        <BreadcrumbItem active>Bookings</BreadcrumbItem>
      </Breadcrumb>
      <SectionBlock>
        <SectionTitle title='Bookings' className='flex-between' />
        <SectionSubtitle className='flex-start'>
          <BookingFilter
            type='status'
            value={status}
            options={['all', 'draft', 'requested', 'accepted', 'denied', 'finished', 'canceled']}
            setFilter={(p, v) => setStatus(v)}
            className='ml-3'
          />
        </SectionSubtitle>
        <SectionBody className='md no-pad'>
          <BookingTable
            bookings={bookings}
            provider={provider}
            sortProp={sortProp}
            sortValue={sortValue}
            sortData={(p, v) => sortData(p, v)}
            setPage={v => setPage(v)}
            loading={loading}
            refreshData={() => fetchData()}
          />
        </SectionBody>
        <SectionFooter>
          <PaginationWrapper meta={bookings.meta} setPage={v => setPage(v)} />
        </SectionFooter>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminBookingsIndexRoute)));
