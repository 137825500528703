import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import { ReviewDetailCard } from 'components/review/review-card';
import { BookingPreviewCard } from 'components/booking/booking-card';
import CustomerPreviewSection from 'components/customer/customer-preview-section';
import LoadingIndicator from 'components/loading/loading-indicator';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const AdminReviewsDetailRoute = (props) => {
  const { reviewId, providerId, history, provider, store, toast } = props;
  const [ review, setReview ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('review', reviewId, { include: 'booking,user' });
      setReview(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title={'Blush Admin - Review #' + reviewId}>
      <SectionBlock>
        <SectionBody className='flex-between'>
          <h4 className='title'>Review #{review.id}</h4>
        </SectionBody>
      </SectionBlock>

      <Row>
        <Col md='4'>
          <ReviewDetailCard review={review} loading={loading} />
        </Col>

        <Col md='8'>
          <CustomerPreviewSection customer={review.user && review.user} loading={loading} />
        </Col>
      </Row>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminReviewsDetailRoute)));
