import AppAdapter from 'adapters/app';

class ProviderSettingAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';


	static urlForUpdateRecord(modelName, id) {
		return this.buildURL() + `/settings/${id}`;
	}
}

export default ProviderSettingAdapter;