import AppAdapter from 'adapters/app';

class UserAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
	
}

export default UserAdapter;