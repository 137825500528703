import React from 'react';
import AppSerializer from 'serializers/app';
import Axios from 'utils/axios';
import JsonApiError from 'utils/json-api-error';

const axios = new Axios().instance();

class AuthApi {

	static async login(data) {
		try {
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/login`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
	  	throw JsonApiError.formatErrors(e);
		}
	}

	static async register(data) {
		try {
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/register`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
	  	throw JsonApiError.formatErrors(e);
		}
	}

	static async forgotPassword(data) {
		try {
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.put(`/forgot-password`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
		} catch(e) {
			throw JsonApiError.formatErrors(e);
		}
	}

	static async resetPassword(data, token) {
		try {
			if(token) {
				axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
			}
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.put(`/reset-password`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
			throw JsonApiError.formatErrors(e);
		}
	}

	static async facebookAuth(data) {
		try {
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/facebook-auth`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
			throw JsonApiError.formatErrors(e);
		}
	}

	static async googleAuth(data) {
		try {
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/google-auth`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
			throw JsonApiError.formatErrors(e);
		}
	}

}

export default AuthApi;
