import React from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';

const PlainCard = (props) => {
	const { html, title, line1, line2, timestamp, className = '', children } = props;


	// Render
	return (
		<div className={'card plain ' + className}>
			<div className='card-body p-15'>
				{html && html}
				{title && <h5 className='title'>{title}</h5>}
				{line1 && <h6>{line1}</h6>}
				{line2 && <h6>{line2}</h6>}
			</div>
			{children}
			{timestamp && (
				<div className='card-footer'>
					<small className='italic gray-color'>Last Updated: {timestamp}</small>
				</div>
			)}
		</div>
	)
}

export default PlainCard;
