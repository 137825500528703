import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import BookingFlowCart from 'components/booking/booking-flow-cart';
import BurgerMenu from 'react-burger-menu';
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { Nav, NavItem, Button } from 'reactstrap';

export const CheckoutContext = React.createContext();

const CheckoutProviderWrapper = (props) => {
  const { booking, steps, step, changeStep, history, location, menuStyle = 'slide', children } = props;
  const [ menu, toggleMenu ] = useState(false);
  const [ route, setRoute ] = useState('');
  const Menu = BurgerMenu[menuStyle];


  // Hooks
  useEffect(() => {
    menu && toggleMenu(!menu);
  }, [location.pathname])


  // Methods
  const setMenuStatus = (state) => {
    if(menu != state.isOpen) {
      toggleMenu(state.isOpen)
    }
  };


  // Render
  return (
    <CheckoutContext.Provider value={{booking, steps, step, changeStep, menu, toggleMenu}}>
      <Menu isOpen={menu} outerContainerId='application' pageWrapId='booking_flow' width='80%' right onStateChange={setMenuStatus}>
				<BookingFlowCart
          type=''
          booking={booking}
          steps={steps}
          step={step}
          changeStep={step => changeStep(step)}
          toggleMenu={() => toggleMenu(!menu)}
        />
      </Menu>
      {children}
    </CheckoutContext.Provider>
  )
};


const withCheckout = function(WrappedComponent) {
  return (props) => (
    <CheckoutContext.Consumer>
      {context => <WrappedComponent checkout={context} {...props} />}
    </CheckoutContext.Consumer>
  );
};

const CheckoutProvider = withRouter(CheckoutProviderWrapper);

export { CheckoutProvider, withCheckout };
