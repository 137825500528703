import React from 'react';
import { withCheckout } from 'contexts/checkout-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, NavbarToggler, ListGroup, ListGroupItem, Button } from 'reactstrap';

const BookingFlowSteps = (props) => {
  const { checkout, steps, step } = props;


	// Methods
	const selected = (stepView) => {
		return step.view == stepView;
	}

	const filteredSteps = () => {
		return steps.filter(step => step.step != 5);
	}


	// Render
  return (
    <div className='progress-wrapper bb'>
      <Container fluid>
        <Row className='flex-between'>
          <Col md='8'>
            <ListGroup className='flex-start' horizontal>
              {steps && filteredSteps().map(step => (
                <ListGroupItem key={step.step} className='flex'>
                  <span className={selected(step.view) ? 'active' : ''}>{step.title}</span>
                  {step.step != 7 && <FAWrapper icon='chevron-right' className='light-gray-color sm m-2'/>}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col md='4' className='text-right'>
            <Button type='button' color='text-gray' size='sm' active={checkout.menu} onClick={() => checkout.toggleMenu(!checkout.menu)}>
              <FAWrapper icon='shopping-cart' size='2x'/>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withCheckout(BookingFlowSteps);
