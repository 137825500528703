import React, { Component } from 'react';
import InfoCard from 'components/cards/info-card';
import PreviewCard from 'components/cards/preview-card';
import SelectCard from 'components/cards/select-card';
import ExternalLink from 'components/misc/external-link';
import FAWrapper from 'components/vendors/fa-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ChargePreviewCard = (props) => {
  const { charge = {}, selectedID, loading, onClick, className = '' } = props;

  return (
    <PreviewCard
      icon='money-bill-wave'
      status={charge.reason}
      title={charge.displayTotal}
      line1={charge.card && charge.card.summary}
      line2={charge.displayCreatedAt}
      className={className}>
      <ExternalLink to={charge.receiptUrl} className='btn btn-text-blue'><FAWrapper icon='external-link' className='mr-15'/>View Receipt</ExternalLink>
    </PreviewCard>
  )
}

const ChargeSelectCard = (props) => {
  const { charge = {}, selectedID, loading, onClick, className = '' } = props;

  return (
    <SelectCard
      icon='money-bill-wave'
      status={charge.reason}
      title={charge.displayTotal}
      line1={charge.card && charge.card.summary}
      line2={charge.displayCreatedAt}
      onSelect={() => onClick(charge.id)}
      className={className}>
      <ExternalLink to={charge.receiptUrl} className='btn btn-text-blue'><FAWrapper icon='external-link' className='mr-15'/>View Receipt</ExternalLink>
    </SelectCard>
  )
}

export { ChargePreviewCard, ChargeSelectCard };
