import AppSerializer from 'serializers/app';

class CustomerSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get attrs() {
		return {}
	}

	get relationships() {
		return {
			location: { serialize: true },
			provider: { serialize: 'id' },
		}
	}

}

export default CustomerSerializer;