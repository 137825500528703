import React, { Component } from 'react';
import PreviewCard from 'components/cards/preview-card';
import ListCard from 'components/cards/list-card';
import ExternalLink from 'components/misc/external-link';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';


const RefundPreviewCard = (props) => {
  const { refund = {}, loading, onClick, className = '' } = props;

  // Render
  return (
    <PreviewCard
      icon='money-bill-wave'
      status={refund.status}
      title={refund.displayAmount}
      line1={refund.card && refund.card.summary}
      line2={refund.displayCreatedAt}
      className={className}>
      <ExternalLink to={refund.receiptUrl} className='btn btn-text-blue'>View Receipt<FAWrapper icon='external-link' className='ml-15'/></ExternalLink>
    </PreviewCard>
  )
}


export { RefundPreviewCard };
