import React, { useEffect, useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import { NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Button, Dropdown, Form, FormGroup, Label, Modal, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { inputProp, setProp } from 'utils/store-helpers';

const PhotoForm = (props) => {
  const { model = {}, provider, store, toast, modal, toggleModal, nextAction, className = '' } = props;
  const [ photo, setPhoto ] = useState({});
  const [ photos, setPhotos ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    isEmpty(model) ? createPhoto() : setPhoto(model);
    isEmpty(photos) && fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let photos = await store.query('photo', {});
      setPhotos(photos);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const savePhoto = async () => {
    try {
      setTaskRunning(true)
      await photo.save();
      toast.showSuccess('Photo saved!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Methods
  const createPhoto = () => {
    let photo = store.createRecord('photo', {
      provider: provider,
      position: 1,
    });
    setPhoto(photo)
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!taskRunning) savePhoto();
  }


  // Render
  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <SectionBlock className='basic'>
        <SectionTitle title={photo.id ? 'Edit Photo' : 'Add Photo'} className='flex-between'>
          {modal && <Button color='text-gray' onClick={() => toggleModal()}><FAWrapper icon='times'/></Button>}
        </SectionTitle>

        <SectionBody className='md' >
          <FormGroup>
            <FilestackPhotoWrapper
              size='lg'
              width={1}
              height={1}
              photo={photo.photo}
              setPhoto={d => setProp(photo, 'photo', d)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='title'>Title</Label>
            <Input id='title'
              type='text'
              defaultValue={photo.title}
              placeholder='My Photo'
              onChange={e => inputProp(photo, 'title', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='position'>Position</Label>
            <Input id='position'
              type='select'
              defaultValue={photo.displayPosition}
              onChange={d => inputProp(photo, 'position', d)}>
              <option key={0} value={0}>1</option>
              {photos.map((photo, index) => (
                <option key={index} value={index}>{index + 2}</option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for='description'>Description</Label>
            <Input id='description'
              type='textarea'
              rows='3'
              defaultValue={photo.description}
              placeholder='Please share any relevant information...'
              onChange={d => inputProp(photo, 'description', d)}
            />
          </FormGroup>
        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            type='submit'
            icon='check'
            title='Save'
            taskRunning={taskRunning}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withAdmin(withStore(withToast(PhotoForm)));
