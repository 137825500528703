import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Button, FormGroup, Label, Input } from 'reactstrap';
import { timeout } from 'utils/helpers';


const ProviderFlowConfirm = (props) => {
  const { provider, history } = props;
  const [ msg, setMsg ] = useState('');


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    setMsg('Step 1: Saving your information...');
    await timeout(600);
    setMsg('Step 2: Building your dashboard...');
    await timeout(600);
    setMsg("Step 3: You're good to go. Have fun!");
    await timeout(600);
    history.push(`/admin/${provider.id}/dashboard`);
  }


  return (
    <SectionBlock>
      <SectionTitle title='Confirm'/>
      <SectionBody className='md'>
        <Empty
          type='index'
          icon='check-circle'
          iconSize='3x'
          title='Nice work!'
          msg={msg}
          loading={true}
        />
      </SectionBody>
    </SectionBlock>
  )
}


export default withRouter(ProviderFlowConfirm);
