import React, { useEffect, useState } from 'react';
import { CheckoutContext } from 'contexts/checkout-context';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';

import Moment from 'moment';
import BookingFormDetails from 'components/booking/booking-form-details';
import BookingFormServices from 'components/booking/booking-form-services';
import BookingFormReview from 'components/booking/booking-form-review';
import BookingFormConfirm from 'components/booking/booking-form-confirm';
import BookingFormSteps from 'components/booking/booking-form-steps';

import { isEmpty } from 'utils/helpers';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Row, Col, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getTimezoneFromLatLong } from 'utils/common/commonFunctions';
import moment from "moment-timezone";

const BookingForm = (props) => {
  const { booking = {}, isAdmin = true, session, store, toast, nextAction } = props;
  const [view, setView] = useState('showDetails');
  const [loading, setLoading] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);


  const steps = [
    {
      view: 'showDetails',
      title: 'Details',
      value: '25',
      step: 1,
    }, {
      view: 'showServices',
      title: 'Services',
      value: '50',
      step: 2,
    },
    {
      view: 'showReview',
      title: 'Review',
      value: '75',
      step: 3,
    }, {
      view: 'showConfirm',
      title: 'Confirm',
      value: '100',
      step: 4,
    }
  ]


  // Methods
  const saveBooking = async (booking) => {
    const startDateTime = booking?.startDate;
    let endDateTime = booking?.endDate;

    try {
      if (taskRunning) { return; }
      setTaskRunning(true);
      booking.set('amount', booking.get('previewAmount'));
      const getTotalServiceTime = booking?.previewDuration;
      const clonedMoment = endDateTime.clone();

      const updatedEndDateTime = clonedMoment.add(
        getTotalServiceTime,
        "minutes"
      );
      const timezone = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude, true);
      const startTimezoneTime = moment.tz(startDateTime?.format('YYYY-MM-DDTHH:mm:ss'), timezone);
      const endTimezoneTime = moment.tz(updatedEndDateTime?.format('YYYY-MM-DDTHH:mm:ss'), timezone);

      const gmtStartTime = startTimezoneTime.clone().utc();
      const gmtEndTime = endTimezoneTime.clone().utc();
      booking.set("endDate", gmtEndTime);
      booking.set("startDate", gmtStartTime);

      await booking.save();
      setView('showConfirm');
    } catch (e) {
      toast.showError(e);
    } finally {
      booking.set("endDate", endDateTime);
      booking.set("startDate", startDateTime);
      setTaskRunning(false);
    }
  }

  const step = (view) => {
    return view ? steps.find(step => step.view == view) : {};
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Edit Booking' />
      <SectionBody className='no-p no-m'>
        <Container>
          <BookingFormSteps steps={steps} step={step(view)} changeStep={step => setView(step.view)} />
        </Container>
        {view == 'showDetails' && (
          <BookingFormDetails
            booking={booking}
            session={session}
            store={store}
            isAdmin={isAdmin}
            prevAction={() => exitAction()}
            nextAction={() => setView('showServices')}
          />
        )}
        {view == 'showServices' && (
          <BookingFormServices
            booking={booking}
            session={session}
            store={store}
            prevAction={() => setView('showDetails')}
            nextAction={() => setView('showReview')}
          />
        )}
        {view == 'showReview' && (
          <BookingFormReview
            booking={booking}
            session={session}
            store={store}
            isAdmin={isAdmin}
            taskRunning={taskRunning}
            prevAction={() => setView('showServices')}
            nextAction={() => saveBooking(booking)}
          />
        )}
        {view == 'showConfirm' && (
          <BookingFormConfirm
            booking={booking}
            session={session}
            store={store}
            isAdmin={isAdmin}
            prevAction={() => setView('showServices')}
            nextAction={() => nextAction()}
          />
        )}

      </SectionBody>
    </SectionBlock>
  )
}


export default withSession(withStore(withToast(BookingForm)));
