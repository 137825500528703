import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withAdmin } from "contexts/admin-context";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import { AdminRoute } from "components/basics/routes";
import ScheduleSection from "components/schedule/schedule-section";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
} from "reactstrap";

const AdminSettingsScheduleRoute = (props) => {
  const { providerId, history, provider, store, toast } = props;
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(false);

  // Hooks
  useEffect(() => {
    fetchData();
  }, []);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord("schedule", null, {
        include: "schedule_date",
      });

      setSchedule(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  // Render
  return (
    <AdminRoute title="Blush Admin - Schedule">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`/admin/${providerId}/settings`}>Settings</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Schedule</BreadcrumbItem>
      </Breadcrumb>

      <ScheduleSection
        schedule={schedule}
        loading={loading}
        refreshData={fetchData}
      />
    </AdminRoute>
  );
};

export default withAdmin(withStore(withToast(AdminSettingsScheduleRoute)));
