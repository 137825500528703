import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import BookingForm from 'components/booking/booking-form';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminBookingsEditRoute = (props) => {
  const { bookingId, providerId, history, store, toast } = props;
  const [ booking, setBooking ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('booking', bookingId, { include: 'location,services,provider,customer'});
      setBooking(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(true);
    }
  }

  const viewBooking = () => {
    history.push(`/admin/${providerId}/bookings/${bookingId}`);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Edit Booking'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/bookings`}>Bookings</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to={`/admin/${providerId}/bookings/${bookingId}`}>Booking #{booking.confirmationCode}</Link></BreadcrumbItem>
        <BreadcrumbItem active>Edit</BreadcrumbItem>
      </Breadcrumb>

      <BookingForm booking={booking} isAdmin={true} nextAction={() => viewBooking()} />
    </AdminRoute>
  );
}

export default withStore(withToast(AdminBookingsEditRoute));
