import React from 'react';
import * as Sentry from '@sentry/browser';
import { logger } from 'utils/helpers';

class SentryService {

	get env() {
		return window.env.REACT_APP_NODE_ENV;
	}

	static captureInfoException(e) {
		if (env == 'development') { return; }
		logger('info error: ', e);
		Sentry.withScope(scope => {
			scope.setLevel('info');
			Sentry.captureException(e);
		});
	}

	static captureWarningException(e) {
		if (env == 'development') { return; }
		logger('warning error: ', e);
		Sentry.withScope(scope => {
			scope.setLevel('warning');
			Sentry.captureException(e);
		});
	}

	static captureCriticalException(e) {
		if (env == 'development') { return; }
		logger('critical error: ', e);
		Sentry.withScope(scope => {
			scope.setLevel('fatal');
			Sentry.captureException(e);
		});
	}

}

export default SentryService;

// Docs
// https://github.com/getsentry/sentry-javascript
