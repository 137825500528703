import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';

import Head from 'components/basics/head';
import MktNav from 'components/navs/mkt-nav';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import CardSection from 'components/card/card-section';
import AppFooter from 'components/app/app-footer';

import { isEmpty, timeout } from 'utils/helpers';
import { Container, Breadcrumb, BreadcrumbItem, Button, Modal } from 'reactstrap';

const UserSettingsCreditCards = (props) => {
  const { userId, user } = props;


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Cards'/>
      <MktAnnouncement/>
      <MktNav />

      <main className='app-main'>
        <Container className='md'>

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/user/${userId}/settings`}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Cards</BreadcrumbItem>
          </Breadcrumb>

          <CardSection user={user} />

        </Container>
			</main>

      <AppFooter />
  	</div>
	);
}


export default withUser(UserSettingsCreditCards);
