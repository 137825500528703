import React, { useEffect } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { BankGridCard, BankPreviewCard } from 'components/bank/bank-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const BankList = (props) => {
  const { banks = [], type = 'grid', store, toast, nextAction, loading, className = '' } = props;

  // Render
    if (loading) {
      return <LoadingSkeleton type={type}/>

    } else if (banks && banks.length > 0) {
      return (
        <Row>
          {type == 'preview' && banks.map(bank => (
            <Col md='12' key={bank.id}>
              <BankPreviewCard
                bank={bank}
                store={store}
                nextAction={nextAction}
                className={className}
              />
            </Col>
          ))}
          {type == 'grid' && banks.map(bank => (
            <Col sm='6' key={bank.id}>
              <BankGridCard
                bank={bank}
                store={store}
                className={className}
              />
            </Col>
          ))}
        </Row>
      )
    }
    return (
      <Empty
        type={type}
        icon='university'
        title='Bank Accounts'
        msg="Sorry, it looks like you don't have any bank accounts on file yet."
      />
    )
  }

export default withStore(withToast(BankList));
