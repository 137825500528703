import React from 'react';
import { Link } from 'react-router-dom';

import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';

const MktCard = (props) => {
	const { status, photo, title, subtitle, line1, line2, className = '', children } = props;


	// Render
	return (
		<div className={'card mkt ' + className}>
			<div className='card-header mkt'>
				<img className='card-header-img' src={photo} />
			</div>
			<div className='card-body'>
				{status && <Badge color={status} className='sm mb-2' pill>{status}</Badge>}
				{title && <h5 className='title'>{title}</h5>}
				{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
				{line1 && <h6>{line1}</h6>}
				{line2 && <h6>{line2}</h6>}
				{children}
			</div>
		</div>
	)
}

export default MktCard;
