import React, { Fragment, useEffect, useState } from 'react';
import ChargeList from 'components/charge/charge-list';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Button, Dropdown, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const RefundFlowSelect = (props) => {
  const { refund = {}, booking = {}, store, toast, prevAction, nextAction } = props;
  const [ charges, setCharges ] = useState([]);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('charge', { include: 'card', bookingId: booking.id });
      let filteredModel = model.filter(c => c.reason != 'tip');
      setCharges(filteredModel);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const setCharge = (chargeId) => {
    let charge = store.peekRecord('charge', chargeId);
    refund.set('charge', charge);
    nextAction();
  }


  // Render
  return (
    <Fragment>
      <SectionBody className='md'>
        <ChargeList
          type='select'
          charges={charges}
          selectedID={refund.charge && refund.charge.id}
          action={chargeId => setCharge(chargeId)}
        />
      </SectionBody>
      <SectionFooter className='flex-between'>
        <PrevButton
          title='Exit'
          onClick={() => prevAction()}
        />
        <NextButton
          title='Next'
          disabled={!refund.charge}
          onClick={() => nextAction()}
        />
      </SectionFooter>
    </Fragment>
  )
}

export default RefundFlowSelect;
