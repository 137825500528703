import React, { Fragment, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { withCheckout } from 'contexts/checkout-context';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import CategoryList from 'components/category/category-list';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty } from 'utils/helpers';
import { pushRelation, pushService } from 'utils/store-helpers';

const BookingFlowServices = (props) => {
  const { booking, session, store, toast, prevAction, nextAction, checkout } = props;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('category', {
        status: 'active',
        sortProp: 'position',
        sortValue: 'asc',
        include: 'services',
        state: booking?.location?.state || '',
        source: 'booking'
      });
      const servicesArray = model && model?.flatMap(item => item?.services?.map(service => service));
      if (booking && booking?.services) {
        booking?.services?.map(item => {
          const quantity = item?.quantity || 1;
          const service = servicesArray?.find(s => s?.id === item?.id);
          item.originalPrice = service ? service?.price : item?.price;
          item.price = service ? (quantity * service?.price) : (quantity * item?.price);
        })
      }
      setCategories(model)
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const pushServiceHandle = (serviceId) => {
    let service = store.peekRecord('service', serviceId);
    // pushRelation(booking, 'services', service);
    let services = booking?.["services"];
		let selectedIndex = services?.findIndex(item => item?.id === service?.id);
    if(selectedIndex <= -1){
      pushService(booking, 'services', service);
    }
    booking.updateEndDate();
    checkout.toggleMenu(!checkout.menu);
  }


  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Back' onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionTitle title='Select services' />
        <SectionBody className='md'>
          <CategoryList
            type='select'
            categories={categories}
            loading={loading}
            bookingFlow={true}
            selectedIDs={booking.serviceIds}
            onClick={serviceId => pushServiceHandle(serviceId)}
          />
        </SectionBody>

        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            disabled={isEmpty(booking.services)}
            onClick={nextAction}
            className='btn-block btn-lg'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}


export default withCheckout(BookingFlowServices);
