import React from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ActivitySection from 'components/activity/activity-section';
import AnalyticsSection from 'components/analytics/analytics-section';
import BookingSection from 'components/booking/booking-section';
import ProviderBalance from 'components/provider/provider-balance';
import IntercomBtnWrapper from 'components/vendors/intercom-btn-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminDashboardRoute = (props) => {
  const { history, providerId, provider } = props;

  return (
  	<AdminRoute title='Blush Admin - Dashboard'>

      <Breadcrumb>
        <BreadcrumbItem active>Dashboard</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionTitle className={!provider.isActive ? 'flex-between' : 'no-b flex-between'}>
          <h5 className='title'>Hi {provider.firstName}!</h5>
          <Badge color={provider.status} pill>{provider.status}</Badge>
        </SectionTitle>
        {!provider.isActive && (
          <SectionBody className='none'>
            <h6 className='uppercase'>
              <FAWrapper icon='exclamation-triangle' className='mr-3'/>Please complete the setup guide!
              <Link to='getting-started' className='btn btn-text-primary ml-2'>
                Get Started<FAWrapper icon='chevron-right' className='ml-2'/>
              </Link>
            </h6>
          </SectionBody>
        )}
      </SectionBlock>

      <SectionBlock>
        <SectionTitle title='Balance' className='flex-between no-b'>
          <ProviderBalance provider={provider}/>
        </SectionTitle>
      </SectionBlock>

      <AnalyticsSection type='preview' provider={provider}/>

      <Row>
        <Col md='8'>
          <BookingSection status='requested' sortProp='id' sortValue='desc' include='provider,services,location' />
        </Col>

        <Col md='4'>
          <ActivitySection sortProp='id' sortValue='desc' provider={provider} className='bb' />

          <SectionBlock>
            <SectionBody>

              <h6><b>Need Help?</b>
                <IntercomBtnWrapper
                  size='xs'
                  color='text-blue'
                  className='ml-2'>
                  Contact Support
                </IntercomBtnWrapper>
              </h6>

            </SectionBody>
          </SectionBlock>
        </Col>

      </Row>
  	</AdminRoute>
	);
}

export default withAdmin(AdminDashboardRoute);
