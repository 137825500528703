import React from 'react';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import Empty from 'components/misc/empty';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { timeout } from 'utils/helpers';

const RefundFlowConfirm = (props) => {
  const { review, booking, store, toast, nextAction } = props;

  // Hooks

	// Render
  return (
    <div>
      <SectionBody className='md'>
        <Empty
          type='index'
          icon='check-circle'
          iconSize='3x'
          title='Nice work!'
          msg='Your refund has been issued. Please allow 5-7 business days for it to process.'
        />
      </SectionBody>
      <SectionFooter className='flex-end'>
        <NextButton
          text='Finish'
          onClick={() => nextAction()}
        />
      </SectionFooter>
    </div>
  )
}


export default RefundFlowConfirm;
