import React, { useState } from 'react';
import IntervalForm from 'components/interval/interval-form';
import EventForm from 'components/event/event-form';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Modal, Row, Col, ButtonGroup, Button } from 'reactstrap';


const ScheduleFlow = (props) => {
  const { modal, toggleModal, showEvent, showInterval } = props;

  return (
    <SectionBlock className='basic'>
      <SectionTitle className='flex-between'>
        <h4>Schedule Manager</h4>
        {modal && <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>}
      </SectionTitle>

      <SectionBody>
        <ButtonGroup>
          <Row>

            <Col>
              <Button color='toggle-outline-lighter-gray' size='xl' onClick={() => showInterval()}>
                <div className='pt-15 pb-15'>
                  <FAWrapper icon='calendar-plus' iconFam='fad' size='3x'/>
                  <div className='card-body text-center'>
                    <h4>Business Hours</h4>
                    <h6>Add business hours to make yourself available during certain times.</h6>
                  </div>
                </div>
              </Button>
            </Col>

            <Col>
              <Button color='toggle-outline-lighter-gray' size='xl' onClick={() => showEvent()}>
                <div className='pt-15 pb-15'>
                  <FAWrapper icon='calendar-times' iconFam='fad' size='3x'/>
                  <div className='card-body text-center'>
                    <h4>Off-Time</h4>
                    <h6>Add off-time to make yourself unavailable during certain times.</h6>
                  </div>
                </div>
              </Button>
            </Col>

          </Row>
        </ButtonGroup>
      </SectionBody>
    </SectionBlock>
  )
}

export default ScheduleFlow;
