import AppAdapter from 'adapters/app';

class LocationAdapter extends AppAdapter {
	constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
}

export default LocationAdapter;