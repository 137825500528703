import AppAdapter from 'adapters/app';

class ScheduleAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
	

	// Methods
	static urlForQueryRecord(type, id) {
		if(id) {
			return this.buildURL() + `/${type}/${id}`;	
		}
		return this.buildURL() + `/schedule`;
	}
}

export default ScheduleAdapter;