import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import BankForm from 'components/bank/bank-form';
import LicenseForm from 'components/license/license-form';
import MerchantForm from 'components/merchant/merchant-form';
import ProviderForm from 'components/provider/provider-form';
import AppCard from 'components/app/app-card';
import Empty from 'components/misc/empty';
import { TextButton } from 'components/bootstrap/button-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Modal, Row, Col, Badge } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const SetupList = (props) => {
  const { type = 'list', provider, history, store, toast, action } = props;
  const [ setup, setSetup ] = useState([]);
  const [ modal, setModal ] = useState(false);
  const [ view, setView ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  },[])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('setup', {});
      setSetup(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const submitProvider = async () => {
    try {
      setTaskRunning(true);
      store.adapterFor('provider').set('action', 'pending');
      provider.set('status', 'pending');
      await provider.save();
      store.adapterFor('provider').set('action', null);
      toast.showSuccess('Provider submitted!');
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false)
    }
  }


  // Methods
  const toggleModal = (name = '') => {
    setModal(!modal);
    setView(name);
  }

  const refreshData = () => {
    setModal(false);
    fetchData();
  }

  const visitGoodHire = () => {
    window.open('https://apply.checkr.com/welcome?next=%2Fapply%2Fnickita-llc-53bfccfc81%2F9cec133a7074f2cc63a2c3f6e128ad71');
    // window.open('https://trueme.goodhire.com/custom-link/e518a452-1148-4cd1-ab8e-ca36282534e1'); old goodhire link https://trueme.goodhire.com/custom-link/878ef7dd-e79a-4ac9-922f-54f6900c331d
  }


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  return (
    <Fragment>
      <Modal isOpen={modal}>
        {view == 'bank' && (
          <BankForm
            provider={provider}
            modal={modal}
            toggleModal={() => toggleModal()}
            nextAction={() => refreshData()}
          />
        )}
        {view == 'insurance' && (
          <LicenseForm
            provider={provider}
            licenseType='insurance'
            modal={modal}
            toggleModal={() => toggleModal()}
            nextAction={() => refreshData()}
          />
        )}
        {view == 'cosmetology' && (
          <LicenseForm
            provider={provider}
            licenseType='cosmetology'
            modal={modal}
            toggleModal={() => toggleModal()}
            nextAction={() => refreshData()}
          />
        )}
        {view == 'merchant' && (
          <MerchantForm
            provider={provider}
            modal={modal}
            toggleModal={() => toggleModal()}
            nextAction={() => refreshData()}
          />
        )}
        {view == 'provider' && (
          <ProviderForm
            provider={provider}
            modal={modal}
            toggleModal={() => toggleModal()}
            nextAction={() => refreshData()}
          />
        )}
      </Modal>
      <AppCard
        icon='user-circle'
        title='Profile'
        line1='Please complete your profile so we can better market your skills.'>
        {setup.profileComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => toggleModal('provider')}/>}
      </AppCard>

      <AppCard
        icon='university'
        title='Merchant'
        line1='Please complete your merchant so we can process payments on your behalf.'>
        {setup.merchantComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => toggleModal('merchant')}/>}
      </AppCard>

      <AppCard
        icon='calendar-alt'
        title='Schedule'
        line1="Please complete your schedule so you only receive requests based on your availability.">
        {setup.scheduleComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => history.push(`/admin/${provider.id}/settings/schedule`)} />}
      </AppCard>

      <AppCard
        icon='file-certificate'
        title='Insurance Certificate'
        line1='Please upload a copy of your insurance for our records.'>
        {setup.insuranceComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => toggleModal('insurance')}/>}
      </AppCard>

      <AppCard
        icon='file-certificate'
        title='Cosmetology License'
        line1='Please upload a copy of your cosmetology license for our records.'>
        {setup.cosmetologyComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => toggleModal('cosmetology')}/>}
      </AppCard>

      <AppCard
        icon='piggy-bank'
        title='Banking'
        line1='Please add a bank account so we can process payouts on your behalf.'>
        {setup.bankingComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => toggleModal('bank')}/>}
      </AppCard>

      <AppCard
        icon='check-circle'
        title='Background Check'
        line1='50% of the cost of the background check is incurred by you and Blush covers the remainder.'>
        {setup.backgroundComplete ? <FAWrapper icon='check-circle' size='2x' className='gray-color' /> : <TextButton title='Manage' onClick={() => visitGoodHire()} />}
      </AppCard>

      <AppCard
        icon='broadcast-tower'
        title='Activate Account'
        line1='Ready to get started? Submit your profile for verification here.'>
        {(provider.isActive || provider.isPending) ? <Badge color={provider.status} pill>{provider.status}</Badge> : <TextButton title='Activate' taskRunning={taskRunning} onClick={() => submitProvider()} />}
      </AppCard>
    </Fragment>
  )
}


export default withRouter(withStore(withToast(SetupList)));
