import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { MobileNavContext } from 'contexts/mobile-nav-context';
import UserDropdown from 'components/user/user-dropdown';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Nav, Navbar, NavbarToggler, NavbarBrand, NavItem } from 'reactstrap';
import { BlushLogo } from 'utils/assets/branding';

const AuthNav = (props) => {
	const { isRegister, session } = props;
	const [ isOpen, setIsOpen ] = useState(false);

	const toggle = () => setIsOpen(prevState => !prevState);

  const logout = () => {
  	props.session.logout();
  	props.history.push('/');
  }

	return (
		<Navbar className='navbar container-fluid flex-between'>
			<NavbarBrand href="/"><img src={BlushLogo} /></NavbarBrand>

			<div className='flex-end'>

				<MobileNavContext.Consumer>
					{state => (
						<NavbarToggler className={state.isOpen ? 'btn hamburger hamburger-emphatic is-active' : 'btn hamburger hamburger-emphatic'} type="button" onClick={state.toggleOpen}>
						  <span className="hamburger-box">
						    <span className="hamburger-inner"></span>
						  </span>
						</NavbarToggler>
					)}
				</MobileNavContext.Consumer>

				<Nav className='ml-auto flex-end' navbar>
					{session.isAuthenticated() ? (
						<UserDropdown user={session.user} logout={logout} />
					) : (
						<div>
							{isRegister ? (
								<NavItem className='flex-end'>
									<h6 className='uppercase'>Already have an account?</h6>
									<NavLink to='/login' className='btn btn-nav-primary'>Login</NavLink>
								</NavItem>
								) : (
								<NavItem className='flex-end'>
									<h6 className='uppercase'>Need an account?</h6>
									<NavLink to='/register' className='btn btn-nav-primary'>Register</NavLink>
								</NavItem>
							)}
						</div>
					)}
				</Nav>

			</div>

		</Navbar>
	)
}

export default withSession(withRouter(AuthNav));
