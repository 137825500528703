import React, { useEffect, useState } from 'react';
import CategoryList from 'components/category/category-list';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import Empty from 'components/misc/empty';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { pushRelation } from 'utils/store-helpers';

const BookingFormServices = (props) => {
  const { booking = {}, store, toast, prevAction, nextAction, taskRunning } = props;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('category', {
        status: 'active',
        sortProp: 'position',
        sortValue: 'asc',
        include: 'services',
        state: booking?.location?.state || '',
        source: 'booking'
      });
      const servicesArray = model && model?.flatMap(item => item?.services?.map(service => service));
      if (booking && booking?.services) {
        booking?.services?.map(item => {
          const quantity = item?.quantity || 1;
          const service = servicesArray?.find(s => s?.id === item?.id);
          item.originalPrice = service ? service?.price : item?.price;
          item.price = service ? (quantity * service?.price) : (quantity * item?.price);
        })
      }
      setCategories(model)
    } catch (e) {
      toast?.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const pushService = (serviceId) => {
    let service = store.peekRecord('service', serviceId);
    // pushRelation(booking, 'services', service);
    pushService(booking, 'services', service)

  }


  // Render
  return (
    <SectionBlock className='no-b no-m'>
      <SectionBody className='md'>
        <CategoryList
          type='select'
          categories={categories}
          loading={loading}
          selectedIDs={booking.serviceIds}
          editBooking={true}
          booking={booking}
          onClick={serviceId => pushService(serviceId)}
        />
      </SectionBody>

      <SectionFooter className='flex-between'>
        <PrevButton title='Back' onClick={prevAction} />

        <NextButton
          title='Next'
          onClick={nextAction}
        />

      </SectionFooter>
    </SectionBlock>
  )
}

export default BookingFormServices;
