import AppAdapter from 'adapters/app';

class BalanceAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static bookingId = '';
	static providerId = '';


	static urlForQueryRecord(modelName, id) {
		return this.buildURL() + `/${modelName}`;
	}
}

export default BalanceAdapter;