import React, { Component } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { BookingListCard, BookingPreviewCard } from 'components/booking/booking-card';
import BookingTable from 'components/booking/booking-table';
import Empty from 'components/misc/empty';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col } from 'reactstrap';
import { timeout, isEmpty } from 'utils/helpers';

const BookingList = (props) => {
  const { bookings = [], provider, type = 'list', meta, sortProp, sortValue, sortList, setPage, onClick, noEmpty, loading } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  else if (bookings && bookings.length > 0) {
    return (
      <Row>
        {type == 'list' && bookings.map(booking => (
          <Col md='12' key={booking.id}>
            <BookingListCard
              type={type}
              booking={booking}
              provider={provider}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'preview' && bookings.map(booking => (
          <Col md='12' key={booking.id}>
            <BookingPreviewCard
              type={type}
              booking={booking}
              provider={provider}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'table' && (
          <Col xs='12'>
            <BookingTable
              bookings={bookings}
              provider={provider}
              sortProp={sortProp}
              sortValue={sortValue}
              sortList={sortList}
            />
          </Col>
        )}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='cut'
      title='Bookings'
      msg="Sorry, we couldn't find any bookings that match your criteria."
    />
  )
}


export default withStore(withToast(BookingList));
