import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import FAWrapper from 'components/vendors/fa-wrapper';

const MktAnnouncement = (props) => {
	const {} = props;

	return (
		<Link to='/for-stylists' className='mkt-announcement-block flex-end'>
			<h6 className='white-color'>Become A Blush Pro<FAWrapper icon='chevron-right' className='ml-15' /></h6>
		</Link>
	)
}

export default MktAnnouncement;