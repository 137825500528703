import React, { useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import RegisterForm from 'components/auth/register-form';
import LoginForm from 'components/auth/login-form';

const BookingFlowAuth = (props) => {
  const { booking, session, store, toast, prevAction, nextAction } = props;
  const [ register, setRegister ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const updateFlow = () => {
    booking.set('user', session.user);
    nextAction();
  }


	// Render
	return (
    <Container className='sm'>
      <SectionBlock>
        <SectionTitle title={register ? 'Register to continue' : 'Login to continue'}/>
        <SectionBody className='md'>
          {register ? (
            <RegisterForm
              userID={session.userId}
              flow={true}
              toggleView={() => setRegister(!register)}
              nextAction={() => updateFlow()}
            />
          ) : (
            <LoginForm
              userID={session.userId}
              flow={true}
              toggleView={() => setRegister(!register)}
              nextAction={() => updateFlow()}
            />
          )}
        </SectionBody>

			</SectionBlock>
    </Container>
	)
}

export default BookingFlowAuth;
