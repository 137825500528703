import AppModel from 'models/app';
import Moment from 'moment';

class Article extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		
		// Attrs
		this.status = props.status;
		this.photo = props.featured_image;
		this.title = props.title;
		this.summary = props.summary;
		this.body = props.body;
		this.slug = props.slug;

		// Misc
		this.createdAt = props.created;
		this.publishedAt = props.published;

		
		// Relationships
		this.author = this.belongsTo('author', props.author);
		this.categories = props.categories;
		this.tags = props.tags;
	}


	// Computed
	get formattedSlug() {
		return '/blog/' + this.slug;
	}

	get displayPublishedAt() {
		return Moment(this.publishedAt).format('ddd MMM Do, YYYY');
	}
}

export default Article;