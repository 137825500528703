import React, { useEffect, useState } from 'react';
import { withGa } from 'contexts/google-analytics-context';
import { withMixpanel } from 'contexts/mixpanel-context';
import { withPixel } from 'contexts/pixel-context';
import { isEmpty, logger, timeout } from 'utils/helpers';

export const TrackerContext = React.createContext();

const TrackerWrapper = ({ ga, mixpanel, pixel, children }) => {
  const [ loaded, setLoaded ] = useState(false);


  // Hooks
  useEffect(() => {
    if (pixel.loaded && mixpanel.loaded && ga.loaded) setLoaded(true);
  }, [pixel, mixpanel, ga])


  // Methods
  const trackEvent = (eventName, data = {}) => {
    if (!loaded) return;
    ga.trackEvent({ label: eventName, action: data.view });
    mixpanel.trackEvent(eventName, data);
    // pixel.trackEvent(eventName);
    logger('trackEvent: ', { eventName, data });
  }

  const trackView = (url, data = {}) => {
    if (!loaded) return;
    ga.trackView(url);
    mixpanel.trackView(url);
    pixel.trackView(url);
    logger('trackView: ', { url, data });
  }

  const trackUser = (user) => {
    if (!loaded) return;
    ga.trackUser(user);
    mixpanel.trackUser(user);
    // pixel.trackUser(user);
    logger('trackUser: ', { user });
  }



  // Render
  return (
    <TrackerContext.Provider value={{ trackEvent, trackView, trackUser, loaded }}>
      {children}
    </TrackerContext.Provider>
  )
}


const withTracker = function(WrappedComponent) {
  return (props) => (
    <TrackerContext.Consumer>
      {context => <WrappedComponent tracker={context || {}} {...props} />}
    </TrackerContext.Consumer>
  )
}

const TrackerProvider = withGa(withMixpanel(withPixel(TrackerWrapper)));

export { TrackerProvider, withTracker }

