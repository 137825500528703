import React, { Component } from 'react';

const CartBlock = (props) => {
	let { type, className, children } = props;

	return (
		<div className={'cart-block' + ' ' + type + ' ' + className}>
			{children}
    </div>
	)
}

const CartTitle = (props) => {
	let { type, className, children } = props;

	return (
		<div className={'cart-title' + ' ' + type + ' ' + className}>
			{children}
    </div>
	)
}

const CartBody = (props) => {
	let { type, className, children } = props;

	return (
		<div className={'cart-body' + ' ' + type + ' ' + className}>
			{children}
    </div>
	)
}

const CartFooter = (props) => {
	let { type, className, children } = props;

	return (
		<div className={'cart-footer' + ' ' + type + ' ' + className}>
			{children}
    </div>
	)
}

export { CartBlock, CartTitle, CartBody, CartFooter };

