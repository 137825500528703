import AppSerializer from 'serializers/app';

class PayoutSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get relationships() {
		return {
			bank: { serialize: 'id' },
			provider: { serialize: 'id' },
		}
	}

}

export default PayoutSerializer;