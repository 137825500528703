import React from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import PromoSection from 'components/promo/promo-section';
import AppFooter from 'components/app/app-footer';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const UserSettingsPromotions = (props) => {
  const { userId, user } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Promotions'/>
      <MktAnnouncement />
      <MktNav />

      <main className='app-main'>
        <Container className='md'>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`/user/${userId}/settings`}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Promotions</BreadcrumbItem>
          </Breadcrumb>

          <PromoSection
            type='preview'
            user={user}
            className='p-15 b'
          />
        </Container>
			</main>
      <AppFooter />
  	</div>
	);
}

export default withUser(UserSettingsPromotions);
