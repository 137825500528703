import AppAdapter from 'adapters/app';

class ReviewAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static action = '';

}

export default ReviewAdapter;