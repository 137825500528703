const fontAwesome = require('@fortawesome/fontawesome-svg-core');

import { 
	faFacebookF,
	faGoogle,
	faStripeS,
} from '@fortawesome/free-brands-svg-icons';

fontAwesome.library.add(
	faFacebookF,
	faGoogle,
	faStripeS
)	

export default fontAwesome;