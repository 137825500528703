import React, { Component } from 'react';
import { Puff } from 'svg-loaders-react';

const LoadingIndicator = (props) => {
	const { type, color = 'black', size = '40px', className = '' } = props;

	// Methods
	const computedColor = () => {
		switch (color) {
			case 'black':
			return '#000000';

			case 'primary':
			return '#837590';

			case 'danger':
			return '#D4353F';

			case 'gray':
			return '#9EA7B3';

			default:
			return '#FFFFFF';
		}
	}


	// Render
	return <Puff height={size} width={size} stroke={computedColor()} />
}

export default LoadingIndicator;
