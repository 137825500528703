import React, { useEffect, useState } from 'react';
import Router from 'router';

// Contexts
import { CookieProvider } from 'contexts/cookie-context';
import { GoogleAnalyticsProvider } from 'contexts/google-analytics-context';
import { MixpanelProvider } from 'contexts/mixpanel-context';
import { PixelProvider } from 'contexts/pixel-context';
import { SentryProvider } from 'contexts/sentry-context';
import { SessionProvider } from 'contexts/session-context';
import { StoreProvider } from 'contexts/store-context';
import { TrackerProvider } from 'contexts/tracker-context';
import { ToastProvider } from 'contexts/toast-context';

// Libs
import Mixpanel from 'mixpanel-browser';
import GoogleAnalytics from 'react-ga';
import * as Sentry from '@sentry/browser';


const App = ({ data }) => {


  // Render
  return (
    <div id='application' className='application'>
      <SentryProvider>
        <PixelProvider>
          <MixpanelProvider>
            <GoogleAnalyticsProvider>
              <TrackerProvider>
                <ToastProvider>
                  <CookieProvider>
                    <StoreProvider>
                      <SessionProvider>
                        <Router data={data} />
                      </SessionProvider>
                    </StoreProvider>
                  </CookieProvider>
                </ToastProvider>
              </TrackerProvider>
            </GoogleAnalyticsProvider>
          </MixpanelProvider>
        </PixelProvider>
      </SentryProvider>
    </div>
  )
}

export default App;
