import React from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import LicenseSection from 'components/license/license-section';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminSettingsLicensingRoute = (props) => {
  const { history, providerId, provider } = props;


  // Render
  return (
    <AdminRoute title='Blush Admin - Licensing'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${provider.id}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Licensing</BreadcrumbItem>
      </Breadcrumb>
      <LicenseSection type='grid' sortProp='position' sortValue='asc' provider={provider} />
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsLicensingRoute);

