import React, { Component } from 'react';
import { Button } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

const TableHeader = (props) => {
	let { text, value, sortProp, sortValue, sortData } = props;


	// Render
	if (value == sortProp) {
		return (
			<th className='active'>
				<Button
					color='text-dark-gray'
					onClick={() => sortData(value, sortValue == 'asc' ? 'desc' : 'asc')}
					active>
					{text}<FAWrapper icon={sortValue == 'asc' ? 'sort-up' : 'sort-down'} className='ml-3 sm'/>
				</Button>
	    </th>
		)
	}
	return (
		<th>
			<Button
				color='text-dark-gray'
				onClick={() => sortData(value, 'asc')}>
				{text}<FAWrapper icon='sort' className='ml-3 sm'/>
			</Button>
    </th>
	)
}

export default TableHeader;

