import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';

const LegalNav = (props) => {
	const {} = props;

	return (
		<div className='section-block sidenav'>
			<div className='section-title'>
				<h4 className='bold'>Sections</h4>
			</div>
			<Nav className='p-15' vertical>
        <NavItem><NavLink to='/terms-of-service' className='btn btn-nav-gray'>Terms of Service</NavLink></NavItem>
        <NavItem><NavLink to='/privacy-policy' className='btn btn-nav-gray'>Privacy Policy</NavLink></NavItem>
      </Nav>
    </div>
	)
}

export default LegalNav;
