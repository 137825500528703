import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PreviewBlock = (props) => {
	let { type, className = '', children } = props;

	return (
		<div className={'preview-block ' + type + ' ' + className}>
			{children}
    </div>
	)
}

const PreviewTitle = (props) => {
	let { type, title, className = '', children } = props;

	return (
		<div className={'preview-title ' + type + ' ' + className}>
			{title && <h6 className='title'>{title}</h6>}
			{children}
    </div>
	)
}

const PreviewBody = (props) => {
	let { type, className = '', children } = props;

	return (
		<div className={'preview-body ' + type + ' ' + className}>
			{children}
    </div>
	)
}

const PreviewFooter = (props) => {
	let { type, className = '', children } = props;

	return (
		<div className={'preview-footer ' + type + ' ' + className}>
			{children}
    </div>
	)
}

export { PreviewBlock, PreviewTitle, PreviewBody, PreviewFooter }
