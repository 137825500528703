import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import DatePickerWrapper from "components/vendors/date-picker-wrapper";
import TimePickerWrapper from "components/vendors/time-picker-wrapper";
import FAWrapper from "components/vendors/fa-wrapper";
import { PrevButton, NextButton } from "components/bootstrap/button-wrapper";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  CustomInput,
  Modal,
} from "reactstrap";
import { isEmpty } from "utils/helpers";
import { pushProp } from "utils/store-helpers";

const IntervalForm = (props) => {
  const {
    model = {},
    data = {},
    schedule,
    store,
    toast,
    modal,
    toggleModal,
    nextAction,
  } = props;
  const [interval, setInterval] = useState({});
  const [view, setView] = useState("times");
  const [viewDays, setViewDays] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);
  const [deleteRunning, setDeleteRunning] = useState(false);

  // Hooks
  useEffect(() => {
    !isEmpty(model) ? setInterval(model) : createInterval();
  }, []);

  // Tasks
  const saveInterval = async () => {
    try {
      setTaskRunning(true);
      await interval.save();
      toast.showSuccess("Schedule updated");
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  };

  const deleteInterval = async (interval) => {
    try {
      setDeleteRunning(true);
      await interval.destroy();
      toast.showSuccess("Schedule updated");
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setDeleteRunning(false);
    }
  };

  // Methods
  const createInterval = () => {
    let model = store.createRecord("interval", {
      schedule: schedule,
      startDate: moment(data.date).minute("60").second("00"),
      endDate: moment(data.date).minute("90").second("00"),
    });
    model.set("daysOfWeek", [moment(data.date).weekday()]);
    setInterval(model);
  };

  const checkInterval = (day) => {
    return interval?.daysOfWeek?.includes(day);
  };

  // Render
  return (
    <SectionBlock className="basic">
      <SectionTitle
        title={interval.id ? "Edit Business Hours" : "Add Business Hours"}
        className="flex-between"
      >
        {modal && (
          <Button color="text-gray" onClick={toggleModal}>
            <FAWrapper icon="times" />
          </Button>
        )}
      </SectionTitle>
      {view == "times" && (
        <Fragment>
          <SectionBody className="sm">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Start Time</Label>
                  <TimePickerWrapper
                    type="dropdown"
                    date={interval.startDate}
                    setTime={(date) => interval.updateStartDate(date)}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>End Time</Label>
                  <TimePickerWrapper
                    type="dropdown"
                    date={interval.endDate}
                    setTime={(date) => interval.updateEndDate(date)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Button
                type="button"
                color="text-gray"
                onClick={() => {
                  // setView("dates")
                }}
              >
                Apply to multiple days
              </Button>
            </FormGroup>

            <FormGroup className="flex-between">
              <div
                className={
                  checkInterval(0)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 0);
                }}
              >
                Sun
              </div>
              <div
                className={
                  checkInterval(1)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 1);
                }}
              >
                MON
              </div>
              <div
                className={
                  checkInterval(2)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 2);
                }}
              >
                TUE
              </div>
              <div
                className={
                  checkInterval(3)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 3);
                }}
              >
                WED
              </div>
              <div
                className={
                  checkInterval(4)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 4);
                }}
              >
                THU
              </div>
              <div
                className={
                  checkInterval(5)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 5);
                }}
              >
                FRI
              </div>
              <div
                className={
                  checkInterval(6)
                    ? "interval-form-days-button-selected"
                    : "interval-form-days-button"
                }
                onClick={() => {
                  pushProp(interval, "daysOfWeek", 6);
                }}
              >
                SAT
              </div>
            </FormGroup>
          </SectionBody>

          <SectionFooter className="flex-between">
            {interval.id ? (
              <PrevButton
                icon="trash"
                title="Delete"
                taskRunning={deleteRunning}
                onClick={() => deleteInterval(interval)}
              />
            ) : (
              <span></span>
            )}

            <NextButton
              icon="check"
              title="Save"
              taskRunning={taskRunning}
              onClick={() => saveInterval()}
            />
          </SectionFooter>
        </Fragment>
      )}
      {/* {view == "dates" && (
        <Fragment>
          <SectionBody className="sm">
            <FormGroup className="flex-between sm">
              <h6>Sunday</h6>
              <CustomInput
                type="checkbox"
                id="sun"
                checked={checkInterval(0)}
                onChange={() => pushProp(interval, "daysOfWeek", 0)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Monday</h6>
              <CustomInput
                type="checkbox"
                id="mon"
                checked={checkInterval(1)}
                onChange={() => pushProp(interval, "daysOfWeek", 1)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Tuesday</h6>
              <CustomInput
                type="checkbox"
                id="tue"
                checked={checkInterval(2)}
                onChange={() => pushProp(interval, "daysOfWeek", 2)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Wednesday</h6>
              <CustomInput
                type="checkbox"
                id="wed"
                checked={checkInterval(3)}
                onChange={() => pushProp(interval, "daysOfWeek", 3)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Thursday</h6>
              <CustomInput
                type="checkbox"
                id="thu"
                checked={checkInterval(4)}
                onChange={() => pushProp(interval, "daysOfWeek", 4)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Friday</h6>
              <CustomInput
                type="checkbox"
                id="fri"
                checked={checkInterval(5)}
                onChange={() => pushProp(interval, "daysOfWeek", 5)}
              />
            </FormGroup>

            <FormGroup className="flex-between sm">
              <h6>Saturday</h6>
              <CustomInput
                type="checkbox"
                id="sat"
                checked={checkInterval(6)}
                onChange={() => pushProp(interval, "daysOfWeek", 6)}
              />
            </FormGroup>
          </SectionBody>
          <SectionFooter className="flex-between">
            <PrevButton
              icon="chevron-left"
              title="Back"
              onClick={() => setView("times")}
            />

            <NextButton
              icon="check"
              title="Save"
              taskRunning={taskRunning}
              onClick={() => saveInterval()}
            />
          </SectionFooter>
        </Fragment>
      )} */}
    </SectionBlock>
  );
};

export default withStore(withToast(IntervalForm));
