import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import TimePickerWrapper from 'components/vendors/time-picker-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Form, FormGroup, FormText, Label, Input, Button,  Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { setProp } from 'utils/store-helpers';

const EventForm = (props) => {
  const { model = {}, data = {}, schedule, store, toast, modal, toggleModal, nextAction } = props;
  const [ event, setEvent ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ deleteRunning, setDeleteRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    !isEmpty(model) ? setEvent(model) : createEvent();
  }, []);


  // Tasks
  const saveEvent = async (event) => {
    try {
      setTaskRunning(true);
      await event.save();
      toast.showSuccess('Schedule updated');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false)
    }
  }


  const destroyEvent = async (event) => {
    try {
      setDeleteRunning(true);
      await event.destroy();
      toast.showSuccess('Schedule updated');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDeleteRunning(false);
    }
  }


  // Methods
  const createEvent = () => {
    let model = store.createRecord('event', {
      schedule: schedule,
      startDate: moment(data.date).minute('60').second('00'),
      endDate: moment(data.date).minute('90').second('00'),
    });
    setEvent(model);
  }


  // Render
  return (
    <SectionBlock className='basic'>
      <SectionTitle title={event.id ? 'Edit Off-Time' : 'Add Off-Time'} className='flex-between'>
        {modal && <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>}
      </SectionTitle>

      <SectionBody>
        <Row>
          <Col md='6'>
            <FormGroup>
              <Label>Start Date</Label>
              <DatePickerWrapper
                type='dropdown'
                date={event.startDate}
                setDate={date => setProp(event, 'startDate', date)}
              />
            </FormGroup>
          </Col>

          <Col md='6'>
              <FormGroup>
              <Label>Start Time</Label>
              <TimePickerWrapper
                type='dropdown'
                date={event.startDate}
                setTime={date => setProp(event, 'startDate', date)}
              />
            </FormGroup>
          </Col>

          <Col md='6'>
            <FormGroup>
              <Label>End Date</Label>
              <DatePickerWrapper
                type='dropdown'
                date={event.endDate}
                setDate={date => setProp(event, 'endDate', date)}
              />
            </FormGroup>
          </Col>

          <Col md='6'>
              <FormGroup>
              <Label>End Time</Label>
              <TimePickerWrapper
                type='dropdown'
                date={event.endDate}
                setTime={date => setProp(event, 'endDate', date)}
              />
            </FormGroup>
          </Col>
        </Row>
      </SectionBody>

      <SectionFooter className='flex-between'>
        {event.id ? (
          <PrevButton
            title='Delete'
            icon='trash'
            taskRunning={deleteRunning}
            onClick={() => destroyEvent(event)}
          />
        ) : <span></span> }

        <NextButton
          title='Save'
          icon='check'
          taskRunning={taskRunning}
          onClick={() => saveEvent(event)}
        />
      </SectionFooter>
    </SectionBlock>
  )
}

export default withStore(withToast(EventForm));
