import AppSerializer from 'serializers/app';

class BookingSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get attrs() {
		return {
			commissionFee: false,
			travelFee: false,
			platformFee: false,
			stripeFee: false,
			subtotal: false,
			total: false,
			tipAmount: false,
			refundAmount: false,
			providerCommissionPercent: false
		}
	}

	get relationships() {
		return {
			card: { serialize: 'id' },
			customer: { serialize: 'id' },
			location: { serialize: true },
			promo: { serialize: 'id' },
			provider: { serialize: 'id' },
			user: { serialize: 'id' },
			services: { serialize: true },
		}
	}

}

export default BookingSerializer;