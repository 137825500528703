import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import SpecialEventForm from 'components/misc/special-event-form';
import AppFooter from 'components/app/app-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col } from 'reactstrap';
import { SpecialEvent } from 'utils/assets/splashes';

const MktSpecialEventsRoute = (props) => {
  const { history } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Special Events'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='sm'
        title='Special Events'
        bg={SpecialEvent}
        className='d-block d-md-none'
      />

      <main className='split-main'>
        <Container fluid={true}>
          <Row>

            <Col md='6' className=''>
              <div className='cover-block sm flex p-15'>
                <SpecialEventForm/>
              </div>
              <AppFooter />
            </Col>

            <Col md='6' className='no-pad'>
              <div className='cover-block img lg'>
                <div className='cover-bg-overlay animated fadeIn'></div>
                <div className='cover-bg animated fadeIn' style={{backgroundImage: `url(${SpecialEvent}`}}></div>
              </div>
            </Col>

          </Row>
        </Container>

      </main>
  	</div>
	);
}

export default MktSpecialEventsRoute;
