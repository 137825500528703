import React, { useState } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';

const AvatarCard = (props) => {
	const { photo, title, line1, line2 } = props;

	return (
		<div className='avatar-block flex-start'>
			<div className='avatar-img-block'>
				<img className='avatar-img circle xs' src={photo}/>
			</div>
			<div className='avatar-content ml-15'>
				{title && <h6 className='title'>{title}</h6>}
				{line1 && <h6>{line1}</h6>}
				{line2 && <h6>{line2}</h6>}
			</div>
		</div>
	)
}

export default AvatarCard;
