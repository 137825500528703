import React, { Fragment, useState } from 'react';
import { SearchBookingResult, SearchCustomerResult, SearchPayoutResult } from 'components/search/search-result';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal } from 'reactstrap';

const SearchResults = (props) => {
	const { query = '', results = [], providerId, highlightIndex, loading, nextAction } = props;


	// Methods
	const showResults = () => {
		return query.length >= 3 ? true : false;
	}

	const indexName = (index) => {
		return index.split('_')[0];
	}


	// Render
	return (
		<div className={showResults() ? 'search-results-wrapper show' : 'search-results-wrapper'}>
			<div className='search-results-bg' onClick={() => nextAction()}/>
			<div className='search-results-box'>
				{loading ? (
					<div className='pl-15 pr-15'>
						<LoadingSkeleton type='preview' count={3} />
					</div>
				) : (
					<Fragment>
						{results.map(result => (
				      <li key={result.index} className='search-index'>
				      	<div className='search-index-header'>
				        	<h6>{indexName(result.index)}</h6>
			        	</div>
				        <ul className='search-index-content'>
				          {result.hits.map(hit => (
				          	<Fragment key={hit.objectID}>
				          		{indexName(result.index) == 'bookings' && (
				          			<SearchBookingResult
					            		hit={hit}
					            		providerId={providerId}
					            		highlightIndex={highlightIndex}
				            		/>
			          			)}
				          		{indexName(result.index) == 'customers' && (
				          			<SearchCustomerResult
					            		hit={hit}
					            		providerId={providerId}
					            		highlightIndex={highlightIndex}
				          			/>
			          			)}
				          		{indexName(result.index) == 'payouts' && (
				          			<SearchPayoutResult
					            		hit={hit}
					            		providerId={providerId}
					            		highlightIndex={highlightIndex}
				          			/>
			          			)}
		          			</Fragment>
				          ))}
				        </ul>
				      </li>
			    	))}
		    	</Fragment>
		    )}
	    </div>
		</div>
	)
}

export default SearchResults;
