import React from "react";
import LoadingIndicator from "components/loading/loading-indicator";

const BookingFinance = (props) => {
  const { type, booking, isAdmin, loading, financeData } = props;

  return (
    <div className="table-wrapper">
      <table className="stats mt-2">
        <tbody>
          <tr>
            <td>Service(s)</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>{booking.displayAmount}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Discount</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>
                  {financeData?.discount
                    ? "$ " + financeData?.discount
                    : booking.displayDiscount}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>
                  {financeData?.subTotal
                    ? "$ " + financeData?.subTotal
                    : booking.displaySubtotal}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Processing Fee</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>{booking.displayStripeFee}</span>
              )}
            </td>
          </tr>
          <tr className="totals">
            <td>Total</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>
                  <span className="small bold gray-color mr-3">USD</span>
                  {booking.displayTotal}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="stats mt-2">
        <tbody>
          {isAdmin && (
            <tr>
              <td>Your Commission</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayCommissionFee}</span>
                )}
              </td>
            </tr>
          )}
          <tr className="">
            <td>Your Tip</td>
            <td>
              {loading ? (
                <LoadingIndicator color="gray" size="18px" />
              ) : (
                <span>{booking.displayTipAmount}</span>
              )}
            </td>
          </tr>
          {booking.refundAmount > 0 && (
            <tr className="">
              <td>Refund Total</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span className="danger-color">
                    ({booking.displayRefund})
                  </span>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BookingFinance;
