import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import AppCard from 'components/app/app-card';
import SwitchWrapper from 'components/bootstrap/switch-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { NavLink, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';
import { toggleProp } from 'utils/store-helpers';

const ProviderNotificationForm = (props) => {
  const { settingsId, store, toast, nextAction } = props;
  const [ settings, setSettings ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.findRecord('provider-setting', settingsId);
      setSettings(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const submitForm = async () => {
  	try {
      setTaskRunning(true);
  		await settings.save();
  		toast.showSuccess('Settings updated!');
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

	const handleSubmit = (e) => {
		submitForm();
		e.preventDefault();
	}


	// Render
  return (
    <Form onSubmit={handleSubmit}>
      <SectionBlock>
        <SectionTitle title='Notifications' />

        <SectionBody className='md'>
          <AppCard
            icon='at'
            title='Email Notifications'
            subtitle="Select whether you'd like to receive email notifications.">
            <SwitchWrapper name='emailNotifications' value={settings.emailNotifications} onClick={v => toggleProp(settings, v)}/>
          </AppCard>

          <AppCard
            icon='sms'
            title='SMS Notifications'
            subtitle="Select whether you'd like to receive SMS notifications.">
            <SwitchWrapper name='smsNotifications' value={settings.smsNotifications} onClick={v => toggleProp(settings, v)}/>
          </AppCard>

          <AppCard
            icon='mobile'
            title='Push Notifications'
            subtitle="Select whether you'd like to receive push notifications.">
            <SwitchWrapper name='pushNotifications' value={settings.pushNotifications} onClick={v => toggleProp(settings, v)}/>
          </AppCard>

          <AppCard
            icon='tags'
            title='Marketing Notifications'
            subtitle="Select whether you'd like to receive marketing notifications.">
            <SwitchWrapper name='mktNotifications' value={settings.mktNotifications} onClick={v => toggleProp(settings, v)}/>
          </AppCard>
        </SectionBody>

        <SectionFooter>
          <NextButton
            title='Save'
            icon='check'
            taskRunning={taskRunning}
            onClick={() => submitForm(settings)}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withStore(withToast(ProviderNotificationForm));
