
export const CardOptions = {
  iconStyle: 'solid',
  customFontSource: {
    family: 'Source Sans Pro',
    src: 'url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro)',
    weight: '500',
  },
  style: {
    base: {
      iconColor: '#9EA7B3',
      color: '#383838',
      '::placeholder': {
        color: '#9EA7B3'
      },
      fontFamily: 'Source Sans Pro',
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: '#9B5D72',
      color: '#9B5D72',
    },
    invalid: {
      iconColor: '#FB4E5D',
      color: '#FB4E5D',
    },
  }
}

export const ElementOptions = [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
    }
  ]

export const FontOptions = {
    cssSrc: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro',
  }
