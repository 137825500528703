import React, { Component } from 'react';

const SectionPreBlock = (props) => {
	let { className = '', children } = props;

	return (
		<div className={'section-pre-block ' + className}>
			{children}
    </div>
	)
}

const SectionBlock = (props) => {
	let { className = '', children } = props;

	return (
		<div className={'section-block ' + className}>
			{children}
    </div>
	)
}

const SectionTitle = (props) => {
	let { type, title, className = '', children } = props;

	return (
		<div className={'section-title ' + className}>
			{title && <h5 className='title'>{title}</h5>}
			{children}
    </div>
	)
}

const SectionSubtitle = (props) => {
	let { type, title, className = '', children } = props;

	return (
		<div className={'section-subtitle ' + className}>
			{children}
    </div>
	)
}

const SectionBody = (props) => {
	let { type, className = '', children } = props;

	return (
		<div className={'section-body ' + className}>
			{children}
    </div>
	)
}

const SectionFooter = (props) => {
	let { type, className = '', children } = props;

	return (
		<div className={'section-footer ' + className}>
			{children}
    </div>
	)
}

export { SectionPreBlock, SectionBlock, SectionTitle, SectionSubtitle, SectionBody, SectionFooter };
