import AppAdapter from 'adapters/app';

class BookingAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
	static action = '';


	// Overrides
	static urlForQueryRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);

		if(this.action == 'draft') {
			return url + '/draft';
		}

		if(this.action == 'pending') {
			return url + '/pending';
		}

		if(this.action == 'accept') {
			return url + '/accept';
		}

		if(this.action == 'deny') {
			return url + '/deny';
		}

		if(this.action == 'finish') {
			return url + '/finish';
		}

		if(this.action == 'cancel') {
			return url + '/cancel';
		}
		if(this.action == 'delay') {
			return url + '/delay';
		}
		if(this.action == 'transit') {
			return url + '/transit';
		}		
		return url;
	}

}

export default BookingAdapter;