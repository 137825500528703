import React, { useState } from 'react';
import LoadingIndicator from 'components/loading/loading-indicator';
import CurrencyInputWrapper from 'components/vendors/currency-input-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Button, Dropdown, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';
import { inputCurrency } from 'utils/store-helpers';

const RefundFlowDetails = (props) => {
  const { refund = {}, booking, store, toast, nextAction } = props;
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveRefund = async () => {
    try {
      setTaskRunning(true);
      await refund.save();
      toast.showSuccess('Refund sent!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    saveRefund();
  }


  // Render
  return (
    <Form onSubmit={handleSubmit}>
      <SectionBody className='md'>
        <FormGroup className='mt-3 text-center mb-5'>
          <img className='avatar-img lg mb-3' src={booking.provider && booking.provider.photo} />
          <h4>How much would you like to refund {booking.customer && booking.customer.name}?</h4>
        </FormGroup>
        <FormGroup className='mb-5'>
          <CurrencyInputWrapper
            type=''
            value={refund.amount}
            placeholder='$00.00'
            className='clean xl text-center'
            onChange={d => inputCurrency(refund, 'amount', d)}
          />
        </FormGroup>
      </SectionBody>
      <SectionBody className='border p-15 mb-15'>
        <div className='flex-between'>
          <h6>Card</h6>
          <h5>{refund.charge.card.summary}</h5>
        </div>
        <div className='flex-between'>
          <h6>Available Balance</h6>
          <h5>{loading ? <LoadingIndicator color='gray' size='18px'/> : refund.charge.displayBalance}</h5>
        </div>
        <div className='flex-between'>
          <h6>Total</h6>
          <h5>{refund.displayTotal}</h5>
        </div>
      </SectionBody>

      <SectionFooter className='flex-between'>
        <h6><FAWrapper icon='lock' className='mr-15 small'/>This form is secure</h6>
        <NextButton
          title='Save'
          icon='check'
          taskRunning={taskRunning}
        />
      </SectionFooter>

    </Form>
  )
}


export default RefundFlowDetails;
