import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import AppCard from 'components/app/app-card';
import AppFooter from 'components/app/app-footer';
import ResetPasswordForm from 'components/auth/reset-password-form';
import FacebookBtn from 'components/auth/facebook-btn';
import GoogleBtn from 'components/auth/google-btn';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Modal, Button, FormGroup } from 'reactstrap';


const UserSettingsAccount = (props) => {
  const { userId, user, session, store } = props;
  const [ modal, toggleModal ] = useState(false);


  // Render
  return (
  	<div className='route'>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)}>
        <ResetPasswordForm modal={true} user={user} toggleModal={() => toggleModal(!modal)}/>
      </Modal>

      <Head title='Blush - Account Info'/>
      <MktAnnouncement/>
      <MktNav />

      <main className='app-main'>
        <Container className='md'>

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/user/${userId}/settings`}>Settings</Link></BreadcrumbItem>
            <BreadcrumbItem active>Account</BreadcrumbItem>
          </Breadcrumb>

          <SectionBlock>
            <SectionTitle title='Account'/>
            <SectionBody className='md'>
              <AppCard
                icon='key'
                title='Change Password'
                subtitle='Update your account password for email access.'>
                <Button color='text-blue' size='xs' onClick={() => toggleModal(!modal)}>Reset Password</Button>
              </AppCard>
              <AppCard
                icon='key'
                title='Facebook Login'
                subtitle='Connect your Facebook account for single-tap login access'>
                {user.facebookPresent ? <FAWrapper icon='check-circle' className='accent-color' size='2x'/> : <FacebookBtn/>}
              </AppCard>
              <AppCard
                icon='key'
                title='Google Login'
                subtitle='Connect your Google account for single-tap login access'>
                {user.googlePresent ? <FAWrapper icon='check-circle' className='accent-color' size='2x'/> : <GoogleBtn/>}
              </AppCard>

            </SectionBody>
	  			</SectionBlock>

        </Container>
			</main>

      <AppFooter />
  	</div>
	);
}

export default withStore(withToast(withUser(UserSettingsAccount)));
