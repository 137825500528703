import React, { Component } from "react";

import LoadingIndicator from "components/loading/loading-indicator";
import { isEmpty } from "utils/helpers";

const BookingFinancePreview = (props) => {
  const { type, booking, isAdmin, loading } = props;

  return (
    <div className="table-wrapper">
      <table className="stats mt-2">
        {isAdmin ? (
          <tbody>
            <tr>
              <td>Your Commission</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewCommissionFee}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Platform Fee</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewPlatformFee}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Subtotal</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewAmount}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewStripeFee}</span>
                )}
              </td>
            </tr>
            <tr className="totals">
              <td>Total</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>
                    <span className="small bold gray-color mr-3">USD</span>
                    {booking.displayPreviewTotal}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Service(s)</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewAmount}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Discount</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewDiscount}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Subtotal</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewSubtotal}</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>{booking.displayPreviewStripeFee}</span>
                )}
              </td>
            </tr>
            <tr className="totals">
              <td>Total</td>
              <td>
                {loading ? (
                  <LoadingIndicator color="gray" size="18px" />
                ) : (
                  <span>
                    <span className="small bold gray-color mr-3">USD</span>
                    {booking.displayPreviewTotal}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default BookingFinancePreview;
