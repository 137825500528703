import React, { useEffect, useState } from 'react';
import Empty from 'components/misc/empty';
import { ProviderListCard, ProviderPreviewCard, ProviderMktCard, ProviderSelectCard } from 'components/provider/provider-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col } from 'reactstrap';

const ProviderList = (props) => {
  const { type = 'list', providers = [], loading, selectedID, onClick } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>

  } else if (providers.length > 0) {
    return (
      <Row>
        {type == 'list' && providers.map(provider => (
          <Col md='12' key={provider.id}>
            <ProviderListCard
              provider={provider}
              selectedID={selectedID}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'preview' && providers.map(provider => (
          <Col md='12' key={provider.id}>
            <ProviderPreviewCard
              provider={provider}
              loading={loading}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'mkt' && providers.map(provider => (
          <Col md='4' lg='3' key={provider.id}>
            <ProviderMktCard
              provider={provider}
              loading={loading}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'select' && providers.map(provider => (
          <Col md='12' key={provider.id}>
            <ProviderSelectCard
              provider={provider}
              selectedID={selectedID}
              loading={loading}
              onClick={onClick}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='briefcase'
      title='Providers'
      msg="Sorry, it looks like there aren't any stylists available during that time."
    />
  )
}


export default ProviderList;
