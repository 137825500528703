import AppSerializer from 'serializers/app';

class UserSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get attrs() {
		return {

		}
	}

	get relationships() {
		return {
			location: { serialize: true },
		}
	}

}

export default UserSerializer;