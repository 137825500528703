import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import CurrencyInputWrapper from 'components/vendors/currency-input-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { inputCurrency } from 'utils/store-helpers';
import { Row, Col, Button, Dropdown, Form, FormGroup, Label, Input } from 'reactstrap';

const PayoutFormManual = (props) => {
  const { type = 'form', provider, store, toast, toggleModal, prevAction, refreshData } = props;
  const [ balance, setBalance ] = useState({});
  const [ bank, setBank ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ payout, setPayout ] = useState({});
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      createPayout();
      await fetchBalance();
      await fetchBank();
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const fetchBalance = async () => {
    try {
      let balance = await store.queryRecord('balance', {});
      setBalance(balance)
    } catch(e) {
      throw toast.showError(e);
    }
  }

  const fetchBank = async () => {
    try {
      let banks = await store.query('bank', {});
      setBank(banks.find(bank => bank.default))
    } catch(e) {
      throw toast.showError(e);
    }
  }

  const savePayout = async (payout) => {
    try {
      if (taskRunning) { return; }
      setTaskRunning(true);
      payout.setRelation('bank', bank)
      await payout.save();
      toast.showSuccess('Payout saved!');
      refreshData();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const createPayout = () => {
    let payout = store.createRecord('payout', {
      status: 'pending',
      amount: 0,
      provider: provider,
    });
    setPayout(payout);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    savePayout(payout);
  }


  // Render
  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <SectionBlock className='basic'>
        <SectionTitle className='flex-between'>
          <h4>Manual Payout</h4>
          <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>
        </SectionTitle>

        <SectionBody className='xs'>
            <FormGroup>
              <CurrencyInputWrapper
                type=''
                value={payout.amount}
                placeholder='$00.00'
                className='clean xl text-center'
                onChange={d => inputCurrency(payout, 'amount', d)}
              />
            </FormGroup>
        </SectionBody>

        <SectionBody className='border p-15 mb-15'>
          <div className='flex-between'>
            <h6>Bank Account</h6>
            <h5 className='accent-color'>{loading ? <LoadingIndicator color='gray' size='18px'/> : bank.summary}</h5>
          </div>
          <div className='flex-between'>
            <h6>Available Balance</h6>
            <h5 className='accent-color'>{loading ? <LoadingIndicator color='gray' size='18px'/> : balance.displayAvailableBalance}</h5>
          </div>
          <div className='flex-between'>
            <h6>Payout Amount</h6>
            <h5 className='accent-color'>{loading ? <LoadingIndicator color='gray' size='18px'/> : payout.displayTotal}</h5>
          </div>
        </SectionBody>

        <SectionFooter className='flex-between'>
          <PrevButton
            title='Back'
            onClick={() => prevAction()}
          />
          <NextButton
            icon='check'
            title='Save'
            taskRunning={taskRunning}
            onClick={() => savePayout(payout)}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}


export default withStore(withToast(PayoutFormManual));
