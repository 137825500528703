import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { NextButton } from 'components/bootstrap/button-wrapper';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import { inputProp, setProp, setRelation  } from 'utils/store-helpers'
import { Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const UserForm = (props) => {
  const { store, toast, user = {} } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);

  // Tasks
  const saveUser = async () => {
    try {
      setTaskRunning(true);
      await user.save();
      toast.showSuccess('User saved!', user);
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  // Methods
  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser();
  }


  // Render
  return (
    <Form onSubmit={handleSubmit}>
      <SectionTitle title='Edit Profile' size='lg'/>
      <SectionBody className='md'>
        <FormGroup className='text-center'>
          <FilestackPhotoWrapper
            model={user}
            photo={user.photo}
            setPhoto={p => setProp(user, 'photo', p)}
          />
        </FormGroup>

        <FormGroup>
          <Label for='name'>Name</Label>
          <Input id='name'
            type='text'
            defaultValue={user.name}
            placeholder='Kate Moss'
            onChange={e => inputProp(user, 'name', e)}
          />
        </FormGroup>

        <FormGroup>
          <Label for='email'>Email</Label>
          <Input id='email'
            type='email'
            defaultValue={user.email}
            placeholder='kate@ck.com'
            autoComplete='xxx'
            onChange={e => inputProp(user, 'email', e)}
          />
        </FormGroup>

        <FormGroup>
          <Label for='phone'>Phone</Label>
          <PhoneMaskedInput id='phone'
            type='phone'
            value={user.phone}
            placeholder='(303) 444-2226'
            onChange={e => inputProp(user, 'phone', e)}
          />
        </FormGroup>

        <FormGroup>
          <Label for='location'>Location</Label>
          <GoogleAutocompleteWrapper
            location={user.location}
            setLocation={location => setRelation(user, 'location', location)}
          />
        </FormGroup>
      </SectionBody>

      <SectionFooter>
        <NextButton
          type='submit'
          icon='check'
          title='Save'
          taskRunning={taskRunning}
          />
      </SectionFooter>
    </Form>
  )
}

export default withStore(withToast(UserForm));
