import LodashArray from 'lodash/array';
import RandomString from 'randomstring';

// Methods

export const inputProp = (model, name, e) => {
  const { value } = e.target;
  model.set(name, value);
}

export const inputCurrency = (model, name, e) => {
  const { value } = e.target;
  model.set(name, value.split('$')[1]);
}

export const setProp = (model, name, value) => {
  model.set(name, value);
}

export const setRelation = (model, name, value) => {
  model.setRelation(name, value);
}

export const toggleProp = (model, name) => {
  let currentValue = model.get([name]) ? model.get([name]) : false;
  model.set(name, !currentValue);
}

export const pushProp = (model, name, value) => {
  model[name].includes(value) ? removeObject(model, name, value) : addObject(model, name, value);
}

export const pushRelation = (model, name, value) => {
  if (model[name].map(relation => relation.id).includes(value.id)) {
    let relationship = model[name].find(relation => relation.id == value.id);
    removeObject(model, name, relationship);
  } else {
    addObject(model, name, value);
  }
}

export const removeObject = (model, name, value) => {
  let newArray = LodashArray.pull(model[name], value);
  model.set(name, newArray);
}

export const addObject = (model, name, value) => {
  let newArray = !model[name].includes(value) ? model[name].push(value) : model[name];
  model.set(name, model[name]);
}

export const pushService = (model, name, value) => {
	addServiceObject(model, name, value);
}

export const removeService = (model, name, value) => {
	removeServiceObject(model, name, value);
}

export const updateAmount = (model, name, value) => {
	try {
		let obj = {};
		let services = model[name];
		let selectedIndex = services?.findIndex(item => item?.id === value?.id);

		if (selectedIndex >= 0) {
			let servicePrice = services[selectedIndex]?.originalPrice ? services[selectedIndex]?.originalPrice : services[selectedIndex]?.price
			obj = {
				...services[selectedIndex],
				...{
					price: services[selectedIndex]?.quantity ? ((services[selectedIndex]?.quantity) * servicePrice) : servicePrice,
					originalPrice : services[selectedIndex]?.originalPrice ? services[selectedIndex]?.originalPrice : services[selectedIndex]?.price
				}
			}
			services[selectedIndex] = obj;
			model[name] = services;
		}
		model.set(name, model[name]);
	} catch (e) {
		console.log('error===', e)
	}
}

export const addServiceObject = (model, name, value) => {
	try {
		let obj = {
			...value,
			...{ quantity: 1 }
		};
		let services = model[name];
		let selectedIndex = services?.findIndex(item => item?.id === value?.id);

		if (selectedIndex >= 0) {
			let servicePrice = services[selectedIndex]?.originalPrice ? services[selectedIndex]?.originalPrice : services[selectedIndex]?.price
			obj = {
				...services[selectedIndex],
				...{
					quantity: services[selectedIndex]?.quantity ? services[selectedIndex]?.quantity + 1 : 2,
					price: services[selectedIndex]?.quantity ? ((services[selectedIndex]?.quantity + 1) * servicePrice) : servicePrice * 2
				}
			}
			services[selectedIndex] = obj;
			model[name] = services;
		} else {
			model[name].push({
				...obj,
				...{ originalPrice: value?.price }
			});
		}
		model.set(name, model[name]);
	} catch (e) {
		console.log('error===', e)
	}
}

export const removeServiceObject = (model, name, value) => {

	let services = model[name];
	let selectedIndex = services?.findIndex(item => item?.id === value?.id);
	let quantity = services[selectedIndex]?.quantity;

	if (selectedIndex >= 0 && quantity > 1) {
		let servicePrice = services[selectedIndex]?.originalPrice ? services[selectedIndex]?.originalPrice : services[selectedIndex]?.price

		let obj = {
			...services[selectedIndex],
			...{
				quantity: quantity - 1,
				price: services[selectedIndex]?.quantity ? ((quantity - 1) * servicePrice) : servicePrice * 2
			}
		}
		services[selectedIndex] = obj;
		model[name] = services;

		model.set(name, model[name]);
	} else {
		let filterredData = model[name]?.filter(item => item?.id !== value?.id);
		model.set(name, filterredData);
	}
}

export const getServiceQty = (model, name, value) => {

	let services = model[name];
	let selectedIndex = services?.findIndex(item => item?.id === value?.id);
	if (selectedIndex >= 0) {
		return services[selectedIndex]?.quantity || 0;
	}
	return 0;
}