import React from 'react';
import { Link } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import Head from 'components/basics/head';
import AuthNav from 'components/navs/auth-nav';
import MailCenterForm from 'components/misc/mail-center-form';
import AppFooter from 'components/app/app-footer';


const MailCenterRoute = (props) => {
  const { history, session } = props;

  // Render
  return (
    <div className='route'>
      <Head title='Blush - Mail Center'/>
      <AuthNav session={session} isRegister={true} />
      <main className='auth-main'>
        <div className='container-x-small'>
          <MailCenterForm />
        </div>
      </main>
      <AppFooter />
    </div>
  );
}

export default withSession(MailCenterRoute);
