import AppModel from "models/app";
import Moment from "moment";
import {
  createDateFormat,
  createDateTimeFormat,
} from "utils/common/commonFunctions";

class ScheduleDate extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);
    // Attrs

    // this.dateFormatForSorting = createDateTimeFormat(
    //   props.date.format("YYYY-MM-DD") + " " + props.slotStart
    // );

    this.startDate = props.date.format("YYYY-MM-DD") + " " + props.slotStart;

    this.endDate = props.date.format("YYYY-MM-DD") + " " + props.slotEnd;

    this.date = props.date.format("YYYY-MM-DD");

    // if (this.date === "2023-04-22") console.log(props, "check props");

    this.isAllDay = props?.isAllDay;

    // Keys
    this.scheduleId = props.scheduleId;
    // Relationships
    this.schedule = this.belongsTo("schedule", props.schedule);
  }
  // Computed;
  get displayStartDate() {
    return moment(this.startDate);
  }
  get displayEndDate() {
    return moment(this.endDate);
  }
  get calendarObject() {
    let event = {};
    event.id = this.id;
    event.type = "schedule_dates";
    event.className = "fc-schedule-date";
    event.title =
      Moment(this.startDate).format("hh:mma") +
      " - " +
      Moment(this.endDate).format("hh:mma");

    event.startTime = Moment(this.startDate).format("YYYY-MM-DD HH:mm");
    event.endTime = Moment(this.endDate).format("YYYY-MM-DD HH:mm");
    event.date = Moment(this.startDate).format("YYYY-MM-DD");

    // event.daysOfWeek = [1, 2, 3, 4, 5, 6];
    // event.startRecur = Moment().startOf("year").format();
    return event;
  }
  // // Functions
  // updateStartDate(date) {
  //   this.set("startDate", date);
  //   if (this.startDate >= this.endDate) {
  //     this.updateEndDate(Moment(date).add("30", "m"));
  //   }
  // }
  // updateEndDate(date) {
  //   this.set("endDate", date);
  //   if (this.startDate >= this.endDate) {
  //     this.updateStartDate(Moment(date).subtract("30", "m"));
  //   }
  // }
}

export default ScheduleDate;
