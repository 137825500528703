import React from 'react';
import CurrencyInput from 'react-currency-input';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const CurrencyInputWrapper = (props) => {
  const { type, name, value, placeholder, readOnly, onChange, className = '' } = props;

  return (
    <CurrencyInput
      type='text'
      name={name}
      prefix='$'
      precision='2'
      value={value}
      className={'form-control' + ' ' + className}
      placeholder={placeholder}
      onChangeEvent={onChange}
    />
  )
}

export default CurrencyInputWrapper;

// Docs
// https://github.com/jsillitoe/react-currency-input#readme
