import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FilestackPhotoWrapper from 'components/vendors/filestack-photo-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { inputProp, setProp, setRelation } from 'utils/store-helpers';
import { Row, Col, Button, Form, FormGroup, FormText, Label, Input } from 'reactstrap';

const ProviderForm = (props) => {
  const { provider = {}, store, toast, modal, toggleModal } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const saveProvider = async () => {
    try {
      setTaskRunning(true);
      await provider.save();
      toast.showSuccess('Provider saved!');
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  // Render
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <SectionBlock className='basic'>
        <SectionTitle title='Your Profile' className='flex-between'>
          {modal && <Button color='text-gray' onClick={() => toggleModal()}><FAWrapper icon='times'/></Button>}
        </SectionTitle>
        <SectionBody className='md'>
          <FormGroup className='text-center'>
            <FilestackPhotoWrapper
              photo={provider.photo}
              setPhoto={d => setProp(provider, 'photo', d)}
            />
          </FormGroup>
          <FormGroup>
            <Label for='name'>Name</Label>
            <Input id='name'
              type='text'
              defaultValue={provider.name}
              placeholder='Full name'
              onChange={e => inputProp(provider, 'name', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='email'>Email</Label>
            <Input id='email'
              type='text'
              defaultValue={provider.email}
              onChange={e => inputProp(provider, 'email', e)}
              placeholder='Email address'
            />
          </FormGroup>

          <FormGroup>
            <Label for='address'>Business Address</Label>
            <GoogleAutocompleteWrapper
              location={provider.location}
              setLocation={location => setRelation(provider, 'location', location)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='phone'>Phone Number</Label>
            <PhoneMaskedInput id='phone'
              value={provider.phone}
              placeholder='(303) 444-2226'
              onChange={e => inputProp(provider, 'phone', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='web_url'>Web Address</Label>
            <Input id='web_url'
              type='text'
              defaultValue={provider.social.webUrl}
              placeholder='www.katemoss.com'
              onChange={e => inputProp(provider, 'social.webUrl', e)}
            />
          </FormGroup>

          <Row>
            <Col md='6'>
              <FormGroup>
                <Label for='dob'>Date of Birth</Label>
                <DobMaskedInput id='dob'
                  value={provider.dob}
                  placeholder='MM/DD/YYYY'
                  onChange={e => inputProp(provider, 'dob', e)}
                />
              </FormGroup>
            </Col>
              <Col md='6'>
              <FormGroup>
                <Label for='ssn'>Last Four SSN</Label>
                <Input id='ssn'
                  type='password'
                  defaultValue={provider.ssnLastFour}
                  readOnly={provider.ssnLastFourProvided}
                  placeholder='••••'
                  onChange={e => inputProp(provider, 'ssnLastFour', e)}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for='description'>Description</Label>
            <Input id='description'
              type='textarea'
              rows='4'
              value={provider.description}
              placeholder='Please share a little about yourself...'
              onChange={e => inputProp(provider, 'description', e)}
            />
          </FormGroup>
        </SectionBody>

        <SectionFooter className='flex-start'>
          <NextButton
            type='button'
            icon='check'
            title='Save'
            taskRunning={taskRunning}
            onClick={() => saveProvider()}
          />
        </SectionFooter>

      </SectionBlock>
    </Form>
  )
}

export default withStore(withToast(ProviderForm));
