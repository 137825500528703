import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { isEmpty, logger, timeout } from 'utils/helpers';

const GoogleAnalyticsContext = React.createContext(null);

const GoogleAnalyticsProvider = ({ children }) => {
  const [ loaded, setLoaded ] = useState(false);

  // Hooks
  useEffect(() => {
    if (typeof window !== 'undefined') init();
  }, [])

  
  // Private Methods
  const init = () => {
    ReactGa.initialize(window.env.REACT_APP_GOOGLE_ANALYTICS_ID, { 
      debug: false
    });
    setLoaded(true);
  }


  // Public Methods
  const trackEvent = ({ label = '', category = '', action = '', value = '' }) => {
    ReactGa.event({ label, category, action });
  }

  const trackView = (view) => {
    ReactGa.pageview(view);
  }

  const trackUser = (user) => {
    ReactGa.set({ 
      userId: user.id || '', 
      userName: user.name || '', 
      userEmail: user.email || ''
    });
  }



  // Render
  return (
    <GoogleAnalyticsContext.Provider value={{ trackEvent, trackView, trackUser, loaded }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}


const withGa = function(WrappedComponent) {
  return (props) => (
    <GoogleAnalyticsContext.Consumer>
      {context => <WrappedComponent ga={context || {}} {...props} />}
    </GoogleAnalyticsContext.Consumer>
  )
}

export { GoogleAnalyticsProvider, withGa }

// Docs
// https://www.npmjs.com/package/react-ga

