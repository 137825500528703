import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { withSession } from 'contexts/session-context';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CardForm from 'components/card/card-form';
import { BasicButton } from 'components/bootstrap/button-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, FormGroup, Label, Input, Modal, Button } from 'reactstrap';
import { setProp, setRelation } from 'utils/store-helpers';
import { logger } from 'utils/helpers';

const stripePromise = loadStripe(typeof window === 'undefined' ? '' : window.env.REACT_APP_STRIPE_KEY);

const CardSelect = (props) => {
  const { type = 'form', booking, session, store } = props;
  const [ cards, setCards ] = useState([]);
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    store.adapterFor('card').set('userId', session.userId);
    fetchData();
    return () => {
      store.adapterFor('card').set('userId', null);
    }
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let cards = await store.query('card', { status: 'active' });
      setCards(cards);
      setPrimaryCard(cards);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const setPrimaryCard = (cards) => {
    let card = cards.find(card => card.default);
    if (card) booking.setRelation('card', card);
  }

  const selectCard = (cardId, form = false) => {
    let card = store.peekRecord('card', cardId);
    booking.setRelation('card', card);
    if (form) {
      toggleModal(!modal);
      fetchData();
    };
  }


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  return (
    <Fragment>
      <Modal isOpen={modal}>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <CardForm
                user={session.user}
                stripe={stripe}
                elements={elements}
                modal={modal}
                toggleModal={() => toggleModal(!modal)}
                refreshData={() => fetchData()}
                nextAction={card => selectCard(card, true)}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </Modal>
      <FormGroup>
        <Input
          type='select'
          value={booking.card && booking.card.id}
          onChange={e => selectCard(e.target.value)}>
          {cards.map(card => <option key={card.id} value={card.id}>{card.summary}</option>)}
        </Input>
      </FormGroup>
      <FormGroup>
        <BasicButton title='Add Card' onClick={() => toggleModal(!modal)} />
      </FormGroup>
    </Fragment>
  )
}

export default withStore(withToast(withSession(CardSelect)));
