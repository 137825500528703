import React, { Fragment, useState } from 'react';
import ToastWrapper from 'components/bootstrap/toast-wrapper';
import { isEmpty, logger, timeout } from 'utils/helpers';

export const ToastContext = React.createContext();

const ToastProvider = (props) => {
  const { children } = props;
  const [ show, setShow ] = useState(false);
  const [ toast, setToast ] = useState({});


  // Methods
  const showError = async (e = []) => {
    setToast({ type: 'error', code: buildCode(e), title: 'Error', message: buildMsg(e) });
    setShow(true);
    removeToast(3000);
  }

  const showInfo = async (msg) => {
    setToast({ type: 'info', code: 200, title: 'Info', message: msg });
    setShow(true);
    removeToast(3000);
  }

  const showSuccess = (msg) => {
    setToast({ type: 'success', code: 200, title: 'Success', message: msg })
    setShow(true);
    removeToast(3000);
  }

  const removeToast = async (time = 0) => {
    await timeout(time);
    setShow(false);
  }


  const buildCode = (e = []) => {
    return 422;
  }

  const buildMsg = (e = []) => {
    let message = '';
    logger('Error: ', e);
    if (Array.isArray(e)) {
      e.forEach(error => {
        message = error.detail + "\n";
      });
    }
    return message;
  }


  // Render
  return (
    <ToastContext.Provider value={{showError, showInfo, showSuccess}}>
      {show && (
        <ToastWrapper
          type={toast.type}
          code={toast.code}
          title={toast.title}
          message={toast.message}
        />
      )}
      {children}
    </ToastContext.Provider>
  )
}


const withToast = function(WrappedComponent) {
  return (props) => (
    <ToastContext.Consumer>
      {context => <WrappedComponent toast={context} {...props}/>}
    </ToastContext.Consumer>
  )
}

export { ToastProvider, withToast }

