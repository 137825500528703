import AppModel from 'models/app';
import { isEmpty, truncate } from 'utils/helpers';

class Provider extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.name = props.name;
		this.username = props.username;
		this.email = props.email;
		this.description = props.description;
		this.phone = props.phone;
		this.photo = props.photo;
		this.status = props.status;
		this.ssnLastFour = props.ssnLastFour;
		this.dob = props.dob;


		// Server Computed
		this.avgScore = props.avgScore || props['avg-score'];
		this.totalReviews = props.totalReviews;
		this.availableBalance = props.availableBalance;
		this.currentBalance = props.currentBalance;
		this.ssnLastFourProvided = props.ssnLastFourProvided;


		// JSON
		this.social = props.social || {};


		// Relationships
		this.balance = this.belongsTo('balance', props.balance);
		this.user = this.belongsTo('user', props.user);
		this.location = this.belongsTo('location', props.location);
		this.schedule = this.belongsTo('schedule', props.schedule);
		this.settings = this.belongsTo('provider-setting', props.settings);
		this.setup = this.belongsTo('setup', props.setup);
		this.banks = this.hasMany('bank', props.banks);
		this.licenses = this.hasMany('licenses', props.licenses);
		this.photos = this.hasMany('photo', props.photos);
		this.reviews = this.hasMany('review', props.reviews);
		this.services = this.hasMany('service', props.services);
	}


	// Computed
	get firstName() {
		return this.name.split(' ')[0];
	}

	get lastName() {
		return this.name.split(' ')[1];
	}

	get serviceIds() {
		return !isEmpty(this.services) ? this.services.map(service => service.id) : [];
	}

	// Statuses
	get isDraft() {
		return this.status == 'draft' ? true : false
	}

	get isActive() {
		return this.status == 'active' ? true : false
	}

	get isPending() {
		return this.status == 'pending' ? true : false
	}

	get isDisabled() {
		return this.status == 'disabled' ? true : false
	}

	set providerFirstName(val) {
		this.name = val + ' ' + this.name.split(' ')[1];
	}

	set providerLastName(val) {
		this.name = this.name.split(' ')[0] + ' ' + val;
	}

	get previewDescription() {
		return this.description ? truncate(this.description, 60, true) : '--';
	}


	// Functions
	url() {
		return `/providers/${this.id}`;
	}

}

export default Provider;
