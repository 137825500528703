import React from "react";

export const ClockTime = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.121"
      height="27.121"
      viewBox="0 0 26.121 27.121"
    >
      <g
        id="Group_1"
        data-name="Group 1"
        transform="translate(-18.652 -18.626)"
      >
        <path
          id="Shape"
          d="M11.071,22.213A11.106,11.106,0,1,0,0,11.106,11.089,11.089,0,0,0,11.071,22.213Z"
          transform="translate(20.641 22.474)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Shape-2"
          data-name="Shape"
          d="M0,0V6.5H4.645"
          transform="translate(31.694 28.01)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Shape-3"
          data-name="Shape"
          d="M5.574,0,0,5.574"
          transform="translate(19.713 19.687)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Shape-4"
          data-name="Shape"
          d="M0,0,5.574,5.574"
          transform="translate(38.139 19.687)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Shape-5"
          data-name="Shape"
          d="M2.787,0,0,2.787"
          transform="translate(21.57 41.9)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Shape-6"
          data-name="Shape"
          d="M0,0,2.787,2.787"
          transform="translate(39.067 41.9)"
          fill="none"
          stroke="#212529"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
