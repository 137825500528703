import AppModel from "models/app";
import Accounting from "accounting";

class Promo extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.status = props.status;
    this.code = props.code;
    this.amount = props.amount;
    // this.type = "flat";

    this.promoType = props?.promoCode?.type;

    // Relationships
    this.booking = this.belongsTo("booking", props.booking);
    this.promoCode = this.belongsTo("promoCode", props.promoCode);
    this.user = this.belongsTo("user", props.user);
  }

  // Status
  get isDraft() {
    return this.status == "draft";
  }

  get isActive() {
    return this.status == "active";
  }

  get isFinished() {
    return this.status == "finished";
  }

  get displayAmount() {
    if (this.promoType?.includes("percentage")) {
      return "% " + this.amount;
    } else return Accounting.formatMoney(this.amount);
  }
}

export default Promo;
