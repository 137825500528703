import React from 'react';
import Head from 'components/basics/head';
import SimpleNav from 'components/navs/simple-nav';
import MktHeader from 'components/mkt/mkt-header';
import AppFooter from 'components/app/app-footer';
import ResetPasswordForm from 'components/auth/reset-password-form';
import { Container } from 'reactstrap';


const ResetPasswordRoute = (props) => {
  const { history } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Reset Password'/>
      <SimpleNav/>

      <main className='auth-main'>
        <Container className='xs'>
          <ResetPasswordForm />
        </Container>
			</main>

      <AppFooter />
  	</div>
	);
}

export default ResetPasswordRoute;
