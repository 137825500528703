import React, { Fragment, useEffect, useState } from 'react';
import EventForm from 'components/event/event-form';
import IntervalForm from 'components/interval/interval-form';
import ScheduleFlow from 'components/schedule/schedule-flow';
import ScheduleCalendar from 'components/schedule/schedule-calendar';
import { BasicButton, PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Button, FormGroup, FormText, Label, Modal, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const ProviderFlowSchedule = (props) => {
  const { provider = {}, store, toast, prevAction, nextAction } = props;
  const [ data, setData ] = useState({});
  const [ schedule, setSchedule ] = useState({});
  const [ event, setEvent ] = useState({});
  const [ interval, setInterval ] = useState({});
  const [ view, setView ] = useState('schedule');
  const [ modal, toggleModal ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    store.adapterFor('schedule').set('providerId', provider.id);
    store.adapterFor('interval').set('providerId', provider.id);
    store.adapterFor('event').set('providerId', provider.id);
    fetchData();
    return () => {
      store.adapterFor('schedule').set('providerId', null);
      store.adapterFor('interval').set('providerId', null);
      store.adapterFor('event').set('providerId', null);
    };
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let schedule = await store.queryRecord('schedule', null, { include: 'intervals,events' });
      setSchedule(schedule);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false)
    }
  }

  const saveSchedule = async () => {
    try {
      setTaskRunning(true);
      await provider.save();
      toast.showSuccess('Schedule saved!');
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const selectDate = (fcData) => {
    if (fcData.event && fcData.event.extendedProps.type == 'Interval') {
      store.findRecord('interval', fcData.event.id).then(interval => showInterval(interval));
    } else if (fcData.event && fcData.event.extendedProps.type == 'Event') {
      store.findRecord('event', fcData.event.id).then(event => showEvent(event));
    } else {
      showSchedule(fcData);
    }
  }

  const showEvent = (event = {}) => {
    setEvent(event);
    setView('event');
    toggleModal(true);
  }

  const showInterval = (interval = {}) => {
    setInterval(interval);
    setView('interval');
    toggleModal(true);
  }

  const showSchedule = (fcData) => {
    setView('schedule');
    setData(fcData);
    toggleModal(true);
  }

  const updateData = () => {
    toggleModal(!modal);
    fetchData();
  }


	// Render
  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)}>
        {view == 'event' && (
          <EventForm
            model={event}
            data={data}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            prevAction={() => showSchedule()}
            nextAction={() => updateData()}
          />
        )}
        {view == 'interval' && (
          <IntervalForm
            model={interval}
            data={data}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            prevAction={() => showSchedule()}
            nextAction={() => updateData()}
          />
        )}
        {view == 'schedule' && (
          <ScheduleFlow
            provider={provider}
            schedule={schedule}
            modal={modal}
            toggleModal={() => toggleModal(!modal)}
            showEvent={() => showEvent()}
            showInterval={() => showInterval()}
          />
        )}
      </Modal>
      <SectionPreBlock>
        <PrevButton title='Exit' onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionTitle title='Set schedule'/>

        <SectionBody className='lg'>
          <ScheduleCalendar
            type='wizard'
            schedule={schedule}
            provider={provider}
            loading={loading}
            selectDate={v => selectDate(v)}
          />
        </SectionBody>
        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            taskRunning={taskRunning}
            disabled={isEmpty(schedule)}
            onClick={() => saveSchedule(provider)}
            className='btn-lg btn-block'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}


export default ProviderFlowSchedule;
