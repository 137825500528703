import React, { useEffect, useState } from 'react';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import AdminIntro from 'components/admin/admin-intro';
import { isEmpty, timeout } from 'utils/helpers';
import { Container, Row } from 'reactstrap';

const AdminContext = React.createContext();

const AdminProviderWrapper = (props) => {
  const { providerId, history, store, toast, children } = props;
  const [ provider, setProvider ] = useState({});
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    init();
    return () => clearAdapters()
  }, []);



  // Methods
  const init = async () => {
    try {
      setLoading(true);
      await fetchData();
      await setPreferences();
      await loadDashboard();
    } catch(e) {
      toast.showError(e);
      history.push('/login');
    } finally {
      setLoading(false);
    }
  }


  const fetchData = async () => {
    try {
      setData({ title: 'Step 1 of 3', subtitle: 'Fetching data...' });
      let model = await store.queryRecord('provider', providerId, { include: 'location,services,settings' });
      setAdapters(model);
      setProvider(model);
      await timeout(300);
    } catch (e) {
      throw e;
    }
  }

  const setPreferences = async () => {
    setData({ title: 'Step 2 of 3', subtitle: 'Setting preferences...' });
    await timeout(500);
  }

  const loadDashboard = async () => {
    setData({ title: 'Step 3 of 3', subtitle: 'Building dashboard...' });
    await timeout(500);
  }

  const setAdapters = (provider) => {
    store.adapterFor('activity').set('providerId', provider.id);
    store.adapterFor('analytics').set('providerId', provider.id);
    store.adapterFor('balance').set('providerId', provider.id);
    store.adapterFor('bank').set('providerId', provider.id);
    store.adapterFor('booking').set('providerId', provider.id);
    store.adapterFor('customer').set('providerId', provider.id);
    store.adapterFor('event').set('providerId', provider.id);
    store.adapterFor('interval').set('providerId', provider.id);
    store.adapterFor('license').set('providerId', provider.id);
    store.adapterFor('merchant').set('providerId', provider.id);
    store.adapterFor('payout').set('providerId', provider.id);
    store.adapterFor('photo').set('providerId', provider.id);
    store.adapterFor('provider-setting').set('providerId', provider.id);
    store.adapterFor('refund').set('providerId', provider.id);
    store.adapterFor('review').set('providerId', provider.id);
    store.adapterFor('schedule').set('providerId', provider.id);
    store.adapterFor('service').set('providerId', provider.id);
    store.adapterFor('setup').set('providerId', provider.id);
  }

  const clearAdapters = () => {
    store.adapterFor('activity').set('providerId', null);
    store.adapterFor('analytics').set('providerId', null);
    store.adapterFor('balance').set('providerId', null)
    store.adapterFor('bank').set('providerId', null);
    store.adapterFor('booking').set('providerId', null);
    store.adapterFor('customer').set('providerId', null);
    store.adapterFor('event').set('providerId', null);
    store.adapterFor('interval').set('providerId', null);
    store.adapterFor('license').set('providerId', null);
    store.adapterFor('merchant').set('providerId', null);
    store.adapterFor('payout').set('providerId', null);
    store.adapterFor('photo').set('providerId', null);
    store.adapterFor('provider-setting').set('providerId', null);
    store.adapterFor('refund').set('providerId', null);
    store.adapterFor('review').set('providerId', null);
    store.adapterFor('schedule').set('providerId', null);
    store.adapterFor('service').set('providerId', null);
    store.adapterFor('setup').set('providerId', null);
  }


  // Render
  return (
    <AdminContext.Provider value={provider}>
      {loading && <AdminIntro title={data.title} message={data.subtitle} />}
      {provider.id ? children : null}
    </AdminContext.Provider>
  )
};


const withAdmin = function(WrappedComponent) {
  return (props) => (
    <AdminContext.Consumer>
      {context => <WrappedComponent provider={context} {...props} />}
    </AdminContext.Consumer>
  );
};

const AdminProvider = withSession(withStore(withToast(AdminProviderWrapper)));

export { AdminProvider, withAdmin };
