import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { Fade, Dropdown, DropdownToggle, DropdownMenu, NavItem } from 'reactstrap';

const DropdownWrapper = (props) => {
	const { btn, children, isOpen, toggle, disabled = false, className = '' } = props;

	return (
		<Dropdown isOpen={isOpen} toggle={toggle} disabled={disabled} className={'dropdown-wrapper ' + className}>
			{children}
		</Dropdown>
	)
}

const DropdownMenuWrapper = (props) => {
	const { isOpen, right, className = '', children } = props;

	return (
		<DropdownMenu right={right} className={className}>
			<div className={isOpen ? 'dropdown-menu-inner fade-in-down-quick' : 'fade-out-up-quick'}>
				{children}
			</div>
		</DropdownMenu>
	)
}

export { DropdownWrapper, DropdownMenuWrapper }

// Docs
