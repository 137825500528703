import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

const MktConsumerPrefooter = (props) => {
	const {} = props;

	return (
		<div className='mkt-prefooter'>
			<Container>
				<Row className='flex-between'>

					<Col md='7'>
						<div className='mkt-prefooter-content'>
							<h2 className='bold'>Book your next appointment</h2>
							<h5>Book with Blush and enjoy your next beauty appointment from the comforts of your own home.</h5>
						</div>
					</Col>

					<Col md='5'>
						<div className='flex-end'>
							<Link to='/book' className='btn btn-lighter-gray btn-lg'>
								Book Now<FAWrapper icon={'chevron-right'} className='ml-15' />
							</Link>
						</div>
					</Col>

				</Row>
			</Container>
		</div>
	)

}

export default MktConsumerPrefooter;
