import React from 'react';
import App from 'app';

import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { logger } from 'utils/helpers';

import FabIcons from 'utils/font-awesome/fab-icons';
import FadIcons from 'utils/font-awesome/fad-icons';
import FalIcons from 'utils/font-awesome/fal-icons';

import 'bootstrap/scss/bootstrap.scss';
import 'swiper/css/swiper.min.css';
import 'animate.css';
import 'styles/app.scss';

import 'assets/fonts/silver-south-script.otf';
import 'assets/fonts/metropolis-bold.otf';
import 'assets/fonts/metropolis-medium.otf';
import 'assets/fonts/metropolis-regular.otf';
import 'assets/fonts/metropolis-light.otf';
import 'assets/fonts/metropolis-extra-light.otf';

const data = window._INITIAL_DATA_;
if (typeof window === 'undefined') { global.window = {} }

logger(window.env.REACT_APP_NODE_ENV + ' env');
	
hydrate(
  <BrowserRouter>
		<HelmetProvider>
    	<App data={data}/>
  	</HelmetProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
