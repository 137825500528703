import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import IntercomBtnWrapper from 'components/vendors/intercom-btn-wrapper';
import ContactForm from 'components/misc/contact-form';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { WorkHeader } from 'utils/assets/headers';

const MktContactRoute = (props) => {
  const { history } = props;

  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Contact'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='md'
        title='Contact Us'
        subtitle="Have a question? Our team's standing by ready to assist so you can get back to your day ASAP."
        bg={WorkHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>

      <main className='mkt-main'>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Call or Text</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6>Have a question? Talk to one of our live Blush Representatives to help guide you in the right direction!</h6>
                    <div className='mt-15 flex-row'>
                      <IntercomBtnWrapper>Call or Text</IntercomBtnWrapper>
                      <h6 className='ml-15'>720-504-6428</h6>
                    </div>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body flex'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Email</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6>We are dedicated to ensuring your experience with us is the best it can be. Whether you have questions regarding a booking or our services, use the contact form below. We will contact you shortly!</h6>
                    <div className='mt-15'>
                      <Row>
                        <Col md='11'>
                          <ContactForm/>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>
      <MktFooter />
  	</div>
	);
}

export default MktContactRoute;
