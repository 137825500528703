import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';

const MktPricing = (props) => {
	const { type, value, title, subtitle, description, url } = props;
		
	return (
		<div className={'mkt-pricing-block ' + type}>
			<h1 className='mkt-pricing-stat'>{value}%</h1>
			<div className='mkt-pricing-content'>
				<h3>{title}</h3>
				<small className='gray-color uppercase'>{subtitle}</small>
				<h6>{description}</h6>
				{url && (
					<div className='mt-15'>
						<Link to={url} className='btn btn-text-primary'>
							Get Started<FAWrapper icon='chevron-right' className='ml-15' />
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export default MktPricing;