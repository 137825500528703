import React from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';

const PreviewCard = (props) => {
	const { status, icon, photo, title, subtitle, line1, line2, onClick, className = '', children } = props;


	// Render
	return (
		<div className={'card preview ' + className}>
			<div className='flex-start'>
				<div className={icon ? 'card-header icon preview mr-15' : 'card-header preview mr-15'}>
					{icon && <FAWrapper icon={icon} iconFam='fad' size='2x' className='card-header-icon'/>}
					{photo && <img className='card-header-img' src={photo} />}
				</div>
				<div className='flex-between w-100'>
					<div className='card-body'>
						{title && <h5 className='title'>{title}</h5>}
						{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
						{line1 && <h6>{line1}</h6>}
						{line2 && <h6>{line2}</h6>}
						{onClick && <Button color='text-blue' size='xs' onClick={onClick}>Learn More</Button>}
						{children}
					</div>
					<div>
						{status && <Badge color={status} className='sm' pill>{status}</Badge>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PreviewCard;
