import React from 'react';
import Confetti from 'react-confetti';

const ConfettiWrapper = (props) => {
  return (
    <Confetti recycle={false} />
  )
}

export default ConfettiWrapper;

// Docs
// https://github.com/alampros/react-confetti#readme
