import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withUser } from "contexts/user-context";
import { withSession } from "contexts/session-context";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import MktAnnouncement from "components/mkt/mkt-announcement";
import MktNav from "components/navs/mkt-nav";
import Head from "components/basics/head";
import AppFooter from "components/app/app-footer";
import BookingActions from "components/booking/booking-actions";
import BookingDetail from "components/booking/booking-detail";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import { isEmpty } from "utils/helpers";

const UserBookingsDetail = (props) => {
  const { bookingId, userId, history, user, store, toast, session } = props;
  const [booking, setBooking] = useState({});
  const [loading, setLoading] = useState(false);
  const [financeData, setFinanceData] = useState({
    discount: "",
    subTotal: "",
  });

  // Hooks
  useEffect(() => {
    fetchData();
  }, []);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord("booking", bookingId, {
        include:
          "card,charges,customer,location,promo,provider,review,services,user",
      });

      setFinanceData({
        discount: model?.discount,
        subTotal: model?.subtotal,
      });

      setBooking(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  // Render
  return (
    <div className="route">
      <Head title={`Blush - ${user.name} - Booking`} />
      <MktAnnouncement />
      <MktNav />

      <main className="app-main">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/user/${user.id}`}>{user.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              Booking #{booking.confirmationCode}
            </BreadcrumbItem>
          </Breadcrumb>

          <SectionBlock>
            <SectionTitle className="flex-between no-b">
              <h5>Booking #{booking.confirmationCode}</h5>
              <BookingActions
                type="detail"
                booking={booking}
                loading={loading}
                refreshData={() => fetchData(booking.id)}
              />
            </SectionTitle>
          </SectionBlock>

          <BookingDetail
            booking={booking}
            loading={loading}
            refreshData={() => fetchData(booking.id)}
            financeData={financeData}
          />
        </Container>
      </main>

      <AppFooter />
    </div>
  );
};

export default withStore(withToast(withSession(withUser(UserBookingsDetail))));
