import React from 'react';
import { Link } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import Head from 'components/basics/head';
import AuthNav from 'components/navs/auth-nav';
import AppFooter from 'components/app/app-footer';
import LoginForm from 'components/auth/login-form';
import { Container } from 'reactstrap';


const LoginRoute = (props) => {
  const { history, session } = props;

  // Methods
  const nextAction = (userId) => history?.replace(`/user/${userId?.id ? userId?.id : userId}`);


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Login'/>
      <AuthNav session={session} isRegister={false} />
      <main className='auth-main'>
    		<Container className='xs'>
          <LoginForm userID={session.userId} nextAction={userId => nextAction(userId)} />
        </Container>
			</main>
      <AppFooter />
  	</div>
	);
}

export default withSession(LoginRoute);
