import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import FAWrapper from 'components/vendors/fa-wrapper';
import { BlushIconWhite, BlushLogoWhite } from 'utils/assets/branding';
import { TooltipWrapper } from 'components/bootstrap/tooltip-wrapper';
import { Button, Collapse, Nav, Navbar, NavbarToggler, NavbarBrand, NavItem } from 'reactstrap';

const AdminSidenav = (props) => {
	const { providerId, className = '', isCollapsed, toggle } = props;

	return (
		<Nav className={'sidebar ' + className} vertical>
			{isCollapsed ? (
				<NavbarBrand href="/"><img src={BlushIconWhite} /></NavbarBrand>
			) : (
				<NavbarBrand href="/"><img src={BlushLogoWhite} /></NavbarBrand>
			)}

			<NavItem>
				<NavLink id='dashboard' to={`/admin/${providerId}/dashboard`} className='btn btn-nav-gray'>
					<FAWrapper icon='tachometer-alt-fast' className='mr-15'/><span>Dashboard</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='dashboard' fade={true}>Dashboard</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='setup' to={`/admin/${providerId}/getting-started`} className='btn btn-nav-gray'>
					<FAWrapper icon='tasks' className='mr-15'/><span>Getting Started</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='setup' fade={true}>Getting Started</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='appointments' to={`/admin/${providerId}/appointments`} className='btn btn-nav-gray'>
					<FAWrapper icon='calendar' className='mr-15'/><span>Appointments</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='appointments' fade={true}>Appointments</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='bookings' to={`/admin/${providerId}/bookings`} className='btn btn-nav-gray'>
					<FAWrapper icon='calendar' className='mr-15'/><span>Bookings</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='bookings' fade={true}>Bookings</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='customers' to={`/admin/${providerId}/customers`} className='btn btn-nav-gray'>
					<FAWrapper icon='id-card' className='mr-15'/><span>Customers</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='customers' fade={true}>Customers</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='payouts' to={`/admin/${providerId}/payouts`} className='btn btn-nav-gray'>
					<FAWrapper icon='piggy-bank' className='mr-15'/><span>Payouts</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='payouts' fade={true}>Payouts</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='reviews' to={`/admin/${providerId}/reviews`} className='btn btn-nav-gray'>
					<FAWrapper icon='star' className='mr-15'/><span>Reviews</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='payouts' fade={true}>Reviews</TooltipWrapper>}
			</NavItem>

			<NavItem>
				<NavLink id='settings' to={`/admin/${providerId}/settings`} className='btn btn-nav-gray'>
					<FAWrapper icon='sliders-v' className='mr-15'/><span>Settings</span>
				</NavLink>
				{isCollapsed && <TooltipWrapper placement='right' target='settings' fade={true}>Settings</TooltipWrapper>}
			</NavItem>

			{isCollapsed ? (
				<NavItem id='collapse' className='sidebar-footer'>
					<Button color='text-gray' onClick={() => toggle()} className='btn btn-nav-gray'>
						<FAWrapper icon={'chevron-right'} className='mr-15'/><span>Open</span>
					</Button>
					<TooltipWrapper placement='right' target='collapse' fade={true}>Open</TooltipWrapper>
				</NavItem>
			) : (
				<NavItem id='collapse' className='sidebar-footer'>
					<Button color='text-gray' onClick={() => toggle()} className='btn btn-nav-gray'>
						<FAWrapper icon='chevron-left' className='mr-15'/><span>Collapse</span>
					</Button>
					{isCollapsed && <TooltipWrapper placement='right' target='collapse' fade={true}>Collapse</TooltipWrapper>}
				</NavItem>
			)}
		</Nav>
	)
}

export default AdminSidenav;
