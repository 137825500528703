import AppModel from "models/app";
import Moment from "moment";

class Interval extends AppModel {
  constructor(type, store, props = {}) {
    super(type, store, props);

    // Attrs
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    this.daysOfWeek = props.daysOfWeek; // Array

    // Keys
    this.scheduleId = props.scheduleId;

    // Relationships
    this.schedule = this.belongsTo("schedule", props.schedule);
  }

  // Computed
  get displayStartDate() {
    return moment(this.startDate);
  }

  get displayEndDate() {
    return moment(this.endDate);
  }

  get calendarObject() {
    let event = {};
    event.id = this.id;
    event.type = "Interval";
    event.className = "fc-schedule-interval";
    event.title =
      Moment(this.startDate).format("hh:mma") +
      " - " +
      Moment(this.endDate).format("hh:mma");
    // event.startTime = Moment(this.startDate).format("HH:mm");
    // event.endTime = Moment(this.endDate).format("HH:mm");

    event.startTimeString = Moment(this.startDate).format("HH:mm");
    event.endTimeString = Moment(this.endDate).format("HH:mm");

    event.daysOfWeek = this.daysOfWeek;
    // event.startRecur = Moment().startOf("year").format();
    event.date = Moment(this.startDate).format("YYYY-MM-DD");
    event.overlap = true;

    return event;
  }

  // Functions
  updateStartDate(date) {
    this.set("startDate", date);
    if (this.startDate >= this.endDate) {
      this.updateEndDate(Moment(date).add("30", "m"));
    }
  }

  updateEndDate(date) {
    this.set("endDate", date);
    if (this.startDate >= this.endDate) {
      this.updateStartDate(Moment(date).subtract("30", "m"));
    }
  }
}

export default Interval;
