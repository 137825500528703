import AppAdapter from 'adapters/app';

class CandidateAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
	static action = '';


	// Overrides
	static urlForQueryRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);
		return this.action ? this.buildURL() + `/${this.action}` : url;
	}

 	static urlForCreateRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);
		return this.action ? this.buildURL() + `/${this.action}` : url;
	}

 	static urlForUpdateRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);
		return this.action ? this.buildURL() + `/${this.action}` : url;
	}

}

export default CandidateAdapter;