import AppAdapter from 'adapters/app';

class MerchantAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';


	// Overrides
	static urlForQueryRecord(modelName, id) {
		return this.buildURL() + `/${modelName}`;
	}

	static urlForUpdateRecord(modelName, id) {
		return this.buildURL() + `/${modelName}`;
	}



}

export default MerchantAdapter;