import React, { useState } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Collapse, Button } from 'reactstrap';

const MktCollapse = (props) => {
	const { icon, title, msg, className = '', children } = props;
	const [ collapse, toggleCollapse ] = useState(false);

	return (
		<div className={'mkt-collapse ' + className}>
			<div className='mkt-collapse-title flex-between' onClick={() => toggleCollapse(!collapse)}>
				<h5 className='title'>{title}</h5>
				{collapse ? <FAWrapper icon='plus' size='2x' className='spin-135'/> : <FAWrapper icon='plus' size='2x'/>}
			</div>
			<Collapse isOpen={collapse}>
      	<div className='mkt-collapse-content'>
      		{children}
    		</div>
      </Collapse>
		</div>
	)
}

export default MktCollapse;
