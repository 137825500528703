import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import PayoutActions from 'components/payout/payout-actions';
import { PayoutDetailCard } from 'components/payout/payout-card';
import { BankPreviewCard } from 'components/bank/bank-card';
import BookingList from 'components/booking/booking-list';
import LoadingIndicator from 'components/loading/loading-indicator';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const AdminPayoutsDetailRoute = (props) => {
  const { payoutId, providerId, history, provider, store, toast } = props;
  const [ payout, setPayout ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('payout', payoutId, { include: 'bank' });
      setPayout(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title={'Blush Admin - Payouts #' + payoutId}>
      <SectionBlock>
        <SectionBody className='flex-between'>
          <h4 className='title'>Payout #{payout.id}</h4>
          <PayoutActions
            type='detail'
            payout={payout}
            provider={provider}
            loading={loading}
          />
        </SectionBody>
      </SectionBlock>

      <Row>
        <Col md='4'>
          <PayoutDetailCard payout={payout} loading={loading} />
        </Col>

        <Col md='8'>
          <SectionBlock>
            <SectionTitle title='Bank'/>
            <SectionBody>
              <BankPreviewCard bank={payout.bank} loading={loading} />
            </SectionBody>
          </SectionBlock>
        </Col>
      </Row>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminPayoutsDetailRoute)));
