import AppModel from 'models/app';
import PhoneFormatter from 'phone-formatter';
import Accounting from 'accounting';

class Customer extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.name = props.name;
		this.email = props.email;
		this.photo = props.photo;
		this.phone = props.phone;

		// Computed
		this.totalBookings = props.totalBookings;
		this.netCommissions = props.netCommissions;
		this.netTips = props.netTips;


		// Relationships
		this.location = this.belongsTo('location', props.location);
		this.provider = this.belongsTo('provider', props.provider);
		this.bookings = this.hasMany('booking', props.bookings);
		// this.cards = this.hasMany('card', props.cards);
	}


	// Computed
	get displayPhone() {
		return this.phone ? PhoneFormatter.format(this.phone, '(NNN) NNN-NNNN') : '';
	}

	get displayTotalBookings() {
		return this.totalBookings ? Accounting.formatNumber(this.totalBookings) : 0;
	}

	get displayNetCommissions() {
		return this.netCommissions ? Accounting.formatMoney(this.netCommissions) : 0;
	}

	get displayNetTips() {
		return this.netTips ? Accounting.formatMoney(this.netTips) : 0;
	}

	get url() {
		return this.provider.id ? `/admin/${this.provider.id}/customers/${this.id}` : `customers/${this.id}`;
	}

}

export default Customer;
