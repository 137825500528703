import Moment from "moment";
var momentTz = require("moment-timezone");

export const TIME_FORMAT = "HH:mm A";

export const createISOTimeString = (timeString) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isoStr = momentTz
    .tz(
      timeString, //"2021-01-27 14:02:45",
      "YYYY/MM/DD HH:mm A", //"DD-MM-YYYY HH:mm";
      timezone
    )
    .toISOString();
  return isoStr;
};

export const createTZString = (timeString) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return momentTz(timeString).tz(timezone);
};

export const getTimezoneFromLatLong = async (latitude, longitude, timezoneId) => {
  return new Promise((resolve, reject) => {
    const apiKey = window.env.REACT_APP_GOOGLE_API_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=1331161200&key=${apiKey}`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data?.status === 'OK') {
          if (timezoneId) {
            resolve(data?.timeZoneId);
            return
          }
          const date = new Date();
          const options = { timeZone: data?.timeZoneId, timeZoneName: 'short' };
          const formatter = new Intl.DateTimeFormat('en-US', options);
          const parts = formatter.formatToParts(date);
          const abbreviationPart = parts.find(part => part.type === 'timeZoneName');
          resolve(abbreviationPart?.value);
        } else {
          reject("");
        }
      })
      .catch(error => {
        reject("")
      });
  });
};

export const createDateTimeFormat = (timeString) => {
  let startDateTime = createTZString(timeString);

  return Moment(startDateTime._d).format("YYYY-MM-DD HH:mm");
};

export const createDateFormat = (timeString) => {
  let startDateTime = createTZString(timeString);

  return Moment(startDateTime._d).format("YYYY-MM-DD");
};

export const compareIfDateIsSameOrGreater = (date1, date2) => {
  return Moment(date2).isSameOrAfter(date1);
};
