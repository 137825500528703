import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import MktTestimonial from 'components/mkt/mkt-testimonial';
import MakeupHeader from 'assets/headers/makeup.jpg';
import { Lorem1, Lorem2, Lorem3, Lorem4 } from 'utils/placeholders';
import { Container, Row, Col } from 'reactstrap';

const MktTestimonialsRoute = (props) => {
  const { history } = props;


  return (
  	<div className='route'>
      <Head title='Blush - Testimonials'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='lg'
        title='Testimonials'
        bg={MakeupHeader}
      />
      <main className='mkt-main'>

         <section className='mkt-section'>
          <div className='mkt-section-body lg flex'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Don't Take Our Word For it</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 3, offset: 1}}>
                  <Row>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                    <Col md='4'>
                      <MktTestimonial
                        type='team'
                        photo='https://via.placeholder.com/300x300'
                        title='Title'
                        subtitle='test@example.com'
                        preview='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                      />
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>

      <MktFooter />
  	</div>
	);
}

export default MktTestimonialsRoute;
