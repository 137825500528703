import LocalStorage from 'local-storage';
import axios from 'axios';

import { timeout, isEmpty } from 'utils/helpers';

class Axios {
  constructor(props) {}


  // Methods
  instance() {
    let instance = axios.create({ baseURL: typeof window === 'undefined' ? '' : window.env.REACT_APP_API_DOMAIN });
    this.authorize(instance);
    return instance;
  }

  authorize(instance) {
  	let token = LocalStorage.get('token');
  	if(token) {
  		instance.defaults.headers.common['Authorization'] = `JWT ${token}`;
  	}
  }

}

export default Axios;
