import AppModel from 'models/app';
import Accounting from 'accounting';

class Refund extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		// Attrs
		this.status = props.status;
		this.amount = props.amount;
		this.receiptUrl = props.receiptUrl;


		// Relationships
		this.booking = this.belongsTo('booking', props.booking);
		this.card = this.belongsTo('card', props.card);
		this.charge = this.belongsTo('charge', props.charge);
		this.user = this.belongsTo('charge', props.user);
	}

	
	// Statuses
	get isDraft() {
		return this.status == 'draft'
	}

	get isPending() {
		return this.status == 'pending'
	}

	get isSucceeded() {
		return this.status == 'succeeded'
	}

	get isFailed() {
		return this.status == 'failed'
	}

	get isCanceled() {
		return this.status == 'canceled'
	}
	

	// Finances
	get displayAmount() {
		return Accounting.formatMoney(this.amount);
	}

	get displayServiceFees() {
		return Accounting.formatMoney(0);
	}

	get displayTotal() {
		return Accounting.formatMoney(this.amount);
	}
}

export default Refund;