import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import LoadingIndicator from 'components/loading/loading-indicator';
import { Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const ProviderBalance = (props) => {
  const { store, toast, className = '' } = props;
  const [ balance, setBalance ] = useState({});
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() =>{
    fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let balance = await store.queryRecord('balance', {});
      setBalance(balance);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='flex-end'>
      {loading ? <LoadingIndicator color='gray' size='18px'/> : <h6 className='accent-color flex'><span className='mr-2'>{balance.displayAvailableBalance}</span> <small>({balance.displayPendingBalance} pending)</small></h6>}
    </div>
  )
}


export default withStore(withToast(ProviderBalance));
