import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import PlainCard from 'components/cards/plain-card';
import PreviewCard from 'components/cards/preview-card';
import FAWrapper from 'components/vendors/fa-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BankGridCardWrapper = (props) => {
  const { bank = {}, store, toast, selectedId, nextAction, loading, className = '' } = props;
  const [ updateRunning, setUpdateRunning ] = useState(false);
  const [ destroyRunning, setDestroyRunning ] = useState(false);


  // Methods
	const updateBank = async (bank) => {
		try {
      setUpdateRunning(true)
      await bank.save();
      toast.showSuccess('Bank updated!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setUpdateRunning(false);
    }
  }

  const destroyBank = async (bank) => {
    try {
      setDestroyRunning(true);
      await bank.destroy();
      toast.showSuccess('Bank destroyed!');
      nextAction();
    } catch(e) {
      toast.showError(e);
    } finally {
      setDestroyRunning(false);
    }
  }

  // Render
  return (
    <PlainCard
      icon='university'
      status={bank.default}
      title={bank.summary}
      line1={bank.accountHolderName}
      line2={bank.displayCurrency}
      className={className}>
      <div className='card-footer'>
        {bank.default ? (
          <Badge color='primary' pill><FAWrapper icon='check' className='mr-3'/>Primary</Badge>
        ) : (
          <Button type='button' color='text-gray' size='xs' onClick={() => updateBank(bank)}>
            {updateRunning ? <LoadingIndicator color='gray' size='18px' /> : 'Set Default'}
          </Button>
        )}
        <Button type='button' color='text-danger' size='xs' onClick={() => destroyBank(bank)}>
          {destroyRunning ? <LoadingIndicator color='danger' size='18px' /> : 'Delete'}
        </Button>
      </div>
    </PlainCard>
  )
}

const BankPreviewCard = (props) => {
  const { bank = {}, store, toast, selectedId, loading, className = '' } = props;

  return (
     <PreviewCard
      icon='university'
      status={bank.default ? 'default' : null}
      title={bank.accountHolderName}
      line1={bank.summary}
      line2={bank.displayCurrency}
    />
  )
}

const BankGridCard = withStore(withToast(BankGridCardWrapper));

export { BankGridCard, BankPreviewCard };
