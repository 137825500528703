import React from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ProviderNotificationsForm from 'components/provider/provider-notifications-form';
import { Breadcrumb, BreadcrumbItem, Container, Row } from 'reactstrap';

const AdminSettingsNotificationsRoute = (props) => {
  const { history, providerId, provider } = props;

  return (
    <AdminRoute title='Blush Admin - Notifications'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Notifications</BreadcrumbItem>
      </Breadcrumb>
      <ProviderNotificationsForm
        provider={provider}
        settingsId={provider.settings.id}
      />
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsNotificationsRoute);
