import React, { useState } from 'react';

import { isEmpty } from 'utils/helpers';
import { Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap';

const PaginationWrapper = (props) => {
	const { meta = {}, setPage, className = '' } = props;


  // Methods
  const totalPages = () => Math.ceil(meta.totalRecords / meta.pageSize);

  const firstPage = () => 0;

  const prevPage = () => parseFloat(meta.page) + 0;

  const currentPage = () => parseFloat(meta.page) + 1;

  const nextPage = () => parseFloat(meta.page) + 2;

  const lastPage = () => totalPages() - 1;

  const pages = () => {
    let index = 0;
    let pages = [];
    while (index < totalPages()) {
      pages.push(index += 1);
    }
    return pages;
  }

  const updatePage = (p) => setPage(p);


  // Render
  return (
		<Pagination className={'paginator ' + className} aria-label='Page navigation'>

      <PaginationItem disabled={meta.page == firstPage()}>
        <PaginationLink first onClick={() => setPage(firstPage())} />
      </PaginationItem>

      {pages().map(page => (
	      <PaginationItem key={page} active={page == currentPage()}>
        	<PaginationLink onClick={() => setPage(page - 1)}>
          	{page}
        	</PaginationLink>
    		</PaginationItem>
    	))}

      <PaginationItem disabled={meta.page == lastPage()}>
        <PaginationLink last onClick={() => setPage(lastPage())} />
      </PaginationItem>

    </Pagination>
	)
}

export default PaginationWrapper;

// Docs
