import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import Loadable from '@loadable/component';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import BookingPreview from 'components/booking/booking-preview';
import Empty from 'components/misc/empty';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Modal, Row, Col } from 'reactstrap';
import { isEmpty, logger } from 'utils/helpers';

const FullCalendarWrapper = Loadable(() => import('components/vendors/full-calendar-wrapper'));

const BookingCalendar = (props) => {
  const { type = 'detail', provider, showForm, store, toast, className = '' } = props;
  const [ booking, setBooking ] = useState({});
  const [ bookings, setBookings ] = useState([]);
  const [ data, setData ] = useState([]);
  const [ startDate, setStartDate ] = useState(Moment().startOf('month').toISOString());
  const [ endDate, setEndDate ] = useState(Moment().endOf('month').toISOString());
  const [ showPreview, setShowPreview ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try{
      setLoading(true);
      let model = await store.query('booking', params());
      model = model?.filter(booking => booking?.status !== 'canceled')
      setData(model?.map(booking => booking.calendarObject));
      setBookings(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    params.page = 0;
    params.pageSize = 100000;
    params.include = 'customer,location';
    return params;
  }

  const updateData = (fcData) => {
    logger('View updated: ', fcData);
    setStartDate(Moment(fcData.view.activeStart).toISOString());
    setEndDate(Moment(fcData.view.activeEnd).toISOString());
    fetchData(true);
  }

  const showFormFxn = (fcData) => {
    logger('Date clicked: ', fcData);
  }

  const showPreviewFxn = (fcData) => {
    logger('Event clicked: ', fcData);
    store.findRecord('booking', fcData.event.id).then(booking => {
      setBooking(booking);
      setShowPreview(!showPreview)
    });
  }


  // Render
  if (loading) {
    return <LoadingSkeleton type={'calendar'} count={12}/>
  }
  return (
    <div className='component'>
      <Modal isOpen={showPreview} toggle={() => setShowPreview(!showPreview)}>
        <BookingPreview
          modal={true}
          bookingId={booking.id}
          showActions={true}
          action={() => setShowPreview(!showPreview)}
        />
      </Modal>
      <FullCalendarWrapper
        type={type}
        data={data}
        clickedDate={args => showFormFxn(args)}
        clickedEvent={args => showPreviewFxn(args)}
        datesRender={args => updateData(args)}
      />
    </div>
  )
	}


export default withStore(withToast(BookingCalendar));
