import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withUser } from 'contexts/user-context';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import MktNav from 'components/navs/mkt-nav';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import AppCard from 'components/app/app-card';
import AppFooter from 'components/app/app-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { TextButton, BasicButton } from 'components/bootstrap/button-wrapper';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const SettingsIndex = (props) => {
  const { user, userId, session, store } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const destroyAccount = async () => {
    try {
      setTaskRunning(true);
      await session.user.destroy();
      session.logout();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const deactivateAccount = () => {
    let confirm = window.confirm(`Are you sure you'd like to deactivate your account?`);
    if (confirm) { destroyAccount() }
  }


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Settings'/>
      <MktAnnouncement/>
      <MktNav />
      <main className='app-main'>
        <Container className='md'>
          <Breadcrumb>
            <BreadcrumbItem active>Settings</BreadcrumbItem>
          </Breadcrumb>
          <Row>
            <Col xs={'12'}>
              <AppCard
                icon='address-card'
                title='Profile'
                subtitle='Manage your public-facing profile from here.'
                link={`settings/profile`}
              />
            </Col>

            <Col xs={'12'}>
              <AppCard
                icon='key'
                title='Account'
                subtitle='Manage your account &amp; security access from here.'
                link={`settings/account`}
              />
            </Col>

            <Col xs={'12'}>
              <AppCard
                icon='credit-card'
                title='Credit Cards'
                subtitle='Manage the payment methods you have on file.'
                link={`settings/credit-cards`}
              />
            </Col>

            <Col xs={'12'}>
              <AppCard
                icon='sliders-h'
                title='Notifications'
                subtitle="Manage which notifications you'd like to receive."
                link={`settings/notifications`}
              />
            </Col>

            <Col xs={'12'}>
              <AppCard
                icon='ad'
                title='Promotions'
                subtitle='Have a promo code? Add it here or view your promotions.'
                link={`settings/promotions`}
              />
            </Col>

            <Col xs={'12'}>
              <AppCard
                icon='trash'
                title='Deactivate account'
                subtitle='Tired of Blush? Deactivate your account from here.'>
                <BasicButton title='Deactivate Now' onClick={() => deactivateAccount()}/>
              </AppCard>
            </Col>

          </Row>
        </Container>
			</main>

      <AppFooter />
  	</div>
	);
}

export default withSession(withStore(withToast(SettingsIndex)));
