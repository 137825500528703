import React from 'react';
import Empty from 'components/misc/empty';
import { PromoListCard, PromoPreviewCard } from 'components/promo/promo-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col } from 'reactstrap';


const PromoList = (props) => {
  const { type = 'list', promos = [], loading, onClick, className = ''} = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (promos.length > 0) {
    return (
      <Row>
        {type == 'preview' && promos.map(promo => (
          <Col md='12' key={promo.id}>
            <PromoPreviewCard
              promo={promo}
              onClick={onClick}
              className={className}
            />
          </Col>
        ))}
        {type == 'list' && promos.map(promo => (
          <Col md='12' key={promo.id}>
            <PromoListCard
              promo={promo}
              onClick={onClick}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='user-tag'
      title='Promos'
      msg="Sorry, it looks like we don't have any promos on file yet."
    />
  )
}

export default PromoList;
