import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { MakeupHeader } from 'utils/assets/headers';
import { Container, Row, Col } from 'reactstrap';

const MktAboutRoute = (props) => {
  const { history } = props;

  // Render
  return (
  	<div className='route'>
      <Head title='Blush - About'/>
      <MktAnnouncement />
      <MktNav />
      <MktHeader
        size='md'
        title='About'
        bg={MakeupHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>


      <main className='mkt-main'>

        <section className='mkt-section'>
          <div className='mkt-section-body xs flex'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Our Mission</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <blockquote className='light italic'>
                      <span>"</span>
                      Making Beauty Convenient
                      <span>"</span>
                    </blockquote>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body xs flex'>
            <Container>
              <Row>

                <Col md='3'>
                  <div className='mkt-section-title'>
                    <h2>Our Story</h2>
                    <hr />
                  </div>
                </Col>

                <Col md={{size: 8, offset: 1}}>
                  <div className='mkt-section-content'>
                    <h6 className='p'>Blush was created to help bridge the gap between Beauty Service Professionals and Clients who are in need of services that are restricted with time and everyday priorities.  With over 2 decades of experience in the Beauty Industry, our leadership team has been able to develop strategies that help acquire and retain the most important and influential clients.  Our expertise in World Class service and employee development has been at the forefront of our businesses and we are excited to utilize these strategies in the gig economy.</h6>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>
      <MktFooter />
  	</div>
	);
}

export default MktAboutRoute;
