import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import PhotoSection from 'components/photo/photo-section';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminSettingsPhotoRoute = (props) => {
  const { history, providerId, provider } = props;

  // Render
  return (
    <AdminRoute title='Blush Admin - Photos'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Photos</BreadcrumbItem>
      </Breadcrumb>
      <PhotoSection type='grid' sortProp='position' sortValue='asc' provider={provider}/>
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsPhotoRoute);

