import React from 'react';
import { Row, Col, Badge, Button } from 'reactstrap';
import FAWrapper from 'components/vendors/fa-wrapper';

const LineCard = (props) => {
	const { photo, title, line1, line2, showAction, onClick, className = '', children } = props;


	// Render
	return (
		<div className={'card line ' + className}>
			<Row className='flex'>
				<Col md='4'>
					<div className={'card-header line'}>
						<img className='card-header-img' src={photo} />
					</div>
				</Col>
				<Col md={showAction ? '6' : '8'}>
					<div className='card-body'>
						{title && <h6 className='title'>{title}</h6>}
						{line1 && <h6 className='subtitle'>{line1}</h6>}
						{children}
					</div>
				</Col>
				{showAction && (
					<Col md='2'>
						<div className='pr-15'>
							<Button
								type='button'
								color='text-danger'
								size='xs'
								onClick={onClick}>
								<FAWrapper icon='trash' size='1x'/>
							</Button>
						</div>
					</Col>
					)}
			</Row>
		</div>
	)
}

export default LineCard;
