import React, { Component } from 'react';
import { Container } from 'reactstrap';

import Head from 'components/basics/head';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktFooter from 'components/mkt/mkt-footer';


class Stylists extends Component {

  render() {

    return (
    	<div className='route'>

        <Head title='Blush - Stylists'/>
        <MktNav />
        <main className='mkt-main'>
	    		<Container />
  			</main>
        <MktFooter />

    	</div>
  	);
  }

}

export default Stylists;
