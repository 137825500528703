import AppModel from 'models/app';
import { truncate } from 'utils/helpers';

class Review extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.score = this.attr('decimal', props.score);
		this.comment = props.comment;


		// Relationships
		this.booking = this.belongsTo('booking', props.booking);
		this.user = this.belongsTo('user', props.user);
	}

	get previewComment() {
		return this.comment ? truncate(this.comment, 60, true) : '--';
	}

	get url() {
		return `reviews/${this.id}`;
	}

}

export default Review;
