import React, { UseState } from 'react';
import FAWrapper from 'components/vendors/fa-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, ButtonGroup, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ActivityCard = (props) => {
	const { icon, timestamp, title, description, loading, link, className = '', children } = props;

	if (loading) {
		return <LoadingSkeleton type={type} count={1} />
	}
	return (
		<div className={'card activity ' + className}>
			<div className='card-body'>
				<div className='flex-start-top '>
					{icon && <FAWrapper icon={icon} className='gray-color mt-1'/>}
					<div className='ml-15'>
						{timestamp && <small className='gray-color'>{timestamp}</small>}
						{title && <h6 className='title'>{title}</h6>}
						{description && <h6>{description}</h6>}
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ActivityCard;
