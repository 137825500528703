import React from 'react';
import AppSerializer from 'serializers/app';
import Axios from 'utils/axios';
import { timeout } from 'utils/helpers';
import JsonApiError from 'utils/json-api-error';

const axios = new Axios().instance();

class SupportApi {

	static async contact(data) {
		try {
			await timeout(300);
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/support`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
	  	throw JsonApiError.formatErrors(e);
		}
	}

	static async specialEvent(data) {
		try {
			await timeout(300);
			let formattedData = new AppSerializer().serialize(data);
	  	let response = await axios.post(`/special-event`, formattedData);
	  	let formattedResponse = new AppSerializer().normalize(response.data.data, response.data.included, response.data.meta);
	  	return formattedResponse;
	  } catch(e) {
	  	throw JsonApiError.formatErrors(e);
		}
	}

}

export default SupportApi;
