import AppSerializer from 'serializers/app';

class ProviderSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get attrs() {
		return {
			availableBalance: false,
			currentBalance: false,
			ssnLastFourProvided: false,
		}
	}

	get relationships() {
		return {
			user: { serialize: 'id' },
			location: { serialize: true },
			services: { serialize: 'id' },
		}
	}

}

export default ProviderSerializer;