import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import QueryString from 'query-string';
import AuthApi from 'apis/auth-api';
import Empty from 'components/misc/empty';
import LoadingIndicator from 'components/loading/loading-indicator';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { NavLink, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ResetPasswordForm = (props) => {
  const { user = {}, store, toast, history, modal, toggleModal } = props;
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ isComplete, setIsComplete ] = useState(false);
  const [ password, setPassword ] = useState('');
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Tasks
  const submitForm = async (data, token) => {
  	try {
      setTaskRunning(false);
  		let user = await AuthApi.resetPassword(data, token);
      toast.showSuccess('Password saved!');
      setIsComplete(true);
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  // Methods
	const handleSubmit = (e) => {
		let params = QueryString.parse(location.search);
		let data = {
			id: !isEmpty(user) ? parseInt(user.id) : parseInt(params.user_id),
			password: password,
			confirmPassword: confirmPassword,
		};
		submitForm(data, params.token);
		e.preventDefault();
	}

	const nextAction = () => {
		modal ? toggle() : history.push('/');
	}

	// Render
  return (
    <SectionBlock className='basic'>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <SectionTitle className='flex-between'>
          <h4>Reset Password</h4>
          {modal && <Button color='text-gray' onClick={() => toggle()}><FAWrapper icon='times'/></Button>}
        </SectionTitle>

        <SectionBody className='sm'>
          {isComplete ? (
            <Empty
              type='index'
              icon='shield-check'
              title='Password Reset!'
              msg='Nice work! You can now login with your new password.'
            />
          ) : (
            <div>
              <FormGroup>
                <Label for='password'>New Password</Label>
                <Input id='password'
                  type='password'
                  value={password}
                  placeholder='••••••••••'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label for='confirm_password'>Confirm Password</Label>
                <Input id='confirm_password'
                  type='password'
                  value={confirmPassword}
                  placeholder='••••••••••'
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <small className='gray-color'>Submitting this form will reset your password permanently.</small>
              </FormGroup>
            </div>
          )}
        </SectionBody>

        <SectionFooter className='flex-start'>
          {isComplete ? (
            <NextButton
              title='Finish'
              onClick={() => nextAction()}
            />
          ) : (
            <NextButton
              type='submit'
              title='Save'
              icon='check'
              taskRunning={taskRunning}
            />
          )}
        </SectionFooter>

      </Form>
    </SectionBlock>
  )
}

export default withStore(withToast(withRouter(withToast(ResetPasswordForm))));
