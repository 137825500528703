import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import LegalNav from 'components/navs/legal-nav';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';

const TermsRoute = (props) => {
  const { history } = props;


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Terms of Service'/>
      <MktAnnouncement />
      <MktNav />
      <MktHeader
        title='Terms of Service'
        subtitle='Last updated September 10th, 2020'
        className='soft-gray-bg'>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>
      <main className='mkt-main'>

        <section className='mkt-section sm'>
          <div className='mkt-section-body'>
            <Container>
              <Row>

                <Col md='3'>
                  <LegalNav />
                </Col>

                <Col md={{size: 8, offset: 1}}>

                  <SectionBlock>
                    <SectionBody>
                      <p>These Terms of Service constitute a legally binding agreement (the “Agreement”) between you and Nickita LLC (d/b/a Blush),
                      its parents, subsidiaries, representatives, affiliates, officers and directors (collectively, the “Company,” “we,” “us” or “our”)
                      governing your use of the Company’s application, website, and technology platform (collectively, the “Software Platform”).</p>

                      <p>By entering into this Agreement, and/or by using or accessing the Software Platform you expressly acknowledge that you understand
                      this Agreement (including the dispute resolution and arbitration provisions in Section 16) and accept all of its terms.
                      IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE SOFTWARE
                      PLATFORM OR ANY OF THE SERVICES PROVIDED THROUGH THE SOFTWARE PLATFORM.</p>

                      <hr/>

                      <h4>1. The Software Platform</h4>
                      <p>The Software Platform provides a marketplace where persons (“Customers”) who seek certain personal services, including haircuts,
                      hairstyling, make up, and other services as may be added by the Company from time to time  can be matched with services providers.
                      One option for Customers is to request certain professional services from certain professionals licensed to provide those personal
                      services (“Blush Professionals”). Blush Professionals and Customers are collectively referred to herein as “Users,” and the personal
                      services provided by Blush Professionals to Customers shall be referred to herein as the “Beauty Services.”  As a User, you authorize
                      the Company to match you with a Blush Professional or Customer based on factors such as your location, the requested time and date for
                      the Beauty Services, the type of Beauty Services requested, user preferences, and platform efficiency, and to cancel an existing match
                      and rematch based on the same considerations. Any decision by a User to offer or accept Beauty Services is a decision made in such User’s
                      sole discretion. Each Beauty Service provided by a Blush Professional to a Customer shall constitute a separate agreement between such
                      persons.</p>

                      <hr/>

                      <h4>2. Modifications to the Agreement</h4>
                      <p>The Company reserves the right to modify the terms and conditions of this Agreement, such modifications shall be binding on you only
                      upon your acceptance of the modified Agreement. The Company reserves the right to modify any information on pages referenced in the
                      hyperlinks from this Agreement from time to time, and such modifications shall become effective upon posting. Continued use of the
                      Software Platform or Beauty Services after any such changes shall constitute your consent to such changes. Unless material changes are
                      made to the arbitration provisions herein, you agree that modification of this Agreement does not create a renewed opportunity to opt
                      out of arbitration (if applicable).</p>

                      <hr/>

                      <h4>3. Eligibility</h4>
                      <p>The Software Platform may only be used by individuals who have the right and authority to enter into this Agreement, are fully able
                      and competent to satisfy the terms, conditions, and obligations herein. By becoming a User, you represent and warrant that you are at least
                      18 years old.  The Software Platform is not available to Users who have had their User account temporarily or permanently deactivated. You
                      may not allow other persons to use your User account, and you agree that you are the sole authorized user of your account. To use the Software
                      Platform, each User shall create a User account. Each person may only create one User account, and Company reserves the right to deactivate any
                      additional or duplicate accounts.</p>

                      <hr/>

                      <h4>4. Charges</h4>
                      <p>As a Customer, you understand that request or use of Beauty Services may result in charges to you (“Charges”).  Charges for Beauty Services
                      include fees for services, any tips to the Blush Professional that you elect to pay, and any cancellation fees that you may incur from time to time.
                      The Company has the authority and reserves the right to determine and modify pricing by posting applicable pricing terms for any specific Beauty Services.
                      Pricing may vary based on the type of Beauty Service you request or the Blush Professional you select.</p>
                      <ul>
                        <li>
                          <h6>Tips</h6>
                          <p>
                            Following the Beauty Services, you may elect to tip your Blush Professional in cash or through the Company application.
                            You may also elect to set a default tip amount or percentage through the app.
                            Any tips will be provided entirely to the applicable Blush Professional.
                          </p>
                        </li>

                        <li>
                          <h6>Facilitation of Charges</h6>
                          <p>
                            All Charges are facilitated through a third-party payment processor (e.g., Stripe, Inc.). The Company may replace its third-party payment processor
                            without notice to you. Charges shall only be made through the Software Platform. With the exception of tips, cash payments are strictly prohibited.
                            Your payment of Charges to the Company satisfies your payment obligation for your use of the Software Platform and Beauty Services.
                            The Company may group multiple charges into a single aggregate transaction on your payment method based on the date(s) they were incurred.
                          </p>
                        </li>

                        <li>
                          <h6>No Refunds</h6>
                          <p>
                            Except as provided herein, all Charges are non-refundable. This no-refund policy shall apply at all times regardless of your decision to terminate
                            usage of the Software Platform, any disruption to the Software Platform or Beauty Services, or any other reason whatsoever.
                            In the event a Customer is dissatisfied with the Beauty Services he/she receives, the Customer may request a refund from the Company.
                            The Company may request any additional information it deems reasonably necessary to determine whether a refund is warranted.
                            The Company’s decision of whether or not to provide a refund to a Customer will be made in the Company’s sole discretion.
                            Any refunds issued will be credited to amounts owed and otherwise payable to the Blush Professional pursuant to this Agreement.
                          </p>
                        </li>

                        <li>
                          <h6>Credit Card Authorization</h6>
                          <p>
                            Upon addition of a new payment method or each request for Beauty Services, the Company may seek authorization of your selected
                            payment method to verify the payment method, and ensure the fees for such requested Beauty Services will be covered.
                            The authorization is not a charge; however, it may reduce your available credit by the authorization amount until your bank’s next processing cycle.
                            Should the amount of our authorization exceed the total funds on deposit in your account, you may be subject to overdraft of NSF charges by the bank issuing your debit or prepaid card.
                            We cannot be held responsible for these charges and are unable to assist you in recovering them from your issuing bank.
                          </p>
                        </li>
                      </ul>

                      <hr/>

                      <h4>5. Blush Professional Payments</h4>
                      <ul>
                        <li>
                          a) The Blush Professional is entitled to a Blush Professional Fee for the Blush Professional Services you perform for Customers, as provided in this Agreement.
                          The “Blush Professional Fee” shall equal 70% of the stated price or fee for the Beauty Service.
                          TRADEMARKhe remaining portion of the price or fee for the Beauty Service shall be retained and earned by the Company.
                        </li>

                        <li>
                          b) In some situations where a Customer cancels a request for Beauty Services that you have accepted, you may earn a cancellation fee.
                          In those cases, you will earn a Blush Professional Fee in the amount of the cancellation payment.
                        </li>

                        <li>
                          c) In addition to the Blush Professional Fee, you will receive the following payments, if applicable: (i) any tips provided by a Customer to the Blush Professional,
                          and (ii) any bonuses or incentives you earn based on your provision of Blush Professional Services.
                        </li>

                        <li>
                          d) The Company will collect payments owed to Blush Professional by Customers and other third parties as your limited payment
                          collection agent and you agree that the receipt of such payments by the Company satisfies the payer’s obligation to the Blush Professional.
                          The Company reserves the right to adjust or withhold all or a portion of a Blush Professional Fee or other payment owed to you (except tips)
                          (i) if we believe that you have attempted to defraud or abuse Customers, the Company, or the Company’s payment systems, or
                          (ii) in order to resolve a Customer complaint. The Company’s decision to adjust or withhold the Blush Professional Fee or other payment in any
                          way shall be exercised in a reasonable manner. If you have agreed to any other amounts being deducted from your Blush Professional Fees with any party,
                          those amounts will be deducted before remittance to you, and we may determine the order of these other deductions if allowed by law.
                          We will use reasonable efforts to ensure that your Blush Professional Fee and any other payments to you will be paid to you on at least a weekly basis.
                          You acknowledge and agree that all payments owed to you shall not include any interest and will be net of any amounts that we are required to withhold by law.
                        </li>
                      </ul>

                      <hr/>

                      <h4>6. Company Communication</h4>
                      <ul>
                        <li>
                          a) By entering into this Agreement or using the Software Platform, you agree to receive communications from us, including via e-mail, text message, calls, and push notifications.
                          You agree that texts, calls or prerecorded messages may be generated by automatic telephone dialing systems.
                          Communications from the Company, its affiliated companies and/or Blush Professionals, may include but are not limited to:
                          operational communications concerning your User account or use of the Software Platform or Beauty Services, updates concerning new and existing features on the Software Platform,
                          communications concerning promotions run by us or our third-party partners, and news concerning the Company and industry developments.
                          Standard text messaging charges applied by your cell phone carrier will apply to text messages we send.
                          </li>

                        <li>
                          b) IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU CAN UNSUBSCRIBE FROM OUR PROMOTIONAL EMAIL LIST BY FOLLOWING THE UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF.
                          IF YOU WISH TO OPT OUT OF PROMOTIONAL CALLS OR TEXTS, YOU MAY DO SO BY MODIFYING YOUR NOTIFICATION SETTINGS IN THE SOFTWARE PLATFORM.
                          IN ADDITION, YOU CAN CLICK “UNSUBSCRIBE” IN THE LINK AT THE BOTTOM OF OUR EMAILS TO UNSUBSCRIBE FROM FUTURE COMMUNICATIONS.
                          HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL TEXTS MAY IMPACT YOUR USE OF THE SOFTWARE PLATFORM OR RELATED SERVICES.
                        </li>
                      </ul>

                      <hr/>

                      <h4>7. Your Information</h4>
                      <p>
                        Your Information is any information you provide, publish or post to or through the Software Platform (including any profile information you provide) or
                        send to other Users (including via in-application feedback, any email feature, or through any the Company-related Facebook, Twitter or other social media posting) (your “Information”).
                        You consent to us using your Information to create a User account that will allow you to use the Software Platform and participate in the Beauty Services.
                        Our collection and use of personal information in connection with the Software Platform and Beauty Services is as provided in the Company’s Privacy Policy located at
                        <a href='https://blush-now.com/privacy-policy'>https://blush-now.com/privacy-policy</a> (the “Privacy Policy”).
                        You are solely responsible for your Information and your interactions with other members of the public, and we act only as a passive conduit for your online posting of your Information.
                        You agree to provide and maintain accurate, current and complete information and that we and other members of the public may rely on your Information as accurate, current and complete.
                        To enable the Company to use your Information for the purposes described in the Privacy Policy and this Agreement,
                        you grant to us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, transferable, sub-licensable (through multiple tiers) right and license to exercise the
                        copyright, publicity, and database rights you have in your Information, and to use, copy, perform, display and distribute such Information to prepare derivative works,
                        or incorporate into other works, such Information, in any media now known or not currently known.
                        The Company does not assert any ownership over your Information; rather, as between you and the Company, subject to the rights granted to us in this Agreement,
                        you retain full ownership of all of your Information and any intellectual property rights or other proprietary rights associated with your Information.
                      </p>

                      <hr/>

                      <h4>8. Restrictions</h4>
                      <p>With respect to your use of the Software Platform and your participation in the Beauty Services, you agree that you will not:</p>
                      <ul>
                        <li>
                          a) impersonate any person or entity;
                        </li>

                        <li>
                          b) stalk, threaten, or otherwise harass any person, or carry any weapons;
                        </li>

                        <li>
                          c) violate any law, statute, rule, permit, ordinance or regulation;
                          </li>

                        <li>
                          d) interfere with or disrupt the Software Platform or the servers or networks connected to the Software Platform;
                        </li>

                        <li>
                          e) post Information or interact on the Software Platform or Beauty Services in a manner which is fraudulent, libelous, abusive, obscene, profane, sexually oriented, harassing, or illegal;
                        </li>

                        <li>
                          f) use the Software Platform in any way that infringes any third party’s rights, including: intellectual property rights, copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;
                        </li>

                        <li>
                          g) post, email or otherwise transmit any malicious code, files or programs designed to interrupt, damage, destroy or limit the functionality of the Software Platform or any computer software or hardware or telecommunications equipment or
                          surreptitiously intercept or expropriate any system, data or personal information;
                        </li>

                        <li>
                          h) “frame” or “mirror” any part of the Software Platform, without our prior written authorization or use meta tags or
                          code or other devices containing any reference to us in order to direct any person to any other web site for any purpose;
                        </li>

                        <li>
                          i) modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Software Platform;
                        </li>

                        <li>
                          j) use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, scrape, “data mine”,
                          or in any way reproduce or circumvent the navigational structure or presentation of the Software Platform or its contents;
                        </li>

                        <li>
                          k) link directly or indirectly to any other web sites;
                        </li>

                        <li>
                          l) transfer or sell your User account, password and/or identification, or any other User’s Information to any other party;
                        </li>

                        <li>
                          m) discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation;
                        </li>

                        <li>
                          n) circumvent the Company or the Software Platform to arrange any Beauty Services from a Blush Professional, including, but not limited to, exchanging personal contact information with the Customer or the Blush Professional (as the case may be);and
                        </li>

                        <li>
                          o) cause any third party to engage in the restricted activities above.
                        </li>
                      </ul>

                      <hr/>

                      <h4>9. Blush Professional Representations, Warranties, and Agreements.</h4>
                      <p>By providing Beauty Services as a Blush Professional on the Software Platform, you represent, warrant, and agree that:</p>

                      <ul>
                        <li>
                          a) You possess a valid cosmetology license and are authorized by the applicable local authorities to provide the Beauty Services that you offer to the Customers;
                        </li>

                        <li>
                          b) You own, or have the equipment and supplies to provide the Beauty Services; such equipment is in good working order and such equipment and supplies meet the
                          industry safety standards and all applicable statutory requirements for equipment and supplies of its kind;
                        </li>

                        <li>
                          c) You will not engage in reckless behavior while performing the Beauty Services, provide the Beauty Services as a Blush Professional while
                          under the influence of alcohol or drugs, or take action that harms or threatens to harm the safety of the Customer, the Company,
                          it’s User community or third parties;
                        </li>

                        <li>
                          d) You will not, while providing the Beauty Services, demand that a Customer pay in cash, or use a credit card reader,
                          such as a Square Reader, to accept payment or engage in any other activity in a manner that is inconsistent with your
                          obligations under this Agreement;
                        </li>

                        <li>
                          e) You will not attempt to defraud the Company or Customers on the Software Platform or in connection with your provision of Beauty Services.
                          If we suspect that you have engaged in fraudulent activity we may withhold applicable fees owed to you or other payments for the Beauty Services
                          in question and take any other action against you available under the law;
                        </li>

                        <li>
                          f) You agree that we may obtain information about you, including your criminal and driving records, and you agree to provide any further necessary
                          authorizations to facilitate our access to such records during the term of the Agreement;
                        </li>

                        <li>
                          g) You have a valid policy of general liability insurance (in coverage amounts consistent with all applicable legal requirements and the
                          minimum amounts set by the Company, which may be modified from time to time) that names or schedules you for to provide Beauty Services;
                        </li>

                        <li>
                          h) You will pay all applicable federal, state and local taxes based on your provision of Beauty Services and any payments received by you; and
                        </li>

                        <li>
                            i) As a Blush Professional on the Software Platform, you acknowledge and agree that you and the Company are in a direct business relationship,
                            and the relationship between the parties under this Agreement is solely that of independent contracting parties.
                            You and the Company expressly agree that (1) this is not an employment agreement and does not create an employment relationship between you and the Company;
                            and (2) no joint venture, franchisor- franchisee, partnership, or agency relationship is intended or created by this Agreement.
                            You have no authority to bind the Company, and you undertake not to hold yourself out as an employee, agent or authorized representative of the Company.
                            The Company does not, and shall not be deemed to, direct or control you generally or in your performance under this Agreement specifically,
                            including in connection with your provision of Beauty Services or your acts or omissions.
                            You retain the sole right to determine when, where, and for how long you will utilize the Software Platform.
                            You retain the option to accept or to decline or ignore a Customer’s request for Beauty Services via the Software Platform,
                            or to cancel an accepted request for Beauty Services via the Software Platform, subject to the Company’s then-current cancellation policies.
                            You acknowledge and agree that you have complete discretion to provide Beauty Services or otherwise engage in other business or employment activities.
                        </li>
                      </ul>

                      <hr/>

                      <h4>10. Customer Representations, Warranties, and Agreements.</h4>
                      <ul>
                        <li>
                          a) You have the authority to enter into this Agreement and each of the transactions conducted through the Software Platform.
                        </li>
                        <li>
                          b) The Beauty Services that you select are personal to you and are not being ordered or procured for a third party.
                          Such third party should obtain their own account and order the Beauty Services directly.
                        </li>
                        <li>
                          c) You hereby consent to the Blush Professional entering your premises or other property where the Beauty Services are to be provided.
                          You further represent that you have the authority to use the premises where the Beauty Services are to be provided and to invite the Blush Professional onto such premises.
                          You agree to provide the Blush Professional with a clean and safe place where he or she can provide the Beauty Services.
                        </li>

                        <li>
                          d) You agree not to solicit any additional services from the Blush Professional except through the Software Platform.
                        </li>

                      </ul>

                      <hr/>

                      <h4>11. Intellectual Property.</h4>
                      <ul>
                        <li>
                          a) All intellectual property rights in the Software Platform shall be owned by the Company absolutely and in their entirety.
                          These rights include database rights, copyright, design rights (whether registered or unregistered), trademarks (whether registered or unregistered) and other similar
                          rights wherever existing in the world together with the right to apply for protection of the same. All other trademarks, logos, service marks,
                          company or product names set forth in the Software Platform are the property of their respective owners. You acknowledge and agree that any
                          questions, comments, suggestions, ideas, feedback or other information (“Submissions”) provided by you to us are non-confidential and shall
                          become the sole property of the Company. The Company shall own exclusive rights, including all intellectual property rights, and shall be
                          entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or
                          compensation to you.  “BLUSH” and other the Company logos, designs, graphics, icons, scripts and service names are registered trademarks,
                          trademarks or trade dress of the Company in the United States and/or other countries (collectively, the “Company Marks”).
                          If you provide Beauty Services as a Blush Professional, the Company grants to you, during the term of this Agreement, and subject to your
                          compliance with the terms and conditions of this Agreement, a limited, revocable, non-exclusive license to display and use the Company Marks
                          solely on the Company stickers/decals, and any other the Company-Branded items provided or sold by the Company directly to you in connection
                          with providing the Beauty Services (“License”). The License is non-transferable and non-assignable, and you shall not grant to any third party
                          any right, permission, license or sublicense with respect to any of the rights granted hereunder without the Company’s prior written permission,
                          which it may withhold in its sole discretion. The Company logo (or any the Company Marks) may not be used in any manner that is likely to cause
                          confusion. You may identify yourself as a Blush Professional on the Software Platform, but may not misidentify yourself as the Company, an employee
                          of the Company, or a representative of the Company.
                        </li>

                        <li>
                          b) You acknowledge that the Company is the owner and licensor of the Company Marks, including all goodwill associated therewith, and
                          that your use of the Company logo (or any the Company Marks) will confer no interest in or ownership of the Company Marks in you but
                          rather inures to the benefit of the Company. You agree to use the Company logo strictly in accordance with the Company’s trademark usage guidelines,
                          as may be provided to you and revised from time to time, and to immediately cease any use that the Company determines to nonconforming or otherwise unacceptable.
                        </li>

                        <li>
                          c) You agree that you will not: (1) create any materials that use the Company Marks or any derivatives of the Company Marks as a trademark, service mark,
                          trade name or trade dress, other than as expressly approved by the Company in writing; (2) use the Company Marks in any way that tends to impair their
                          validity as proprietary trademarks, service marks, trade names or trade dress, or use the Company Marks other than in accordance with the terms,
                          conditions and restrictions herein; (3) take any other action that would jeopardize or impair the Company’s rights as owner of the Company Marks or the
                          legality and/or enforceability of the Company Marks, including, challenging or opposing the Company’s ownership in the Company Marks; (4) apply for
                          trademark registration or renewal of trademark registration of any of the Company Marks, any derivative of the Company Marks, any combination of the
                          Company Marks and any other name, or any trademark, service mark, trade name, symbol or word which is similar to the Company Marks; (5) use the Company Marks
                          on or in connection with any product, service or activity that is in violation of any law, statute, government regulation or standard.
                        </li>
                      </ul>

                      <hr/>

                      <h4>12. Indemnity.</h4>
                      <p>
                        You will defend, indemnify, and hold the Company including our affiliates, subsidiaries, parents, successors and assigns, and each of our respective
                        officers, directors, employees, agents, or shareholders harmless from any claims, actions, suits, losses, costs, liabilities and expenses
                        (including reasonable attorneys’ fees) relating to or arising out of your use of the Software Platform and participation in the Beauty Services,
                        including: (1) your breach of this Agreement or the documents it incorporates by reference; (2) your violation of any law or the rights of a third party,
                        including, Blush Professionals, Customers, Users, and other third parties, as a result of your own interaction with such party; (3) any allegation that any
                        materials that you submit to us or transmit through the Software Platform or to us infringe or otherwise violate the copyright, trademark, trade secret or
                        other intellectual property or other rights of any third party; (4) your provision of Beauty Services as a Blush Professional; and/or (5) any other activities
                        in connection with the Beauty Services. This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person.
                      </p>

                      <hr/>

                      <h4>13. Limitation Of Liability</h4>
                      <p>
                        IN NO EVENT WILL COMPANY, INCLUDING OUR AFFILIATES, SUBSIDIARIES, PARENTS, SUCCESSORS AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
                        AGENTS, OR SHAREHOLDERS (COLLECTIVELY “COMPANY” FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL,
                        OR INDIRECT DAMAGES (INCLUDING DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR
                          TRANSMITTED BY THE SOFTWARE PLATFORM, SERVICE INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE
                        SOFTWARE PLATFORM, THE STYLING SERVICES, OR THIS AGREEMENT, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE SOFTWARE PLATFORM MAY BE USED BY YOU TO REQUEST AND SCHEDULE STYLING SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU
                        AGREE THAT COMPANY HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY SUCH STYLING SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY
                        SET FORTH IN THIS AGREEMENT.
                      </p>

                      <hr/>

                      <h4>14. Term and Termination</h4>
                      <p>
                        This Agreement is effective upon your creation of a User account. This Agreement may be terminated: (a) by User, without cause, upon seven (7) days’
                        prior written notice to the Company; or (b) by either Party immediately, without notice, upon the other Party’s material breach of this Agreement.
                        In addition, the Company may terminate this Agreement or deactivate your User account immediately in the event: (1) you no longer qualify to provide Beauty
                        Services (including as a result of changes in applicable law, rule, permit, ordinance or regulation); (2) you fall below the Company’s star rating or
                        cancellation threshold; (3) the Company has the good faith belief that such action is necessary to protect the safety of the Company community or third parties,
                        provided that in the event of a deactivation pursuant to (1)-(3) above, you will be given notice of the potential or actual deactivation and an opportunity to
                        attempt to cure the issue to the Company’s reasonable satisfaction prior to the Company permanently terminating the Agreement. For all other breaches of this
                        Agreement, you will be provided notice and an opportunity to cure the breach. If the breach is cured in a timely manner and to the Company’s satisfaction,
                        this Agreement will not be permanently terminated. Sections 2, 6, 7 (with respect to the license) ,11, 12, 13, 15, 16, and 17shall survive any termination or
                        expiration of this Agreement.
                      </p>

                      <hr/>

                      <h4>15. Disclaimers</h4>
                      <p>
                        The following disclaimers are made on behalf of the Company, our affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, and shareholders.
                      </p>
                      <ul>
                        <li>
                          a) The Company does not provide Beauty Services, and the Company is not a salon, spa, barber shop, or other provider of personal services.
                          It is up to the Blush Professional to decide whether or not to offer Beauty Services to a Customer contacted through the Software Platform,
                          and it is up to the Customer to decide whether or not to accept Beauty Services from any Blush Professional contacted through the Software Platform.
                          We cannot ensure that a Blush Professional or Customer will complete an Beauty Services (including completing the Beauty Services to the Customer’s satisfaction).
                           We have no control over the quality or safety of the Beauty Services.
                        </li>

                        <li>
                          b) The Software Platform is provided on an “as is” basis and without any warranty or condition, express, implied or statutory.
                          We do not guarantee and do not promise any specific results from use of the Software Platform and/or the Beauty Services,
                          including the ability to provide or receive Beauty Services at any given location or time.
                          To the fullest extent permitted by law, we specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.
                          Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.
                        </li>

                        <li>
                          c) We do not warrant that your use of the Software Platform or Beauty Services will be accurate, complete, reliable, current, secure, uninterrupted, always available,
                          or error- free, or will meet your requirements, that any defects in the Software Platform will be corrected, or that the Software Platform is free of viruses or
                          other harmful components. We disclaim liability for, and no warranty is made with respect to, connectivity and availability of the Software Platform or Beauty Services.
                        </li>

                        <li>
                          d) We cannot guarantee that each Customer is who he or she claims to be. Please use common sense when using the Software Platform and Beauty Services,
                          including looking at the photos of the Blush Professional or Customer you have matched with to make sure it is the same individual you see in person.
                          Please note that there are also risks of dealing with underage persons or people acting under false pretense, and we do not accept responsibility or liability for any content,
                          communication or other use or access of the Software Platform by persons under the age of 18 in violation of this Agreement.
                          We encourage you to communicate directly with each potential Blush Professional or Customer prior to engaging in an arranged transportation service.
                        </li>

                        <li>
                          e) The Company is not responsible for the conduct, whether online or offline, of any User of the Software Platform or Beauty Services.
                          You are solely responsible for your interactions with other Users. By using the Software Platform and participating in the Beauty Services,
                          you agree to accept such risks and agree that the Company is not responsible for the acts or omissions of Users on the Software Platform or participating in the Beauty Services.
                        </li>

                        <li>
                          f) You are responsible for the use of your User account and the Company expressly disclaims any liability arising from the unauthorized use of your User account.
                          Should you suspect that any unauthorized party may be using your User account or you suspect any other breach of security, you agree to notify us immediately.
                        </li>

                        <li>
                          g) It is possible for others to obtain information about you that you provide, publish or post to or through the Software Platform (including any profile information you provide),
                          send to other Users, or share during the Beauty Services, and to use such information to harass or harm you. We are not responsible for the use of any personal information that you
                          disclose to other Users on the Software Platform or through the Beauty Services. Please carefully select the type of information that you post on the Software Platform or through
                          the Beauty Services or release to others. We disclaim all liability, regardless of the form of onClick, for the acts or omissions of other Users (including unauthorized users, or “hackers”).
                        </li>

                        <li>
                          h) Opinions, advice, statements, offers, or other information or content concerning the Company or made available through the Software Platform, but not directly by us, are those of
                          their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content. Under no circumstances will we be responsible for any loss
                          or damage resulting from your reliance on information or other content posted by third parties, whether on the Software Platform or otherwise. We reserve the right, but we have no
                          obligation, to monitor the materials posted on the Software Platform and remove any such material that in our sole opinion violates, or is alleged to violate, the law or this agreement
                          or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of Users or others.
                        </li>

                        <li>
                          i) Location data provided by the Software Platform is for basic location purposes only and is not intended to be relied upon in situations where precise location information is
                          needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage. Neither the Company, nor any of its
                          content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of location data tracked or displayed by the Software Platform.
                          Any of your Information, including geolocational data, you upload, provide, or post on the Software Platform may be accessible to the Company and certain Users of the Software Platform.
                        </li>
                      </ul>

                      <hr/>

                      <h4>16. Mediation; Arbitration.</h4>
                      <ul>
                        <li>
                          a) Any dispute concerning the contents of the Agreement, if it cannot be settled through direct negotiation, shall first be submitted to mediation according to the terms specified below.
                          All parties agree to attempt in good faith to settle the dispute by mediation before resorting to arbitration.
                        </li>

                        <li>
                          b) Any party that wishes to have a dispute mediated shall submit a written request for mediation to the other party.
                          Mediation shall commence within 15 days after the date of the written request for mediation.
                          Any decision reached by mediation shall be reduced to writing, signed by all parties, and shall be binding on each party.
                          The costs of mediation shall be shared equally by the parties.
                          Each party shall cooperate fully and fairly with the mediator in any attempt to reach a mutually satisfactory compromise to a dispute.
                        </li>

                        <li>
                          c) If the dispute is not resolved within 30 days after it is referred to the mediator, the dispute shall be submitted for arbitration according to the terms
                          specified below or on terms agreeable to the parties at the time the dispute is submitted to arbitration.
                          To the maximum extent permitted by law, all disputes, controversies, claims, or demands of any kind or nature arising between the parties in connection with the Agreement,
                          whether at law or in equity or based upon common law or any federal or state statute, rule, or regulation will be resolved through binding arbitration.
                          Any arbitration commenced hereunder shall be initiated in Denver, Colorado and shall be governed by the Rules of the American Arbitration Association (the “AAA”) from time to time in force.
                          This submission and agreement to arbitrate shall be specifically enforceable.
                        </li>

                        <li>
                          d) The arbitration shall occur before a single arbitrator that shall be mutually agreed upon by the parties (the “Arbitrator”).
                          If the parties cannot agree on a single arbitrator, then an arbitrator shall be selected in accordance with the rules of the AAA.
                        </li>

                       <li>
                          e) The parties agree that for all claims brought to enforce the provisions of the Agreement, the non-prevailing party will pay,
                          and the Arbitrator will award, the prevailing party’s reasonable attorneys’ fees and costs incurred in connection with such claims.
                        </li>
                      </ul>

                      <hr/>

                      <h4>17. Miscellaneous</h4>
                      <ul>
                        <li>
                          a) The Agreement and the exhibits and schedules thereto constitute the entire agreement between the parties, superseding all previous communications and negotiations, whether written or oral.
                          No modification of the Agreement shall be binding unless it is in writing and executed by an authorized representative of each party.
                          The Agreement shall not be deemed to create any rights in third parties.
                        </li>

                        <li>
                          b) If any part of the Agreement is held by any court or arbiter of competent jurisdiction to be invalid, the remaining parts of the Agreement shall continue to be valid and enforceable as to the parties hereto.
                        </li>

                        <li>
                          c) The waiver or failure of either party to exercise in any respect any right provided for such party herein shall not be deemed a waiver of any further right hereunder.
                        </li>

                        <li>
                          d) Any notice which may be permitted or required under the Agreement shall be delivered personally, or by email or other electronic means, or sent by United States registered or
                          certified mail, postage prepaid, addressed: (a) to Attn: Nikita Jhaveri, 8376 Green Island Circle, Lone Tree CO 80124 or nikita@blush-now.com (b) to the User at the address and email address provided in your
                          registration of your account.  Notice given by email or other electronic means shall be deemed to be received upon the later of confirmation of receipt or 1 business day following the date of transmission.
                          Notice by registered or certified mail or overnight carrier and shall be deemed to be received 2 days following the date of mailing, provided such notice is properly addressed and sufficient postage is
                          affixed thereto, or the actual date of receipt, whichever is earlier.
                        </li>

                        <li>
                          e) The Agreement shall be governed by, construed and enforced in accordance with the laws of the State of Colorado, without reference to, or application of, its conflict of laws principles.
                        </li>
                      </ul>

                      <hr/>

                    </SectionBody>
                  </SectionBlock>

                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>
      <MktFooter />

  	</div>
	);
}

export default TermsRoute;
