import React, { Component } from 'react';
import LoadingIndicator from 'components/loading/loading-indicator';

const CustomerStats = (props) => {
	const { type, customer, loading } = props;

	return (
		<table className={'stats ' + type}>
			<tbody>
				<tr>
					<td>Total Bookings</td>
					<td>{loading ? <LoadingIndicator color='gray' size='18px'/> : customer.displayTotalBookings}</td>
				</tr>
				<tr>
					<td>Net Commissions</td>
					<td>{loading ? <LoadingIndicator color='gray' size='18px'/> : customer.displayNetCommissions}</td>
				</tr>
				<tr>
					<td>Net Tips</td>
					<td>{loading ? <LoadingIndicator color='gray' size='18px'/> : customer.displayNetTips}</td>
				</tr>
			</tbody>
    </table>
	)
}

export default CustomerStats;
