import React from 'react';
import { Link } from 'react-router-dom';
import BookingActions from 'components/booking/booking-actions';
import TableHeader from 'components/misc/table-header';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Table, Badge } from 'reactstrap';

const BookingTable = (props) => {
  const { bookings = [], provider, sortProp, sortValue, sortData, loading, refreshData, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type='table'/>
  }
  return (
    <Table className='index' responsive>
      <thead>
        <tr>
          <TableHeader text='ID' value='id' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <TableHeader text='Status' value='status' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <th className='uppercase'>Customer</th>
          <th className='uppercase'>Summary</th>
          <TableHeader text='Date' value='startDate' sortProp={sortProp} sortValue={sortValue} sortData={sortData}/>
          <th className='uppercase'>Total</th>
          <th className='uppercase'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map(booking => (
          <tr key={booking.id}>
            <td>
              <Link className='td' to={booking.url}>#{booking.displayConfirmationCode}</Link>
            </td>
            <td>
              <Link className='td' to={booking.url}><Badge color={`${booking.status}`} className='sm' pill>{booking.status}</Badge></Link>
            </td>
            <td>
              <Link className='td' to={booking.url}>{booking.customer && booking.customer.name}</Link>
            </td>
            <td>
              <Link className='td' to={booking.url}>{booking?.totalServiceCount + `x Services`}</Link>
            </td>
            <td>
              <Link className='td' to={booking.url}>{`${booking.displayShortDateTime} ${booking?.timezoneAbbreviation || ''}`}</Link>
            </td>
            <td>
              <Link className='td' to={booking.url}><b>{booking.displayTotal}</b></Link>
            </td>
            <td>
              <BookingActions type='table' booking={booking} isAdmin={true} refreshData={refreshData} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default BookingTable;
