import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';
import { Fade, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, NavItem, Button } from 'reactstrap';

const UserDropdown = (props) => {
	const { user, logout } = props;
	const [ fadeIn, setFadeIn ] = useState(false);
	const [ fadeOut, setFadeOut ] = useState(false);
	const [ dropdownOpen, setDropdownOpen ] = useState(false);
	const [ isEnter, setIsEnter ] = useState(false);
	const [ isExit, setIsExit ] = useState(false);
	
	
	// Tasks
	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	}


	// Render
	return (
		<DropdownWrapper isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>

			<NavItem>
		    <DropdownToggle color='transparent' className='dropdown-toggle-user'>
					<img className='circle' src={user.photo}/>
				</DropdownToggle>
			</NavItem>

			<DropdownMenuWrapper isOpen={dropdownOpen} right={true} className='user'>

				<DropdownItem tag='li'>
	      	<NavLink to={`/user/${user.id}`} exact>
	      		<div className='nav-user flex-start'>
	      			<img className='circle mr-15' src={user.photo}/>
	      			<div>
	      				<h6><b>{user.name}</b></h6>
	      				<h6>{user.email}</h6>
	    				</div>
	  				</div>
	    		</NavLink>
	    	</DropdownItem>

	    	<DropdownItem divider />

	      <DropdownItem tag='li'>
	      	<NavLink className='btn' to={`/user/${user.id}/bookings`}>View Profile</NavLink>
	    	</DropdownItem>
	      
	      <DropdownItem tag='li'>
	      	<NavLink className='btn' to={`/user/${user.id}/settings`}>Settings</NavLink>
	    	</DropdownItem>
	      
	      {user.providerId && (
	      	<DropdownItem tag='li'>
	      		<NavLink className='btn' to={`/admin/${user.providerId}/dashboard`}>My Dashboard</NavLink>
	    		</DropdownItem>
	    	)}

	    	<DropdownItem divider />

	      <DropdownItem tag='li'>
	      	<NavLink className='btn sub-font' to='/about'>About</NavLink>
	    	</DropdownItem>

	      <DropdownItem tag='li'>
	        <NavLink className='btn sub-font' to='/our-services'>Our Services</NavLink>
	      </DropdownItem>

	      <DropdownItem tag='li'>
	        <NavLink className='btn sub-font' to='/trust-safety'>Trust &amp; Safety</NavLink>
	      </DropdownItem>

	      <DropdownItem tag='li'>
	      	<NavLink className='btn sub-font' to='/special-events'>Special Events</NavLink>
	    	</DropdownItem>
	      
	      <DropdownItem tag='li'>
	      	<NavLink className='btn sub-font' to='/contact'>Contact</NavLink>
	    	</DropdownItem>
	      
	      <DropdownItem divider />
	      
	      <DropdownItem tag='li'>
	      	<Button className='btn' color='text-gray' onClick={logout}>Log out</Button>
	    	</DropdownItem>

    	</DropdownMenuWrapper>

		</DropdownWrapper>
	)
}

export default UserDropdown;