import React, { Component } from 'react';
import { withSession } from 'contexts/session-context';
import { Link } from 'react-router-dom';

import FAWrapper from 'components/vendors/fa-wrapper';


const ProviderLoginBtn = (props) => {
	const { session, className = '' } = props;

	if (session.isAuthenticated && session.user.providerId) {
		return (
			<Link
				to={`/admin/${session.user.providerId}`}
				className={className}>
				Visit Dashboard<FAWrapper icon='chevron-right' className='ml-15' />
			</Link>
		)
	}
	return (
		<Link
			to={`/stylists/new`}
			className={className}>
			Get Started<FAWrapper icon='chevron-right' className='ml-15' />
		</Link>
	)
}

export default withSession(ProviderLoginBtn);
