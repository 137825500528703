import AppModel from 'models/app';
import Moment from 'moment';

class Articles extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		
		
		// Attrs
		this.photo = props.profile_image;
		this.firstName = props.first_name;
		this.lastName = props.last_name;
		this.title = props.title;
		this.email = props.email;
		this.slug = props.slug;

		// Misc
		this.createdAt = props.created;

		
		// Relationships
		this.articles = this.hasMany('article', props.article);
	}


	// Computed
	get name() {
		return this.firstName + ' ' + this.lastName;
	}

	get formattedSlug() {
		return '/blog/' + this.slug;
	}

	get displayPublishedAt() {
		return Moment(this.publishedAt).format('ddd MMM Do, YYYY');
	}
}

export default Articles;