import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import Butter from 'buttercms';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import AvatarCard from 'components/cards/avatar-card';
import { BlogGridCard } from 'components/blog/blog-card';
import BlogList from 'components/blog/blog-list';
import ShareButtons from 'components/vendors/share-button-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const BlogDetailRoute = (props) => {
  const { history, slug, store, toast } = props;
  const [ article, setArticle ] = useState({});
  const [ loading, setLoading ] = useState([]);
  const butter = Butter(typeof window === 'undefined' ? '' : window.env.REACT_APP_BUTTER_KEY);


  // Hooks
  useEffect(() => {
    fetchData()
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await butter.post.retrieve(slug);
      let model = store.createRecord('article', response.data.data);
      setArticle(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <div className='route'>
      <Head title='Blush - Blog'/>
      <MktAnnouncement />
      <MktNav />

      <main className='mkt-main'>
        <Container>

          <section className='mkt-detail-block'>
            <Breadcrumb>
              <BreadcrumbItem><Link to='/'>Home</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to='/blog'>Blog</Link></BreadcrumbItem>
              <BreadcrumbItem active>{article.title}</BreadcrumbItem>
            </Breadcrumb>

            <img className='mkt-detail-img' src={article.photo} />

            <div className='mkt-detail-title bb'>
              <h2><b>{article.title}</b></h2>
              <h5 className='gray-color'>Published {article.displayPublishedAt}</h5>
              <AvatarCard
                photo={article.author && article.author.photo}
                title={article.author.name}
              />
            </div>
            <ShareButtons
              img={article.photo}
              title={article.title}
              content={article.summary}
              url={article.formattedSlug}
            />

            <div className='mkt-detail-body md'>
              <SanitizedHTML
                html={article.body}
                className='mkt-detail-content'
              />
            </div>
          </section>

          <section className='mkt-detail-block'>
            <div className='mkt-section-title'>
              <h2 className='bold'>Related Articles</h2>
              <hr/>
            </div>

            <div className='mkt-detail-body md flex'>
              <BlogList
                type='grid'
                page='1'
                pageSize='3'
                article={article}
              />
            </div>
          </section>

        </Container>

        <MktConsumerPrefooter />
      </main>

      <MktFooter />
    </div>
  );
}

export default withStore(withToast(BlogDetailRoute));
