import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import LineCard from "components/cards/line-card";
import PreviewCard from "components/cards/preview-card";
import ListCard from "components/cards/list-card";
import SelectCard from "components/cards/select-card";
import MktCard from "components/cards/mkt-card";
import ServicePreview from "components/service/service-preview";
import { LoadingSkeleton } from "components/loading/loading-skeleton";
import { TextButton } from "components/bootstrap/button-wrapper";
import { Container, Row, Col, Modal, Badge, Button } from "reactstrap";
import FAWrapper, { FalIcon } from "components/vendors/fa-wrapper";
import { isEmpty, truncate } from "utils/helpers";
import { getServiceQty, pushService, removeService } from "utils/store-helpers";
import Accounting from "accounting";
import "../../styles/components/_card.scss";

const ServiceLineCard = (props) => {
  const {
    service = {},
    loading,
    showAction = true,
    onClick,
    className = "",
    isMultipleService = false,
    booking,
    orderSummary = false,
  } = props;
  const [modal, toggleModal] = useState(false);

  const servicesList = booking?.services || [];
  const currentServiceList = servicesList?.filter(
    (item) => item?.id === service?.id
  );
  const currentService =
    currentServiceList?.length > 0 ? currentServiceList?.[0] : {};

  return (
    <LineCard
      photo={service.photo}
      title={service.title}
      // line1={service.displayPrice}
      line1={
        !isMultipleService && !orderSummary
          ? service?.displayPrice
          : orderSummary
          ? Accounting.formatMoney(service?.originalPrice || 0)
          : Accounting.formatMoney(currentService?.price || service?.price || 0)
      }
      showAction={showAction}
      onClick={() => {
        // if (!isMultipleService) {
        onClick(service.id);
        // }
      }}
      className={className}
    >
      {isMultipleService ? (
        getServiceQty(booking, "services", service) <= 0 ? (
          <div
            className="multiple_product_selection"
            onClick={() => {
              pushService(booking, "services", service);
            }}
          >
            <div className="inner_container_multiple_select">
              <h4 className="add_content">Add </h4>
              <FalIcon icon="plus" size={16} />
            </div>
          </div>
        ) : (
          <div className="multiple_product_selection">
            <div className="multiple_select_container">
              <div
                onClick={() => {
                  removeService(booking, "services", service);
                }}
                className="icon_styles"
              >
                <FalIcon icon="minus" size={16} />
              </div>
              <h4 className="plus_icons_styles">
                {" " + getServiceQty(booking, "services", service) + " "}
              </h4>
              <div
                className="icon_styles"
                onClick={() => {
                  pushService(booking, "services", service);
                }}
              >
                <FalIcon icon="plus" size={16} />
              </div>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
      {orderSummary && service?.quantity ? (
        <h6 className="subtitle">{`x ${service?.quantity}`}</h6>
      ) : null}
    </LineCard>
  );
};

const ServiceListCard = (props) => {
  const { service = {}, selectedIDs, loading, onClick, className = "" } = props;
  const [modal, toggleModal] = useState(false);

  return (
    <Fragment>
      <Modal isOpen={modal} className="sm">
        <ServicePreview
          model={service}
          toggleModal={() => toggleModal(!modal)}
        />
      </Modal>
      <ListCard
        status={service.status}
        photo={service.photo}
        title={service.title}
        subtitle={service.displayPrice + service.displayDuration}
        link={service.url}
        className={className}
      />
    </Fragment>
  );
};

const ServiceMktCard = (props) => {
  const { service = {}, selectedIDs, loading, onClick, className = "" } = props;
  const [modal, toggleModal] = useState(false);

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)} className="sm">
        <ServicePreview
          type={"mkt"}
          model={service}
          toggleModal={() => toggleModal(!modal)}
        >
          <Link to="/book" className="btn btn-primary btn-lg btn-block">
            Book Now
            <FAWrapper icon="chevron-right" className="ml-2" />
          </Link>
        </ServicePreview>
      </Modal>
      <MktCard
        status={service.status}
        photo={service.photo}
        title={service.title}
        subtitle={service.displayPrice + "+ " + service.displayDuration}
        line1={service.description && truncate(service.description, 60, true)}
        className="no-b"
      >
        <TextButton
          title="Learn More"
          endIcon="chevron-right"
          onClick={() => toggleModal(!modal)}
        />
      </MktCard>
    </Fragment>
  );
};

const ServicePreviewCard = (props) => {
  const { service = {}, selectedIDs, loading, onClick, className = "" } = props;
  const [modal, toggleModal] = useState(false);

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)} className="sm">
        <ServicePreview
          model={service}
          showLocalData={props?.showLocalData}
          toggleModal={() => toggleModal(!modal)}
        />
      </Modal>
      <PreviewCard
        status={service.status}
        photo={service.photo}
        title={service.title}
        subtitle={service.displayPrice + service.displayDuration}
        onClick={() => toggleModal(!modal)}
        className={className}
      >
        {service?.quantity ? <h6>{`x ${service?.quantity}`}</h6> : null}
      </PreviewCard>
    </Fragment>
  );
};

const ServiceSelectCard = (props) => {
  const {
    service = {},
    selectedIDs = [],
    loading,
    onClick,
    className = "",
  } = props;
  const [modal, toggleModal] = useState(false);
  const selected = selectedIDs.includes(service.id) ? true : false;

  return (
    <Fragment>
      <Modal isOpen={modal} className="sm">
        <ServicePreview
          model={service}
          showLocalData={props?.showLocalData}
          toggleModal={() => toggleModal(!modal)}
        />
      </Modal>
      <SelectCard
        status={service.status}
        photo={service.photo}
        title={service.title}
        subtitle={service.displayPrice + service.displayDuration}
        line2={service.previewDescription}
        selected={selected}
        editBooking={props?.editBooking}
        booking={props?.booking}
        service={props?.service}
        onSelect={() => onClick(service.id)}
        className={className}
      >
        <TextButton
          title="Learn More"
          endIcon="chevron-right"
          onClick={() => toggleModal(!modal)}
        />
      </SelectCard>
    </Fragment>
  );
};

export {
  ServiceLineCard,
  ServiceListCard,
  ServiceMktCard,
  ServicePreviewCard,
  ServiceSelectCard,
};
