import React from 'react';
import Empty from 'components/misc/empty';
import { LicenseGridCard } from 'components/license/license-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col, Button } from 'reactstrap';


const LicenseList = (props) => {
  const { type = 'list', licenses = [], provider, nextAction, loading, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (licenses.length > 0) {
    return (
      <Row>
        {type == 'grid' && licenses.map(license => (
          <Col sm='6' md='4' key={license.id}>
            <LicenseGridCard
              type={type}
              license={license}
              nextAction={nextAction}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='id-badge'
      title='Licenses'
      msg="Please upload a copy of your cosmetology and insurance for account approval."
    />
  )
}


export default LicenseList;
