import React, { Fragment, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { withCheckout } from "contexts/checkout-context";
import ProviderList from "components/provider/provider-list";
import LoadingIndicator from "components/loading/loading-indicator";
import { PrevButton, NextButton } from "components/bootstrap/button-wrapper";
import {
  SectionPreBlock,
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import { isEmpty } from "utils/helpers";
import { setProp } from "utils/store-helpers";
import { getTimezoneFromLatLong } from "utils/common/commonFunctions";

const BookingFlowProviders = (props) => {
  const { booking, session, store, toast, prevAction, nextAction, checkout } =
    props;
  const [loading, setLoading] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);
  const [providers, setProviders] = useState([]);

  // Hooks
  useEffect(() => {
    fetchData();
  }, []);

  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      store.adapterFor("provider").set("action", "availability");

      let getTotalServiceTime = booking.previewDuration;

      let clonedMoment = booking.endDate.clone();

      let newClonedProvidersEndDate = clonedMoment.add(
        getTotalServiceTime,
        "minutes"
      );

      const timezone = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude, true);

      let providers = await store.query("provider", {
        status: "active",
        startDate: booking.startDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: newClonedProvidersEndDate.format("YYYY-MM-DD HH:mm:ss"),
        latitude: booking.location.latitude,
        longitude: booking.location.longitude,
        serviceIds: booking.serviceIds.join(","),
        timezone: timezone,
        include: "location,photos,reviews",
      });
      store.adapterFor("provider").set("action", null);
      setProviders(providers);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  };

  const setProvider = (providerId) => {
    let provider = store.peekRecord("provider", providerId);
    setProp(booking, "provider", provider);
    checkout.toggleMenu(!checkout.menu);
  };

  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title="Back" onClick={prevAction} />
      </SectionPreBlock>
      <SectionBlock>
        <SectionTitle title="Select provider" />
        <SectionBody className="md">
          <ProviderList
            type="select"
            status="active"
            providers={providers}
            selectedID={booking.provider.id}
            loading={loading}
            onClick={(providerId) => setProvider(providerId)}
          />
        </SectionBody>

        <SectionFooter className="flex-between">
          <NextButton
            title="Next"
            taskRunning={taskRunning}
            disabled={isEmpty(booking.provider)}
            onClick={nextAction}
            className="btn-block btn-lg"
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  );
};

export default withCheckout(BookingFlowProviders);
