import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import LocalStorage from 'local-storage';
import { withSession } from 'contexts/session-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Modal, ModalHeader, ModalHeaderImg, ModalBody, ModalFooter } from 'components/basics/modals';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { TextButton, NextButton } from 'components/bootstrap/button-wrapper';
import { isEmpty, logger, timeout } from 'utils/helpers';
import { HpHero } from 'utils/assets/headers';
import MktApi from 'apis/mkt-api';

const MktSubscription = (props) => {
	const { session, store, toast } = props;
	const [ firstName, setFirstName ] = useState('');
	const [ lastName, setLastName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ modal, toggleModal ] = useState(false);
	const [ taskRunning, setTaskRunning ] = useState(false);

	// Hooks
	useEffect(() => {
		init();
	}, [])



	// Methods
	const init = async () => {
		await timeout(3000);
		let user = session.isAuthenticated();
		let cookie = LocalStorage.get('subscribeViewed');
		if (!user && !cookie) {
			toggleModal(true);
		}
	}


	const saveSubscription = async () => {
		try {
			setTaskRunning(true);
			await MktApi.mailchimp({ firstName: firstName, lastName: lastName, email: email });
			toast.showSuccess('You are subscribed!');
			toggleModal(!modal);
			LocalStorage.set('subscribeViewed', true);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	}

	const denyEmail = () => {
		LocalStorage.set('subscribeViewed', true);
		toggleModal(!modal);
	}


	// Render
	return (
		<Modal isOpen={modal} toggle={() => denyEmail()}>
			<ModalHeaderImg>
				<img alt='Blush Hair Style' src={HpHero} />
				<TextButton color='gray' onClick={() => denyEmail()}><FAWrapper icon='times'/></TextButton>
			</ModalHeaderImg>
			<ModalBody>
				<h3>Welcome to Blush!</h3>
				<h6>Join our newsletter to receive company news, beauty education and giveaways!</h6>
				<hr />
				<Row>
					<Col xs='12' sm='6'>
						<FormGroup>
							<Label for='first'>First Name</Label>
							<Input
								type='text'
								name='firstName'
								value={firstName}
								onChange={e => setFirstName(e.target.value)}
								placeholder='Kate'
							/>
						</FormGroup>
					</Col>
					<Col xs='12' sm='6'>
						<FormGroup>
							<Label for='first'>Last Name</Label>
							<Input
								type='text'
								name='email'
								value={lastName}
								onChange={e => setLastName(e.target.value)}
								placeholder='Moss'
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for='first'>Your Email Address</Label>
					<Input
						type='text'
						name='email'
						value={email}
						onChange={e => setEmail(e.target.value)}
						placeholder='kate@moss.com'
					/>
				</FormGroup>
			</ModalBody>
			<ModalFooter>
      	<NextButton
      		icon='check'
          title='Save'
          taskRunning={taskRunning}
          onClick={() => saveSubscription()}
          className='btn-block btn-lg'
        />
			</ModalFooter>
		</Modal>
	)
}

export default withSession(withStore(withToast(MktSubscription)));
