import React, { useState } from 'react';
import { Progress } from 'reactstrap';

const ProgressBarWrapper = (props) => {
	const { step = {} } = props;

	return (
		<div className='progress-bar-wrapper'>
			<Progress value={step.value} />
		</div>
	)
}

export default ProgressBarWrapper;

// Docs
