import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { ActivityActivityCard } from 'components/activity/activity-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const ActivityList = (props) => {
  const { type = 'list', activities = [], loading, className = '' } = props;

  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (activities.length > 0) {
    return (
      <Row>
        {type == 'list' && activities.map(activity => (
          <Col md='12' key={activity.id}>
            <ActivityActivityCard
              type={type}
              activity={activity}
              loading={loading}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='bolt'
      title='Recent Activity'
      msg="Sorry, we couldn't find any recent activity on your account."
    />
  )
}


export default ActivityList;
