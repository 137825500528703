import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from 'components/auth/register-form';
import LoginForm from 'components/auth/login-form';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Container, Button, FormGroup, Label, Input } from 'reactstrap';

const ReviewFlowAuth = (props) => {
  const { session, prevAction, nextAction } = props;
  const [ showRegister, setShowRegister ] = useState(false)

	// Render
  return (
    <Container className='xs'>
      <SectionBlock>
        {showRegister ? <SectionTitle title='Register to continue'/> : <SectionTitle title='Login to continue'/>}
        <SectionBody className='basic'>
          {showRegister ? (
            <RegisterForm
              userID={session.userId}
              flow={true}
              toggleView={() => setShowRegister(!showRegister)}
              nextAction={() => null}
            />
          ) : (
            <LoginForm
              userID={session.userId}
              flow={true}
              toggleView={() => setShowRegister(!showRegister)}
              nextAction={() => null}
            />
          )}
        </SectionBody>
      </SectionBlock>
    </Container>
  )
}

export default ReviewFlowAuth;
