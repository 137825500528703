import AppModel from 'models/app';
import { Amex, ApplePay, Discover, GooglePay, Paypal, MasterCard, Visa } from 'utils/assets/cards';

class Card extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		

		// Attrs
		this.token = props.token;
		this.name = props.name;
		this.brand = props.brand;
		this.lastFour = props.lastFour;
		this.expMonth = props.expMonth;
		this.expYear = props.expYear;
		this.default = props.default;


		// Keys
		this.userId = props.userId;


		// Relationships
		this.booking = this.belongsTo('booking', props.booking);
		this.user = this.belongsTo('user', props.user);
	}

	
	// Computed
	get summary() {
		return this.brand  + ' ••••' + this.lastFour.toString();
	}

	get shortSummary() {
		return '•••• ' + this.lastFour.toString();
	}

	get expiration() {
		return this.expMonth + '/' + this.expYear;
	}

	get cardImg() {
		if(this.get('brand') == 'Visa') {
			return Visa;
		}
		if(this.get('brand') == 'Mastercard') {
			return MasterCard;
		}
		if(this.get('brand') == 'Discover') {
			return Discover;
		}
		if(this.get('brand') == 'Amex') {
			return Amex;
		}
		if(this.get('brand') == 'Paypal') {
			return Paypal;
		}
		if(this.get('brand') == 'Google-pay') {
			return GooglePay;
		}
		if(this.get('brand') == 'Apple-pay') {
			return ApplePay;
		}
	}


	
}

export default Card;