import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { BlogGridCard, BlogPreviewCard } from 'components/blog/blog-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col } from 'reactstrap';
import { timeout, isEmpty } from 'utils/helpers';

const BlogList = (props) => {
  const { type = 'list', articles = [], loading, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (articles.length > 0) {
    return (
      <Row>
        {type == 'preview' && articles.map(article => (
          <Col md='12' key={article.slug}>
            <BlogPreviewCard
              type={type}
              article={article}
              className={className}
            />
          </Col>
        ))}
        {type == 'grid' && articles.map(article => (
          <Col md='12' key={article.slug}>
            <BlogGridCard
              type={type}
              article={article}
              className={className}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='newspaper'
      title='Articles'
      msg="Sorry, we're couldn't find any recent articles. Please check back later."
    />
  )
}

export default BlogList;
