import AppModel from 'models/app';

class Notification extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		

		// Attrs
		this.title = props.title;
		this.description = props.description;

		// Relationships
		this.user = this.belongsTo('user', props.user);
	}

}

export default Notification;