import SentryService from 'services/sentry-service';
import { isEmpty, logger } from 'utils/helpers';

class JsonApiError {

  static formatErrors(e) {
    let formattedError;
    if (e.response) {
      formattedError = this.serverErrors(e.response.data.errors);

    } else if (e.request) {
      SentryService.captureCriticalException(e);
      formattedError = this.miscError(500, 'Request Error', e);

    } else {
      SentryService.captureCriticalException(e);
      formattedError = this.miscError(500, 'Misc Error', e.message);

    }
    return formattedError;
  }

  static miscError(status, title, detail) {
    return [{ status: status, title: title, detail: detail }];
  }

  static serverErrors(errors) {
    let formattedErrors = [];

    errors.forEach(error => {
      error.detail = error.detail.replace(/:/g, '');
      formattedErrors.push(error);
    });
    return formattedErrors;
  }
}

export default JsonApiError;
