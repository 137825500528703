import React, { useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import { withSession } from 'contexts/session-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import AppCard from 'components/app/app-card';
import { TextButton, BasicButton } from 'components/bootstrap/button-wrapper';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const AdminSettingsIndexRoute =(props) => {
  const { history, providerId, provider, session, toast } = props;
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Methods
  const logout = async () => {
    try {
      setTaskRunning(true);
      session.logout();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(true);
    }
  }


  const deactivateAccount = () => {
    let confirm = window.confirm(`Are you sure you'd like to deactivate your account?`);
    if (confirm) { this.logout() }
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Dashboard'>

      <Breadcrumb>
        <BreadcrumbItem>Settings</BreadcrumbItem>
      </Breadcrumb>

      <Row>
        <Col xs={12}>
          <AppCard
            icon='user-circle'
            title='Profile'
            subtitle='Edit your public-facing profile.'
            link='settings/profile'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='university'
            title='Merchant'
            subtitle='Manage your merchant account so we can process payments on your behalf.'
            link='settings/merchant'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='address-card'
            title='Licensing'
            subtitle='Upload and manage your licenses to keep your account in good standing.'
            link='settings/licensing'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='cut'
            title='Services'
            subtitle="Select which services you'd like to offer customers."
            link='settings/services'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='calendar-week'
            title='Schedule'
            subtitle="Manage your business hours and vacation time from here."
            link='settings/schedule'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='piggy-bank'
            title='Banking'
            subtitle="Add your banking information so we can send money to your account."
            link='settings/banking'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='images'
            title='Photos'
            subtitle="Upload photos that showcase your work."
            link='settings/photos'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='bells'
            title='Notifications'
            subtitle="Manage which notifications you'd like to receive and where."
            link='settings/notifications'
          />
        </Col>

        <Col xs={12}>
          <AppCard
            icon='trash'
            title='Deactivate Account'
            subtitle="Tired of Blush? Deactivate your account from here.">
            <BasicButton title='Deactivate Now' onClick={() => deactivateAccount()}/>
          </AppCard>
        </Col>

      </Row>
    </AdminRoute>
  );
}

export default withAdmin(withSession(withToast(AdminSettingsIndexRoute)));
