import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SearchResults from 'components/search/search-results';
import AlgoliaService from 'services/algolia-service';
import FAWrapper from 'components/vendors/fa-wrapper';
import { timeout, randomString } from 'utils/helpers';
import { Form, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

const SearchWrapper = (props) => {
  const { providerId, history, location } = props;
  const [ query, setQuery ] = useState('');
  const [ results, setResults ] = useState([]);
  const [ path, setPathName ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ highlightIndex, setHighlightIndex ] = useState(-1);
  const algolia = new AlgoliaService();


  // Hooks
  useEffect(() => {
    query.length >= 3 && searchData(query);
  }, [query]);

  useEffect(() => {
    path != location.pathname && clearSearch();
    setPathName(location.pathname)
  }, [location.pathname])


	// Methods
	const searchData = async () => {
		try {
      setLoading(true);
      let model = await algolia.queryIndices(providerId, query);
			setResults(model);
		} catch(e) {
			console.log(e);
		} finally {
      setLoading(false);
		}
	}

	const handleKeyDown = (e) => {
		if (e.keyCode == 38 && highlightIndex > -1) {
      console.log('Up key pressed');
      setHighlightIndex(highlightIndex - 1);
			console.log('New HighlightIndex: ', highlightIndex);
		}
		if (e.keyCode == 40 && highlightIndex < hits.length -1) {
      console.log('Down key pressed');
      setHighlightIndex(highlightIndex + 1);
			console.log('New HighlightIndex: ', highlightIndex);
		}
	}

	const clearSearch = () => {
    setQuery('');
    setResults([]);
	}


	// Render
  return (
    <div className='search-wrapper'>
      <Form className='search-form'>
        <InputGroup>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText><FAWrapper icon='search'/></InputGroupText>
          </InputGroupAddon>
          <Input
            type='search'
            name={randomString()}
            value={query}
            className='form-control search'
            placeholder='Search for anything...'
            onKeyDown={e => handleKeyDown(e.target.value)}
            onChange={e => setQuery(e.target.value)}
          />
        </InputGroup>
      </Form>
      <SearchResults
        query={query}
        results={results}
        providerId={providerId}
        highlightIndex={highlightIndex}
        loading={loading}
        nextAction={() => clearSearch()}
      />
    </div>
  );
}

export default withRouter(SearchWrapper);
