import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import LegalNav from 'components/navs/legal-nav';
import FAWrapper from 'components/vendors/fa-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';

const PrivacyRoute = (props) => {
  const { history } = props;


  // Render
  return (
    <div className='route'>
      <Head title='Blush - Privacy Policy'/>
      <MktAnnouncement />
      <MktNav />
      <MktHeader
        title='Privacy Policy'
        subtitle='Last updated September 10th, 2020'
        className='soft-gray-bg'>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>
      <main className='mkt-main'>

        <section className='mkt-section sm'>
          <div className='mkt-section-body'>
            <Container>
              <Row>

                <Col md='3'>
                  <LegalNav />
                </Col>

                <Col md={{size: 8, offset: 1}}>

                  <SectionBlock>
                    <SectionBody>
                      <p>Nickita LLC (“Company” or “We”) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes:</p>
                      <ul>
                        <li>
                          The types of information we may collect or that you may provide when you purchase, download, install, register with, access,
                          or use the Blush (the “App”) or the Company’s websites (collectively, the “Website”).
                        </li>
                        <li>b) Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
                      </ul>
                      <p>This policy applies only to information we collect in this App, in email, text, and other electronic communications sent through, or in connection with this App , and the Website. :</p>
                      <p>This policy DOES NOT apply to information that:</p>
                      <ul>
                        <li>We collect offline or on any other Company apps or websites, including websites you may access through this App.</li>
                        <li>You provide to or is collected by any third party (see Third-Party Information Collection).</li>
                      </ul>
                      <p>Our websites and apps, and these other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.</p>
                      <p>
                        Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it.
                        If you do not agree with our policies and practices, do not download, register with, or use this App or the services offered therein.
                        By downloading, registering with, or using this App, you agree to this privacy policy.
                        This policy may change from time to time (see Changes to Our Privacy Policy).
                        Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.
                      </p>

                      <hr/>

                      <h4>1. Children Under the Age of 16</h4>
                      <p>
                        The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16.
                        If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information.
                        If you believe we might have any information from or about a child under 16, please contact us at nikita@blush-now.com
                      </p>
                      <p>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information.</p>

                      <hr/>

                      <h4>2. Information We Collect and How We Collect It</h4>
                      <p>We collect information from and about users of our App and our Website:</p>
                      <ul>
                        <li>Directly from you when you provide it to us</li>
                        <li>Automatically when you use the App or the Websites.</li>
                      </ul>

                      <h5><i>Information You Provide to Us</i></h5>
                      <p>When you download, register with, or use this App or the Website, we may ask you provide information:</p>
                      <ul>
                        <li>
                          By which you may be personally identified, such as name, postal address, email address, telephone number any other information the
                          App or the Website collects that is defined as personal or personally identifiable information under applicable law (“personal information”).
                        </li>
                        <li>That is about you but individually does not identify you, such as reviews or postings within the App or the Website.</li>
                      </ul>
                      <p>This information includes:</p>
                      <ul>
                        <li>
                          Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App or the Website,
                          and subscribing to our service, and posting material, and requesting further services. We may also ask you for information when you enter a contest or
                          promotion sponsored by us, and when you report a problem with the App.
                        </li>
                        <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
                        <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                        <li>
                          Details of transactions you carry out through the App or the Website and of the fulfillment of your orders.
                          You may be required to provide financial information before placing an order through the App or the Website.
                        </li>
                        <li>Your search queries on the App or the Website.</li>
                      </ul>
                      <p>
                        You may also provide information for publication or display (“Posted”) on public areas of the App, the Website, or websites that you access through the App or the Website
                        (collectively, “User Contributions”). Your User Contributions are Posted and transmitted to others at your own risk. Additionally, we cannot control the actions of
                        third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by
                        unauthorized persons.
                      </p>

                      <h5><i>Automatic Information Collection and Tracking</i></h5>
                      <p>When you download, access, and use the App, it may use technology to automatically collect</p>
                      <ul>
                        <li>
                          <h6>Usage Details.</h6>
                          <p>When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.</p>
                        </li>
                        <li>
                          <h6>Device Information.</h6>
                          <p>We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</p>
                        </li>
                        <li>
                          <h6>Stored Information and Files.</h6>
                          <p>The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</p>
                        </li>
                        <li>
                          <h6>Location Information.</h6>
                          <p>This App collects real-time information about the location of your device.</p>
                        </li>
                      </ul>
                      <p>If you do not want us to collect this information do not download the App or delete it from your device. For more information, see Your Choices About How We Use and Disclose Your Information). Note, however, that opting out of the App’s collection of location information will disable its location-based features.</p>
                      <p>
                        We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking).
                        You may opt out of the location data collection by changing the privacy settings within the App.  However, opting out of this may impact your use of the App and the related services.
                      </p>

                      <h5><i>Information Collection and Tracking Technologies</i></h5>
                      <p>The technologies we use for automatic information collection may include:</p>
                      <ul>
                        <li>
                          <h6>Cookies (or mobile cookies).</h6>
                          <p>A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</p>
                        </li>
                        <li>
                          <h6>Web Beacons.</h6>
                          <p>
                            Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
                            for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and
                            verifying system and server integrity).
                          </p>
                        </li>
                      </ul>

                      <h5><i>Third-Party Information Collection</i></h5>
                      <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
                      <ul>
                        <li>Advertisers, ad networks, and ad servers.</li>
                        <li>Analytics companies.</li>
                        <li>Your mobile device manufacturer.</li>
                        <li>Your mobile service provider.</li>
                      </ul>
                      <p>
                        These third parties may use tracking technologies to collect information about you when you use this app.
                        The information they collect may be associated with your personal information or they may collect information, including personal information,
                        about your online activities over time and across different websites, apps, and other online services websites.
                        They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
                      </p>
                      <p>
                        We do not control these third parties’ tracking technologies or how they may be used.
                        If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
                        For information about how you can opt out of receiving targeted advertising from many providers,
                        see Your Choices About Our Collection, Use, and Disclosure of Your Information.
                      </p>

                      <hr/>

                      <h4>3. How we use your information.</h4>
                      <p>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
                      <ul>
                        <li>Provide you with the App, the Website and their content, and any other information, products or services that you request from us.</li>
                        <li>Fulfill any other purpose for which you provide it.</li>
                        <li>Give you notices about your account/subscription, including expiration and renewal notices.</li>
                        <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                        <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
                      </ul>

                      <p>The usage information we collect helps us to improve our App or Website and to deliver a better and more personalized experience by enabling us to:</p>
                      <ul>
                        <li>Estimate our audience size and usage patterns.</li>
                        <li>Store information about your preferences, allowing us to customize our App and Website according to your individual interests.</li>
                        <li>Speed up your searches.</li>
                        <li>Recognize you when you use the App.</li>
                      </ul>

                      <p>We primarily use location information we collect to best match our customers and Blush Professionals.</p>
                      <p>
                        We may also use your information to contact you about our own and third parties’ goods and services that may be of interest to you.
                        If you do not want us to use your information in this way, please adjust your user preferences in your account profile.
                        For more information, see Your Choices About Our Collection, Use, and Disclosure of Your Information.
                      </p>
                      <p>
                        We may use the information we collect to display advertisements to our advertisers’ target audiences.
                        Even though we do not disclose your personal information for these purposes without your consent,
                        if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.
                      </p>

                      <hr/>

                      <h4>Disclosure of Your Information</h4>
                      <p>We may disclose aggregated information about our users, and information that does not identify any individual without restriction.</p>
                      <p>In addition, we may disclose personal information that we collect or you provide:</p>
                      <ul>
                        <li>To our subsidiaries and affiliates.</li>
                        <li>To contractors, service providers, and other third parties we use to support our business.</li>
                        <li>
                          To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution,
                          or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy,
                          liquidation, or similar proceeding, in which personal information held by the Company about our App users or Website
                          users is among the assets transferred.
                        </li>
                        <li>
                          To third parties to market their products or services to you if you have consented to/not opted out of these disclosures.
                          We contractually require these third parties to keep personal information confidential and use it only for the purposes
                          for which we disclose it to them. For more information, see Your Choices About Our Collection, Use, and Disclosure of Your Information.
                        </li>
                        <li>
                          To fulfill the purpose for which you provide it. For example, if you give us an email address to use the “email a friend”
                          feature of our Website or App, we will transmit the contents of that email and your email address to the recipients.
                        </li>
                        <li>For any other purpose disclosed by us when you provide the information.</li>
                        <li>With your consent.</li>
                        <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                        <li>To enforce our rights arising from any contracts entered into between you and us, including the Terms of Services, and for billing and collection.</li>
                        <li>
                          If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, our stylists, or others.
                          This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                        </li>
                      </ul>

                      <hr/>

                      <h4>Your Choices About Our Collection, Use, and Disclosure of Your Information</h4>
                      <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information. </p>
                      <ul>
                        <li>
                          <h6>Tracking Technologies.</h6>
                          <p>
                            You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent.
                            If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App or the Website may
                            then be inaccessible or not function properly.
                          </p>
                        </li>
                        <li>
                          <h6>Location Information.</h6>
                          <p>
                            You can choose whether or not to allow the App to collect and use real-time information about your device’s
                            location through the device’s privacy settings. If you block the use of location information, some parts of the
                            App may become inaccessible or not function properly.
                          </p>
                        </li>
                        <li>
                          <h6>Promotion by the Company.</h6>
                          <p>
                            If you do not want us to use your email address or phone number to promote our own or third parties’ products or services,
                            you can opt-out by texting “STOP” to any SMS communications you receive from us or by clicking on “Unsubscribe” at the bottom
                            of any email communication you receive from us.
                          </p>
                        </li>
                      </ul>
                      <p>
                        We do not control third parties’ collection or use of your information to serve interest-based advertising.
                        However these third parties may provide you with ways to choose not to have your information collected or used in this way.
                        You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website.
                      </p>

                      <hr/>

                      <h4>Data Security</h4>
                      <p>
                        We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access,
                        use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.
                        Any payment will be encrypted.
                      </p>
                      <p>
                        The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a
                        password for access to certain parts of our App, you are responsible for keeping this password confidential.
                        We ask you not to share your password with anyone. We urge you to be careful about giving out information in
                        public areas of the App like message boards. The information you share in public areas may be viewed by any user of the App.
                      </p>
                      <p>
                        Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure.
                        Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App.
                        Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
                      </p>

                      <hr/>

                      <h4>Changes to our Privacy Policy</h4>
                      <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page.</p>

                      <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable nikita@blush-now.com for you and for periodically visiting this privacy policy to check for any changes.</p>

                      <hr/>

                      <h4>Contact Information</h4>
                      <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a href='mailto:nikita@blush-now.com' target='_blank'>nikita@blush-now.com</a></p>.


                    </SectionBody>
                  </SectionBlock>

                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

      </main>
      <MktFooter />

    </div>
  );
}

export default PrivacyRoute;
