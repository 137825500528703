import React, { useEffect, useState } from "react";

import LoadingIndicator from "components/loading/loading-indicator";
import FAWrapper from "components/vendors/fa-wrapper";
import { SectionTitle, SectionBody } from "components/blocks/section-blocks";
import { CustomInput, Button, Spinner } from "reactstrap";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import { ClockTime } from "assets/svgJS/clock-time";
import Axios from "utils/axios";

import { useParams } from "react-router-dom";

const UpdateAvailbility = (props) => {
  const {
    modal,
    toggleModal,
    schedule,
    nextAction,
    toast,
    provider,
    dateSelectedFromCalendar,
  } = props;
  const [datesArray, setDatesArray] = useState([]);

  const [startDate, setStartDate] = useState(
    dateSelectedFromCalendar ? dateSelectedFromCalendar : new Date()
  );

  const [headerStartDate, setHeaderStartDate] = useState(null);
  const [headerEndDate, setHeaderEndDate] = useState(null);
  const [miniCalendarSelectedDate, setMiniCalendarSelectedDate] = useState(
    new Date()
  );

  const [timeSlots, setTimeSlots] = useState([
    {
      displayText: "07:00 AM - 08:00 AM",
      startTime: "07:00:00",
      endTime: "08:00:00",
    },
    {
      displayText: "08:00 AM - 09:00 AM",
      startTime: "08:00:00",
      endTime: "09:00:00",
    },
    {
      displayText: "09:00 AM - 10:00 AM",
      startTime: "09:00:00",
      endTime: "10:00:00",
    },
    {
      displayText: "10:00 AM - 11:00 AM",
      startTime: "10:00:00",
      endTime: "11:00:00",
    },

    {
      displayText: "11:00 AM - 12:00 PM",
      startTime: "11:00:00",
      endTime: "12:00:00",
    },

    {
      displayText: "12:00 PM - 01:00 PM",
      startTime: "12:00:00",
      endTime: "13:00:00",
    },

    {
      displayText: "01:00 PM - 02:00 PM",
      startTime: "13:00:00",
      endTime: "14:00:00",
    },

    {
      displayText: "02:00 PM - 03:00 PM",
      startTime: "14:00:00",
      endTime: "15:00:00",
    },
    {
      displayText: "03:00 PM - 04:00 PM",
      startTime: "15:00:00",
      endTime: "16:00:00",
    },
    {
      displayText: "04:00 PM - 05:00 PM",
      startTime: "16:00:00",
      endTime: "17:00:00",
    },
    {
      displayText: "05:00 PM - 06:00 PM",
      startTime: "17:00:00",
      endTime: "18:00:00",
    },
    {
      displayText: "06:00 PM - 07:00 PM",
      startTime: "18:00:00",
      endTime: "19:00:00",
    },
  ]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [allDayCheckBoxArr, setAllDayCheckBoxArr] = useState([]);

  const [finalAllDaySlotsToDelete, setFinalAllDaySlotsToDelete] = useState([]);
  const [finalAllDaySlotsToCreate, setFinalAllDaySlotsToCreate] = useState([]);

  const [btnLoader, setBtnLoader] = useState(false);

  const [allInitialzedDatesArr, setAllInitializedDatesArr] = useState([]);

  const { providerId } = useParams();

  useEffect(() => {
    initializeDateData(startDate);
  }, []);

  const initializeDateData = (startDate) => {
    let latestDateArr = getDateArray(startDate);

    if (
      !allInitialzedDatesArr.includes(moment(startDate).format("YYYY-MM-DD"))
    ) {
      mapSelectedSlots(latestDateArr);
      setAllInitializedDatesArr((prevDates) => {
        return [...prevDates, ...latestDateArr];
      });
    }

    setHeaderStartDate(latestDateArr[0]);
    setHeaderEndDate(latestDateArr[latestDateArr?.length - 1]);
    setDatesArray(latestDateArr);
  };

  const mapSelectedSlots = (datesArr) => {
    let firstArr = [];

    let uniqueDatesArr = [];

    //Store dates which have been modified in unquiesDatesArr
    schedule?.scheduleDate?.forEach((item) => {
      let date = moment(item?.startDate).format("YYYY-MM-DD");
      if (!uniqueDatesArr.includes(date)) {
        uniqueDatesArr.push(item?.date);
      }
    });

    //Map all dates in Calendar except the dates which are coming from Api
    datesArr?.forEach((date) => {
      if (!uniqueDatesArr.includes(date))
        timeSlots?.forEach((elem) => {
          firstArr.push({
            date: date,
            slotStart: elem?.startTime,
            slotEnd: elem?.endTime,
            displayText: elem?.displayText,
            isChecked: true,
            type: "not-verified",
          });
        });
    });

    //Map dates coming from api
    let secondArr = schedule.scheduleDate
      ?.filter((elem) => {
        //To Remove Dates which are already initialized
        let date = moment(elem?.startDate).format("YYYY-MM-DD");
        return !allInitialzedDatesArr.includes(date);
      })
      ?.map((item, i) => {
        let date = moment(item?.startDate).format("YYYY-MM-DD");
        let slotStart = moment(item?.startDate).format("HH:mm:ss");
        let slotEnd = moment(item?.endDate).format("HH:mm:ss");
        let flag = item?.isAllDay;

        return {
          date: date,
          slotStart: slotStart,
          slotEnd: slotEnd,
          displayText: `${moment(item?.startDate).format("hh:mm A")} - ${moment(
            item?.endDate
          ).format("hh:mm A")}`,
          isChecked: flag,
          type: "not-verified",
        };
      });

    //Check if there are 7am - 7pm slots
    let allDaySlotsDates = secondArr
      ?.filter((slot) => {
        if (
          slot?.slotStart === "07:00:00" &&
          slot?.slotEnd === "19:00:00" &&
          slot?.isChecked === true &&
          checkIfAnySlotHaveFalseValue(secondArr, slot.date)
        ) {
          return slot;
        }
      })
      ?.map((elem) => {
        return elem?.date;
      });

    let allDayCheckBoxArrDates = datesArr?.filter(
      (item) => !uniqueDatesArr.includes(item)
    );

    allDayCheckBoxArrDates.push(...allDaySlotsDates);

    //Remove 7am - 7pm slots if present any
    let filteredSlots = secondArr?.filter((item) => {
      if (item?.slotStart === "07:00:00" && item?.slotEnd === "19:00:00") {
        return null;
      } else return item;
    });

    setAllDayCheckBoxArr((prevDates) => {
      return [...prevDates, ...allDayCheckBoxArrDates];
    });

    setSelectedSlots((prevSlots) => {
      return [...prevSlots, ...firstArr, ...filteredSlots];
    });
  };

  const checkIfAnySlotHaveFalseValue = (arr, date) => {
    let flag = true;
    arr.forEach((slot) => {
      if (slot.date === date) {
        if (slot.isChecked === false) {
          flag = false;
          return false;
        }
      }
    });

    return flag;
  };

  const handlePrevClick = () => {
    let startDateTemp = subDays(startDate, 7);
    setStartDate(startDateTemp);
    initializeDateData(startDateTemp);
  };

  const handleNextClick = () => {
    let startDateTemp = addDays(startDate, 7);
    setStartDate(startDateTemp);
    initializeDateData(startDateTemp);
  };

  function getDateArray(date) {
    return Array(7)
      .fill(new Date(date))
      .map((el, idx) => {
        let dateTemp = new Date(el.setDate(el.getDate() - el.getDay() + idx));
        return moment(dateTemp).format("YYYY-MM-DD");
      });
  }

  const convertToUTCDateTime = (initialStartDateTime, initialEndDateTime) => {
    let utcStartDateTime = initialStartDateTime.toISOString();

    let utcStartDateTimeArr = utcStartDateTime.split("T");

    let finalDate = utcStartDateTimeArr[0];
    let finalSlotStart = utcStartDateTimeArr[1];

    let utcEndDateTime = initialEndDateTime.toISOString();
    let utcEndDateTimeArr = utcEndDateTime.split("T");

    let finalSlotEnd = utcEndDateTimeArr[1];

    return {
      finalDate,
      finalSlotStart,
      finalSlotEnd,
    };
  };

  const createDeleteAllDaySlotsFinalData = (type, flag) => {
    let finalArr = [];

    let arr = [];

    if (type === "create") {
      arr = finalAllDaySlotsToCreate;
    } else if (type === "delete") {
      arr = finalAllDaySlotsToDelete;
    }

    arr?.forEach((date) => {
      let currentSlot = {
        scheduleId: Number(schedule.id),
        date: date,
        isChecked: flag,
        slotStart: "07:00:00",
        slotEnd: "19:00:00",
      };

      let startDateTime = `${currentSlot?.date} ${currentSlot.slotStart}`;
      let initialStartDateTime = new Date(startDateTime);
      let endDateTime = `${currentSlot?.date} ${currentSlot.slotEnd}`;
      let initialEndDateTime = new Date(endDateTime);

      let obj = convertToUTCDateTime(initialStartDateTime, initialEndDateTime);
      let finalDate = obj?.finalDate;
      let finalSlotStart = obj?.finalSlotStart;
      let finalSlotEnd = obj?.finalSlotEnd;

      finalArr.push({
        scheduleId: Number(schedule.id),
        date: currentSlot?.date,
        slotStart: currentSlot.slotStart,
        slotEnd: currentSlot.slotEnd,
        isChecked: currentSlot?.isChecked,
        isAllDay: currentSlot?.isChecked,
      });
    });
    return finalArr;
  };

  const createScheduleDateApi = async () => {
    setBtnLoader(true);

    //Verified means it will be send in api payload
    let dataToSend = selectedSlots
      ?.filter((elem) => elem?.type === "verified") //To merge 7 am to 7pm and remove other data
      ?.map((item) => {
        let startDateTime = `${item?.date} ${item.slotStart}`;
        let initialStartDateTime = new Date(startDateTime);
        let endDateTime = `${item?.date} ${item.slotEnd}`;
        let initialEndDateTime = new Date(endDateTime);
        let obj = convertToUTCDateTime(
          initialStartDateTime,
          initialEndDateTime
        );
        let finalDate = obj?.finalDate;
        let finalSlotStart = obj?.finalSlotStart;
        let finalSlotEnd = obj?.finalSlotEnd;

        return {
          scheduleId: Number(schedule.id),
          date: item?.date,
          slotStart: item.slotStart,
          slotEnd: item?.slotEnd,
          isChecked: item?.isChecked,
          isAllDay: item?.isChecked,
        };
      });

    // createDelete 7amto7pm slot
    // Add 7am- 7pm  slots which are unchecked

    if (finalAllDaySlotsToDelete?.length > 0) {
      let arr = createDeleteAllDaySlotsFinalData("delete", false);
      dataToSend.push(...arr);
    }

    if (finalAllDaySlotsToCreate?.length > 0) {
      let arr = createDeleteAllDaySlotsFinalData("create", true);

      dataToSend.push(...arr);
    }

    console.log("dataToSend", dataToSend);

    let data = {
      data: {
        attributes: {
          schedule: dataToSend,
        },
      },
    };
    try {
      let axios = new Axios().instance();
      let response = await axios.post(
        `/providers/${providerId}/scheduletime`,
        data
      );

      nextAction();
      toast.showSuccess("Schedule updated");
      toggleModal();
    } catch (e) {
      throw toast.showError(e);
    }
    setBtnLoader(false);
  };

  const handleCheckboxChange = (date, time, checked) => {
    if (checked) {
      setSelectedSlots((prevData) => {
        let updatedSlots = prevData?.map((slot) => {
          if (
            slot?.date === date &&
            slot?.slotStart === time?.startTime &&
            slot?.slotEnd === time?.endTime
          ) {
            return {
              ...slot,
              isChecked: checked,
              type: "verified",
            };
          } else return slot;
        });

        return updatedSlots;
      });

      setFinalAllDaySlotsToDelete((prevDates) => {
        let newDates = prevDates?.filter((dateToCompare) => {
          if (dateToCompare !== date) {
            return dateToCompare;
          }
        });
        return newDates;
      });
    } else {
      setSelectedSlots((prevData) => {
        return prevData?.map((item) => {
          let date2 = item?.date;
          let slotStart = item?.slotStart;
          let slotEnd = item?.slotEnd;

          if (date === date2) {
            if (time?.startTime === slotStart && time?.endTime === slotEnd) {
              return {
                ...item,
                isChecked: false,
                type: "verified",
              };
            } else {
              return {
                ...item,
                type: "verified",
              };
            }
          } else return item;
        });
      });

      setFinalAllDaySlotsToCreate((prevData2) => {
        let newDates = prevData2?.filter((dateToCheck) => {
          if (dateToCheck !== date) {
            return dateToCheck;
          }
        });
        return newDates;
      });

      setAllDayCheckBoxArr((prevDates) =>
        prevDates?.filter((item) => item !== date)
      );
    }
  };

  const handleAllDaySelected = (date, checked) => {
    if (checked) {
      //Below logic is to remove slots and add new list so that all the slots are craeted for this date

      let updatedSelectedSlots = selectedSlots?.map((item) => {
        if (item?.date === date) {
          return {
            ...item,
            isChecked: true,
            type: "verified",
          };
        } else {
          return item;
        }
      });

      setSelectedSlots(updatedSelectedSlots);

      setAllDayCheckBoxArr((prevData1) => {
        setFinalAllDaySlotsToCreate((prevData2) => {
          return [...prevData2, date];
        });
        return [...prevData1, date];
      });

      //Remove date from FinalAllDaySlotsToDelete
      setFinalAllDaySlotsToDelete((prevDates) => {
        let newDates = prevDates?.filter((dateToCompare) => {
          if (dateToCompare !== date) {
            return dateToCompare;
          }
        });
        return newDates;
      });
    } else {
      let updatedSelectedSlots = selectedSlots?.map((item) => {
        if (item?.date === date) {
          return {
            ...item,
            isChecked: false,
            type: "verified",
          };
        } else {
          return item;
        }
      });

      setSelectedSlots(updatedSelectedSlots);
      setFinalAllDaySlotsToDelete((prevSlots) => {
        let slots = prevSlots;

        slots.push(date);
        return slots;
      });
      filterOutAllDayCheckBoxArr(date);
    }

    //Remove date from FinalAllDaySlotsToCreate
    setFinalAllDaySlotsToCreate((prevDates) => {
      let newDates = prevDates?.filter((dateToCompare) => {
        if (dateToCompare !== date) {
          return dateToCompare;
        }
      });
      return newDates;
    });
  };

  const handleSelectDropDownMonthChange = (val) => {
    initializeDateData(new Date(val)); //changing calendar to 1st of the selected Month
    setMiniCalendarSelectedDate(val);
  };

  const filterOutAllDayCheckBoxArr = (date) => {
    setAllDayCheckBoxArr((prevData) =>
      prevData?.filter((item) => item !== date)
    );
  };

  const checkIfSlotIsChecked = (startTime, endTime, date) => {
    return (
      selectedSlots?.filter((elem) => {
        if (
          elem?.slotStart === startTime &&
          elem?.slotEnd === endTime &&
          elem?.date === date &&
          elem?.isChecked === true
        ) {
          return elem;
        }
      }).length > 0
    );
  };

  return (
    <div className="">
      <SectionTitle className="flex-between">
        <h4>UPDATE AVAILABILITY</h4>
        {modal && (
          <Button color="text-gray" onClick={toggleModal}>
            <FAWrapper icon="times" />
          </Button>
        )}
      </SectionTitle>

      <SectionBody className="update-availability-section-body">
        <div className="flex-between">
          <div className="flex">
            <div
              className="update-availbility-prev-click"
              onClick={handlePrevClick}
            >
              <FAWrapper icon="chevron-left" />
            </div>
            <div
              className="update-availbility-next-click"
              onClick={handleNextClick}
            >
              <FAWrapper icon="chevron-right" />
            </div>
          </div>

          {headerStartDate && headerEndDate ? (
            <h4 className="update-availbility-header-dates">
              <div className="react-date-picker-container">
                <input
                  value={miniCalendarSelectedDate}
                  onChange={(e) => {
                    handleSelectDropDownMonthChange(e.target.value);
                  }}
                  type="date"
                ></input>
              </div>
              {moment(headerStartDate).format("MMMM Do")} -{" "}
              {moment(headerEndDate).format("Do MMMM, yyyy")}
            </h4>
          ) : null}

          <Button
            onClick={() => {
              createScheduleDateApi();
            }}
            className="update-availbility-save-btn"
            disabled={btnLoader}
            color="primary"
          >
            {btnLoader ? (
              <>
                <span>Saving...</span>
              </>
            ) : (
              <span>Save</span>
            )}
          </Button>
        </div>

        <table className="update-availbility-table">
          <thead>
            <tr>
              <th>
                <ClockTime />
              </th>

              {days &&
                days?.map((day, dayIndex) => (
                  <th key={day}>
                    <div className="flex-column">
                      <span>{day}</span>
                      {datesArray &&
                        datesArray?.map((date, dateIndex) => {
                          if (dayIndex === dateIndex)
                            return (
                              <span key={`datesArrKey${dateIndex}`}>
                                {moment(date).format("DD")}
                              </span>
                            );
                        })}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr key={"All Day"}>
              <td>All Day</td>
              {datesArray &&
                datesArray?.map((date, dateIndex) => (
                  <td
                    className="update-availbility-table-td"
                    key={`${date} All Day`}
                  >
                    <input
                      type="checkbox"
                      disabled={
                        moment(date, "YYYY-MM-DD")
                          .startOf("day")
                          .isBefore(moment().startOf("day"))
                          ? true
                          : false
                      }
                      checked={allDayCheckBoxArr.includes(date)}
                      className="update-availbility-table-input-checkbox"
                      onChange={(e) => {
                        handleAllDaySelected(date, e.target.checked);
                      }}
                    />
                  </td>
                ))}
            </tr>
            {timeSlots &&
              timeSlots?.map((time, timeIndex) => (
                <tr key={time?.displayText}>
                  <td>{time?.displayText}</td>
                  {datesArray &&
                    datesArray?.map((date, dateIndex) => (
                      <td
                        className="update-availbility-table-td"
                        key={`${date} ${time?.displayText}`}
                      >
                        <input
                          type="checkbox"
                          className="update-availbility-table-input-checkbox"
                          disabled={
                            moment(date, "YYYY-MM-DD")
                              .startOf("day")
                              .isBefore(moment().startOf("day"))
                              ? true
                              : false
                          }
                          checked={checkIfSlotIsChecked(
                            time?.startTime,
                            time?.endTime,
                            date,
                            time?.displayText
                          )}
                          onChange={(e) => {
                            handleCheckboxChange(date, time, e.target.checked);
                          }}
                        />
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </SectionBody>
    </div>
  );
};

export default UpdateAvailbility;
