import React, { useEffect, useState } from 'react';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import CategoryList from 'components/category/category-list';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { pushRelation } from 'utils/store-helpers';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';

const ServiceForm = (props) => {
  const { provider, store, toast, prevAction, nextAction } = props;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('category', {
        status: 'active',
        sortProp: 'position',
        sortValue: 'asc',
        include: 'services'
      });
      setCategories(model)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const saveProvider = async () => {
    try {
      setTaskRunning(true);
      provider.set("isFromProvider", true);
      await provider.save();
      toast.showSuccess('Services saved!');
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false)
    }
  }


  // Methods
  const pushService = (serviceId) => {
    let service = store.peekRecord('service', serviceId);
    pushRelation(provider, 'services', service);
  }


  // Render
  return (
    <SectionBlock>
      <SectionTitle title='Services' />
      <SectionBody className='md'>
        <CategoryList
          type='select'
          categories={categories}
          loading={loading}
          selectedIDs={provider.serviceIds}
          onClick={serviceId => pushService(serviceId)}
        />
      </SectionBody>

      <SectionFooter className='flex-start'>
        <NextButton
          type='button'
          title='Save'
          icon='check'
          taskRunning={taskRunning}
          onClick={() => saveProvider()}
        />
      </SectionFooter>

    </SectionBlock>
  )
}


export default withAdmin(withStore(withToast(ServiceForm)));
