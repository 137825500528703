import React, { Fragment, useEffect, useState } from "react";
import CardSelect from "components/card/card-select";
import PromoSelect from "components/promo/promo-select";
import ServiceList from "components/service/service-list";
import BookingFlowPreview from "components/booking/booking-flow-preview";
import {
  BookingSummaryCard,
  BookingInfoCard,
} from "components/booking/booking-card";
import { LocationInfoCard } from "components/location/location-card";
import { ProviderPreviewCard } from "components/provider/provider-card";
import { PrevButton, NextButton } from "components/bootstrap/button-wrapper";
import {
  SectionPreBlock,
  SectionBlock,
  SectionTitle,
  SectionBody,
  SectionFooter,
} from "components/blocks/section-blocks";
import {
  PreviewBlock,
  PreviewTitle,
  PreviewBody,
  PreviewFooter,
} from "components/blocks/preview-blocks";
import { Row, Col, FormGroup, Label, Input, Modal, Button } from "reactstrap";
import { isEmpty } from "utils/helpers";
import { inputProp } from "utils/store-helpers";
import moment from "moment-timezone";
import { getTimezoneFromLatLong } from "utils/common/commonFunctions";

const BookingFlowReview = (props) => {
  const { booking, store, toast, session, changeStep, prevAction, nextAction } =
    props;
  const [taskRunning, setTaskRunning] = useState(false);

  // Hooks
  useEffect(() => {
    isEmpty(booking.user) ? booking.set("user", session.user) : null;
  }, []);

  // Methods
  const saveBooking = async () => {
    const startDateTime = booking?.startDate;
    let endDateTime = booking?.endDate;
    try {
      if (taskRunning) {
        return;
      }
      setTaskRunning(true);
      booking.set("amount", booking.get("previewAmount"));
      const getTotalServiceTime = booking.previewDuration;
      const clonedMoment = endDateTime.clone();

      const updatedEndDateTime = clonedMoment.add(
        getTotalServiceTime,
        "minutes"
      );

      const timezone = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude, true);
      const startTimezoneTime = moment.tz(startDateTime?.format('YYYY-MM-DDTHH:mm:ss'), timezone);
      const endTimezoneTime = moment.tz(updatedEndDateTime?.format('YYYY-MM-DDTHH:mm:ss'), timezone);

    const gmtStartTime = startTimezoneTime.clone().utc();
    const gmtEndTime = endTimezoneTime.clone().utc();
      booking.set("endDate", gmtEndTime);
      booking.set("startDate", gmtStartTime);
      await booking.save();
      toast.showSuccess("Booking saved!");
      nextAction();
    } catch (e) {
      toast.showError(e);
    } finally {
      booking.set("endDate", endDateTime);
      booking.set("startDate", startDateTime);
      setTaskRunning(false);
    }
  };

  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title="Back" onClick={prevAction} />
      </SectionPreBlock>
      <Row>
        <Col md={{ size: 4, order: 2 }} className="d-none d-md-block">
          <BookingFlowPreview
            type="preview"
            booking={booking}
            orderSummary={true}
            changeStep={(step) => changeStep(step)}
          />
          <div className="mt-15">
            <NextButton
              icon="check"
              title="Confirm"
              taskRunning={taskRunning}
              disabled={isEmpty(booking.card)}
              onClick={() => saveBooking()}
              className="btn-block btn-lg"
            />
          </div>
        </Col>
        <Col md={{ size: 8, order: 1 }}>
          <SectionBlock>
            <SectionTitle title="Review booking" />
            <SectionBody className="md">
              <PreviewBlock className="bb">
                <PreviewTitle title="Location" className="flex-between">
                  <Button
                    type="button"
                    color="text-gray"
                    size="xs"
                    onClick={() => changeStep("showLocation")}
                  >
                    Edit step
                  </Button>
                </PreviewTitle>
                <PreviewBody>
                  <LocationInfoCard
                    location={booking.location}
                    className="p-15"
                  />
                </PreviewBody>
              </PreviewBlock>

              <PreviewBlock className="bb">
                <PreviewTitle title="Scheduling" className="flex-between">
                  <Button
                    type="button"
                    color="text-gray"
                    size="xs"
                    onClick={() => changeStep("showSchedule")}
                  >
                    Edit step
                  </Button>
                </PreviewTitle>
                <PreviewBody>
                  <BookingInfoCard booking={booking} className="p-15" />
                </PreviewBody>
              </PreviewBlock>

              <PreviewBlock className="bb">
                <PreviewTitle title="Provider" className="flex-between">
                  <Button
                    type="button"
                    color="text-gray"
                    size="xs"
                    onClick={() => changeStep("showProviders")}
                  >
                    Edit step
                  </Button>
                </PreviewTitle>
                <PreviewBody>
                  <ProviderPreviewCard provider={booking.provider} />
                </PreviewBody>
              </PreviewBlock>

              <PreviewBlock className="bb">
                <PreviewTitle title="Billing" className="flex-between" />
                <PreviewBody>
                  {session.isAuthenticated() && (
                    <CardSelect type="preview" booking={booking} />
                  )}
                </PreviewBody>
              </PreviewBlock>

              <PreviewBlock className="bb">
                <PreviewTitle title="Promo Code" className="flex-between" />
                <PreviewBody>
                  {session.isAuthenticated() && (
                    <PromoSelect type="preview" booking={booking} />
                  )}
                </PreviewBody>
              </PreviewBlock>

              <PreviewBlock className="no-b">
                <PreviewTitle title="Notes" className="flex-between" />
                <PreviewBody>
                  <FormGroup>
                    <Input
                      id="email"
                      type="textarea"
                      rows="4"
                      defaultValue={booking.instructions}
                      onChange={(p) => inputProp(booking, "instructions", p)}
                      placeholder="Please add any special instructions for your stylist such as parking, gate code, etc..."
                    />
                  </FormGroup>
                </PreviewBody>
              </PreviewBlock>
            </SectionBody>
            <SectionFooter className="flex-between d-block d-md-none">
              <NextButton
                icon="check"
                title="Confirm"
                taskRunning={taskRunning}
                disabled={isEmpty(booking.card)}
                onClick={() => saveBooking(booking)}
                className="btn-block btn-lg"
              />
            </SectionFooter>
          </SectionBlock>
        </Col>
      </Row>
    </Fragment>
  );
};

export default BookingFlowReview;
