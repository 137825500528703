import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import CategoryList from 'components/category/category-list';
import FAWrapper from 'components/vendors/fa-wrapper';
import { EventHeader } from 'utils/assets/headers';

const MktOurServicesRoute = (props) => {
  const { history, store, toast } = props;
  const [ categories, setCategories ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, []);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('category', {
        status: 'active',
        sortProp: 'position',
        sortValue: 'asc',
        include: 'services'
      });
      setCategories(model);
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
  	<div className='route'>
      <Head title='Blush - Our Services'/>
      <MktAnnouncement/>
      <MktNav />
      <MktHeader
        size='md'
        title='Our Services'
        bg={EventHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>
      <main className='mkt-main'>

         <section className='mkt-section sm'>
          <div className='mkt-section-body lg'>
            <Container>
              <CategoryList
                type='section'
                categories={categories}
                loading={loading}
                showServices={true}
              />
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>

      <MktFooter />
  	</div>
	);
}

export default withStore(withToast(MktOurServicesRoute));
