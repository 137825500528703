import React, { useEffect, useState } from 'react';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import DatePickerWrapper from 'components/vendors/date-picker-wrapper';
import TimePickerWrapper from 'components/vendors/time-picker-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { inputProp, setRelation } from 'utils/store-helpers';
import { getTimezoneFromLatLong } from 'utils/common/commonFunctions';
import moment from 'moment-timezone';

const BookingFormDetails = (props) => {
  const { booking, isAdmin, prevAction, nextAction } = props;
  const [providers, setProviders] = useState(true);
  const [taskRunning, setTaskRunning] = useState(false);
  const [selectedTimezoneName, setSelectedTimezoneName] = useState("");


  // Methods
  const setDate = (date) => {
    booking.updateStartDate(date);
  }

  const setTime = (date) => {
    booking.updateStartDate(date);
  }

  useEffect(() => {
    getSelectedTimezone();

  }, [booking]);

  const getSelectedTimezone = async () => {
    const timezone = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude, true);
    setSelectedTimezoneName(timezone);
  }

  const getFormattedDate = (date) => {
    try {
      if (date && selectedTimezoneName) {
        const utcMoment = moment.utc(date);
        const mstMoment = utcMoment.tz(selectedTimezoneName || "");
        const gmtStartTimeString = mstMoment?.format('hh:mma');
        return mstMoment;
      }
      return date

    } catch (e) {
      return date
    }
  }
  // Render
  return (
    <SectionBlock className='no-b no-m'>

      <SectionBody className='md'>
        <FormGroup>
          <Label>Select Location</Label>
          <GoogleAutocompleteWrapper
            type='wizard'
            location={booking.location}
            disabled={!isAdmin}
            setLocation={location => setRelation(booking, 'location', location)}
          />
        </FormGroup>

        <Row>
          <Col sm='6'>
            <FormGroup>
              <Label>Select Date</Label>
              <DatePickerWrapper
                type='dropdown'
                date={booking.startDate}
                booking={booking}
                showDropdown={false}
                disabled={!isAdmin}
                setDate={date => setDate(date)}
              />
            </FormGroup>
          </Col>
          <Col sm='6'>
            <FormGroup>
              <Label>Select Time</Label>
              <TimePickerWrapper
                type='dropdown'
                date={!booking?.isUpdate ? getFormattedDate(booking?.startDate) : booking?.startDate}
                booking={booking}
                showDropdown={false}
                disabled={!isAdmin}
                setTime={date => setDate(date)}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label>Special Instructions</Label>
          <Input id='email'
            type='textarea'
            rows='4'
            defaultValue={booking.instructions}
            onChange={p => inputProp(booking, 'instructions', p)}
            placeholder="Please add any special instructions for your stylist such as parking, gate code, etc..."
          />
        </FormGroup>
      </SectionBody>

      <SectionFooter className='flex-between'>
        <PrevButton title='Exit' onClick={prevAction} />
        <NextButton title='Next' taskRunning={taskRunning} onClick={()=> {
          if(!booking?.isUpdate){
            setDate(getFormattedDate(booking?.startDate) )
          }
          nextAction()
        }} />
      </SectionFooter>

    </SectionBlock>
  )
}

export default BookingFormDetails;
