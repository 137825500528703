import React, { Component } from 'react';
import Loadable from '@loadable/component';
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { Button } from 'reactstrap';

const ReactFilestack = Loadable(() => import('filestack-react'), { ssr: false });

const FilestackPhotoWrapper = (props) => {
	const { type = 'user', photo, size = 'lg', width = 1, height = 1, setPhoto } = props;
	const pickerOptions = {
		accept: ['image/*'],
    maxFiles: 1,
    minFiles: 1,
    imageMin: [480, 480],
    imageMax: [1080, 1080],
    imageDim: [1080, 1080],
    uploadInBackground: false,
    transformations: {
    	rotate: true,
    	crop: {
    		force: true,
    		aspectRatio: width/height
    	}
    }
	}


  // Methods
	const photoUploaded = (p) => {
		if(!isEmpty(p.filesUploaded)) {
			let photo = p.filesUploaded[0];
			setPhoto(photo.url);
		}
	}


  // Render
	return (
		<ReactFilestack
			apikey={typeof window == 'undefined' ? '' : window.env.REACT_APP_FILESTACK_KEY}
			actionOptions={pickerOptions}
			onSuccess={p => photoUploaded(p)}
			customRender={({ onPick }) => (
				<Button type='button' color='photo' onClick={onPick}>
        	<div className={'photo-block ' + type + ' ' + size}>
          	{photo ? (
          		<img className='photo-img' src={photo}/>
        		) : (
          		<div className='photo-block-inner'>
            		<FAWrapper iconFam='fad' icon='images' size='2x'/>
            		<small className='mt-3'>Upload Photo</small>
          		</div>
        		)}
        	</div>
      	</Button>
			)}
		/>
	)
}

export default FilestackPhotoWrapper;

// Docs
