import React, { Fragment, useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import RefundFlowProgress from 'components/refund/refund-flow-progress';
import RefundFlowSelect from 'components/refund/refund-flow-select';
import RefundFlowDetails from 'components/refund/refund-flow-details';
import RefundFlowConfirm from 'components/refund/refund-flow-confirm';
import { SectionBlock, SectionTitle, SectionSubtitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Container, Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const RefundFlow = (props) => {
  const { booking, charge = {}, store, toast, modal, toggleModal } = props;
  const [ refund, setRefund ] = useState({});
  const [ view, setView ] = useState('showSelect');

  const steps = [
    {
      name: 'showSelect',
      title: 'Select Charge',
      value: '33.33'
    }, {
      name: 'showDetails',
      title: 'Details',
      value: '66.67'
    }, {
      name: 'showConfirm',
      title: 'Confirmation',
      value: '100'
    }
  ]


  // Hooks
  useEffect(() => {
    createRefund();
  }, []);


  // Methods
  const createRefund = () => {
    let refund = store.createRecord('refund', { status: 'draft' });
    setRefund(refund);
  }


  // Render
  return (
    <SectionBlock className='basic'>
      <SectionTitle className='flex-between'>
        <h4>Issue Refund</h4>
        {modal && <Button color='text-gray' onClick={toggleModal}><FAWrapper icon='times' /></Button>}
      </SectionTitle>
      <SectionSubtitle>
        <RefundFlowProgress steps={steps} step={view}  />
      </SectionSubtitle>
      {view == 'showSelect' && (
        <RefundFlowSelect
          refund={refund}
          booking={booking}
          store={store}
          toast={toast}
          prevAction={() => toggleModal()}
          nextAction={() => setView('showDetails')}
        />
      )}

      {view  == 'showDetails' && (
        <RefundFlowDetails
          refund={refund}
          booking={booking}
          store={store}
          toast={toast}
          prevAction={() => setView('showSelect')}
          nextAction={() => setView('showConfirm')}
        />
      )}

      {view == 'showConfirm' && (
        <RefundFlowConfirm
          refund={refund}
          store={store}
          toast={toast}
          bookingId={booking.id}
          nextAction={() => toggle()}
        />
      )}
    </SectionBlock>
  )
}

export default withStore(withToast(RefundFlow));
