import AppModel from 'models/app';
import Accounting from 'accounting';

class Analytics extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);
		

		// Totals
		this.totalBookings = props.totalBookings;
		this.totalCharges = props.totalCharges;
		this.totalCustomers = props.totalCustomers;
		this.totalPayouts = props.totalPayouts;
		this.totalReviews = props.totalReviews;
		this.avgScore = this.attr('decimal', props.avgScore);

		// Finances
		this.pendingCommissions = props.pendingCommissions;
		this.netCommissions = props.netCommissions;
		this.netTips = props.netTips;
		this.netRefunds = props.netRefunds;
		this.netRevenue = props.netRevenue;
		this.paidRevenue = props.paidRevenue;

		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}


	get displayTotalBookings() {
		return Accounting.formatNumber(this.totalBookings);
	}

	get displayTotalCustomers() {
		return Accounting.formatNumber(this.totalCustomers);
	}

	get displayTotalPayouts() {
		return Accounting.formatNumber(this.totalPayouts);
	}

	get displayTotalReviews() {
		return Accounting.formatNumber(this.totalReviews);
	}


	// Avgs
	get displayAvgScore() {
		return Accounting.formatNumber(this.avgScore);
	}
	

	// Finances
	get displayPendingCommissions() {
		return Accounting.formatMoney(this.pendingCommissions);
	}

	get displayNetCommissions() {
		return Accounting.formatMoney(this.netCommissions);
	}

 	get displayNetTips() {
		return Accounting.formatMoney(this.netTips);
	}

	get displayNetRefunds() {
		return Accounting.formatMoney(this.netRefunds);
	}

	get displayNetRevenue() {
		return Accounting.formatMoney(this.netRevenue);
	}

	get displayPaidRevenue() {
		return Accounting.formatMoney(this.paidRevenue);
	}

}

export default Analytics;