import AppSerializer from 'serializers/app';

class ChargeSerializer extends AppSerializer {
	constructor(store, props) {
		super(store, props);
	}

	get attrs() {
		return {
		}
	}

	get relationships() {
		return {
			booking: { serialize: 'id' },
			card: { serialize: 'id' },
			user: { serialize: 'id' },
		}
	}

}

export default ChargeSerializer;