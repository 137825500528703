import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withSession } from 'contexts/session-context';
import { withToast } from 'contexts/toast-context';
import AuthApi from 'apis/auth-api';
import FacebookBtn from 'components/auth/facebook-btn';
import GoogleBtn from 'components/auth/google-btn';
import LoadingIndicator from 'components/loading/loading-indicator';
import { Row, Col, Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';
import TermsConditionPopup from 'components/auth/terms-condition-popup';
import serializerFor from 'store/serializer-for';
import Axios from 'utils/axios';
import JsonApiError from 'utils/json-api-error';
import adapterFor from 'store/adapter-for';
import LocalStorage from 'local-storage';

const LoginForm = (props) => {
  const { session, toast, flow, toggleView, nextAction,isProviderAuth } = props;
  const [ email, setEmail ] = useState('');
  const [ name, setName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  const [signature, setSignature] = useState('');
  const [userInfo, setUserInfo] = useState({});

  // Methods
  const submitForm = async (data) => {
    try {
      setTaskRunning(true);
  		let user = await AuthApi.login(data);
        if(user?.providerId && (!user?.termsCondition || !user?.signedUrl)){
          LocalStorage.set('token', user.token);
          setUserInfo(user); // handle signature flow
          setTermsConditionModal(true); 
        }else{
          await session.authenticate(user);
          toast.showSuccess('User logged in!', user);
          nextAction(user); 
        }
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }


  const onSignatureUpload = async(sign) => {
    if (sign) {

      const fileData = await base64ToFile(sign);
      console.log(fileData)
      try {
        const baseURL = typeof window === 'undefined' ? '' : window.env.REACT_APP_API_DOMAIN;
        var formdata = new FormData();
        formdata.append("media", fileData, `signature.jpeg`);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(baseURL + "/upload", requestOptions)
          .then(response => response.json())
          .then(result => updateProfile(result?.photo))
          .catch(error => console.log('error', error));

      } catch (e) {
        console.log(e);
      }
    }
  }
  const base64ToFile = async (base64) => {
    const res = await fetch(base64)
    const buf = await res.arrayBuffer()
    const file = new File([buf], "signature.jpeg", {
      type: 'image/jpeg',
    })
    return file;
  };
  const update= async(data,user) => {
		try {
			let url = adapterFor(user?.type).urlForUpdateRecord(user?.type, user?.id);
			let axios = new Axios().instance();
			let response = await axios.put(url, data);
			return response.data;
		} catch(e) {
			throw e;
		}
	}
const updateProfile = async(signatureUrl) => {
  let userData = {
    ...userInfo,
    termsCondition: true,
    signedUrl: signatureUrl,
  }

  try {
    let data = serializerFor('user').serialize(userData);
    let response = await update(data,userData)
    let formattedResponse = serializerFor('user').normalize(response.data, response.included, response.meta);
 
    await session.authenticate(formattedResponse);
    toast.showSuccess('User logged in!', formattedResponse);
    nextAction(formattedResponse); 

  } catch(e) {
    throw JsonApiError.formatErrors(e);
  }
}

	const handleSubmit = (e) => {
		let data = { email: email, password: password };
		submitForm(data);
		e.preventDefault();
	}


	// Render
  return (
    <Form className={!flow ? 'form-block border' : ''} onSubmit={e => handleSubmit(e)}>
      {!flow &&
        <div className='form-title'>
          <h4>Login</h4>
        </div>
      }
      <div className={!flow ? 'form-body xs' : 'form-body xs no-pad'}>

        <div className='subsection-body border-bottom'>
          <Row>
            <Col sm='6'>
              <FacebookBtn
                session={session}
                toast={toast}
                isProviderAuth={isProviderAuth}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
            <Col sm='6'>
              <GoogleBtn
                session={session}
                toast={toast}
                isProviderAuth={isProviderAuth}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
          </Row>
        </div>

        <div className='subsection-hr'>
          <small>Log in via email</small>
        </div>

        <div className='subsection-body'>
          <FormGroup>
            <Label for='user_email'>Email</Label>
            <Input id='user_email'
              type='email'
              value={email}
              placeholder='kate@ck.com'
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for='user_password'>Password</Label>
            <Input id='user_password'
              type='password'
              value={password}
              placeholder='••••••••••'
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>

          <FormGroup className='pt-15'>
            <Link to='/forgot-password' className='btn btn-text-blue'>Forgot Password?</Link>
          </FormGroup>
        </div>

      </div>

      <div className='form-footer'>
        <Button type='submit' color='primary' size='lg' block>
          {taskRunning ? <LoadingIndicator color='white' size='18px' /> : 'Login' }
        </Button>
        <div className='mt-15 p-15 text-center'>
          {flow ? (
            <h6 className='uppercase'>Need an account? <Button color='text-blue' onClick={() => toggleView()}>Register here</Button></h6>
          ) : (
            <h6 className='uppercase'>Need an account? <Link to='/register'>Register here</Link></h6>
          )}
        </div>
      </div>
      <TermsConditionPopup
        isOpen={termsConditionModal}
        onSignature={(img) => {
         setSignature(img)
         onSignatureUpload(img)
        }}
        isCancelHide={true}
        dissmissModal={() => { setTermsConditionModal(false) }} />
    </Form>
  )
}

export default withSession(withToast(LoginForm));
