import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReviewCard from 'components/review/review-card';
import ConfettiWrapper from 'components/vendors/confetti-wrapper';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { CartTitle } from 'components/blocks/cart-blocks';
import { SectionBlock, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import Empty from 'components/misc/empty';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button } from 'reactstrap';

const ReviewFlowConfirm = (props) => {
  const { review, booking, history, session, store, toast, prevAction, nextAction } = props;


	// Render
  return (
    <SectionBlock>
      <ConfettiWrapper />
      <CartTitle className='p-4 primary-bg white-color'>
        <h4>Review Confirmed!</h4>
        <h6 className='light'>We've notified your stylist</h6>
      </CartTitle>
      <SectionBody className='md'>
        <Empty
          type='index'
          icon='comment-lines'
          title='Nice work!'
          msg='Your review has been posted and no further action is required. Would you like to rebook again?'>
          <Link to='/book' className='btn btn-text-primary btn-lg'>
            Book Again<FAWrapper icon='chevron-right' className='ml-15'/>
          </Link>
        </Empty>
      </SectionBody>
      <SectionFooter className='flex-between'>
        <span></span>

        <NextButton title='Visit Profile' onClick={nextAction}
        />

      </SectionFooter>
    </SectionBlock>
  )
}

export default withRouter(ReviewFlowConfirm);
