import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import LoadingIndicator from 'components/loading/loading-indicator';

const Empty = (props) => {
	const { type = 'index', icon, iconFam = 'fad', iconSize = '2x', title, msg, loading, className = '', children } = props;


	// Render
	return (
		<div className={'empty-block ' + type}>
			<FAWrapper icon={icon} iconFam={iconFam} size={iconSize} className='img'/>
			<h5 className='title'>{title}</h5>
			<h6>{msg}</h6>
			<div className='mt-15'>
				{loading && <LoadingIndicator />}
				{children}
			</div>
    </div>
	)
}

export default Empty;
