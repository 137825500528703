import React from 'react';
import MaskedInput from 'react-input-mask';
import FAWrapper from 'components/vendors/fa-wrapper';


const DobMaskedInput = (props) => {
  const { name, value, placeholder, readOnly, onChange, className = '' } = props;

  return (
    <MaskedInput
      mask='99/99/9999'
      maskChar={null}
      name={name}
      defaultValue={value}
      readOnly={readOnly}
      className='form-control'
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

const SsnMaskedInput = (props) => {
  const { name, value, placeholder, readOnly, onChange, className = '' } = props;

  return (
    <MaskedInput
      mask='999-99-9999'
      maskChar={null}
      name={name}
      defaultValue={value}
      readOnly={readOnly}
      className='form-control'
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

const PhoneMaskedInput = (props) => {
  const { name, value, placeholder, readOnly, onChange, className = '' } = props;

  return (
    <MaskedInput
      mask='(999) 999-9999'
      maskChar={null}
      name={name}
      defaultValue={value}
      readOnly={readOnly}
      className='form-control'
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}


export  { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput };

// Docs
// https://github.com/sanniassin/react-input-mask
