import React from 'react';
import { CardGridCard, CardPreviewCard } from 'components/card/card-card';
import FAWrapper from 'components/vendors/fa-wrapper';
import Empty from 'components/misc/empty';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const CardList = (props) => {
  const { type = 'grid', cards = [], nextAction, loading, className = '' } = props;


  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  else if (cards.length > 0) {
    return (
      <Row>
        {type == 'preview' && cards.map(card => (
          <Col md='12' key={card.id}>
            <CardPreviewCard card={card} loading={loading} nextAction={nextAction} />
          </Col>
        ))}
        {type == 'grid' && cards.map(card => (
          <Col sm='6' key={card.id}>
            <CardGridCard card={card} loading={loading} nextAction={nextAction} />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='credit-card'
      title='Credit Cards'
      msg="Sorry, it looks like you don't have any credit cards on file."
    />
  )
}

export default CardList;
