import React, { Fragment, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import GridCard from 'components/cards/grid-card';
import LicenseForm from 'components/license/license-form';
import LoadingIndicator from 'components/loading/loading-indicator';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col, Badge, Button, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const LicenseGridCardWrapper = (props) => {
  const { license = {}, store, toast, loading, nextAction, className = '' } = props;
  const [ modal, toggleModal ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


	// Methods
	const destroyLicense = async (license) => {
		try {
      setTaskRunning(true);
      await license.destroy();
      toast.showSuccess('License destroyed!');
      nextAction();
		} catch(e) {
			toast.showError(e);
		} finally {
      setTaskRunning(false);
		}
	}

  const refreshData = () => {
    toggleModal(!modal);
    nextAction();
  }


	// Render
  return (
    <Fragment>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)} className='sm'>
        <LicenseForm modal={modal} model={license} refreshData={refreshData} toggleModal={() => toggleModal(!modal)}/>
      </Modal>
      <GridCard
        photo={license.url}
        title={license.displayLicenseType}
        line1={license.fileName}>
        <Button type='button' color='text-gray' size='xs' onClick={() => toggleModal(!modal)}>
          Edit
        </Button>
        <Button type='button' color='text-danger' size='xs' onClick={() => destroyLicense(license)}>
          {taskRunning ? <LoadingIndicator color='danger' size='18px' /> : 'Delete'}
        </Button>
      </GridCard>
    </Fragment>
  )
}

const LicenseGridCard = withStore(withToast(LicenseGridCardWrapper));

export { LicenseGridCard }
