import React from 'react';
import { Link } from 'react-router-dom';
import LineCard from 'components/cards/line-card';
import PreviewCard from 'components/cards/preview-card';
import SelectCard from 'components/cards/select-card';
import ListCard from 'components/cards/list-card';
import GridCard from 'components/cards/grid-card';
import DetailCard from 'components/cards/detail-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const UserLineCard = (props) => {
  const { user = {}, loading, className = '' } = props;

	return (
		<LineCard
			photo={user.photo}
			title={user.name}
			line1={user.email}
			line2={user.displayPhone}
			className={className}
		/>
	)
}

const UserListCard = (props) => {
  const { user = {}, loading, className = '' } = props;

	return (
		<ListCard
			photo={user.photo}
			title={user.name}
			line1={user.email}
			line2={user.displayPhone}
			link={user.id == session.userId && user.url}
			className={className}
		/>
	)
}

const UserPreviewCard = (props) => {
  const { user = {}, loading, className = '' } = props;

	return (
		<PreviewCard
			photo={user.photo}
			title={user.name}
			line1={user.email}
			line2={user.displayPhone}
			className={className}
		/>
	)
}

const UserDetailCard = (props) => {
  const { user = {}, loading, className = '' } = props;
	return (
		<DetailCard
			photo={user.photo}
			title={user.name}
			line1={user.email}
			line2={user.displayPhone}
			timestamp={user.displayUpdatedAt}
			className={className}>
			<div className='mt-2'>
				<Link to={user.settingsUrl} className='btn btn-text-blue btn-xs'>
					View Settings<FAWrapper icon='chevron-right' className='ml-15' />
				</Link>
			</div>
		</DetailCard>
	)
}

export { UserLineCard, UserListCard, UserPreviewCard, UserDetailCard };
