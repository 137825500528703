import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TableHeader from 'components/misc/table-header';
import StarRatingWrapper from 'components/vendors/star-rating-wrapper';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Table, Button, Badge } from 'reactstrap';


const ReviewTable = (props) => {
  const { reviews = [], providerId, sortProp, sortValue, sortList, loading, refreshData, className = '' } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type='table'/>
  }
  return (
    <Table className='index' responsive>
      <thead>
        <tr>
          <TableHeader text='ID' value='id' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Customer' value='status' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Score' value='amount' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Comment' value='bank' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
          <TableHeader text='Created At' value='createdAt' sortProp={sortProp} sortValue={sortValue} sortList={sortList}/>
        </tr>
      </thead>
      <tbody>
        {reviews.map(review => (
          <tr key={review.id}>
            <td>
              <Link className='td' to={review.url}>{review.id}</Link>
            </td>
            <td>
              <Link className='td' to={review.url}><img src={review.user && review.user.photo} className='avatar-img sm' /></Link>
            </td>
            <td>
              <Link className='td' to={review.url}><StarRatingWrapper rating={review.score} size='20px' /></Link>
            </td>
            <td>
              <Link className='td' to={review.url}>{review.previewComment}</Link>
            </td>
            <td>
              <Link className='td' to={review.url}>{review.displayCreatedAt}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}


export default ReviewTable;
