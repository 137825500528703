import React from 'react';
import { Link } from 'react-router-dom';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktProviderPrefooter from 'components/mkt/mkt-provider-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import MktColumn from 'components/mkt/mkt-column';
import MktCollapse from 'components/mkt/mkt-collapse';
import MktStat from 'components/mkt/mkt-stat';
import MktPricing from 'components/mkt/mkt-pricing';
import MktComparisonTable from 'components/mkt/mkt-comparison-table';
import ProviderLoginBtn from 'components/provider/provider-login-btn';
import FAWrapper from 'components/vendors/fa-wrapper';
import Work from 'assets/headers/work.jpg';
import Dashboard from 'assets/browsers/dashboard.png';
import { Container, Row, Col } from 'reactstrap';

const MktForStylistsRoute = (props) => {
  const { history } = props;


  return (
  	<div className='route'>
      <Head title='Blush - Blush Pro'/>
      <MktAnnouncement/>
      <MktNav/>
      <MktHeader
        size='lg'
        title='Become a Blush Pro'
        subtitle="Join our team of Blush Pros! You are only a few steps away from enhancing / elevating your beauty career!"
        bg={Work}>
        <ProviderLoginBtn className='btn btn-primary btn-lg'/>
      </MktHeader>

      <main className='mkt-main'>

        <section className='mkt-section'>
          <div className='mkt-section-body md flex'>
            <Container>
                <Row className='flex'>

                  <Col md={{size: 7, order: 2}}>
                    <img className='mkt-section-browser animated fadeInRight' src={Dashboard} />
                  </Col>

                  <Col md={{size: 5, order: 1}}>
                    <div className='mkt-section-content'>
                      <div className='mkt-section-title'>
                        <h2>Build Your Brand</h2>
                        <hr/>
                      </div>
                      <h6 className='p animated fadeInUp delay-200ms'>It’s hard work building in a commodity type business. Why work so hard when you have us to
                      help! Join blush today and we'll connect you with opportunities that can lead to many recurring visits and relationships that last.</h6>
                      <ProviderLoginBtn className='btn btn-primary btn-lg  animated fadeInLeft delay-400ms' />
                    </div>
                  </Col>

                </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body sm flex'>
            <Container>
                <Row className='flex'>

                  <Col md='6'>
                    <div className='mkt-section-title'>
                      <h2>How it Works</h2>
                      <hr/>
                    </div>
                    <h6 className='p'>You won’t believe how fast and easy it is to start your own business with Blush. In a few minutes,
                    you can build your profile, set your schedule and once approved, you can start accepting customers!
                    Stop working so hard to find new clients, let Blush help you increase your income and build a career!</h6>
                  </Col>

                  <Col md='6'>
                    <div className='mkt-bullet-block'>
                      <h1>1.</h1>
                      <h3>Register Today</h3>
                    </div>
                    <div className='mkt-bullet-block'>
                      <h1>2.</h1>
                      <h3>Verify Your Account</h3>
                    </div>
                    <div className='mkt-bullet-block'>
                      <h1>3.</h1>
                      <h3>Start Accepting Bookings</h3>
                    </div>
                  </Col>

                </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Why Blush</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body lg flex'>
            <Container>
                <Row className='flex'>

                  <Col md={{size: 5, order: 2}}>
                    <h6 className='p animated fadeInUp'>We believe in the value of each and every one of our beauty professionals. For over a decade,
                    our leadership team has developed and implemented systems for beauty professionals that have led to many
                    six figure incomes.  We understand how to train, inspire and provide the necessary tools to be successful in
                    the beauty industry.</h6>
                    <h6 className='p animated fadeInUp delay-200ms'>Our mission, vision and values have allowed our Service Providers to thrive in the greatest
                    industry in the world. We will help you improve guest retention, create World Class customer service skills and
                    provide tools to build on your beauty expertise.</h6>
                  </Col>

                  <Col md={{size: 7, order: 1}}>
                    <MktStat
                      type='basic'
                      title='Colorado'
                      stat='$26,766'
                      percent={30}
                      description='The average stylist in Colorado makes $26,766 according to salary.com'
                      className='animated fadeInLeft'
                    />
                    <MktStat
                      type='basic'
                      title='United States'
                      stat='$26,846'
                      percent={30}
                      description='The average stylist in the United States makes $26,846 according to salary.com'
                      className='animated fadeInLeft delay-200ms'
                    />
                    <MktStat
                      type='styled'
                      title='Blush Professional'
                      stat='$54,569'
                      percent={75}
                      description='The average stylist on Blush has the potential to make $54,569 or more on a full-time basis!'
                      className='animated fadeInLeft delay-600ms'
                    />

                  </Col>

                </Row>
            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-body sm flex'>
            <Container>
                <Row className='flex'>

                  <Col md='5'>
                    <div className='mkt-section-title'>
                      <h2>What we do</h2>
                      <hr/>
                    </div>
                    <h6 className='p'>
                      Having your own beauty business and working behind the chair is extremely challenging.
                      Not only are there time constraints but the cost of rent, utilities, marketing and other expenses add up.
                      Blush creates a platform for you with minimal business expenses allowing you to do what you love while putting more money in your pocket!
                    </h6>
                  </Col>

                  <Col md='7'>
                    <MktComparisonTable
                      type='basic'
                      className='animated fadeInRight delay-200ms'
                    />
                  </Col>

                </Row>
            </Container>
          </div>
        </section>


        <section className='mkt-section'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Frequently Asked Questions</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body lg flex-top'>
            <Container>

                <MktCollapse isOpen={true} icon='question-mark' title='How it works' className='animated fadeInUp'>
                  <h6>
                    It’s simple. Create a profile and complete the Getting Started guide. Once completed,
                    you are ready to accept your first booking!
                  </h6>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='Can I set my own schedule?' className='animated fadeInUp delay-100ms'>
                  <h6>Absolutely! One of the greatest perks of Blush is you create your own schedule.</h6>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='How do I get paid?' className='animated fadeInUp delay-200ms'>
                  <h6>Once a booking is completed, funds will be transferred to your Blush account. You have two options: Manual Deposit or Automatic Deposit straight into your bank account.</h6>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='What if I need to cancel a booking?' className='animated fadeInUp delay-300ms'>
                  <h6>
                    You are able to manage your bookings in your Blush Professional account. Each client,
                    however, expects our Blush Professionals to maintain a high level of reliability.
                    Cancelling at the last minute can jeopardize your ability to use your Blush Pro account.
                  </h6>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='What services can I offer?' className='animated fadeInUp delay-400ms'>
                  <h6>Currently you have the ability to offer Blowouts, Updos and Make-Up Applications!</h6>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='What products and tools do I use?' className='animated fadeInUp delay-400ms'>
                  <h6>Our clients expect a certain level of expertise from our Pros, not only from a skills standpoint but the products used as well. Below is a guide of products and tools we recommend our Blush Pros to use during our services:</h6>
                  <Row>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Babyliss</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Hot Tools</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Bio Ionic</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Kerastase</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Oribe</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>R+Co</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>RedKen</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Pureology</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>L'Oreal Professional</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Joico</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Bumble + Bumble</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Kevin Murphy</h6>
                    </Col>
                    <Col sm='6' md='4' lg='3'>
                      <h6 className='bullet'>Moroccan Oil</h6>
                    </Col>
                  </Row>
                </MktCollapse>

                <MktCollapse isOpen={true} icon='question-mark' title='Do I have to carry my own insurance?' className='animated fadeInUp delay-400ms'>
                  <h6>Yes, each Blush Professional must carry a minimum of $2,000,000 Liability Insurance. If you do not have liability insurance, below are some resources to help get you started.</h6>
                </MktCollapse>

            </Container>
          </div>
        </section>

        <section className='mkt-section'>
          <div className='mkt-section-title'>
            <Container>
              <h2>Fair &amp; Transparent Pricing</h2>
              <hr/>
            </Container>
          </div>
          <div className='mkt-section-body md flex'>
            <Container>
                <Row>

                  <Col md='6'>
                    <MktPricing
                      type=''
                      value='70'
                      title='Commission Fee'
                      subtitle='per transaction'
                      description="Join Blush today and earn 70% commission on every booking you receive via our marketplace."
                    />
                    <h6>*For any events booked through our group bookings, you will receive 60%. </h6>
                  </Col>

                  <Col md='6'>
                    <MktPricing
                      type=''
                      value='30'
                      title='Service Fee'
                      subtitle='per transaction'
                      description="This covers our operating costs and gives us some room to promote your expertise with the world."
                    />
                  </Col>

                </Row>
            </Container>
          </div>
        </section>

        <MktProviderPrefooter />

			</main>
      <MktFooter/>
  	</div>
	);
}

export default MktForStylistsRoute;
