import AppModel from 'models/app';
import Accounting from 'accounting';
import Moment from 'moment';

class Payout extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.status = props.status;
		this.amount = props.amount;
		this.failMsg = props.failMsg;
		this.descriptor = props.descriptor;


		// Relationships
		this.bank = this.belongsTo('bank', props.bank);
		this.provider = this.belongsTo('provider', props.provider);
	}

	// Computed
	get isDraft() {
		return this.status == 'draft'
	}

	get isPending() {
		return this.status == 'pending'
	}

	get isSucceeded() {
		return this.status == 'succeeded'
	}

	get isFailed() {
		return this.status == 'failed'
	}

	get isPaid() {
		return this.status == 'paid'
	}

	get isCanceled() {
		return this.status == 'canceled'
	}

	get displayAmount() {
		return Accounting.formatMoney(this.amount);
	}

	get displayTotal() {
		return Accounting.formatMoney(this.amount);
	}

	get url() {
		return this.provider.id ? `/admin/${this.provider.id}/payouts/${this.id}` : `payouts/${this.id}`;
	}
}

export default Payout;
