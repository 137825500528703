import React from 'react';
import LoadingIndicator from 'components/loading/loading-indicator';
import { isEmpty, timeout } from 'utils/helpers';
import { Container, Row } from 'reactstrap';

const AdminIntro = (props) => {
	const { title, message } = props;

	return (
		<main className='loading-overlay'>
			<div className='flex'>
				<Container className='xs'>
					<div className='loading-block'>
						<LoadingIndicator type='circle' size='60px' color='primary'/>
						<div className='loading-content'>
							<h5 className='title'>{title}</h5>
							<h6 className='subtitle ml-4'>{message}</h6>
						</div>
					</div>
				</Container>
			</div>
		</main>
	)

}

export default AdminIntro;
