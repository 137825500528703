import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Fade, Tooltip, UncontrolledTooltip } from 'reactstrap';

const TooltipWrapper = (props) => {
	const { target, placement, trigger = 'hover', children } = props;
	const [ open, setOpen ] = useState(false);

	const toggle = () => setOpen(!open);

	if (typeof window == 'undefined') return null
	return (
		<Tooltip isOpen={open} toggle={toggle} trigger={trigger} target={target} offset={50} fade={false} placement={placement}>
			{children}
		</Tooltip>
	)
}

export { TooltipWrapper }

// Docs
// https://reactstrap.github.io/components/tooltips/
