import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { CategorySectionCard, CategorySelectCard } from 'components/category/category-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col } from 'reactstrap';


const CategoryList = (props) => {
  const { type = 'section', categories = [], showServices, selectedIDs, onClick, loading, className = '' } = props;

  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>

  } else if (categories.length > 0) {
    return (
      <Row>
        {type == 'section' && categories.map(category => (
          <Col xs='12' key={category.id}>
            <CategorySectionCard
              type={type}
              category={category}
              showServices={category.title == 'Hair Services'}
              selectedIDs={selectedIDs}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'select' && categories.map(category => (
          <Col xs='12' key={category.id}>
            <CategorySelectCard
              category={category}
              bookingFlow={props?.bookingFlow}
              editBooking={props?.editBooking}
              booking={props?.booking}
              showServices={category.title == 'Hair Services'}
              selectedIDs={selectedIDs}
              onClick={onClick}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='cut'
      title='Categories'
      msg="Sorry, it looks like we don't have any categories on file yet."
    />
  )
}


export default CategoryList;
