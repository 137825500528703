import AppModel from 'models/app';

class Bank extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Create Attrs
		this.name = props.name; // ???
		this.account = props.account; // ???
		this.routing = props.routing; // ???
		

		// Attrs
		this.token = props.token;
		this.accountHolderName = props.accountHolderName;
		this.bankName = props.bankName;
		this.lastFour = props.lastFour;
		this.expMonth = props.expMonth;
		this.expYear = props.expYear;
		this.country = props.country;
		this.currency = props.currency;
		this.default = props.default;


		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}


	// Computed
	get summary() {
		return this.bankName  + ' ••••' + this.lastFour.toString();
	}

	get shortSummary() {
		return '•••• ' + this.lastFour.toString();
	}

	get displayCurrency() {
		return this.currency.toUpperCase();
	}

}

export default Bank;