import React, { Fragment, useState } from "react";
import Loadable from "@loadable/component";
import { withStore } from "contexts/store-context";
import { withToast } from "contexts/toast-context";
import { LoadingSkeleton } from "components/loading/loading-skeleton";
import { Modal, Row, Col } from "reactstrap";
import { isEmpty } from "utils/helpers";
import { useEffect } from "react";

const FullCalendarWrapper = Loadable(() =>
  import("components/vendors/full-calendar-wrapper")
);

const ScheduleCalendar = (props) => {
  const {
    type,
    schedule = {},
    provider,
    loading,
    store,
    toast,
    selectDate,
  } = props;

  console.log("schedule", schedule);

  // Render
  if (loading) {
    return <LoadingSkeleton type={"calendar"} count={12} />;
  }
  return (
    <FullCalendarWrapper
      type={type}
      model={schedule}
      data={schedule.calendarData}
      clickedDate={(v) => selectDate(v)}
      clickedEvent={(v) => selectDate(v)}
    />
  );
};

export default withStore(withToast(ScheduleCalendar));
