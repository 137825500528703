import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import FAWrapper from 'components/vendors/fa-wrapper';
import StarRatingWrapper from 'components/vendors/star-rating-wrapper';
import ReviewList from 'components/review/review-list';
import { PrevButton, TextButton } from 'components/bootstrap/button-wrapper';
import { ModalBlock, ModalHeader, ModalHeaderImg, ModalBody, ModalFooter } from 'components/basics/modals';
import { Container, Row, Col, Badge, ButtonGroup, Button, Input } from 'reactstrap';
import { timeout, isEmpty } from 'utils/helpers';

const ProviderPreview = (props) => {
  const { model = {}, store, toast, toggleModal, children } = props;
  const [ view, setView ] = useState('details');
  const [ provider, setProvider ] = useState({});
  const [ reviews, setReviews ] = useState([]);
  const [ loading, setLoading ] = useState(false);



  // Hooks
  useEffect(() => {
    fetchData();
    return () => setView('details');
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let provider = await store.queryRecord('provider', model.id, { include: 'photos' });
      setProvider(provider);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false)
    }
  }

  const fetchReviews = async () => {
    try {
      setLoading(true);
      let model = await store.query('review', {
        providerId: provider.id,
        include: 'user'
      });
      await timeout(300);
      setReviews(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false)
    }
  }

  const updateView = (viewName) => {
    if (viewName == 'reviews') fetchReviews();
    setView(viewName)
  }


  // Render
  return (
    <ModalBlock>
      {view == 'reviews' && (
        <Fragment>
          <ModalHeader toggleModal={toggleModal}>
            <div className='flex-start'>
              <PrevButton onClick={() => updateView('details')} />
              <h5 className='modal-title ml-2'>Reviews</h5>
            </div>
          </ModalHeader>
          <ModalBody className='md'>
            <ReviewList reviews={reviews} loading={loading} />
          </ModalBody>
        </Fragment>
      )}
      {view == 'details' && (
        <Fragment>
          <ModalHeaderImg>
            <img alt={provider.name} src={provider.photo}/>
            <TextButton color='white' onClick={toggleModal}><FAWrapper icon='times'/></TextButton>
          </ModalHeaderImg>

          <ModalBody>
            <Badge color={provider.status} pill>{provider.status}</Badge>
            <h4 className='title'>{provider.name}</h4>
            <h6 className='flex-start'>
              <StarRatingWrapper rating={provider.avgScore}/>
              <TextButton color='gray' onClick={() => updateView('reviews')} className='underline ml-2'>{provider.totalReviews} Reviews</TextButton>
            </h6>
            <h6>{provider.location && provider.location.cityState}</h6>
            <p>{provider.description}</p>
            <Row>
              {provider.photos && provider.photos.map(photo => (
                <Col key={photo.id} xs='6' sm='6' md='4'>
                  <img src={photo.photo} alt={photo.title} className='thumbnail sm mb-15'/>
                </Col>
              ))}
            </Row>
          </ModalBody>
        </Fragment>
      )}
      {children && (
        <ModalFooter className='flex-between'>
          {children}
        </ModalFooter>
      )}
    </ModalBlock>
  )
}

export default withStore(withToast(ProviderPreview));
