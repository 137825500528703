import React, { useEffect, useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import PromoForm from 'components/promo/promo-form';
import PromoList from 'components/promo/promo-list';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Button, Modal } from 'reactstrap';
import { isEmpty } from 'utils/helpers';


const PromoSection = (props) => {
  const { type = 'list', user, store, toast, className = '' } = props;
  const [ promos, setPromos ] = useState([]);
  const [ status, setStatus ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [page, pageSize, status])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('promo', params());
      setPromos(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (status) params.status = status;
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    return params;
  }

  const refreshData = () => {
    toggleModal(!modal);
    fetchData();
  }


  // Render
  return (
    <SectionBlock>
      <Modal isOpen={modal}>
        <PromoForm
          user={user}
          modal={modal}
          toggleModal={() => toggleModal(!modal)}
          nextAction={() => refreshData()}
        />
      </Modal>

      <SectionTitle title='Promotions' className='flex-between'>
        <Button color='primary' size='md' onClick={() => toggleModal(!modal)}>Add Promotion</Button>
      </SectionTitle>
      <SectionBody className='md'>
        <PromoList
          type={type}
          promos={promos}
          loading={loading}
          className={className}
        />
      </SectionBody>
      <SectionFooter>
        <PaginationWrapper meta={promos.meta} setPage={v => setPage(v)} />
      </SectionFooter>
    </SectionBlock>
  );
}

export default withStore(withToast(PromoSection));
