import AppAdapter from 'adapters/app';

class ProviderAdapter extends AppAdapter {
  constructor(props) {
    super(props)
  }

	static userId = '';
	static providerId = '';
	static action = '';


	// Overrides
	static urlForQuery(modelName) {
		let url = super.urlForQuery(...arguments);

		if (this.action == 'availability') {
			return this.buildURL() + '/available-providers';
		}

		if (this.action == 'proximity') {
			return this.buildURL() + '/nearby-providers';
		}

		return url;
	}


	static urlForQueryRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);

		if(this.action == 'balance') {
			return url + '/balance';
		}

		if(this.action == 'payout') {
			return url + '/payout';
		}

		return url;
	}

 	static urlForUpdateRecord(modelName, id) {
		let url = super.urlForQueryRecord(...arguments);

		if(this.action == 'pending') {
			return url + '/pending';
		}

		if(this.action == 'activate') {
			return url + '/activate';
		}

		return url;
	}

}

export default ProviderAdapter;
