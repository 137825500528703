import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import PayoutFilter from 'components/payout/payout-filter';
import PayoutTable from 'components/payout/payout-table';
import PayoutForm from 'components/payout/payout-form';
import PaginationWrapper from 'components/bootstrap/pagination-wrapper';
import { NextButton } from 'components/bootstrap/button-wrapper';
import { SectionBlock, SectionTitle, SectionSubtitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Breadcrumb, BreadcrumbItem, Modal } from 'reactstrap';

const AdminPayoutsIndexRoute = (props) => {
  const { history, store, toast, providerId, provider } = props;
  const [ payouts, setPayouts ] = useState([]);
  const [ status, setStatus ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ pageSize, setPageSize ] = useState(10);
  const [ sortProp, setSortProp ] = useState('id');
  const [ sortValue, setSortValue ] = useState('desc');
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [status, page, pageSize, sortProp, sortValue]);


  // Methods
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.query('payout', params());
      setPayouts(model)
    } catch(e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }

  const params = () => {
    let params = {};
    if (status) params.status = status;
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortProp) params.sortProp = sortProp;
    if (sortValue) params.sortValue = sortValue;
    params.include = 'bank,provider'
    return params;
  }

  const sortData = (sortProp, sortValue) => {
    setSortProp(sortProp);
    setSortValue(sortValue);
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Payouts'>
      <Modal isOpen={modal} toggle={() => toggleModal(!modal)}>
        <PayoutForm
          provider={provider}
          refreshData={() => refreshData()}
          toggleModal={() => toggleModal(!modal)}
        />
      </Modal>
      <SectionBlock>
        <SectionTitle title='Payouts' className='flex-between'>
          <NextButton title='New Payout' onClick={() => toggleModal(!modal)} />
        </SectionTitle>

          <SectionSubtitle className='flex-start'>
            <PayoutFilter
              type='status'
              value={status}
              options={['all', 'draft', 'pending', 'failed', 'paid', 'canceled']}
              setFilter={(p, v) => setStatus(v)}
              className='ml-3'
            />
          </SectionSubtitle>

        <SectionBody className='md'>
          <PayoutTable
            payouts={payouts}
            sortProp={sortProp}
            sortValue={sortValue}
            sortList={(p, v) => sortData(p, v)}
            loading={loading}
            refreshData={() => fetchData()}
          />
        </SectionBody>
        <SectionFooter>
          <PaginationWrapper meta={payouts.meta} setPage={v => setPage(v)} />
        </SectionFooter>
      </SectionBlock>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminPayoutsIndexRoute)));

