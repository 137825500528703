import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { RefundPreviewCard } from 'components/refund/refund-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { isEmpty } from 'utils/helpers';
import { Row, Col } from 'reactstrap';

const RefundList = (props) => {
  const { type = 'preview', refunds = [], loading, className = '' } = props;

  // Render

  if (loading) {
    return <LoadingSkeleton type={type}/>

  }
  if (refunds.length > 0) {
    return (
      <Row>
        {type == 'preview' && refunds.map((refund, index) => (
          <Col md='12' key={refund.id}>
            <RefundPreviewCard
              refund={refund}
              loading={loading}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='money-bill-wave'
      title='Refunds'
      msg="Sorry, it looks like we don't have any refunds on file yet."
    />
  )
}

export default RefundList;
