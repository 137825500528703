import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { AdminRoute } from 'components/basics/routes';
import ProviderForm from 'components/provider/provider-form';
import { Breadcrumb, BreadcrumbItem, Container, Row } from 'reactstrap';

const AdminSettingsProfileRoute = (props) => {
  const { history, providerId, provider } = props;

  // Render
  return (
    <AdminRoute title='Blush Admin - Profile'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/settings`}>Settings</Link></BreadcrumbItem>
        <BreadcrumbItem active>Profile</BreadcrumbItem>
      </Breadcrumb>
      <ProviderForm provider={provider} nextAction={() => history.push(`/admin/${provider.id}/settings`)}/>
    </AdminRoute>
  );
}

export default withAdmin(AdminSettingsProfileRoute);

