import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withPixel } from 'contexts/pixel-context';
import { withSession } from 'contexts/session-context';
import { withToast } from 'contexts/toast-context';
import AuthApi from 'apis/auth-api';
import LoadingIndicator from 'components/loading/loading-indicator';
import { DobMaskedInput, SsnMaskedInput, PhoneMaskedInput } from 'components/vendors/masked-input-wrapper';
import FacebookBtn from 'components/auth/facebook-btn';
import GoogleBtn from 'components/auth/google-btn';
import { Row, Col, Form, FormGroup, FormText, Label, Input, Button, CustomInput } from 'reactstrap';
import TermsConditionPopup from './terms-condition-popup';
import moment from 'moment/moment';

const RegisterForm = ({ flow, pixel, session, toast, toggleView, nextAction,isProviderAuth }) => {
  const [taskRunning, setTaskRunning] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  const [termsConditionAccept, setTermsConditionAccept] = useState(false);
  const [signature, setSignature] = useState('');


  // Methods
  const submitForm = async (data) => {
    try {
      setTaskRunning(true);
      let user = await AuthApi.register(data);
      user = await session.authenticate(user);
      toast.showSuccess('User registered!', user);
      pixel.trackEvent('CompleteRegistration', user, { content_name: 'Email' });
      nextAction(user.id);
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const base64ToFile = async (base64) => {
    const res = await fetch(base64)
    const buf = await res.arrayBuffer()
    const file = new File([buf], "signature.jpeg", {
      type: 'image/jpeg',
    })
    console.log(file)
    return file;
  };

  const onRegister = (url) => {
    let data = {
      name: firstName + ' ' + lastName,
      email: email,
      phone: phone,
      password: password,
      signedUrl: url
    };
    submitForm(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isProviderAuth && isSignatureAdded() && termsConditionAccept) {
      const fileData = await base64ToFile(signature);
      console.log(fileData)
      try {
        const baseURL = typeof window === 'undefined' ? '' : window.env.REACT_APP_API_DOMAIN;
        const time = moment()
        var formdata = new FormData();
        formdata.append("media", fileData, `signature.jpeg`);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(baseURL + "/upload", requestOptions)
          .then(response => response.json())
          .then(result => onRegister(result?.photo))
          .catch(error => console.log('error', error));

      } catch (e) {
        console.log(e);
      }
    } else if(isProviderAuth){
      toast.showError([{ detail: 'Please agree to terms of service.' }]);
    }else{
      let data = {
        name: firstName + ' ' + lastName,
        email: email,
        phone: phone,
        password: password,
      };
      submitForm(data);
    }

  }

  const isSignatureAdded = () => {
    return signature && signature !== ''
  }
  const onTermsConditionCheckboxClick = () => {
    if (isSignatureAdded()) {
      // setTermsConditionAccept(!termsConditionAccept)
    } else {
      toast.showError([{ detail: 'Please agree to terms of service.' }]);
    }
  }

  // Render
  return (
    <Form className={!flow ? 'form-block border' : ''} onSubmit={e => handleSubmit(e)}>
      {!flow &&
        <div className='form-title'>
          <h4>Register</h4>
        </div>
      }
      <div className={!flow ? 'form-body xs' : 'form-body xs no-pad'}>
        {/* 
        <div className='subsection-body border-bottom'>
          <Row>
            <Col sm='6'>
              <FacebookBtn
                session={session}
                toast={toast}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
            <Col sm='6'>
              <GoogleBtn
                session={session}
                toast={toast}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
          </Row>
        </div> */}

        <div className='subsection-hr'>
          <small>Register via email</small>
        </div>

        <div className='subsection-body no-border'>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for='first'>First Name</Label>
                <Input id='first'
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder='Kate'
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for='last'>Last Name</Label>
                <Input id='last'
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder='Moss'
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for='email'>Email</Label>
            <Input id='email'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='kate@ck.com'
            />
          </FormGroup>

          <FormGroup>
            <Label for='phone'>Phone</Label>
            <PhoneMaskedInput id='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder='(303) 444-2226'
            />
          </FormGroup>

          <FormGroup>
            <Label for='password'>Password</Label>
            <Input id='password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='••••••••'
            />
          </FormGroup>

          {
            isProviderAuth ?
              <FormGroup className='pt-15'
                style={{
                  flexDirection: 'row',
                  display: 'flex'
                }}>
                <CustomInput
                  type='checkbox'
                  id='terms'
                  // disabled
                  checked={termsConditionAccept}
                  onChange={() => onTermsConditionCheckboxClick()}
                />
                <i>Please creating an account, you agree to our <i onClick={() => { setTermsConditionModal(true) }}
                  style={{
                    cursor: 'pointer',
                    color: '#007bff'
                  }}>Terms of Service</i>.</i>
                {/* <i>Please creating an account, you agree to our <Link to='/terms-of-service'>Terms of Service</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.</i> */}
              </FormGroup> : null
          }
        </div>

      </div>

      <div className='form-footer'>
        <Button type='submit' color='primary' size='lg' block>
          {taskRunning ? <LoadingIndicator size='18' color='white' /> : 'Register'}
        </Button>

        <div className='subsection-body border-bottom'>
          <Row>
            <Col sm='6'>
              <FacebookBtn
                session={session}
                toast={toast}
                isProviderAuth={isProviderAuth}
                signature={signature}
                isDisable={isProviderAuth ? (!isSignatureAdded() || !termsConditionAccept): false}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
            <Col sm='6'>
              <GoogleBtn
                session={session}
                toast={toast}
                isProviderAuth={isProviderAuth}
                signature={signature}
                isDisable={isProviderAuth ? !isSignatureAdded() || !termsConditionAccept: false}
                nextAction={userId => nextAction(userId)}
                className='mb-15'
              />
            </Col>
          </Row>
        </div>
        <div className='mt-15 p-15 text-center'>
          {flow ? (
            <h6 className='uppercase'>Already have an account? <Button color='text-blue' onClick={() => toggleView()}>Login here</Button></h6>
          ) : (
            <h6 className='uppercase'>Already have an account? <Link to='/login'>Login here</Link></h6>
          )}
        </div>
      </div>
      <TermsConditionPopup
        isOpen={termsConditionModal}
        onSignature={(img) => {
          setSignature(img)
          setTermsConditionAccept(true)
        }}
        dissmissModal={() => { setTermsConditionModal(false) }} />
    </Form>
  )
}

export default withSession(withToast(withPixel(RegisterForm)));
