import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { CardElement } from '@stripe/react-stripe-js';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Form, FormGroup, FormText, Label, Input, Button,  Modal } from 'reactstrap';
import { CardOptions, ElementOptions, FontOptions } from 'utils/stripe-helpers';


const CardForm = (props) => {
  const { user, elements, store, toast, stripe, modal, toggleModal, nextAction } = props;
  const [ name, setName ] = useState('')
  const [ taskRunning, setTaskRunning ] = useState(false);


  // Tasks
  const saveForm = async () => {
    try {
      setTaskRunning(true);
      let cardElement = elements.getElement(CardElement);
	  	let token = await stripe.createToken(cardElement, { name: name });
      let card = store.createRecord('card', { token: token.token.id, user: user });
      await card.save();
      toast.showSuccess('Card saved!');
      nextAction(card);
    } catch(e) {
    	toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveForm();
  }


  // Render

  return (
    <Form className='form-block' onSubmit={handleSubmit}>
      <div className='form-title flex-between'>
        <h4>Add Card</h4>
        {modal && <Button type='button' color='text-gray' onClick={toggleModal}><FAWrapper icon='times'/></Button>}
      </div>
      <div className='form-body'>
        <FormGroup>
          <Label for='user_name'>Name on Card</Label>
          <Input id='user_name'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Kate Moss'
          />
        </FormGroup>
        <FormGroup>
          <Label for='user_name'>Card Info</Label>
          <CardElement options={CardOptions} />
        </FormGroup>
      </div>

      <div className='form-footer flex-between'>
        <h6><FAWrapper icon='lock' className='mr-15 small'/>This form is secure</h6>
        <Button type='submit' color='primary' size='md'>
          {taskRunning ? <LoadingIndicator color='white' size='18px' /> : 'Save' }
        </Button>
      </div>
    </Form>
  )
}

export default withStore(withToast(CardForm));
