import React, { UseState } from 'react';
import { Row, Col, Badge, ButtonGroup, Button } from 'reactstrap';
import ActivityCard from 'components/cards/activity-card';

const ActivityActivityCard = (props) => {
	const { activity = {}, loading, className = '' } = props;

	return (
		<ActivityCard
			icon={activity.icon}
			title={activity.title}
			description={activity.shortDescription}
			timestamp={activity.displayUpdatedAt}
			loading={loading}
			className={className}
		/>
	)
}

export { ActivityActivityCard };
