import LodashArray from 'lodash/array';
import RandomString from 'randomstring';

export const timeout = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const alias = (data) => {
	return data;
}

export const min = (data) => {
	return Math.min(...data);
}

export const max = (data) => {
	return Math.max(...data);
}

export const sum = (data) => {
	return data.reduce((a, b) => a + b, 0);
}

export const diff = (data) => {
	return data[0].filter(d => !data[1].includes(d));
}

export const avg = (data) => {
	return arr.reduce((a,b) => a + b, 0) / arr.length;
}

export const addObject = (array, record) => {
	let newArray = !array.includes(record) ? array.push(record) : array;
	return array;
}

export const removeObject = (array, record) => {
	let newArray = LodashArray.pull(array, record);
	return newArray;
}

export const truncate = (data, count, ellipsis = false) => {
	if (ellipsis && data.length >= count) {
		return data ? data.slice(0, count) + '...' : null;
	}
	return data ? data.slice(0, count) : null;
}

export const capitalize = (value) => value[0].toUpperCase() + value.slice(1);

export const isEmpty = (data) => {
	if (data == null || data == undefined || data == 'undefined') {
		return true
	}
	if (Array.isArray(data)) {
		return data.length == 0 ? true : false;
	}
	if (data.constructor == Object) {
	 return Object.keys(data) == 0 ? true : false;
	}
	return data.length == 0 ? true : false;
}

export const logger = (...data) => {
	if(window.env.REACT_APP_NODE_ENV == 'development') {
		console.log(...data)
	}
}

export const randomString = (count = 7) => {
	return RandomString.generate(count);
}