import React from 'react';
import { Link } from 'react-router-dom';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge } from 'reactstrap';

const ListCard = (props) => {
	const { status, icon, photo, title, subtitle, line1, line2, link, className = '', children } = props;


	// Render
	return (
		<Link to={link}>
			<div className={'card list ' + className}>
				<div className='flex-start'>
					<div className={icon ? 'card-header icon preview mr-15' : 'card-header preview mr-15'}>
						{icon && <FAWrapper icon={icon} iconFam='fad' size='2x' className='card-header-icon'/>}
						{photo && <img className='card-header-img' src={photo} />}
					</div>
					<div className='flex-between w-100'>
						<div className='card-body'>
							{status && <Badge color={status} className='sm mb-2' pill>{status}</Badge>}
							{title && <h5 className='title'><b>{title}</b></h5>}
							{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
							{line1 && <h6>{line1}</h6>}
							{line2 && <h6>{line2}</h6>}
							{children}
						</div>
						<div><FAWrapper icon='chevron-right' className='btn-text-gray' /></div>
					</div>
				</div>
			</div>
		</Link>
	)

}

export default ListCard;
