import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Head from 'components/basics/head';
import MktAnnouncement from 'components/mkt/mkt-announcement';
import MktNav from 'components/navs/mkt-nav';
import MktHeader from 'components/mkt/mkt-header';
import MktConsumerPrefooter from 'components/mkt/mkt-consumer-prefooter';
import MktFooter from 'components/mkt/mkt-footer';
import FAWrapper from 'components/vendors/fa-wrapper';
import { MakeupHeader } from 'utils/assets/headers';


const MktCovidRoute = (props) => {
  const { history } = props;

  return (
  	<div className='route'>
      <Head title='Blush - Covid-19 Response'/>
      <MktAnnouncement />
      <MktNav />
      <MktHeader
        size='md'
        title='Covid-19 Response'
        bg={MakeupHeader}>
        <div className='mt-4'>
          <Link to='/book' className='btn btn-primary btn-lg'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
        </div>
      </MktHeader>

      <main className='mkt-main'>

        <section className='mkt-section'>
          <div className='mkt-section-body xs flex'>
            <Container className='md'>

              <div className='mkt-section-content'>
                <p>
                  First and foremost, the health and safety of you and our Professionals
                  is our main concern. If you have been experiencing any symptoms of
                  sickness, we ask that you push out your service until you are well again.
                </p>
                <p>
                  Arrival | Your Blush Professional will arrive at your appointment time and ring/knock on
                  the door. Once they enter, please provide them a place for them to wash their hands.
                </p>

                <p>
                  Sanitation | All our Blush Pros’ tools are sanitized in between appointments.
                </p>
                <p>
                  Masks | Our Blush Pros will always arrive in a mask. We recommend for our clients to
                  wear a mask for the safety of both you and the Professional.
                </p>
                <p>
                  Checking Out | Our check-out process is touchless. You will be charged after your
                  service and have the opportunity to leave gratuity and review on your own device.
                </p>
                <p>
                  If you have any further questions regarding our precautions with COVID-19, please <Link to='/contact'>contact support</Link>.
                </p>
                <Link to='/book' className='btn btn-primary'>Book Now<FAWrapper icon='chevron-right' className='sm ml-15'/></Link>
              </div>
            </Container>
          </div>
        </section>

        <MktConsumerPrefooter />

			</main>
      <MktFooter />
  	</div>
	);
}

export default MktCovidRoute;
