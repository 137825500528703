import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SwiperWrapper from "components/vendors/swiper-wrapper";
import MktHeroSlide from "components/mkt/mkt-hero-slide";
import FAWrapper from "components/vendors/fa-wrapper";
import { HpHero } from "utils/assets/headers";
import { Container, Row, Col, Button, Alert } from "reactstrap";
import Axios from "utils/axios";

const MktHero = (props) => {
  const { type, className = "" } = props;
  const [showAlert, dismissAlert] = useState(true);
  const [bgUrl, setBgUrl] = useState(null);

  useEffect(() => {
    getBannerVideoDetails();
  }, []);

  const getBannerVideoDetails = async () => {
    try {
      let axios = new Axios().instance();
      let response = await axios.get(`/banner`, null);
      console.log("response", response);

      console.log(response?.data?.data?.[0]?.attributes?.photo, "photo");
      setBgUrl(response?.data?.data?.[0]?.attributes?.photo);
    } catch (e) {
      throw toast.showError(e);
    }
  };

  return (
    <header className={"mkt-hero-block" + " " + type + " " + className}>
      <video
        style={{
          objectFit: "fill",
        }}
        autoPlay
        muted
        loop
        className="mkt-bg-video"
        src={bgUrl}
      ></video>
      {/* <div
        className="mkt-hero-bg xl"
        style={{ backgroundImage: `url(${HpHero}` }}
      >
        <div className="mkt-hero-overlay"></div>
      </div> */}
      {/* <div className="mkt-hero-content-container">
        <Container>
          <Row>
            <Col lg="7">
              <div className="mkt-hero-content">
                <h2>Welcome to Blush</h2>
                <h5 className="p">
                  Blush is an on location hair and makeup platform that connects
                  you with vetted and certified Beauty Professionals who bring
                  their services directly to you.
                </h5>
                <div className="mt-4">
                  <Link to="/book" className="btn btn-primary btn-lg">
                    Book Now
                    <FAWrapper icon="chevron-right" className="sm ml-15" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="mkt-hero-book-btn-container">
        <Link to="/book" className="btn btn-primary btn-lg">
          Book Now
          <FAWrapper icon="chevron-right" className="sm ml-15" />
        </Link>
      </div>
    </header>
  );
};

export default MktHero;
