import React, { Fragment } from 'react';
import CardSelect from 'components/card/card-select';
import BookingFlowPreview from 'components/booking/booking-flow-preview';
import { BookingSummaryCard, BookingInfoCard } from 'components/booking/booking-card';
import { LocationInfoCard } from 'components/location/location-card';
import { CustomerPreviewCard } from 'components/customer/customer-card';
import { ProviderPreviewCard } from 'components/provider/provider-card';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import { PreviewBlock, PreviewTitle, PreviewBody, PreviewFooter } from 'components/blocks/preview-blocks';
import Empty from 'components/misc/empty';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';

const BookingFormReview = (props) => {
  const { booking, isAdmin, taskRunning, prevAction, nextAction } = props;

	return (
    <Row>
      <Col md={{ size: 4, order: 2}} className='d-none d-md-block'>
      	<SectionBlock className='p-15 no-b no-s'>
	        <BookingFlowPreview
	          type='preview'
	          booking={booking}
			  orderSummary={true}
	          changeStep={step => changeStep(step)}
	        />
	        <div className='mt-15'>
	          <NextButton
	            icon='check'
	            title='Save Changes'
	            taskRunning={taskRunning}
	            onClick={() => nextAction()}
	            className='btn-block btn-lg'
	          />
	        </div>
        </SectionBlock>
      </Col>
      <Col md={{ size: 8, order: 1}}>
			  <SectionBlock className='mt-15 no-b no-s'>
					<SectionBody className='md no-p'>

						<PreviewBlock className='bb'>
	            <PreviewTitle title='Location' className='flex-between' />
	            <PreviewBody>
	              <LocationInfoCard location={booking.location} className='p-15' />
	            </PreviewBody>
	          </PreviewBlock>

	          <PreviewBlock className='bb'>
	            <PreviewTitle title='Scheduling' className='flex-between' />
	            <PreviewBody>
	              <BookingInfoCard booking={booking} className='p-15' />
	            </PreviewBody>
	          </PreviewBlock>

	          {isAdmin ? (
		          <PreviewBlock className='bb'>
		            <PreviewTitle title='Customer' className='flex-between' />
		            <PreviewBody>
		              <CustomerPreviewCard customer={booking.customer} />
		            </PreviewBody>
		          </PreviewBlock>
	          ) : (
		          <Fragment>
		            <PreviewBlock className='bb'>
		              <PreviewTitle title='Provider' className='flex-between' />
		              <PreviewBody>
		                <ProviderPreviewCard provider={booking.provider} />
		              </PreviewBody>
		            </PreviewBlock>

		            <PreviewBlock className='bb'>
		              <PreviewTitle title='Billing' className='flex-between' />
		              <PreviewBody>
		                <CardSelect type='preview' booking={booking} />
		              </PreviewBody>
		            </PreviewBlock>
	            </Fragment>
            )}

	          <PreviewBlock className='no-b'>
	            <PreviewTitle title='Notes' className='flex-between' />
	            <PreviewBody>
	              <FormGroup>
	                <Input id='email'
	                  type='textarea'
	                  rows='4'
	                  defaultValue={booking.instructions}
	                  onChange={p => inputProp(booking, 'instructions', p)}
	                  placeholder="Please add any special instructions for your stylist such as parking, gate code, etc..."
	                />
	              </FormGroup>
	            </PreviewBody>
	          </PreviewBlock>

					</SectionBody>

					<SectionFooter className='flex-between'>
						<PrevButton title='Back' onClick={prevAction} />
						<NextButton title='Next' taskRunning={taskRunning} onClick={() => nextAction()} />
					</SectionFooter>

				</SectionBlock>
			</Col>
		</Row>
	)
}

export default BookingFormReview;
