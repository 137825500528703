import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withAdmin } from 'contexts/admin-context';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { AdminRoute } from 'components/basics/routes';
import CustomerActions from 'components/customer/customer-actions';
import CustomerStats from 'components/customer/customer-stats';
import BookingSection from 'components/booking/booking-section';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { CustomerDetailCard } from 'components/customer/customer-card';
import { LocationInfoCard } from 'components/location/location-card';
import { SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { Row, Col, Badge, ButtonGroup, Button, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const AdminCustomersDetailRoute = (props) => {
  const { customerId, providerId, history, provider, store, toast } = props;
  const [ customer, setCustomer ] = useState({});
  const [ modal, toggleModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  // Hooks
  useEffect(() => {
    fetchData();
  }, [])


  // Tasks
  const fetchData = async () => {
    try {
      setLoading(true);
      let model = await store.queryRecord('customer', customerId, { include: 'provider,location' });
      setCustomer(model);
    } catch (e) {
      toast.showError(e);
    } finally {
      setLoading(false);
    }
  }


  // Render
  return (
    <AdminRoute title='Blush Admin - Customer Detail'>
      <Breadcrumb>
        <BreadcrumbItem><Link to={`/admin/${providerId}/customers`}>Customers</Link></BreadcrumbItem>
        <BreadcrumbItem active>Customer #{customer.id}</BreadcrumbItem>
      </Breadcrumb>

      <SectionBlock>
        <SectionBody className='flex-between'>
          <h4 className='title'>Customer #{customerId}</h4>
          {customer.id && (
            <CustomerActions
              type='detail'
              customer={customer}
              provider={provider}
              loading={loading}
            />
          )}
        </SectionBody>
      </SectionBlock>

      <Row>
        <Col md='4'>
          <CustomerDetailCard
            type='detail'
            providerId={providerId}
            customer={customer}
            loading={loading}
          />

          <SectionBlock>
            <SectionTitle title='Stats'/>
            <SectionBody>
              <CustomerStats customer={customer} loading={loading}/>
            </SectionBody>
          </SectionBlock>
        </Col>

        <Col md='8'>

          <SectionBlock>
            <SectionTitle title='Location' />
            <SectionBody className='sm' >
              <LocationInfoCard location={customer.location} loading={loading} />
            </SectionBody>
          </SectionBlock>

          <BookingSection customerId={customerId} include='services' />

        </Col>
      </Row>
    </AdminRoute>
  );
}

export default withAdmin(withStore(withToast(AdminCustomersDetailRoute)));
