import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { isEmpty } from 'utils/helpers';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal } from 'reactstrap';

const PayoutActions = (props) => {
  const { type = 'detail', payout = {}, provider, store, toast, loading, refreshData } = props;
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);


	// Methods
	const archivePayout = async () => {
    try {
      if (taskRunning) { return; }
      setTaskRunning(true);
      await payout.destroy();
      type == 'detail' ? history.push(`/admin/${provider.id}/payouts`) : refreshData();
    } catch(e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false)
    }
	}


	// Methods
	const confirmArchive = (payout) => {
		let confirmed = confirm(`Are you sure you'd like to archive this payout? This will remove the payout from your dashboard.`)
		if(confirmed) archivePayout();
	}



  // Render
  return (
    <DropdownWrapper isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>

      {type == 'detail' && (
        <DropdownToggle color='primary' size='md'>
          {taskRunning ? <LoadingIndicator color='gray' size='18px'/> : <span>Manage<FAWrapper icon='chevron-down' className='ml-2 sm'/></span>}
        </DropdownToggle>
      )}

      {type == 'table' && (
        <DropdownToggle color='icon-gray'>
          {taskRunning ? <LoadingIndicator color='gray' size='18px'/> : <FAWrapper icon='chevron-down'/>}
        </DropdownToggle>
      )}

      <DropdownMenuWrapper isOpen={dropdownOpen} right={true}>

        <DropdownItem tag='li'>
          <Link className='btn' to={payout.url}>View</Link>
        </DropdownItem>

        <DropdownItem tag='li'>
          <Button color='transparent' onClick={() => confirmArchive()}>Archive</Button>
        </DropdownItem>
      </DropdownMenuWrapper>

    </DropdownWrapper>
  )
}


export default withStore(withToast(PayoutActions));
