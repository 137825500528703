import React, { Component } from 'react';

const ExternalLink = (props) => {
	const { to, text, className = '', children } = props;

	return (
		<a href={to} target='_blank' className={className}>
			{children ? children : text}
		</a>
	)

}

export default ExternalLink;
