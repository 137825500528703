import React, { useEffect, useState } from 'react';
import { withPixel } from 'contexts/pixel-context';
import { withSession } from 'contexts/session-context';
import AuthApi from 'apis/auth-api';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { logger } from 'utils/helpers';
import { Button } from 'reactstrap';
import TermsConditionPopup from './terms-condition-popup';
import serializerFor from 'store/serializer-for';
import Axios from 'utils/axios';
import JsonApiError from 'utils/json-api-error';
import adapterFor from 'store/adapter-for';
import LocalStorage from 'local-storage';

const FacebookBtn = ({ pixel, session, toast, nextAction, className = '', isDisable, signature, isProviderAuth }) => {
  const [taskRunning, setTaskRunning] = useState(false);
  const [termsConditionModal, setTermsConditionModal] = useState(false);
  const [signatureData, setSignatureData] = useState('');
  const [userInfo, setUserInfo] = useState({});


  // Hooks
  useEffect(() => {
    initFacebook();
  }, [])
  const base64ToFile = async (base64) => {
    const res = await fetch(base64)
    const buf = await res.arrayBuffer()
    const file = new File([buf], "signature.jpeg", {
      type: 'image/jpeg',
    })
    console.log(file)
    return file;
  };
  const update = async (data, user) => {
    try {
      let url = adapterFor(user?.type).urlForUpdateRecord(user?.type, user?.id);
      let axios = new Axios().instance();
      let response = await axios.put(url, data);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
  const updateProfile = async (signatureUrl) => {
    let userData = {
      ...userInfo,
      termsCondition: true,
      signedUrl: signatureUrl,
    }

    try {
      let data = serializerFor('user').serialize(userData);
      let response = await update(data, userData)
      let formattedResponse = serializerFor('user').normalize(response.data, response.included, response.meta);
      user = await session.authenticate(formattedResponse);
      toast.showSuccess('User logged in!');
      pixel.trackUser('CompleteRegistration', formattedResponse, { content_name: 'FacebookAuth' });
      nextAction(formattedResponse.id);
    } catch (e) {
      throw JsonApiError.formatErrors(e);
    }
  }
  const uploadSignature = async (data, isSignIn, sign) => {
    if (signature || sign) {
      const fileData = await base64ToFile(signature || sign);
      console.log(fileData)
      try {
        const baseURL = typeof window === 'undefined' ? '' : window.env.REACT_APP_API_DOMAIN;
        var formdata = new FormData();
        formdata.append("media", fileData, `signature.jpeg`);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(baseURL + "/upload", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (isSignIn) {
              updateProfile(result?.photo)
            } else {
              facebookAuthAPI(data, result?.photo, isSignIn)
            }
          })
          .catch(error => console.log('error', error));

      } catch (e) {
        console.log(e);
      }
    }
  }

  const facebookAuthAPI = async (data, signURL, isSignIn) => {
    try {
      setTaskRunning(true);
      let formattedData = { accessToken: data.accessToken, facebookId: data.userID, signedUrl: signURL };
      let user = await AuthApi.facebookAuth(formattedData);
      if (user?.providerId && (!user?.termsCondition || !user?.signedUrl)) {
        setUserInfo(user);
        setTermsConditionModal(true)
      } else {
        user = await session.authenticate(user);
        toast.showSuccess('User logged in!');
        pixel.trackUser('CompleteRegistration', user, { content_name: 'FacebookAuth' });
        nextAction(user.id);
      }
    } catch (e) {
      toast.showError(e);
    } finally {
      setTaskRunning(false);
    }
  }

  // Methods
  const facebookAuth = async (data) => {
    if (isProviderAuth && signature) {
      uploadSignature(data, false);
    } else {
      facebookAuthAPI(data, null, true)
    }
  }

  const initFacebook = () => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: typeof window === 'undefined' ? '' : window.env.REACT_APP_FB_APP_ID,
        cookie: false,
        xfbml: true,
        version: 'v6.0'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  const facebookLogin = () => {
    if (!isDisable) {
      if (checkStatus() == true) {
        facebookAuth(response.authResponse)
      } else {
        FB.login(function (response) {
          logger("User is connected.");
          facebookAuth(response.authResponse);
        }, { scope: 'public_profile,email' });
      }
    } else if (isProviderAuth) {
      toast.showError([{ detail: 'Please agree to terms of service.' }]);
    }
  }

  const checkStatus = () => {
    FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        logger("User is connected.");
        return true;
      }
      logger("User is not connected.");
      return false;
    });
  }

  const fetchUser = () => {
    logger('Welcome!  Fetching your information... ');
    FB.api('/me', response => {
      logger('Successful login for: ' + response.name);
    });
  }


  // Render
  return (
    <div>
      <Button type='button' color='facebook' size='lg' className={className} onClick={facebookLogin} block>
        {taskRunning ? <LoadingIndicator size='18' color='white' /> : <span><FAWrapper iconFam='fab' icon='facebook-f' className='mr-15' /> Login</span>}
      </Button>
      <TermsConditionPopup
        isOpen={termsConditionModal}
        onSignature={(img) => {
          setSignatureData(img)
          uploadSignature({}, true, img)
        }}
        isCancelHide={true}
        dissmissModal={() => { setTermsConditionModal(false) }} />
    </div>
  )
}


export default withSession(withPixel(FacebookBtn));

