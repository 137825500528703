import React, { Component } from 'react';
import Empty from 'components/misc/empty';
import { ServiceLineCard, ServiceListCard, ServiceMktCard, ServicePreviewCard, ServiceSelectCard } from 'components/service/service-card';
import { LoadingSkeleton } from 'components/loading/loading-skeleton';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'utils/helpers';

const ServiceList = (props) => {
  const { type = 'list', services = [], selectedIDs, loading, showAction, onClick, className = '', isMultipleService = false, booking, orderSummary = false } = props;


  // Render
  if (loading) {
    return <LoadingSkeleton type={type}/>
  }
  if (services.length > 0) {
    return (
      <Row>
        {type == 'line' && services.map(service => (
          <Col md='12' key={service.id}>
            <ServiceLineCard
              service={service}
              selectedIDs={selectedIDs}
              showAction={showAction}
              onClick={onClick}
              className={className}
              booking={booking}
              isMultipleService={isMultipleService}
              orderSummary={orderSummary}
            />
          </Col>
        ))}
        {type == 'list' && services.map((service, index) => (
          <Col md='12' key={service.id}>
            <ServiceListCard
              service={service}
              selectedIDs={selectedIDs}
              onClick={onClick}
              className={className + services.length == index + 1 && 'last'}
            />
          </Col>
        ))}
        {type == 'mkt' && services.map(service => (
          <Col xs='6' sm='6' md='6' lg='3' key={service.id}>
            <ServiceMktCard
              service={service}
              selectedIDs={selectedIDs}
              onClick={onClick}
            />
          </Col>
        ))}
        {type == 'preview' && services.map(service => (
          <Col md='12' key={service.id}>
            <ServicePreviewCard
              service={service}
              selectedIDs={selectedIDs}
              onClick={onClick}
              className={className}
              showLocalData={props?.isAdmin || props?.bookingFlow}
            />
          </Col>
        ))}
        {type == 'select' && services.map((service, index) => (
          <Col md='12' key={service.id}>
            <ServiceSelectCard
              service={service}
              selectedIDs={selectedIDs}
              onClick={onClick}
              className={className + services.length == index + 1 && 'last'}
              showLocalData={props?.isAdmin || props?.bookingFlow}
              editBooking={props?.editBooking}
              booking={props?.booking}
            />
          </Col>
        ))}
      </Row>
    )
  }
  return (
    <Empty
      type={type}
      icon='cut'
      title='Services'
      msg="Sorry, it looks like we don't have any services on file yet."
    />
  )
}


export default ServiceList;
