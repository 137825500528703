import AppModel from 'models/app';
import { truncate } from 'utils/helpers';

class Photo extends AppModel {
	constructor(type, store, props = {}) {
		super(type, store, props);


		// Attrs
		this.photo = props.photo;
		this.title = props.title;
		this.description = props.description;
		this.position = this.attr('integer', props.position);


		// Relationships
		this.provider = this.belongsTo('provider', props.provider);
	}

	get displayPosition() {
		return parseFloat(this.position);
	}

	get previewDescription() {
		return this.description ? truncate(this.description, 60, true) : '--';
	}

	get displayDescription() {
		return this.description ? this.description : '--';
	}

}

export default Photo;
