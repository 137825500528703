import React from 'react';
import { Link } from 'react-router-dom';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Row, Col, Badge, Button } from 'reactstrap';

const GridCard = (props) => {
	const { status, icon, photo, map, title, subtitle, line1, line2, className = '', children } = props;


	// Render
	return (
		<div className={'card grid ' + className}>
			<div className='card-header'>
				{icon && <FAWrapper icon={icon} iconFam='fad' size='2x' className='card-header-img'/>}
				{photo && <img className='card-header-img' src={photo} />}
				{map && <GoogleMapWrapper className='card-header-img md' model={map}/>}
			</div>
			<div className='card-body'>
				{status && <Badge color={status} className='md' pill>{status}</Badge>}
				{title && <h5 className='title'>{title}</h5>}
				{subtitle && <h6 className='subtitle'>{subtitle}</h6>}
				{line1 && <h6>{line1}</h6>}
				{line2 && <h6>{line2}</h6>}
			</div>
			<div className='card-footer'>
				{children}
			</div>
		</div>
	)
}

export default GridCard;
